<template>
  <v-card-actions class="py-3">
    <div class="d-flex align-center justify-space-between" style="width: 100%">
      <div class="caption">
        <div v-if="!isOnline" class="d-flex align-center">
          <v-icon class="mr-1" color="gray">
            {{ mdiCheckUnderlineCircle }}
          </v-icon>
          <div>
            <span class="py-0 my-0 pr-1">Offline</span>|
            <span v-if="isUpdatingFeatureService">
              Updating Feature Service...
            </span>
            <span v-else-if="isAddingToRelatedTables">
              Adding to Related Table...
            </span>
            <span v-else-if="isUpdatingDataAfterSubmit">
              Updating app data...
            </span>
            <LastSaved
              v-else
              :lastSavedTime="lastSavedTime"
              :alreadySubmittedFinalOnline="alreadySubmittedFinalOnline"
              :isSaving="isSaving"
              :submittingFinalForm="submittingFinalForm"
            />
          </div>
        </div>
        <div v-else class="d-flex align-center">
          <v-icon class="mr-1" color="orange darken-3">
            {{ mdiLightningBoltCircle }}
          </v-icon>
          <div>
            <span class="py-0 my-0 pr-1">Online</span>|
            <span v-if="isUpdatingFeatureService">
              Updating Feature Service...
            </span>
            <span v-else-if="isAddingToRelatedTables">
              Adding to Related Table...
            </span>
            <span v-else-if="isUpdatingDataAfterSubmit">
              Updating App Data...
            </span>
            <LastSaved
              v-else
              :lastSavedTime="lastSavedTime"
              :alreadySubmittedFinalOnline="alreadySubmittedFinalOnline"
              :isSaving="isSaving"
              :submittingFinalForm="submittingFinalForm"
            />
          </div>
        </div>
      </div>

      <div class="d-flex align-center px-1">
        <div class="d-flex">
          <v-btn
            @click="$emit('invalid-fields-info-dialog-show')"
            text
            x-small
            v-if="invalidFields && invalidFields.length > 0"
            class="px-0 mx-0"
          >
            <v-icon color="orange darken-3">
              {{ mdiAlertCircle }}
            </v-icon>
          </v-btn>
        </div>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              v-bind="attrs"
              v-on="on"
              x-small
              class="px-0 mx-0"
              :disabled="!canEdit"
            >
              <v-icon color="#3F51B5">{{ mdiDotsVertical }}</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              class="px-4 mx-0"
              v-if="!alreadySubmittedFinalOnline"
              @click="
                $emit('auto-save');
                $emit('refresh-log');
              "
              id="save-button"
            >
              <v-icon class="mr-2">
                {{ mdiFile }}
              </v-icon>
              Save Draft
            </v-list-item>

            <v-list-item
              class="px-4 mx-0"
              @click="$emit('form-info-dialog-show')"
            >
              <v-icon class="mr-2">
                {{ mdiInformation }}
              </v-icon>
              Form Submission ID
            </v-list-item>

            <v-list-item
              class="px-4 mx-0"
              @click="$emit('delete-form-result')"
              id="delete-button"
            >
              <v-icon class="mr-2">
                {{ mdiDelete }}
              </v-icon>
              Delete Form
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn
          elevation="2"
          type="submit"
          class="white--text"
          :disabled="!canEdit || submittingFinalForm || isSaving"
          color="#3F51B5"
          id="submitFormBtn"
        >
          Submit
        </v-btn>
      </div>
    </div>
  </v-card-actions>
</template>

<script>
import {
  mdiDotsVertical,
  mdiAlertCircle,
  mdiInformation,
  mdiLightningBoltCircle,
  mdiCheckUnderlineCircle,
  mdiDelete,
  mdiFile,
} from "@mdi/js";
import LastSaved from "@/components/tickets/ticket-edit-form/dynamic-form/dynamic-form-normal-actions/LastSaved";

export default {
  name: "SharedSiteDynamicFormNormalActions",
  props: {
    lastSavedTime: String,
    canEdit: Boolean,
    isSaving: Boolean,
    isOnline: Boolean,
    invalidFields: Array,
    alreadySubmittedFinalOnline: Boolean,
    submittingFinalForm: Boolean,
    isUpdatingFeatureService: Boolean,
    isAddingToRelatedTables: Boolean,
    isUpdatingDataAfterSubmit: Boolean,
  },
  components: {
    LastSaved,
  },
  data() {
    return {
      mdiDotsVertical,
      mdiAlertCircle,
      mdiInformation,
      mdiLightningBoltCircle,
      mdiCheckUnderlineCircle,
      mdiDelete,
      mdiFile,
    };
  },
};
</script>
