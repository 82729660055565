<template>
  <v-card width="100%" elevation="0">
    <v-card-text width="526px">
      <v-row>
        <v-col cols="12" class="d-flex align-center justify-space-between">
          <div>
            {{ item.question.label }}
          </div>
          <div>
            <v-btn
              text
              color="#3F51B5"
              @click.stop="showRepeatingGroupDialog = true"
              :disabled="isDisabled"
            >
              + Add
            </v-btn>
          </div>
        </v-col>

        <v-col cols="12" class="pa-0 ma-0">
          <v-card class="px-0 mx-0">
            <v-card-text class="px-0 mx-0">
              <v-responsive
                style="overflow-x: auto"
                width="100%"
                max-width="100%"
              >
                <v-simple-table>
                  <thead>
                    <tr>
                      <td v-for="h of tableHeaders" :key="h.id">
                        <template v-if="h.information">
                          <div
                            v-if="isHtml(h.information.label)"
                            class="raw-html"
                            v-html="h.information.label"
                          ></div>
                          <b v-else>{{ h.information.label }}</b>
                        </template>
                        <template v-else-if="h.label">
                          <div
                            v-if="isHtml(h.label)"
                            class="raw-html"
                            v-html="h.label"
                          ></div>
                          <b v-else>{{ h.label }}</b>
                        </template>
                      </td>
                      <td></td>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      v-for="(v, index) of tableValues"
                      :key="index"
                      @click.stop="
                        selectedValueIndex = index;
                        showEditRepeatingGroupDialog = true;
                        selectedValueItems = groupValues[index];
                      "
                    >
                      <td v-for="h of tableHeaders" :key="h.id">
                        <div
                          v-if="h.type === 'TEXT' && h.isLong"
                          v-html="v[h.id]"
                          class="raw-html"
                        ></div>
                        <div v-else>
                          {{ v[h.id] }}
                        </div>
                      </td>
                      <td>
                        <div class="d-flex justify-end align-center">
                          <v-btn
                            icon
                            @click.stop="
                              selectedValueIndex = index;
                              showEditRepeatingGroupDialog = true;
                              selectedValueItems = groupValues[index];
                            "
                          >
                            <v-icon>
                              {{ mdiPencil }}
                            </v-icon>
                          </v-btn>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-responsive>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <SharedSiteRepeatingGroupDialog
        v-if="showRepeatingGroupDialog"
        :showRepeatingGroupDialog="showRepeatingGroupDialog"
        :item="item"
        :readOnly="isDisabled"
        :formResultId="formResultId"
        :selectedMapServiceId="selectedMapServiceId"
        :globalId="globalId"
        :objectId="objectId"
        @close-form="showRepeatingGroupDialog = false"
        @form-saved="onFormSaved"
        :formDefinition="formDefinition"
        :canEdit="canEdit"
        :isEditingFinalForm="isEditingFinalForm"
        :alreadySubmittedFinalOnline="alreadySubmittedFinalOnline"
        :selectedGisInfo="selectedGisInfo"
      />

      <SharedSiteEditRepeatingGroupDialog
        v-if="showEditRepeatingGroupDialog"
        :showEditRepeatingGroupDialog="showEditRepeatingGroupDialog"
        :item="item"
        :selectedValueItems="selectedValueItems"
        :selectedValueIndex="selectedValueIndex"
        :readOnly="isDisabled"
        :formResultId="formResultId"
        :formDefinition="formDefinition"
        :canEdit="canEdit"
        :isEditingFinalForm="isEditingFinalForm"
        :alreadySubmittedFinalOnline="alreadySubmittedFinalOnline"
        :selectedGisInfo="selectedGisInfo"
        :globalId="globalId"
        :objectId="objectId"
        @close-form="showEditRepeatingGroupDialog = false"
        @form-saved="onEditFormSaved"
        @delete-item="deleteItem"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiPencil } from "@mdi/js";
import SharedSiteRepeatingGroupDialog from "@/components/shared-site/shared-site-ticket-form-def-drop-down/shared-site-ticket-edit-form/shared-site-dynamic-form/shared-site-repeating-group/SharedSiteRepeatingGroupDialog";
import SharedSiteEditRepeatingGroupDialog from "@/components/shared-site/shared-site-ticket-form-def-drop-down/shared-site-ticket-edit-form/shared-site-dynamic-form/shared-site-repeating-group/SharedSiteEditRepeatingGroupDialog";
import { cloneDeep } from "lodash";

export default {
  name: "SharedSiteRepeatingGroup",
  props: {
    item: Object,
    formDefinition: Object,
    value: Array,
    canEdit: Boolean,
    formResultId: String,
    alreadySubmittedFinalOnline: Boolean,
    isEditingFinalForm: Boolean,
    selectedMapServiceId: String,
    objectId: Number,
    globalId: String,
    selectedGisInfo: Object,
  },
  components: {
    SharedSiteRepeatingGroupDialog,
    SharedSiteEditRepeatingGroupDialog,
  },
  watch: {
    value(val) {
      if (Array.isArray(val)) {
        this.groupValues = cloneDeep(val);
      }
    },
  },
  beforeMount() {
    if (Array.isArray(this.value)) {
      this.groupValues = cloneDeep(this.value);
    }
  },
  methods: {
    isHtml(value) {
      return Boolean(value?.match?.(/<[^>]*>/g));
    },
    onFormSaved(savedValues) {
      this.groupValues.push(savedValues);
      this.showRepeatingGroupDialog = false;
      this.$emit("input", this.groupValues);
    },
    onEditFormSaved(savedValues) {
      this.$set(this.groupValues, this.selectedValueIndex, [...savedValues]);
      this.selectedValueIndex = undefined;
      this.showEditRepeatingGroupDialog = false;
      this.$emit("input", this.groupValues);
    },
    deleteItem(index) {
      this.groupValues.splice(index, 1);
      this.selectedValueIndex = undefined;
      this.showEditRepeatingGroupDialog = false;
      this.$emit("input", this.groupValues);
    },
  },
  data() {
    return {
      mdiPencil,
      showRepeatingGroupDialog: false,
      showEditRepeatingGroupDialog: false,
      selectedValueIndex: undefined,
      selectedValueItems: [],
      groupValues: [],
    };
  },
  computed: {
    tableHeaders() {
      return this.item.question.groupedItems
        .filter(
          (g) =>
            g?.question &&
            !["FILE", "PHOTO", "SIGNATURE"].includes(g?.question?.type)
        )
        .map((g) => {
          const { question, id } = g;
          if (question) {
            const { label, type, isLong } = question;
            return { id, label, type, isLong };
          }
        });
    },
    tableValues() {
      if (!Array.isArray(this.item?.value)) {
        return [];
      }
      return (
        this.item.value?.map((v) => {
          const entries = v.map((it) => {
            const { id, value } = it;
            if (it?.question?.type === "MULTI_SELECT") {
              if (Array.isArray(value)) {
                const formattedValue = value?.map((v) => v.val).join(", ");
                return [id, formattedValue];
              } else {
                return [id, value];
              }
            }
            return [id, value];
          });
          return Object.fromEntries(entries);
        }) ?? []
      );
    },
    isDisabled() {
      if (this.alreadySubmittedFinalOnline) {
        return !this.canEdit || !this.isEditingFinalForm;
      }
      return !this.canEdit;
    },
  },
};
</script>

<style scoped>
.raw-html >>> p {
  margin-bottom: 0;
}
</style>
