<template>
  <v-app-bar
    color="#3F51B5"
    class="white--text py-0 my-0 top-bar"
    height="56px"
    app
  >
    <v-app-bar-nav-icon>
      <v-btn
        text
        color="white"
        @click="toggleMenu(!isMenuOpen)"
        id="sharedSiteMenu"
        class="ml-2"
      >
        <v-icon>{{ mdiMenu }}</v-icon>
      </v-btn>
    </v-app-bar-nav-icon>

    <div
      class="d-flex justify-space-between px-3 align-center"
      v-if="sharedSite"
    >
      <div class="white--text">
        <div :class="{ text: sharedSite.address }">{{ sharedSite.name }}</div>
        <div class="text">{{ sharedSite.address }}</div>
      </div>
    </div>
  </v-app-bar>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { mdiMenu } from "@mdi/js";

export default {
  name: "SharedSiteTopBar",
  computed: {
    ...mapGetters(["isMenuOpen", "numUnsubmittedTickets"]),
    notUtiliSyncLayer() {
      return this.selectedMapService?.service_type !== "U";
    },
    title() {
      return this.selectedMapService?.service_name;
    },
  },
  data() {
    return {
      mdiMenu,
    };
  },
  methods: {
    ...mapMutations(["setMenuState"]),
    toggleMenu(showMenu) {
      this.setMenuState(showMenu);
    },
  },
  props: {
    selectedGisInfo: Object,
    selectedMapService: Object,
    gisDataFieldsAndValues: Array,
    objectId: Number,
    sharedSite: Object,
  },
};
</script>

<style scoped>
.text {
  font-size: 12px;
}
</style>
