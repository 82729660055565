<template>
  <div class="d-flex justify-space-between flex-grow-1 gap">
    <div class="mt-2">
      <p class="pb-1 my-0 caption">Shape</p>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="pa-0 d-flex" text v-bind="attrs" v-on="on" x-small>
            <img
              v-if="shapeOptions.shape === SHAPE_TYPES.MAP_SYMBOL"
              :src="
                require(`@/assets/img/mapSymbols/${shapeOptions.shapeImageFillColor}${shapeOptions.shapeSymbol}.png`)
              "
              :style="{
                width: `${shapeOptions.size}px`,
                height: `${shapeOptions.size}px`,
              }"
            />
            <div v-else-if="shapeOptions.shape === SHAPE_TYPES.ELLIPSE">
              <div
                :style="{
                  width: '30px',
                  height: '25px',
                  'border-radius': '50%',
                  'background-color': shapeOptions.shapeFill,
                  border: `1px solid ${shapeOptions.shapeOutlineColor}`,
                }"
              ></div>
            </div>
            <div v-else-if="shapeOptions.shape === SHAPE_TYPES.RECTANGLE">
              <div
                :style="{
                  width: '30px',
                  height: '25px',
                  'background-color': shapeOptions.shapeFill,
                  border: `1px solid ${shapeOptions.shapeOutlineColor}`,
                }"
              ></div>
            </div>
            <div v-else-if="shapeOptions.shape === SHAPE_TYPES.POLYGON">
              <v-icon :color="shapeOptions.shapeOutlineColor">
                {{ mdiVectorPolygon }}
              </v-icon>
            </div>

            <v-icon class="my-0 py-0">
              {{ mdiChevronDown }}
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            @click="
              shapeOptions.shape = SHAPE_TYPES.RECTANGLE;
              $emit('shape-options-changed', shapeOptions);
            "
          >
            <v-list-item-title class="d-flex justify-center align-center">
              <v-icon>
                {{ mdiVectorRectangle }}
              </v-icon>
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="
              shapeOptions.shape = SHAPE_TYPES.POLYGON;
              $emit('shape-options-changed', shapeOptions);
            "
          >
            <v-list-item-title class="d-flex justify-center align-center">
              <v-icon>
                {{ mdiVectorPolygon }}
              </v-icon>
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="
              shapeOptions.shape = SHAPE_TYPES.ELLIPSE;
              $emit('shape-options-changed', shapeOptions);
            "
          >
            <v-list-item-title class="d-flex justify-center align-center">
              <v-icon>
                {{ mdiEllipseOutline }}
              </v-icon>
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-for="s of symbols"
            :key="s"
            @click="
              shapeOptions.shape = SHAPE_TYPES.MAP_SYMBOL;
              shapeOptions.shapeSymbol = s;
              shapeOptions.shapeUrl = `/img/mapSymbols/${shapeOptions.shapeImageFillColor}${shapeOptions.shapeSymbol}.png`;
              $emit('shape-options-changed', shapeOptions);
            "
          >
            <v-list-item-title class="d-flex justify-center align-center">
              <img
                :src="
                  require(`@/assets/img/mapSymbols/${shapeOptions.shapeImageFillColor}${s}.png`)
                "
                style="width: 30px; height: 30px"
              />
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <div class="mt-2">
      <p class="pb-1 my-0 caption settings-heading">Color</p>
      <v-menu offset-y v-if="shapeOptions.shape === SHAPE_TYPES.MAP_SYMBOL">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="pa-0 d-flex" text v-bind="attrs" v-on="on" x-small>
            <div
              :style="{
                'background-color': shapeOptions.shapeFill,
                width: '25px',
                height: '25px',
                border: '1px solid black',
              }"
            >
              &nbsp;
            </div>

            <v-icon class="my-0 py-0">
              {{ mdiChevronDown }}
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="choice of colorChoices"
            :key="choice.value"
            @click="
              shapeOptions.shapeFill = choice.value;
              shapeOptions.shapeImageFillColor = getShapeFillColor(
                choice.value
              );
              shapeOptions.shapeUrl = `/img/mapSymbols/${shapeOptions.shapeImageFillColor}${shapeOptions.shapeSymbol}.png`;
              $emit('shape-options-changed', shapeOptions);
            "
          >
            <v-list-item-title class="d-flex align-center">
              <div
                :style="{
                  'background-color': choice.value,
                  width: '25px',
                  height: '25px',
                }"
                class="mr-2"
              >
                &nbsp;
              </div>
              <div>{{ choice.label }}</div>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu offset-y v-else>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="pa-0 d-flex" text v-bind="attrs" v-on="on" x-small>
            <div
              :style="{
                'background-color': shapeOptions.shapeFill,
                width: '25px',
                height: '25px',
                border: '1px solid black',
              }"
            >
              &nbsp;
            </div>

            <v-icon class="my-0 py-0">
              {{ mdiChevronDown }}
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <ColorPicker
              v-model="shapeOptions.shapeFill"
              @color-picker-cancelled="showFillDialog = false"
              @input="
                showFillDialog = false;
                $emit('shape-options-changed', shapeOptions);
              "
            />
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <div
      class="mt-2"
      v-if="shapeOptions.shape !== SHAPE_TYPES.MAP_SYMBOL"
      style="width: 120px"
    >
      <p class="pb-1 my-0 caption settings-heading">Outline Color</p>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="pa-0 d-flex" text v-bind="attrs" v-on="on" x-small>
            <div
              :style="{
                width: '25px',
                height: '25px',
                'background-color': shapeOptions.shapeOutlineColor,
                border: '1px solid black',
              }"
            >
              &nbsp;
            </div>

            <v-icon class="my-0 py-0">
              {{ mdiChevronDown }}
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <ColorPicker
              v-model="shapeOptions.shapeOutlineColor"
              @color-picker-cancelled="showFillDialog = false"
              @input="
                showFillDialog = false;
                $emit('shape-options-changed', shapeOptions);
              "
            />
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <div class="mt-2" style="width: 25%">
      <p class="pb-1 my-0 caption">Symbol Size</p>
      <v-slider
        :max="64"
        :min="0"
        hide-details
        v-model="shapeOptions.size"
        @input="$emit('shape-options-changed', shapeOptions)"
      >
        <template v-slot:append>
          <v-text-field
            v-model="shapeOptions.size"
            readonly
            class="number-input my-0 py-0 mt-n2"
            style="width: 20px"
            hide-details
          />
        </template>
      </v-slider>
    </div>
  </div>
</template>

<script>
import {
  mdiClose,
  mdiChevronDown,
  mdiVectorRectangle,
  mdiVectorPolygon,
  mdiEllipseOutline,
} from "@mdi/js";
import SHAPE_TYPES from "@/constants/shapeTypes";
import ColorPicker from "@/components/shared/ColorPicker";

const colorChoices = [
  { label: "Black", value: "black", fileNameColor: "Black" },
  { label: "Blue", value: "#2196F3", fileNameColor: "Blue" },
  { label: "Green", value: "green", fileNameColor: "Green" },
  { label: "Orange", value: "orange", fileNameColor: "Orange" },
  { label: "Purple", value: "purple", fileNameColor: "Purple" },
  { label: "Red", value: "red", fileNameColor: "Red" },
  { label: "Yellow", value: "yellow", fileNameColor: "Yellow" },
];

const symbols = [
  "CircleLargeB",
  "DiamondLargeB",
  "Pin1LargeB",
  "Pin2LargeB",
  "SquareLargeB",
  "StarLargeB",
];

export default {
  name: "SharedSiteShapeForm",
  props: {
    shapeOptionsObj: Object,
  },
  components: {
    ColorPicker,
  },
  data() {
    return {
      mdiClose,
      mdiChevronDown,
      mdiVectorRectangle,
      mdiVectorPolygon,
      mdiEllipseOutline,
      SHAPE_TYPES,
      shapeOptions: {
        shape: SHAPE_TYPES.RECTANGLE,
        shapeFill: "#2196F3",
        shapeOutlineColor: "#2196F3",
        shapeImageFillColor: "Blue",
        shapeSymbol: "CircleLargeB",
        shapeUrl: "/img/mapSymbols/BlueCircleLargeB.png",
        size: 15,
      },
      colorChoices,
      symbols,
      showFillDialog: false,
    };
  },
  beforeMount() {
    this.shapeOptions = { ...this.shapeOptionsObj };
  },
  methods: {
    getShapeFillColor(shapeFill) {
      return this.colorChoices.find((c) => c.value === shapeFill)
        ?.fileNameColor;
    },
  },
  watch: {
    "shapeOptions.shape"(val) {
      const shapeFillNotInChoices = !colorChoices.find(
        (c) => c.value === this.shapeOptions.shapeFill
      );
      if (shapeFillNotInChoices && val === SHAPE_TYPES.MAP_SYMBOL) {
        this.shapeOptions = {
          ...this.shapeOptions,
          shapeFill: "#2196F3",
          shapeImageFillColor: "Blue",
          shapeUrl: `/img/mapSymbols/BlueCircleLargeB.png`,
        };
      }
    },
  },
};
</script>
