var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showUtilibotDialog,
        "max-width": "600px",
        persistent: ""
      }
    },
    [
      _c(
        "v-toolbar",
        { attrs: { color: "#3F51B5", dark: "" } },
        [
          _c("v-toolbar-title", { staticStyle: { width: "100%" } }, [
            _c("div", { staticClass: "d-flex justify-space-between" }, [
              _c("div", { staticClass: "mt-2" }, [_vm._v("Form Utilibots")]),
              _c(
                "div",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("utilibot-dialog-close")
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v(" " + _vm._s(_vm.mdiClose) + " ")])],
                    1
                  )
                ],
                1
              )
            ])
          ])
        ],
        1
      ),
      _c(
        "v-card",
        { attrs: { color: "#fafafa" } },
        [
          _c(
            "v-card-text",
            { staticClass: "pa-5" },
            [
              _vm.formResultTasks.length === 0
                ? _c("div", [
                    _vm._v(" No UtilIBots configured for this form. ")
                  ])
                : _c(
                    "v-expansion-panels",
                    {
                      staticClass: "elevation-0 ma-0 pa-0",
                      attrs: { multiple: "", accordion: "" },
                      model: {
                        value: _vm.openPanels,
                        callback: function($$v) {
                          _vm.openPanels = $$v
                        },
                        expression: "openPanels"
                      }
                    },
                    _vm._l(_vm.formResultTasks, function(t) {
                      return _c(
                        "v-expansion-panel",
                        { key: t.id },
                        [
                          _c("v-expansion-panel-header", [
                            _c(
                              "div",
                              { staticClass: "d-flex justify-space-between" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-center" },
                                  [
                                    _c("b", [
                                      _vm._v(_vm._s(t.type_display_name))
                                    ])
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex align-center" },
                                  [
                                    t.status_name !== "completed_success"
                                      ? _c(
                                          "v-btn",
                                          {
                                            attrs: { text: "", color: "red" },
                                            on: {
                                              click: function($event) {
                                                $event.stopPropagation()
                                                return _vm.reprocessFailedMessages(
                                                  t.form_result_task_id
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v(" Retry ")]
                                        )
                                      : _vm._e(),
                                    _vm.processing[t.form_result_task_id]
                                      ? _c(
                                          "v-icon",
                                          {
                                            staticClass: "mr-4",
                                            attrs: { color: "#00A3FF" }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.mdiAutorenew) +
                                                " "
                                            )
                                          ]
                                        )
                                      : [
                                          _vm.iconUrlMap[t.status_name]
                                            ? _c("img", {
                                                staticClass: "mr-4",
                                                staticStyle: {
                                                  width: "20px",
                                                  height: "20px"
                                                },
                                                attrs: {
                                                  src: require("@/assets/" +
                                                    _vm.iconUrlMap[
                                                      t.status_name
                                                    ])
                                                }
                                              })
                                            : _vm._e()
                                        ]
                                  ],
                                  2
                                )
                              ]
                            )
                          ]),
                          _c("v-expansion-panel-content", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  t.status_message ||
                                    "No additional information available"
                                ) +
                                " "
                            )
                          ])
                        ],
                        1
                      )
                    }),
                    1
                  ),
              _c("FormIdDialog", {
                attrs: {
                  showFormIdDialog: _vm.showFormIdDialog,
                  formResultId: _vm.formResultId
                },
                on: {
                  "form-id-dialog-close": function($event) {
                    _vm.showFormIdDialog = false
                  }
                }
              })
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end mr-1" },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function($event) {
                      _vm.showFormIdDialog = true
                    }
                  }
                },
                [
                  _c("v-icon", { attrs: { color: "#3F51B5" } }, [
                    _vm._v(" " + _vm._s(_vm.mdiInformation) + " ")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }