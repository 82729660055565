<template>
  <div>
    <p class="caption">{{ label }}</p>
    <file-pond
      v-if="!isDisabled"
      name="files"
      ref="files"
      label-idle="Tap or drop files here..."
      :allow-multiple="allowMultiple"
      :files="uploadFiles"
      @addfile="onChange"
      allowFileSizeValidation
      maxFileSize="2MB"
    />
    <v-card v-for="(file, index) of files" :key="file.id">
      <v-card-text>
        <v-list-item>
          <v-list-item-content>
            <v-card class="d-flex flex-row justify-start elevation-0">
              <v-card
                class="elevation-0 d-flex flex-column justify-center mr-2"
              >
                {{ file.fileName || file.uploadedFileName }}
              </v-card>
              <v-card class="elevation-0" width="100%" v-if="showDescription">
                <v-text-field
                  :key="file.id"
                  label="Description"
                  color="#3F51B5"
                  :name="file.id"
                >
                </v-text-field>
              </v-card>
            </v-card>
          </v-list-item-content>

          <v-list-item-action>
            <v-list-item-action-text>
              <v-menu v-if="!isDisabled">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>{{ mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click="removeFile(index, file.id)">
                    <v-list-item-title>
                      <v-icon>{{ mdiDelete }}</v-icon>
                      Delete
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-action-text>
          </v-list-item-action>
        </v-list-item>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import { v4 as uuidv4 } from "uuid";
import { mdiDotsVertical, mdiDelete } from "@mdi/js";
import { cloneDeep } from "lodash";
import { axiosWithJwtAuth } from "@/plugins/axios";
import dependantValueMixin from "@/mixins/dependantValueMixin";

const APIURL = process.env.VUE_APP_API_URL;
const FilePond = vueFilePond();

export default {
  name: "SharedSiteFileInput",
  mixins: [dependantValueMixin],
  computed: {
    isRequired() {
      const { sections } = { ...this.formDefinition.form };
      for (const section of sections) {
        for (const dependantItem of section.items) {
          if (
            +dependantItem.id === +this.item?.question?.required?.dependantId
          ) {
            const dependantItemValue = dependantItem.value;
            if (Array.isArray(dependantItemValue)) {
              if (dependantItemValue.length > 1) {
                return false;
              } else {
                const [dependantValue] = dependantItemValue;
                return this.checkDependantValue(dependantValue);
              }
            } else {
              return this.checkDependantValue(dependantItemValue);
            }
          }
        }
      }
      return this.item?.question?.required?.condition === "ALWAYS";
    },
    isDisabled() {
      if (this.alreadySubmittedFinalOnline) {
        return !this.canEdit || !this.isEditingFinalForm;
      }
      return !this.canEdit;
    },
  },
  props: {
    label: String,
    id: Number,
    allowMultiple: Boolean,
    value: {
      type: [Array, String],
      default() {
        return [];
      },
    },
    formResultId: String,
    showDescription: Boolean,
    formDefinition: Object,
    item: Object,
    canEdit: {
      type: Boolean,
      default: true,
    },
    isEditingFinalForm: Boolean,
    alreadySubmittedFinalOnline: Boolean,
  },
  components: {
    FilePond,
  },
  data() {
    return {
      uploadFiles: undefined,
      files: [],
      mdiDotsVertical,
      mdiDelete,
    };
  },
  methods: {
    async onChange() {
      const files = this.$refs.files.getFiles();
      const fileUploadPromises = files.map(async (f) => {
        const { name: fileName, type: fileType } = f.file;
        const formData = new FormData();
        formData.append("file", f.file);
        formData.append("file_name", fileName);
        formData.append("is_image", false);
        formData.append("site_id", this.$route.query.siteId);
        if (navigator.onLine && this.formResultId) {
          const {
            data: { file },
          } = await axiosWithJwtAuth.post(
            `${APIURL}/shared_site/form_results/${this.formResultId}/files`,
            formData
          );
          return {
            description: "",
            fileName,
            fileType,
            ...file,
            uploaded: true,
            isImage: false,
          };
        } else {
          return {
            description: "",
            fileName,
            fileType,
            id: uuidv4(),
            name: fileName,
            uploaded: false,
            fileNotUploaded: f.file,
            isImage: false,
          };
        }
      });

      if (files.length > 0) {
        for (const f of files) {
          this.$refs.files.removeFile(f);
        }
        this.$emit("start-file-upload");
        const uploadedFiles = await Promise.all(fileUploadPromises);
        this.$emit("end-file-upload");
        this.files = [...this.files, ...uploadedFiles];
        this.$emit("input", this.files);
      }
      await this.validateInput();
    },
    async removeFile(index, fileId) {
      this.files.splice(index, 1);
      this.$emit("input", this.files);
      if (navigator.onLine) {
        await axiosWithJwtAuth.delete(
          `${APIURL}/shared_site/files/${fileId}/${this.$route.query.siteId}`
        );
      }
      await this.validateInput();
    },
    async validateInput() {
      await this.$nextTick();
      const { label, id, isRequired, files } = this;
      const valid = isRequired ? files.length > 0 : true;
      this.$emit("validated", {
        valid,
        label,
        id,
      });
    },
  },
  mounted() {
    this.validateInput();
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        if (Array.isArray(val)) {
          this.files = cloneDeep(val).map((f) => {
            const [uploadedFileName] = f.url.split("/").slice(-1);
            return {
              ...f,
              uploadedFileName,
            };
          });
        }
      },
    },
    isRequired() {
      this.validateInput();
    },
  },
};
</script>

<style scoped>
.thumbnail {
  width: 70px;
}
</style>
