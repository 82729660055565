<template>
  <v-dialog :value="showSharedSiteConvertMarkupDialog" width="500px">
    <v-card v-if="convertingPdf">
      <v-card-text class="py-4">
        <p class="py-2">
          The file is being converted now. Close this dialog and try the MARKUP
          tool again in a few minutes.
        </p>
      </v-card-text>
      <v-card-actions class="d-flex justify-end">
        <v-btn
          color="#3F51B5"
          dark
          @click="$emit('cancel-convert-markup-dialog-close')"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else>
      <v-card-text class="py-4">
        <p class="py-2">
          This PDF has not been converted to a markup format. Would you like to
          convert it so you can add markup to it?
        </p>
      </v-card-text>
      <v-card-actions class="d-flex justify-end gap">
        <v-btn
          text
          color="#3F51B5"
          @click="$emit('cancel-convert-markup-dialog-close')"
        >
          Cancel
        </v-btn>
        <v-btn color="#3F51B5" dark @click="convertPdf"> Convert PDF </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { axiosWithJwtAuth } from "@/plugins/axios";
const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "SharedSiteConvertMarkupDialog",
  props: {
    showSharedSiteConvertMarkupDialog: Boolean,
    selectedFile: Object,
  },
  data() {
    return {
      convertingPdf: false,
    };
  },
  methods: {
    async convertPdf() {
      await axiosWithJwtAuth.post(
        `${APIURL}/shared_site/files/${this.selectedFile.file_id}/pdf_to_png/${this.$route.query.siteId}`
      );
      this.convertingPdf = true;
    },
  },
};
</script>
