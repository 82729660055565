<template>
  <div>
    <div class="d-flex justify-space-between align-center">
      <div class="caption">{{ label }}</div>
      <v-btn
        text
        color="#3F51B5"
        @click="showSharedSiteAddImageDialog = true"
        :disabled="isDisabled"
      >
        <v-icon small>
          {{ mdiPlus }}
        </v-icon>
        Add
      </v-btn>
    </div>

    <v-simple-table>
      <thead>
        <tr>
          <th>Image</th>
          <th>Description</th>
          <th></th>
        </tr>
      </thead>

      <tbody v-if="base64Images.length > 0">
        <tr v-for="(image, index) of base64Images" :key="image.id">
          <td>
            <img
              :src="getPreviewUrl(image)"
              class="thumbnail"
              @click.stop="onClick(image)"
            />
          </td>
          <td>
            <v-text-field
              label="Description"
              color="#3F51B5"
              v-model="image.description"
              @change="$emit('input', base64Images)"
            >
            </v-text-field>
          </td>
          <td>
            <div class="d-flex justify-end align-center">
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>{{ mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click="onClick(image)">
                    <v-list-item-title>
                      <v-icon>{{ mdiFileEdit }}</v-icon>
                      Markup
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="removePhoto(index, image.id)">
                    <v-list-item-title>
                      <v-icon>{{ mdiDelete }}</v-icon>
                      Delete
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>

            <SharedSiteMarkupImageDialog
              v-if="showSharedSiteMarkupImageDialog[image.id]"
              @markup-image-dialog-close="
                showSharedSiteMarkupImageDialog = false;
                onFileMarkupPreviewSaved();
              "
              @file-markup-preview-saved="onFileMarkupPreviewSaved"
              :showSharedSiteMarkupImageDialog="
                showSharedSiteMarkupImageDialog[image.id]
              "
              :selectedFile="selectedFile"
            />
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="3">
            <div class="d-flex justify-center">No Images</div>
          </td>
        </tr>
      </tbody>
    </v-simple-table>

    <SharedSiteAddImageDialog
      @add-photo-dialog-close="showSharedSiteAddImageDialog = false"
      :showSharedSiteAddImageDialog="showSharedSiteAddImageDialog"
      :maxWidthHeight="+maxWidthHeight"
      :readOnly="isDisabled"
      :allowMultiple="allowMultiple"
      :formResultId="formResultId"
      :formDefinition="formDefinition"
      :selectedGisInfo="selectedGisInfo"
      :selectedMapServiceId="selectedMapServiceId"
      :item="item"
      :globalId="globalId"
      :objectId="objectId"
      @input="onSharedSiteAddImageDialogInput"
      @image-deleted="onImageDeleted"
      @file-markup-preview-saved="onFileMarkupPreviewSaved"
      v-if="showSharedSiteAddImageDialog"
    />

    <SharedSiteOfflineDialog
      v-if="showSharedSiteOfflineDialog"
      :showSharedSiteOfflineDialog="showSharedSiteOfflineDialog"
      @offline-dialog-close="showSharedSiteOfflineDialog = false"
    />
  </div>
</template>

<script>
import SharedSiteAddImageDialog from "@/components/shared-site/shared-site-ticket-form-def-drop-down/shared-site-ticket-edit-form/shared-site-dynamic-form/shared-site-photo-input/SharedSiteAddImageDialog";
import SharedSiteMarkupImageDialog from "@/components/shared-site/shared-site-ticket-form-def-drop-down/shared-site-ticket-edit-form/shared-site-dynamic-form/shared-site-photo-input/SharedSiteMarkupImageDialog";
import SharedSiteOfflineDialog from "@/components/shared-site/shared-site-ticket-form-def-drop-down/shared-site-ticket-edit-form/shared-site-dynamic-form/shared-site-photo-input/SharedSiteOfflineDialog";
import { axiosWithJwtAuth } from "@/plugins/axios";
import { mdiDotsVertical, mdiDelete, mdiPlus, mdiFileEdit } from "@mdi/js";
import dependantValueMixin from "@/mixins/dependantValueMixin";
import { cloneDeep } from "lodash";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "SharedSitePhotoInput",
  mixins: [dependantValueMixin],
  computed: {
    isRequired() {
      const { sections } = { ...this.formDefinition.form };
      for (const section of sections) {
        for (const dependantItem of section.items) {
          if (
            +dependantItem.id === +this.item?.question?.required?.dependantId
          ) {
            const dependantItemValue = dependantItem.value;
            if (Array.isArray(dependantItemValue)) {
              if (dependantItemValue.length > 1) {
                return false;
              } else {
                const [dependantValue] = dependantItemValue;
                return this.checkDependantValue(dependantValue);
              }
            } else {
              return this.checkDependantValue(dependantItemValue);
            }
          }
        }
      }
      return this.item?.question?.required?.condition === "ALWAYS";
    },
    isDisabled() {
      if (this.alreadySubmittedFinalOnline) {
        return !this.canEdit || !this.isEditingFinalForm || !this.objectId;
      }
      return (
        !this.canEdit ||
        !this.objectId ||
        (!this.allowMultiple && this.base64Images.length >= 1)
      );
    },
  },
  props: {
    label: String,
    id: Number,
    allowMultiple: Boolean,
    value: {
      type: [Array, String],
      default() {
        return [];
      },
    },
    formResultId: String,
    maxWidthHeight: Number,
    showDescription: Boolean,
    formDefinition: Object,
    item: Object,
    canEdit: {
      type: Boolean,
      default: true,
    },
    isEditingFinalForm: Boolean,
    alreadySubmittedFinalOnline: Boolean,
    selectedMapServiceId: String,
    objectId: Number,
    globalId: String,
    selectedGisInfo: Object,
  },
  components: {
    SharedSiteAddImageDialog,
    SharedSiteMarkupImageDialog,
    SharedSiteOfflineDialog,
  },
  data() {
    return {
      uploadFiles: undefined,
      base64Images: [],
      showSharedSiteMarkupImageDialog: {},
      resizedImageWidth: 0,
      resizedImageHeight: 0,
      windowWidth: window.innerWidth,
      mdiDotsVertical,
      mdiDelete,
      mdiPlus,
      mdiFileEdit,
      selectedFile: {},
      showSharedSiteAddImageDialog: false,
      showSharedSiteOfflineDialog: false,
      formResultFiles: [],
    };
  },
  methods: {
    async onFileMarkupPreviewSaved() {
      const results = await this.getInsertedFiles();
      for (const result of results) {
        const index = this.base64Images.findIndex(
          (b) => b.id === result.file_id
        );
        this.$set(this.base64Images, index, {
          ...this.base64Images[index],
          url: result.s3_file_path,
          dataUrl: result.s3_file_path,
        });
      }
      this.$emit("input", this.base64Images);
    },
    getPreviewUrl(file) {
      const imageFile = this.formResultFiles.find((f) => f.file_id === file.id);
      return imageFile?.s3_file_path ?? imageFile?.s3_file_path_original_image;
    },
    async getInsertedFiles() {
      const {
        data: { results },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/form_results/${this.formResultId}/files`
      );
      this.formResultFiles = cloneDeep(results);
      return results;
    },
    onImageDeleted(imageId) {
      const index = this.base64Images.findIndex((im) => im.id === imageId);
      this.base64Images.splice(index, 1);
    },
    onSharedSiteAddImageDialogInput(newImages) {
      const newImageIds = this.base64Images.map((im) => im.id);
      for (const [index, im] of this.base64Images.entries()) {
        if (newImageIds.includes(im.id)) {
          const newImage = newImages.find((nim) => nim.id === im.id);
          this.base64Images[index] = {
            ...im,
            ...newImage,
          };
        }
      }
      const newImagesNotAdded = newImages.filter(
        (nim) => !newImageIds.includes(nim.id)
      );
      this.base64Images = [...this.base64Images, ...newImagesNotAdded];
      this.$emit("input", this.base64Images);
    },
    async removePhoto(index, imageId) {
      this.base64Images.splice(index, 1);
      this.$emit("input", this.base64Images);
      if (navigator.onLine) {
        await axiosWithJwtAuth.delete(
          `${APIURL}/shared_site/files/${imageId}/${this.$route.query.siteId}`
        );
      }
    },
    async validateInput() {
      await this.$nextTick();
      const { label, id, isRequired, base64Images } = this;
      const valid = isRequired ? base64Images?.length > 0 : true;
      this.$emit("validated", {
        valid,
        label,
        id,
      });
    },
    async onClick(image) {
      if (!navigator.onLine) {
        this.showSharedSiteOfflineDialog = true;
        return;
      }
      const { id, fileName, description } = image;
      const {
        data: { results },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/files/${id}/metadata`
      );
      this.selectedFile = {
        file_id: id,
        s3_file_path_original_image: results?.s3_file_path_original_image,
        name: fileName ?? description,
      };
      this.showSharedSiteMarkupImageDialog = {
        ...this.showSharedSiteMarkupImageDialog,
        [id]: true,
      };
    },
    onClose(imageId) {
      this.showPhotoEditor = { ...this.showPhotoEditor, [imageId]: false };
    },
  },
  watch: {
    base64Images: {
      deep: true,
      async handler() {
        await this.validateInput();
        await this.getInsertedFiles();
      },
    },
    isRequired() {
      this.validateInput();
    },
  },
  async mounted() {
    await this.validateInput();
    await this.getInsertedFiles();
  },
  beforeMount() {
    if (Array.isArray(this.value)) {
      this.base64Images = [...this.value].map((v) => ({
        ...v,
        dataUrl: v.dataUrl ? v.dataUrl : v.url,
      }));
    }
  },
};
</script>

<style scoped>
.thumbnail {
  width: 70px;
}
</style>
