<template>
  <SharedSiteDynamicForm
    v-if="formSelected"
    :formDefinition="formDefinition"
    :selectedMapServiceId="selectedMapServiceId"
    :globalId="globalId"
    :objectId="objectId"
    :taskId="taskId"
    canEdit
    @ticket-edit-form-close-button-click="
      $emit('ticket-edit-form-close-button-click', $event)
    "
    @ticket-edit-form-close="$emit('ticket-edit-form-close')"
    @ticket-edit-form-submitted="$emit('ticket-edit-form-submitted')"
    @ticket-edit-form-error="$emit('ticket-edit-form-error', $event)"
    @form-result-deleted="$emit('form-result-deleted')"
    @input="formResult = $event"
  />
</template>

<script>
import SharedSiteDynamicForm from "@/components/shared-site/shared-site-ticket-form-def-drop-down/shared-site-ticket-edit-form/SharedSiteDynamicForm";
import { axiosWithJwtAuth } from "@/plugins/axios";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "SharedSiteTicketEditForm",
  components: {
    SharedSiteDynamicForm,
  },
  props: {
    formDefinitionId: String,
    selectedMapServiceId: String,
    objectId: Number,
    globalId: String,
    taskId: String,
  },
  data() {
    return {
      formDefinition: {},
    };
  },
  computed: {
    formSelected() {
      return Boolean(this.formDefinition?.form);
    },
  },
  async beforeMount() {
    const { data } = await axiosWithJwtAuth.get(
      `${APIURL}/shared_site/form_definitions/${this.formDefinitionId}`
    );
    this.formDefinition = data;
  },
};
</script>
