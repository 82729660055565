var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "d-flex justify-space-between px-3 py-2 rounded-0",
      staticStyle: { "background-color": "#38383d", color: "white" }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "\n      d-flex\n      gap\n      justify-space-between\n      flex-wrap\n      align-center\n      flex-grow-1\n    "
        },
        [
          _c("div", { staticClass: "d-flex align-center gap flex-wrap" }, [
            _c(
              "div",
              {
                staticClass: "d-flex gap button cursor-pointer square",
                class: {
                  "selected-symbol-type": _vm.itemType === _vm.ITEM_TYPES.TEXT
                },
                on: {
                  click: function($event) {
                    return _vm.onToolbarButtonClick(_vm.ITEM_TYPES.TEXT)
                  }
                }
              },
              [
                _c("v-icon", { attrs: { color: "white" } }, [
                  _vm._v(" " + _vm._s(_vm.mdiFormatText) + " ")
                ])
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "d-flex gap button cursor-pointer square",
                class: {
                  "selected-symbol-type": _vm.itemType === _vm.ITEM_TYPES.LINE
                },
                on: {
                  click: function($event) {
                    return _vm.onToolbarButtonClick(_vm.ITEM_TYPES.LINE)
                  }
                }
              },
              [
                _c("v-icon", { attrs: { color: "white" } }, [
                  _vm._v(" " + _vm._s(_vm.mdiVectorPolyline) + " ")
                ])
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "d-flex gap button cursor-pointer square",
                class: {
                  "selected-symbol-type": _vm.itemType === _vm.ITEM_TYPES.SHAPE
                },
                on: {
                  click: function($event) {
                    return _vm.onToolbarButtonClick(_vm.ITEM_TYPES.SHAPE)
                  }
                }
              },
              [
                _c("v-icon", { attrs: { color: "white" } }, [
                  _vm._v(" " + _vm._s(_vm.mdiShape) + " ")
                ])
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "d-flex gap button cursor-pointer square",
                class: {
                  "selected-symbol-type": _vm.itemType === _vm.ITEM_TYPES.SKETCH
                },
                on: {
                  click: function($event) {
                    return _vm.onToolbarButtonClick(_vm.ITEM_TYPES.SKETCH)
                  }
                }
              },
              [
                _c("v-icon", { attrs: { color: "white" } }, [
                  _vm._v(" " + _vm._s(_vm.mdiFountainPenTip) + " ")
                ])
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "d-flex gap button cursor-pointer square",
                class: {
                  "selected-symbol-type": _vm.itemType === _vm.ITEM_TYPES.ARROW
                },
                on: {
                  click: function($event) {
                    return _vm.onToolbarButtonClick(_vm.ITEM_TYPES.ARROW)
                  }
                }
              },
              [
                _c("v-icon", { attrs: { color: "white" } }, [
                  _vm._v(" " + _vm._s(_vm.mdiArrowTopRight) + " ")
                ])
              ],
              1
            ),
            _c(
              "div",
              {
                on: {
                  click: function($event) {
                    _vm.firstLoad = false
                  }
                }
              },
              [
                _c(
                  "v-menu",
                  {
                    attrs: { "offset-y": "" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _vm.selectedCustomSymbol
                              ? _c("div", { staticClass: "white--text" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "px-2 py-0 d-flex cursor-pointer align-center",
                                      class: {
                                        "selected-symbol-type":
                                          _vm.addingCustomSymbol &&
                                          !_vm.firstLoad
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "d-flex align-center",
                                          on: {
                                            click: function($event) {
                                              return _vm.$emit(
                                                "selected-custom-symbol",
                                                _vm.selectedCustomSymbol
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("SymbolIconDisplay", {
                                            attrs: {
                                              symbol: _vm.selectedCustomSymbol
                                            }
                                          }),
                                          _vm.$vuetify.breakpoint.smAndUp
                                            ? _c(
                                                "div",
                                                { staticClass: "white--text" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.selectedCustomSymbol
                                                          .label
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "py-0 my-0",
                                              attrs: { icon: "" }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: "white" } },
                                            [_vm._v(_vm._s(_vm.mdiChevronDown))]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ])
                              : _c(
                                  "div",
                                  { staticClass: "white--text" },
                                  [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass:
                                              "d-flex cursor-pointer align-center text-capitalize",
                                            attrs: { text: "", dark: "" }
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("p", { staticClass: "py-0 my-0" }, [
                                          _vm._v("Symbol")
                                        ]),
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "white" } },
                                          [_vm._v(_vm._s(_vm.mdiChevronDown))]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c(
                      "v-list",
                      [
                        _c(
                          "v-list-item",
                          { staticClass: "d-flex justify-space-between" },
                          [
                            _c(
                              "form",
                              [
                                _c("validation-provider", {
                                  attrs: { name: "Symbol" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var errors = ref.errors
                                        var valid = ref.valid
                                        return [
                                          _c("v-text-field", {
                                            attrs: {
                                              "error-messages": errors,
                                              success: valid,
                                              label: "Symbol",
                                              color: "#3F51B5"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "append",
                                                  fn: function() {
                                                    return [
                                                      _c("v-icon", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.mdiMagnify
                                                            )
                                                        )
                                                      ])
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ],
                                              null,
                                              true
                                            ),
                                            model: {
                                              value: _vm.symbolKeyword,
                                              callback: function($$v) {
                                                _vm.symbolKeyword = $$v
                                              },
                                              expression: "symbolKeyword"
                                            }
                                          })
                                        ]
                                      }
                                    }
                                  ])
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c(
                                  "v-menu",
                                  {
                                    attrs: { "offset-y": "" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  { attrs: { text: "" } },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.mdiDotsVertical
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  },
                                  [
                                    _c(
                                      "v-list",
                                      [
                                        _c(
                                          "v-list-item",
                                          {
                                            staticClass:
                                              "d-flex justify-space-between gap",
                                            on: {
                                              click: function($event) {
                                                _vm.showSharedSiteManageSymbolsDialog = true
                                              }
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v(
                                                " " + _vm._s(_vm.mdiTune) + " "
                                              )
                                            ]),
                                            _c("div", [
                                              _vm._v("Manage Symbols")
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        ),
                        _vm._l(_vm.activeCustomSymbols, function(s) {
                          return _c(
                            "v-list-item",
                            {
                              key: s.markup_symbol_id,
                              class: {
                                "selected-symbol-type":
                                  _vm.selectedItem &&
                                  _vm.selectedItem.markupSymbolId ===
                                    s.markup_symbol_id
                              },
                              on: {
                                click: function($event) {
                                  _vm.selectedCustomSymbol = s
                                  _vm.firstLoad = false
                                  _vm.saveSelectedCustomSymbol()
                                  _vm.$emit(
                                    "selected-custom-symbol",
                                    _vm.selectedCustomSymbol
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "v-list-item-title",
                                {
                                  staticClass:
                                    "d-flex align-center cursor-pointer"
                                },
                                [
                                  _c("SymbolIconDisplay", {
                                    attrs: { symbol: s }
                                  }),
                                  _c(
                                    "div",
                                    {
                                      class: {
                                        "white--text":
                                          _vm.selectedItem &&
                                          _vm.selectedItem.markupSymbolId ===
                                            s.markup_symbol_id
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(s.label) + " ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        })
                      ],
                      2
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "div",
            { staticClass: "d-flex gap" },
            [
              _c(
                "v-btn",
                {
                  attrs: { icon: "", color: "white" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("undo")
                    }
                  }
                },
                [_c("v-icon", [_vm._v(_vm._s(_vm.mdiUndo))])],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", color: "white" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("redo")
                    }
                  }
                },
                [_c("v-icon", [_vm._v(_vm._s(_vm.mdiRedo))])],
                1
              )
            ],
            1
          )
        ]
      ),
      _c("SharedSiteManageSymbolsDialog", {
        attrs: {
          showSharedSiteManageSymbolsDialog:
            _vm.showSharedSiteManageSymbolsDialog,
          customSymbols: _vm.customSymbols
        },
        on: {
          "close-button-click": function($event) {
            _vm.showSharedSiteManageSymbolsDialog = false
          },
          "custom-symbol-added": function($event) {
            return _vm.$emit("custom-symbol-added")
          },
          "custom-symbol-edited": function($event) {
            return _vm.$emit("custom-symbol-edited")
          },
          "get-custom-symbols": function($event) {
            return _vm.$emit("get-custom-symbols", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }