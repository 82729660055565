<template>
  <section class="template-editor">
    <section
      v-if="editor"
      class="
        d-flex
        align-center
        flex-wrap
        border
        px-2
        py-1
        justify-space-between
      "
      style="background-color: #e3e3e3"
    >
      <div class="d-flex flex-wrap flex-grow-0">
        <v-btn
          icon
          @click="editor.chain().focus().toggleBold().run()"
          :class="{ 'is-active': editor.isActive('bold') }"
        >
          <v-icon>{{ mdiFormatBold }}</v-icon>
        </v-btn>
        <v-btn
          icon
          @click="editor.chain().focus().toggleItalic().run()"
          :class="{ 'is-active': editor.isActive('italic') }"
        >
          <v-icon>{{ mdiFormatItalic }}</v-icon>
        </v-btn>
        <v-btn
          icon
          @click="editor.chain().focus().toggleUnderline().run()"
          :class="{ 'is-active': editor.isActive('underline') }"
        >
          <v-icon>
            {{ mdiFormatUnderline }}
          </v-icon>
        </v-btn>
        <v-btn
          icon
          @click="editor.chain().focus().toggleBulletList().run()"
          :class="{ 'is-active': editor.isActive('bulletList') }"
        >
          <v-icon>
            {{ mdiFormatListBulleted }}
          </v-icon>
        </v-btn>
        <v-btn
          icon
          @click="editor.chain().focus().toggleOrderedList().run()"
          :class="{ 'is-active': editor.isActive('orderedList') }"
        >
          <v-icon>
            {{ mdiFormatListNumbered }}
          </v-icon>
        </v-btn>
        <v-btn icon @click="onLinkButtonClick">
          <v-icon>
            {{ mdiLinkVariant }}
          </v-icon>
        </v-btn>
      </div>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark color="#286054" v-bind="attrs" v-on="on">
            <v-icon small>{{ mdiPlus }}</v-icon>
            Smart Field
            <v-icon>{{ mdiChevronDown }}</v-icon>
          </v-btn>
        </template>

        <v-list class="pa-0 ma-0">
          <v-list-item
            class="py-1 px-4 mx-0 justify-space-between"
            v-for="field of smartFieldChoices"
            :key="field.value"
            @click="insertContent(field.value)"
          >
            {{ field.label }}
          </v-list-item>
        </v-list>
      </v-menu>
    </section>

    <editor-content :editor="editor" class="border" />

    <p class="caption red--text" v-if="Array.isArray(errors)">
      {{ errors[0] }}
    </p>

    <SharedSiteUrlEditorDialog
      v-if="showUrlEditorDialog"
      :showUrlEditorDialog="showUrlEditorDialog"
      v-model="previousUrl"
      @input="setLink"
    />
  </section>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Image from "@tiptap/extension-image";
import Link from "@tiptap/extension-link";
import {
  mdiFormatBold,
  mdiFormatItalic,
  mdiFormatUnderline,
  mdiFormatListBulleted,
  mdiFormatListNumbered,
  mdiLinkVariant,
  mdiChevronDown,
  mdiPlus,
} from "@mdi/js";
import SharedSiteUrlEditorDialog from "@/components/shared-site/shared-site-tasks-tab/shared/shared-site-notification-template-dialog/shared-site-notification-template-body-editor/SharedSiteUrlEditorDialog";
import {
  SMART_FIELD_CHOICES,
  SMART_FIELD,
} from "@/constants/notificationTemplate";
import NotificationTemplateNode from "@/mixins/notificationTemplateNode";
import { ASSIGN_TO } from "@/constants/actionItemChoices";

export default {
  name: "NotificationTemplateBodyEditor",
  components: {
    EditorContent,
    SharedSiteUrlEditorDialog,
  },
  props: {
    item: Object,
    errors: Array,
    valid: Boolean,
    validated: Boolean,
    value: String,
    assignTo: String,
  },
  data() {
    return {
      editor: undefined,
      mdiFormatBold,
      mdiFormatItalic,
      mdiFormatUnderline,
      mdiFormatListBulleted,
      mdiFormatListNumbered,
      mdiLinkVariant,
      mdiPlus,
      mdiChevronDown,
      previousUrl: "",
      showUrlEditorDialog: false,
      SMART_FIELD_CHOICES,
    };
  },
  computed: {
    smartFieldChoices() {
      if (this.assignTo === ASSIGN_TO.SITE_CONTACT) {
        return SMART_FIELD_CHOICES.filter(
          ({ value }) => value !== SMART_FIELD.USER_SITE_LINK
        );
      } else if (this.assignTo === ASSIGN_TO.ORG_USER) {
        return SMART_FIELD_CHOICES.filter(
          ({ value }) => value !== SMART_FIELD.SITE_CONTACT_SITE_LINK
        );
      }
      return SMART_FIELD_CHOICES;
    },
  },
  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,
        Image,
        Underline,
        Link,
        NotificationTemplateNode,
      ],
      onUpdate: () => {
        this.$emit("input", this.editor.getHTML());
      },
    });
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  methods: {
    onLinkButtonClick() {
      this.previousUrl = this.editor.getAttributes("link").href;
      this.showUrlEditorDialog = true;
    },
    setLink(url) {
      this.showUrlEditorDialog = false;
      if (url === null) {
        return;
      }

      if (url === "") {
        this.editor.chain().focus().extendMarkRange("link").unsetLink().run();
        return;
      }

      this.editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url })
        .run();
    },
    insertContent(value) {
      let placeholder;
      if (value === SMART_FIELD.DUE) {
        placeholder = "{{task.due}}";
      } else if (value === SMART_FIELD.USER_SITE_LINK) {
        placeholder = "{{userSiteLink}}";
      } else if (value === SMART_FIELD.SITE_CONTACT_SITE_LINK) {
        placeholder = "{{siteContactSiteLink}}";
      } else if (value === SMART_FIELD.SITE_NUMBER) {
        placeholder = "{{ufield.site_number}}";
      } else if (value === SMART_FIELD.STATUS_WRITTEN) {
        placeholder = "{{ufield.Status_written}}";
      }

      if (placeholder) {
        this.editor
          .chain()
          .focus()
          .insertContent([
            {
              type: "templatePlaceholder",
              attrs: {
                placeholder,
              },
            },
          ])
          .run();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/* Basic editor styles */

.template-editor::v-deep .ProseMirror {
  height: 200px;
  overflow-y: auto;
  margin: 1rem 0;

  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
}

/* Table-specific styling */
.template-editor::v-deep .ProseMirror {
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #f1f3f5;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}

.template-editor::v-deep .tableWrapper {
  overflow-x: auto;
}

.template-editor::v-deep .resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
</style>

<style scoped>
.border {
  border: 1px solid #d8d8d8;
}

.template-editor >>> .ProseMirror:focus {
  outline: none;
}
</style>
