<template>
  <v-dialog
    :value="showNotificationTemplateDialog"
    max-width="500px"
    persistent
  >
    <validation-observer
      slim
      ref="notificationTemplateForm"
      v-slot="{ valid: isFormValid }"
    >
      <form @submit.prevent="save">
        <v-card>
          <v-toolbar dark class="elevation-0" width="100%" color="#3F51B5">
            <div>Notification Template</div>
            <v-spacer />
            <v-btn icon @click="$emit('notification-template-dialog-close')">
              <v-icon>{{ mdiClose }}</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text
            class="pt-3"
            id="dynamic-form-card-text"
            :style="{
              height: $vuetify.breakpoint.xsOnly
                ? `${contentHeight}px`
                : '65vh',
            }"
          >
            <v-card>
              <v-card-text class="px-3 py-5">
                <validation-provider
                  :rules="{ required: true }"
                  v-slot="{ errors, valid }"
                  name="Template Name"
                >
                  <v-text-field
                    v-model="
                      notificationTemplate.task_notification_template_name
                    "
                    label="Template Name *"
                    hide-details
                    :error-messages="errors"
                    :success="valid"
                  >
                  </v-text-field>
                </validation-provider>
              </v-card-text>
            </v-card>

            <section class="d-flex justify-space-between align-center pt-2">
              <div class="caption">Notifications</div>
              <v-btn text @click="addNotification" color="#3F51B5">
                <v-icon small>{{ mdiPlus }}</v-icon>
                Notification
              </v-btn>
            </section>

            <v-card
              v-for="(notification, notificationIndex) of notificationTemplate
                .task_notification_template.notifications"
              :key="notification.id"
            >
              <v-card-text
                class="py-2 px-3 d-flex justify-space-between align-center"
              >
                <validation-provider
                  :rules="{ required: true }"
                  v-slot="{ errors, valid }"
                  name="Notification Name"
                >
                  <v-text-field
                    v-model="notification.name"
                    label="Notification Name *"
                    hide-details
                    :error-messages="errors"
                    :success="valid"
                  >
                  </v-text-field>
                </validation-provider>

                <section class="d-flex align-center">
                  <v-btn icon @click="onToggleNotification(notification.id)">
                    <v-icon>{{
                      showNotificationContent[notification.id]
                        ? mdiChevronUp
                        : mdiChevronDown
                    }}</v-icon>
                  </v-btn>

                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on" class="px-0 mx-0">
                        <v-icon>{{ mdiDotsVertical }}</v-icon>
                      </v-btn>
                    </template>

                    <v-list class="px-0 mx-0">
                      <v-list-item class="px-0 mx-0">
                        <v-btn text>
                          <span class="font-weight-regular"> Duplicate </span>
                        </v-btn>
                      </v-list-item>

                      <v-list-item class="px-0 mx-0">
                        <v-btn text>
                          <span class="font-weight-regular"> Delete </span>
                        </v-btn>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </section>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-text
                class="px-3 py-1"
                v-if="showNotificationContent[notification.id]"
              >
                <section class="d-flex gap pb-2">
                  <validation-provider
                    v-slot="{ errors, valid }"
                    name="From Name"
                  >
                    <v-text-field
                      v-model="notification.from_name"
                      label="From Name"
                      hide-details
                      :error-messages="errors"
                      :success="valid"
                    >
                    </v-text-field>
                  </validation-provider>

                  <validation-provider
                    :rules="{ email: true }"
                    v-slot="{ errors, valid }"
                    name="Reply To"
                  >
                    <v-text-field
                      v-model="notification.reply_to"
                      label="Reply To"
                      hide-details
                      :error-messages="errors"
                      :success="valid"
                    >
                    </v-text-field>
                  </validation-provider>
                </section>

                <v-divider></v-divider>

                <section class="d-flex gap pb-2">
                  <validation-provider
                    v-slot="{ errors, valid }"
                    name="Subject"
                  >
                    <v-text-field
                      v-model="notification.subject"
                      label="Subject"
                      hide-details
                      :error-messages="errors"
                      :success="valid"
                      ref="subjectInput"
                    >
                      <template v-slot:append>
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              small
                              color="#286054"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon small>{{ mdiPlus }}</v-icon>
                            </v-btn>
                          </template>

                          <v-list class="pa-0 ma-0">
                            <v-list-item
                              class="py-1 px-4 mx-0 justify-space-between"
                              v-for="field of SMART_FIELD_CHOICES"
                              :key="field.value"
                              @click="
                                insertSubjectPlaceholder(
                                  field.value,
                                  notificationIndex
                                )
                              "
                            >
                              {{ field.label }}
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </v-text-field>
                  </validation-provider>
                </section>

                <v-divider></v-divider>

                <SharedSiteNotificationTemplateBodyEditor
                  class="py-2"
                  v-model="notification.body"
                  :assignTo="assignTo"
                />

                <section class="py-1 d-flex gap flex-wrap align-center">
                  <div class="font-weight-medium caption">
                    Send Notification
                  </div>

                  <validation-provider
                    :rules="{ required: true, min_value: 0 }"
                    v-slot="{ errors, valid }"
                    name="# of Days"
                  >
                    <v-text-field
                      v-model="notification.notification_date.value"
                      hide-details
                      :error-messages="errors"
                      :success="valid"
                      class="py-0 my-0"
                      type="number"
                      placeholder="# of Days"
                      style="width: 50px"
                    >
                    </v-text-field>
                  </validation-provider>

                  <div class="font-weight-medium caption">Days(s)</div>

                  <validation-provider
                    :rules="{ required: true }"
                    v-slot="{ errors, valid }"
                    name="# of Days"
                  >
                    <v-select
                      :items="BEFORE_AFTER_CHOICES"
                      v-model="notification.notification_date.before_or_after"
                      item-text="label"
                      item-value="value"
                      hide-details
                      :error-messages="errors"
                      :success="valid"
                      class="py-0 my-0"
                    >
                    </v-select>
                  </validation-provider>

                  <div class="caption font-weight-medium">Task Due Date</div>
                </section>
              </v-card-text>
            </v-card>
          </v-card-text>

          <v-card-actions class="d-flex justify-end pa-3">
            <v-btn
              :dark="isFormValid"
              color="#3F51B5"
              type="submit"
              :disabled="!isFormValid"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </validation-observer>
  </v-dialog>
</template>

<script>
import {
  mdiDotsVertical,
  mdiClose,
  mdiPlus,
  mdiChevronUp,
  mdiChevronDown,
} from "@mdi/js";
import { v4 as uuidv4 } from "uuid";
import { axiosWithJwtAuth } from "@/plugins/axios";
import SharedSiteNotificationTemplateBodyEditor from "@/components/shared-site/shared-site-tasks-tab/shared/shared-site-notification-template-dialog/SharedSiteNotificationTemplateBodyEditor";
import contentHeightMixin from "@/mixins/contentHeightMixin";
import { cloneDeep } from "lodash";
import {
  SMART_FIELD_CHOICES,
  SMART_FIELD,
} from "@/constants/notificationTemplate";

const BEFORE_AFTER_CHOICES = [
  { label: "Before", value: "before" },
  { label: "After", value: "after" },
];
const APIURL = process.env.VUE_APP_API_URL;
const notification = {
  name: "",
  body: "",
  from_name: "",
  reply_to: "",
  notification_date: {
    before_or_after: "before",
    unit: "days",
    value: 0,
  },
};

export default {
  name: "SharedSiteNotificationTemplateDialog",
  mixins: [contentHeightMixin],
  data() {
    const notificationId = uuidv4();
    return {
      mdiDotsVertical,
      mdiClose,
      mdiPlus,
      mdiChevronUp,
      mdiChevronDown,
      notificationTemplate: {
        task_notification_template_name: "",
        task_notification_template: {
          notifications: [{ id: notificationId, ...notification }],
        },
      },
      showNotificationContent: {},
      BEFORE_AFTER_CHOICES,
      SMART_FIELD_CHOICES,
    };
  },
  props: {
    showNotificationTemplateDialog: Boolean,
    assignTo: String,
  },
  components: {
    SharedSiteNotificationTemplateBodyEditor,
  },
  beforeMount() {
    this.showNotificationPanes();
  },
  methods: {
    showNotificationPanes() {
      const entries =
        this.notificationTemplate.task_notification_template.notifications.map(
          ({ id }) => {
            return [id, true];
          }
        );
      this.showNotificationContent = Object.fromEntries(entries);
    },
    addNotification() {
      const notificationId = uuidv4();
      const newNotification = { id: notificationId, ...notification };
      this.notificationTemplate.task_notification_template.notifications.push(
        newNotification
      );
      this.showNotificationPanes();
    },
    onToggleNotification(notificationId) {
      this.$set(
        this.showNotificationContent,
        notificationId,
        !this.showNotificationContent[notificationId]
      );
    },
    async save() {
      const success = await this.$refs.notificationTemplateForm.validate();
      if (!success) {
        return;
      }
      const payload = cloneDeep(this.notificationTemplate);
      const transformedPayload = {
        ...payload,
        task_notification_template: {
          notifications: payload.task_notification_template.notifications.map(
            ({ id, ...rest }) => rest
          ),
        },
        site_id: this.$route.query.siteId,
      };
      await axiosWithJwtAuth.post(
        `${APIURL}/shared_site/task_notification_templates`,
        transformedPayload
      );
      this.$emit("notification-template-saved");
    },
    insertSubjectPlaceholder(value, notificationIndex) {
      let placeholder;
      const index =
        this.$refs.subjectInput[notificationIndex].$refs.input.selectionStart;
      if (value === SMART_FIELD.DUE) {
        placeholder = "{{task.due}}";
      } else if (value === SMART_FIELD.USER_SITE_LINK) {
        placeholder = "{{userSiteLink}}";
      } else if (value === SMART_FIELD.SITE_CONTACT_SITE_LINK) {
        placeholder = "{{siteContactSiteLink}}";
      } else if (value === SMART_FIELD.SITE_NUMBER) {
        placeholder = "{{ufield.site_number}}";
      } else if (value === SMART_FIELD.STATUS_WRITTEN) {
        placeholder = "{{ufield.Status_written}}";
      }

      if (placeholder) {
        const { subject } =
          this.notificationTemplate.task_notification_template.notifications[
            notificationIndex
          ];
        const start = subject.slice(0, index);
        const end = subject.slice(index);
        this.notificationTemplate.task_notification_template.notifications[
          notificationIndex
        ].subject = `${start}${placeholder}${end}`;
      }
    },
  },
};
</script>

<style>
#dynamic-form-card-text {
  height: 60vh;
  overflow-y: auto;
}
</style>
