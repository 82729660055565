<template>
  <v-card width="100%" elevation="0">
    <v-card-text>
      <v-row>
        <v-col cols="12" class="d-flex align-center justify-space-between">
          <div>
            {{ item.question.label }}
          </div>
          <div>
            <v-btn
              text
              color="#3F51B5"
              @click.stop="showActionItemDialog = true"
              :disabled="isDisabled"
            >
              + Add
            </v-btn>
          </div>
        </v-col>

        <v-col cols="12" class="pa-0 ma-0">
          <v-card v-if="noActionItems">
            <v-card-text class="d-flex justify-center">
              No {{ item.question.label }}
            </v-card-text>
          </v-card>
          <v-card v-else>
            <v-card-text class="pa-0 ma-0">
              <v-data-table
                :headers="headers"
                hide-default-footer
                disable-pagination
                group-by="groupPosition"
                :items="mappedActionItems"
                item-key="action_item_id"
                class="pa-0 ma-0 cursor-pointer"
                @click:row="onRowClick"
              >
                <template v-slot:[`group.header`]="{ isOpen, toggle, items }">
                  <td
                    colspan="8"
                    class="ma-0 pa-0 group-toggle group-header"
                    @click="toggle"
                  >
                    <div class="d-flex ml-2">
                      <v-btn text x-small>
                        <v-icon>
                          {{ isOpen ? mdiChevronUp : mdiChevronDown }}
                        </v-icon>
                      </v-btn>
                      <div class="ml-2 text-capitalize">
                        <b>{{ getGroupHeader(items) }}</b>
                      </div>
                    </div>
                  </td>
                </template>

                <template v-slot:[`item.due_by`]="{ item }">
                  {{ item.due_by | formatDate }}
                </template>

                <template v-slot:[`item.action_item_id`]="{ item }">
                  <v-btn
                    icon
                    color="#3F51B5"
                    @click.stop="onActionItemClick(item)"
                  >
                    <v-icon>
                      {{ mdiPencil }}
                    </v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <SharedSiteActionItemDialog
        v-if="showActionItemDialog"
        :showActionItemDialog="showActionItemDialog"
        :item="item"
        :readOnly="isDisabled"
        :formResultId="formResultId"
        :formDefinition="formDefinition"
        :newActionItems="actionItems.newActionItems"
        :selectedMapServiceId="selectedMapServiceId"
        :gisInfoObjectId="objectId"
        @form-saved="onFormSaved"
        @close-form="showActionItemDialog = false"
      />

      <SharedSiteEditActionItemDialog
        v-if="showEditActionItemDialog"
        :formDefinition="formDefinition"
        :showEditActionItemDialog="showEditActionItemDialog"
        :item="item"
        :selectedActionItem="selectedActionItem"
        :selectedMapServiceId="selectedMapServiceId"
        :gisInfoObjectId="objectId"
        @form-saved="onEditFormSaved"
        @close-form="showEditActionItemDialog = false"
        @delete-action-item="deleteActionItem"
      />

      <SharedSiteActionItemTabActionItemDialog
        v-if="showActionItemTabActionItemDialog"
        :action="getActionItemLabel(selectedOpenActionItem)"
        :showActionItemDialog="showActionItemTabActionItemDialog"
        :selectedActionItem="selectedOpenActionItem"
        :selectedActionItemId="selectedOpenActionItem.action_item_id"
        hideResponseSection
        @action-item-dialog-close="showActionItemTabActionItemDialog = false"
        @action-item-saved="onActionItemSaved"
        @action-item-response-saved="
          showActionItemTabActionItemDialog = false;
          loadActionItems();
        "
        @reject-action-item="
          showActionItemTabActionItemDialog = false;
          onActionItemSaved();
        "
        @accept-action-item="
          showActionItemTabActionItemDialog = false;
          loadActionItems();
        "
      />

      <SharedSiteActionItemTabEditActionItemDialog
        v-if="showActionItemTabEditActionItemDialog"
        :showEditItemDialog="showActionItemTabEditActionItemDialog"
        :selectedActionItem="selectedOpenActionItem"
        @edit-action-item-dialog-close="
          showActionItemTabEditActionItemDialog = false;
          loadActionItems();
        "
        @action-item-saved="
          showActionItemTabEditActionItemDialog = false;
          loadActionItems();
        "
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiPencil, mdiChevronUp, mdiChevronDown } from "@mdi/js";
import SharedSiteActionItemDialog from "@/components/shared-site/shared-site-ticket-form-def-drop-down/shared-site-ticket-edit-form/shared-site-dynamic-form/shared-site-action-item/SharedSiteActionItemDialog";
import SharedSiteEditActionItemDialog from "@/components/shared-site/shared-site-ticket-form-def-drop-down/shared-site-ticket-edit-form/shared-site-dynamic-form/shared-site-action-item/SharedSiteEditActionItemDialog";
import SharedSiteActionItemTabEditActionItemDialog from "@/components/shared-site/shared-site-ticket-form-def-drop-down/shared-site-ticket-edit-form/shared-site-dynamic-form/shared-site-action-item/SharedSiteActionItemTabEditActionItemDialog";
import SharedSiteActionItemTabActionItemDialog from "@/components/shared-site/shared-site-ticket-form-def-drop-down/shared-site-ticket-edit-form/shared-site-dynamic-form/shared-site-action-item/SharedSiteActionItemTabActionItemDialog";
import { axiosWithJwtAuth } from "@/plugins/axios";
import { cloneDeep } from "lodash";
import ACTION_ITEM_STATUSES from "@/constants/actionItemStatuses";
import { v4 as uuidv4 } from "uuid";
import actionItemMixin from "@/mixins/actionItemMixin";

const APIURL = process.env.VUE_APP_API_URL;
const GROUP_POSITIONS = {
  [ACTION_ITEM_STATUSES.OPEN]: 0,
  [ACTION_ITEM_STATUSES.READY_FOR_REVIEW]: 1,
  [ACTION_ITEM_STATUSES.REJECTED]: 2,
  [ACTION_ITEM_STATUSES.CANCELED]: 4,
  [ACTION_ITEM_STATUSES.CLOSED]: 4,
  [ACTION_ITEM_STATUSES.NEW]: 5,
};
const headers = [
  {
    text: "Due By",
    align: "start",
    value: "due_by",
  },
  {
    text: "Assigned To",
    align: "start",
    value: "assignee",
    width: "120px",
  },
  {
    text: "Description",
    align: "start",
    value: "action_item_description",
  },
  {
    text: "",
    align: "end",
    value: "action_item_id",
  },
];

export default {
  name: "SharedSiteActionItem",
  props: {
    item: Object,
    formDefinition: Object,
    value: Object,
    canEdit: Boolean,
    formResultId: String,
    alreadySubmittedFinalOnline: Boolean,
    isEditingFinalForm: Boolean,
    gisInfos: Array,
    selectedGisInfo: Object,
    selectedMapServiceId: String,
    objectId: Number,
  },
  mixins: [actionItemMixin],
  components: {
    SharedSiteActionItemDialog,
    SharedSiteEditActionItemDialog,
    SharedSiteActionItemTabEditActionItemDialog,
    SharedSiteActionItemTabActionItemDialog,
  },
  async beforeMount() {
    if (this.value) {
      this.actionItems = { ...this.value };
    } else {
      this.$emit("input", cloneDeep(this.actionItems));
    }
    await this.getAssignableUsers();
    await this.getActionItemsByFeature();
  },
  methods: {
    onFormSaved(actionItem) {
      if (!Array.isArray(this.actionItems.newActionItems)) {
        this.actionItems.newActionItems = [];
      }
      this.actionItems.newActionItems.push(actionItem);
      this.showActionItemDialog = false;
      this.$emit("input", cloneDeep(this.actionItems));
    },
    onEditFormSaved(actionItem) {
      const selectedActionItemIndex = this.actionItems.newActionItems.findIndex(
        (a) => a.id === actionItem.id
      );
      this.$set(this.actionItems.newActionItems, selectedActionItemIndex, {
        ...actionItem,
      });
      this.selectedActionItem = {};
      this.showEditActionItemDialog = false;
      this.$emit("input", cloneDeep(this.actionItems));
    },
    deleteActionItem(actionItem) {
      const selectedActionItemIndex = this.actionItems.newActionItems.findIndex(
        (a) => a.id === actionItem.id
      );
      this.actionItems.newActionItems.splice(selectedActionItemIndex, 1);
      this.selectedActionItem = {};
      this.showEditActionItemDialog = false;
      this.$emit("input", cloneDeep(this.actionItems));
    },
    async getAssignableUsers() {
      const {
        data: { results },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/users/${this.$route.query.siteId}`
      );
      this.users = results;
    },
    getAssigneeValue(actionItem) {
      const user = this.users
        .map((u) => {
          const { f_name: fName, l_name: lName, user_id: userId } = u;
          return {
            name: `${fName} ${lName}`,
            userId,
          };
        })
        .find((u) => u.userId === actionItem?.assignee_user_id);
      return user?.name;
    },
    async getActionItemsByFeature() {
      const { selectedMapServiceId, objectId } = this;
      if (!selectedMapServiceId || !objectId) {
        return;
      }
      const {
        data: { results },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/action_item/by_feature`,
        {
          params: {
            map_service_id: selectedMapServiceId,
            object_id: objectId,
            site_id: this.$route.query.siteId,
          },
        }
      );

      this.actionItems.openActionItems = results;
    },
    async onActionItemSaved() {
      await this.loadActionItems();
      this.selectedActionItem = this.actionItems[this.selectedActionItemIndex];
    },
    async loadActionItems() {
      await this.getActionItemsByFeature();
    },
    onRowClick(item) {
      if (item.status !== ACTION_ITEM_STATUSES.NEW) {
        this.selectedActionItem = item;
        this.showActionItemTabActionItemDialog = true;
      }
    },
    onActionItemClick(item) {
      if (item.status === ACTION_ITEM_STATUSES.NEW) {
        this.selectedActionItem = item;
        this.showEditActionItemDialog = true;
      } else {
        this.selectedOpenActionItem = item;
        this.showActionItemTabEditActionItemDialog = true;
      }
    },
    getGroupHeader(items) {
      const [item] = items;
      return item?.displayStatus;
    },
  },
  data() {
    return {
      mdiPencil,
      mdiChevronUp,
      mdiChevronDown,
      showActionItemDialog: false,
      showEditActionItemDialog: false,
      actionItems: {
        newActionItems: [],
        openActionItems: [],
      },
      selectedActionItem: {},
      selectedActionItemIndex: undefined,
      users: [],
      showActionItemTabActionItemDialog: false,
      selectedOpenActionItem: {},
      selectedOpenActionItemIndex: undefined,
      ACTION_ITEM_STATUSES,
      headers,
      showActionItemTabEditActionItemDialog: false,
    };
  },
  computed: {
    noActionItems() {
      return (
        (this.actionItems?.newActionItems?.length ?? 0) === 0 &&
        (this.actionItems?.openActionItems?.length ?? 0) === 0
      );
    },
    isDisabled() {
      if (this.alreadySubmittedFinalOnline) {
        return !this.canEdit || !this.isEditingFinalForm;
      }
      return !this.canEdit;
    },
    mappedActionItems() {
      let openItems = [];
      if (Array.isArray(this.actionItems.openActionItems)) {
        openItems = this.actionItems.openActionItems.map((it) => {
          const { status } = it;
          return {
            ...it,
            assignee: this.getAssigneeValue(it),
            displayStatus:
              status === ACTION_ITEM_STATUSES.OPEN
                ? "Ready for Response"
                : status,
            groupPosition: GROUP_POSITIONS[status],
          };
        });
      }

      let newItems = [];
      if (Array.isArray(this.actionItems.newActionItems)) {
        newItems = this.actionItems.newActionItems.map((it) => {
          return {
            ...it,
            assignee: this.getAssigneeValue(it),
            status: ACTION_ITEM_STATUSES.NEW,
            displayStatus: ACTION_ITEM_STATUSES.NEW,
            action_item_id: uuidv4(),
            groupPosition: GROUP_POSITIONS[ACTION_ITEM_STATUSES.NEW],
          };
        });
      }
      return [...openItems, ...newItems];
    },
  },
};
</script>
