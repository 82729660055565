<template>
  <div class="d-flex justify-space-between flex-grow-1 gap">
    <div class="mt-2" style="width: 50%">
      <p class="pb-1 my-0 caption">Stroke</p>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="pa-0 d-flex" text v-bind="attrs" v-on="on" x-small>
            <div
              :style="{
                'background-color': lineOptions.lineStroke,
                width: '25px',
                height: '25px',
                border: '1px solid black',
              }"
            >
              &nbsp;
            </div>

            <v-icon class="my-0 py-0">
              {{ mdiChevronDown }}
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <ColorPicker
              v-model="lineOptions.lineStroke"
              @color-picker-cancelled="showFillDialog = false"
              @input="
                showFillDialog = false;
                $emit('line-options-changed', lineOptions);
              "
            />
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <div class="mt-2" style="width: 50%">
      <p class="pb-1 px-1 my-0 caption">Width</p>
      <v-slider
        :max="16"
        :min="1"
        hide-details
        v-model="lineOptions.lineStrokeWidth"
        @change="$emit('line-options-changed', lineOptions)"
      >
        <template v-slot:append>
          <v-text-field
            v-model="lineOptions.lineStrokeWidth"
            readonly
            class="my-0 py-0 mt-n2"
            hide-details
            style="width: 20px"
          />
        </template>
      </v-slider>
    </div>
  </div>
</template>

<script>
import {
  mdiClose,
  mdiChevronDown,
  mdiVectorRectangle,
  mdiVectorPolygon,
  mdiEllipseOutline,
} from "@mdi/js";
import SHAPE_TYPES from "@/constants/shapeTypes";
import ColorPicker from "@/components/shared/ColorPicker";

const colorChoices = [
  { label: "Black", value: "black", fileNameColor: "Black" },
  { label: "Blue", value: "#2196F3", fileNameColor: "Blue" },
  { label: "Green", value: "green", fileNameColor: "Green" },
  { label: "Orange", value: "orange", fileNameColor: "Orange" },
  { label: "Purple", value: "purple", fileNameColor: "Purple" },
  { label: "Red", value: "red", fileNameColor: "Red" },
  { label: "Yellow", value: "yellow", fileNameColor: "Yellow" },
];

const symbols = [
  "CircleLargeB",
  "DiamondLargeB",
  "Pin1LargeB",
  "Pin2LargeB",
  "SquareLargeB",
  "StarLargeB",
];

export default {
  name: "SharedSiteLineForm",
  props: {
    lineOptionsObj: Object,
  },
  components: {
    ColorPicker,
  },
  data() {
    return {
      mdiClose,
      mdiChevronDown,
      mdiVectorRectangle,
      mdiVectorPolygon,
      mdiEllipseOutline,
      SHAPE_TYPES,
      lineOptions: {
        lineStroke: "#2196F3",
        lineStrokeWidth: 5,
      },
      colorChoices,
      symbols,
      showFillDialog: false,
    };
  },
  beforeMount() {
    this.lineOptions = { ...this.lineOptionsObj };
  },
};
</script>
