var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-toolbar",
    {
      ref: "toolbar",
      attrs: { dark: "", width: "100%", color: "#3F51B5", height: "56px" }
    },
    [
      _vm.isPdf
        ? _c(
            "v-btn",
            {
              staticClass: "ml-n3",
              attrs: { text: "", color: "white" },
              on: {
                click: function($event) {
                  return _vm.$emit("show-pdf-pages-panel")
                }
              }
            },
            [_c("v-icon", [_vm._v(_vm._s(_vm.mdiMenu))])],
            1
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "mr-3 d-flex align-center gap",
          staticStyle: { "max-width": "50%" }
        },
        [
          _vm.$vuetify.breakpoint.smAndUp
            ? _c("div", { staticClass: "text-truncate" }, [
                _vm._v(" " + _vm._s(_vm.selectedFile.name) + " ")
              ])
            : _vm._e(),
          _c(
            "div",
            [
              _c("p", { staticClass: "caption py-0 my-0" }, [
                _vm._v("Version")
              ]),
              _c("RevisionDropdown", {
                attrs: {
                  selectedPdfPage: _vm.selectedPdfPage,
                  selectedFile: _vm.selectedFile,
                  fileMarkupRevisions: _vm.fileMarkupRevisions,
                  pdfPageFileMarkupCounts: _vm.pdfPageFileMarkupCounts,
                  imageFileMarkupCount: _vm.imageFileMarkupCount
                },
                on: {
                  "revision-selected": function($event) {
                    return _vm.$emit("revision-selected", $event)
                  },
                  "show-more": function($event) {
                    return _vm.$emit("show-more")
                  },
                  "revision-deleted": function($event) {
                    return _vm.$emit("revision-deleted")
                  }
                }
              })
            ],
            1
          )
        ]
      ),
      _c("v-spacer"),
      _c(
        "v-menu",
        {
          attrs: { "offset-y": "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        { attrs: { "x-small": "", icon: "" } },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_c("v-icon", [_vm._v(_vm._s(_vm.mdiDotsVertical))])],
                    1
                  )
                ]
              }
            }
          ])
        },
        [
          _c(
            "v-list",
            [
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-title",
                    {
                      staticClass: "d-flex align-center gap cursor-pointer",
                      on: {
                        click: function($event) {
                          return _vm.$emit("rotate", -90)
                        }
                      }
                    },
                    [
                      _c("v-icon", [
                        _vm._v(" " + _vm._s(_vm.mdiRotateLeft) + " ")
                      ]),
                      _vm._v(" Rotate Left ")
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-title",
                    {
                      staticClass: "d-flex align-center gap cursor-pointer",
                      on: {
                        click: function($event) {
                          return _vm.$emit("rotate", 90)
                        }
                      }
                    },
                    [
                      _c("v-icon", [
                        _vm._v(" " + _vm._s(_vm.mdiRotateRight) + " ")
                      ]),
                      _vm._v(" Rotate Right ")
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-title",
                    {
                      staticClass: "d-flex align-center gap cursor-pointer",
                      style: {
                        "pointer-events": _vm.disableFitToScreen
                          ? "none"
                          : undefined,
                        opacity: _vm.disableFitToScreen ? 0.5 : 1
                      },
                      on: {
                        click: function($event) {
                          return _vm.$emit("fit-to-screen")
                        }
                      }
                    },
                    [
                      _c("v-icon", [
                        _vm._v(" " + _vm._s(_vm.mdiFitToPageOutline) + " ")
                      ]),
                      _vm._v(" Fit to Screen ")
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-title",
                    {
                      staticClass: "d-flex align-center gap cursor-pointer",
                      on: {
                        click: function($event) {
                          return _vm.$emit("crop")
                        }
                      }
                    },
                    [
                      _c("v-icon", [_vm._v(" " + _vm._s(_vm.mdiCrop) + " ")]),
                      _vm._v(" Crop ")
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-title",
                    {
                      staticClass: "d-flex align-center gap cursor-pointer",
                      class: { "text--disabled": _vm.hasLegend },
                      on: { click: _vm.onClickAddLegend }
                    },
                    [
                      _c("v-icon", [
                        _vm._v(" " + _vm._s(_vm.mdiFormatListBulleted) + " ")
                      ]),
                      _vm._v(" Add Legend ")
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-title",
                    {
                      staticClass: "d-flex align-center gap cursor-pointer",
                      on: {
                        click: function($event) {
                          return _vm.$emit("download")
                        }
                      }
                    },
                    [
                      _c("v-icon", [
                        _vm._v(" " + _vm._s(_vm.mdiDownload) + " ")
                      ]),
                      _vm._v(" Download ")
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-btn",
        {
          attrs: { icon: "" },
          on: {
            click: function($event) {
              return _vm.$emit("dialog-close-button-click")
            }
          }
        },
        [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }