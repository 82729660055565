var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: { background: _vm.inRightPane } }, [
    _vm.loadingGisInfo
      ? _c(
          "div",
          {
            staticClass: "d-flex justify-center align-center",
            class: { background: _vm.inRightPane },
            staticStyle: { overflow: "hidden" }
          },
          [_c("v-progress-circular", { attrs: { indeterminate: "" } })],
          1
        )
      : _c(
          "section",
          {
            staticClass: "overflow-y-auto",
            style: {
              height: _vm.$vuetify.breakpoint.lgAndUp
                ? _vm.siteInfoHeight + "px"
                : undefined
            }
          },
          [
            _c(
              "v-expansion-panels",
              {
                staticClass: "d-flex justify-center align-center",
                attrs: { flat: "", multiple: "" },
                model: {
                  value: _vm.openPanels,
                  callback: function($$v) {
                    _vm.openPanels = $$v
                  },
                  expression: "openPanels"
                }
              },
              [
                _c(
                  "v-expansion-panel",
                  {
                    class: { background: _vm.inRightPane },
                    attrs: { value: "0" }
                  },
                  [
                    _c(
                      "v-expansion-panel-header",
                      {
                        staticClass: "text-uppercase nav-border-top px-5",
                        class: { background: _vm.inRightPane }
                      },
                      [_vm._v(" UtiliSync Fields ")]
                    ),
                    _c(
                      "v-expansion-panel-content",
                      {
                        staticClass: "py-0",
                        class: { background: _vm.inRightPane }
                      },
                      [
                        _vm.utiliSyncFields.length > 0
                          ? _c(
                              "section",
                              {
                                staticClass: "px-5 ma-0",
                                class: { background: _vm.inRightPane }
                              },
                              _vm._l(_vm.utiliSyncFields, function(r) {
                                return _c(
                                  "div",
                                  {
                                    key: r.gis_data_value_id,
                                    staticClass: "py-1 ma-0"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        class: { background: _vm.inRightPane }
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "\n                    caption\n                    d-flex\n                    align-center\n                    justify-space-between\n                    gap\n                  "
                                          },
                                          [
                                            _c(
                                              "section",
                                              {
                                                staticClass:
                                                  "d-flex align-center"
                                              },
                                              [
                                                _c("div", [
                                                  _vm._v(
                                                    " " + _vm._s(r.name) + " "
                                                  )
                                                ])
                                              ]
                                            ),
                                            _vm.editingUtiliSyncField[r.name] &&
                                            _vm.getUtiliSyncFieldType(r) ===
                                              "string" &&
                                            !_vm.isUtiliSyncFieldRefField(r)
                                              ? _c("v-switch", {
                                                  attrs: { label: "Rich Text" },
                                                  model: {
                                                    value:
                                                      _vm
                                                        .richTextUtiliSyncField[
                                                        r.name
                                                      ],
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.richTextUtiliSyncField,
                                                        r.name,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "richTextUtiliSyncField[r.name]"
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        ),
                                        !_vm.isRichTextField(r.value)
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "word-break": "break-word"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(r.value) + " "
                                                )
                                              ]
                                            )
                                          : _c("div", {
                                              staticStyle: {
                                                "word-break": "break-word"
                                              },
                                              domProps: {
                                                innerHTML: _vm._s(r.value)
                                              }
                                            })
                                      ]
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          : _c(
                              "section",
                              {
                                staticClass: "px-5 py-3",
                                class: { background: _vm.inRightPane }
                              },
                              [
                                _c("div", { staticClass: "caption" }, [
                                  _vm._v("No UtiliSync fields configured")
                                ])
                              ]
                            )
                      ]
                    )
                  ],
                  1
                ),
                _vm.notUtiliSyncLayer
                  ? _c(
                      "v-expansion-panel",
                      {
                        class: { background: _vm.inRightPane },
                        attrs: { value: "1" }
                      },
                      [
                        _c(
                          "v-expansion-panel-header",
                          {
                            staticClass:
                              "text-uppercase nav-border-top my-0 py-0 px-5",
                            class: { background: _vm.inRightPane }
                          },
                          [_vm._v(" GIS Fields ")]
                        ),
                        _vm.showGisFields
                          ? _c(
                              "v-expansion-panel-content",
                              {
                                staticClass: "my-0 py-0",
                                class: { background: _vm.inRightPane }
                              },
                              [
                                Object.keys(_vm.gisInfoAttributes).length > 0
                                  ? _c(
                                      "section",
                                      { staticClass: "px-5 ma-0" },
                                      _vm._l(_vm.gisInfoAttributes, function(
                                        value,
                                        key
                                      ) {
                                        return _c(
                                          "div",
                                          {
                                            key: key,
                                            staticClass: "py-1 ma-0"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "caption d-flex align-center justify-space-between"
                                              },
                                              [
                                                _c("div", [
                                                  _vm._v(
                                                    " " + _vm._s(key) + " "
                                                  )
                                                ]),
                                                _vm.editingGisField[key] &&
                                                _vm.getEsriFieldType(key) ===
                                                  "esriFieldTypeString" &&
                                                !Array.isArray(
                                                  _vm.getCodeValues(key)
                                                ) &&
                                                !_vm.isEsriFieldRefField(key)
                                                  ? _c("v-switch", {
                                                      attrs: {
                                                        label: "Rich Text"
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.richTextField[
                                                            key
                                                          ],
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.richTextField,
                                                            key,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "richTextField[key]"
                                                      }
                                                    })
                                                  : _vm._e()
                                              ],
                                              1
                                            ),
                                            !_vm.richTextField[key]
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "word-break": "break-word"
                                                    }
                                                  },
                                                  [
                                                    _vm.getEsriFieldType(
                                                      key
                                                    ) === "esriFieldTypeDate" &&
                                                    value !== null
                                                      ? _c("div", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm._f(
                                                                  "formatDate"
                                                                )(
                                                                  new Date(
                                                                    value
                                                                  )
                                                                )
                                                              ) +
                                                              " "
                                                          )
                                                        ])
                                                      : _c("div", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(value) +
                                                              " "
                                                          )
                                                        ])
                                                  ]
                                                )
                                              : _c("div", {
                                                  staticStyle: {
                                                    "word-break": "break-word"
                                                  },
                                                  domProps: {
                                                    innerHTML: _vm._s(value)
                                                  }
                                                })
                                          ]
                                        )
                                      }),
                                      0
                                    )
                                  : _c(
                                      "section",
                                      { staticClass: "px-5 py-3 ma-0" },
                                      [
                                        _c("div", { staticClass: "caption" }, [
                                          _vm._v("No GIS fields configured")
                                        ])
                                      ]
                                    )
                              ]
                            )
                          : _c(
                              "v-expansion-panel-content",
                              { style: { "background-color": "#fafafa" } },
                              [
                                _c(
                                  "v-list",
                                  { staticClass: "pa-0 ma-0" },
                                  [
                                    _c(
                                      "v-list-item",
                                      { staticClass: "px-5 ma-0" },
                                      [
                                        _c(
                                          "v-list-item-content",
                                          { staticClass: "caption" },
                                          [_vm._v(" GIS Fields Not Available ")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }