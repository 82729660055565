<template>
  <v-dialog :value="showChooseTaskDialog" width="330" persistent>
    <v-toolbar
      dark
      color="#3F51B5"
      class="elevation-0"
      width="100%"
      id="top-bar"
      ref="toolbar"
    >
      <v-toolbar-title
        class="d-flex justify-space-between align-center"
        style="width: 100%"
      >
        <div>Select Open Task</div>

        <v-btn icon @click="$emit('choose-task-dialog-close')">
          <v-icon> {{ mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar-title>
    </v-toolbar>

    <v-card>
      <v-card-text
        class="pa-5"
        id="dynamic-form-card-text"
        :style="{
          height: $vuetify.breakpoint.xsOnly ? `${contentHeight}px` : '65vh',
        }"
      >
        <p>
          There is an open task to complete this form. Select the task to apply
          this form to.
        </p>

        <div v-if="taskGroups.length > 0">
          <div v-for="taskGroup of taskGroups" :key="taskGroup.date">
            <div
              class="px-4 d-flex align-center gap"
              v-if="taskGroup.date !== 'null'"
            >
              <div class="font-weight-medium display-1 black--text">
                {{ taskGroup.date | formatVariableDueDate("DD") }}
              </div>
              <section>
                <div style="font-size: 20px" class="black--text">
                  {{ taskGroup.date | formatVariableDueDate("dddd") }}
                </div>
                <div class="black--text">
                  {{ taskGroup.date | formatVariableDueDate("MMMM YYYY") }}
                </div>
              </section>
            </div>
            <v-timeline align-top dense class="ml-n3">
              <v-timeline-item
                color="#00A9F4"
                v-for="task of taskGroup.groupedTasks"
                :key="task.item_id"
                fill-dot
              >
                <v-avatar
                  slot="icon"
                  @click="
                    selectedTask = task;
                    openItem();
                  "
                  style="cursor: pointer"
                  :id="task.item_type + 'Btn'"
                >
                  <v-icon color="white"> {{ getIcon(task) }}</v-icon>
                </v-avatar>
                <div class="d-flex justify-space-between ml-n2">
                  <div class="pr-2">
                    <div class="caption">
                      {{ task.assigned_to_name }}
                    </div>
                    <div>
                      <p
                        v-if="task.description"
                        class="caption font-weight-bold py-0 my-0"
                      >
                        {{ task.description }}
                      </p>
                      <p v-else class="caption font-weight-bold py-0 my-0">
                        No task description set
                      </p>
                    </div>
                    <div class="py-1" v-if="task.item_type === 'action_item'">
                      <v-chip color="#e0e0e0" :disabled="true">
                        {{ getPillText(task) }}
                      </v-chip>
                    </div>
                  </div>
                </div>
              </v-timeline-item>
            </v-timeline>
          </div>
        </div>

        <h2 class="font-weight-regular black--text px-6 py-2">OR</h2>

        <div class="d-flex align-center gap" style="padding: 0 17px">
          <v-btn
            color="#3F51B5"
            @click="$emit('start-new-form')"
            fab
            small
            dark
            id="startFormBtn"
          >
            <v-icon>
              {{ mdiNotePlusOutline }}
            </v-icon>
          </v-btn>
          <div class="font-weight-regular body-1 black--text">
            Start New Form
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose, mdiNotePlusOutline } from "@mdi/js";
import { axiosWithJwtAuth } from "@/plugins/axios";
import moment from "moment";
import contentHeightMixin from "@/mixins/contentHeightMixin";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "SharedSiteChooseTaskDialog",
  props: {
    showChooseTaskDialog: Boolean,
    selectedMapServiceId: String,
    objectId: Number,
    globalId: String,
    formDefinitionId: String,
  },
  mixins: [contentHeightMixin],
  data() {
    return {
      mdiClose,
      mdiNotePlusOutline,
      openTasks: [],
      selectedTask: {},
      showTicketEditForm: false,
    };
  },
  computed: {
    taskGroups() {
      const filteredTasksDates = [
        ...new Set(this.openTasks.map((t) => t?.due_date)),
      ];

      const groups = filteredTasksDates.map((date) => {
        return {
          date,
          groupedTasks: this.openTasks.filter((t) => {
            return (
              moment(t?.due_date).format("YYYY-MM-DD") ===
                moment(date).format("YYYY-MM-DD") || t?.due_date === date
            );
          }),
        };
      });
      return groups;
    },
  },
  methods: {
    async getOpenTasks() {
      const { selectedMapServiceId, objectId, formDefinitionId } = this;
      if (!navigator.onLine) {
        return;
      }
      const {
        data: { results },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/action_item_and_tasks/${this.$route.query.siteId}`,
        {
          params: {
            map_service_id: selectedMapServiceId,
            object_id: objectId,
          },
        }
      );
      const openTasks = results.filter(
        (r) =>
          r.item_type === "task" &&
          r.form_definition_id === formDefinitionId &&
          r.status.toLowerCase() !== "closed" &&
          r.status.toLowerCase() !== "canceled" &&
          r.status.toLowerCase() !== "cancelled"
      );
      this.openTasks = openTasks;
    },
    openItem() {
      this.showTicketEditForm = true;
      this.$emit("task-selected", this.selectedTask);
    },
    getPillText(task) {
      if (task.status === "rejected") {
        return "Rejected";
      } else if (task.status === "ready_for_review") {
        return "Ready for Review";
      } else {
        return "Open";
      }
    },
    getIcon(taskItem) {
      if (taskItem.item_type === "action_item") {
        return "mdi-checkbox-marked-outline";
      } else if (taskItem.item_type === "task") {
        return "mdi-calendar-check";
      } else {
        return "";
      }
    },
  },
  beforeMount() {
    this.getOpenTasks();
  },
};
</script>
