<template>
  <v-app style="background-color: #fafafa">
    <v-main fluid tag="section" class="pa-0 ma-0">
      <SharedSiteLayout
        :class="{ 'overflow-hidden': $vuetify.breakpoint.lgAndUp }"
      >
        <SharedSiteTopBar
          :sharedSite="sharedSite"
          :selectedMapService="selectedMapService"
          :gisDataFieldsAndValues="gisDataFieldsAndValues"
          :objectId="featureId"
        />

        <v-row
          class="px-3 mt-16"
          v-if="loaded"
          :class="{
            'my-0': $vuetify.breakpoint.mdAndDown,
            'py-0': $vuetify.breakpoint.mdAndDown,
          }"
        >
          <v-col
            cols="12"
            lg="4"
            class="d-flex flex-column"
            :class="{
              'my-0': $vuetify.breakpoint.mdAndDown,
              'py-0': $vuetify.breakpoint.mdAndDown,
            }"
          >
            <v-card class="my-1 flex-grow-0" id="createNewForm">
              <v-card-title
                class="font-weight-regular d-flex justify-space-between"
                v-ripple="$vuetify.breakpoint.mdAndDown"
                :style="{
                  cursor: $vuetify.breakpoint.mdAndDown ? 'pointer' : undefined,
                }"
                @click="onHeadingClick('createNewForm')"
              >
                <section class="d-flex gap">
                  <v-btn
                    fab
                    dark
                    color="#2193F6"
                    x-small
                    :ripple="false"
                    elevation="0"
                    class="disable-pointer"
                  >
                    <v-icon>
                      {{ mdiFileDocumentPlusOutline }}
                    </v-icon>
                  </v-btn>
                  <div>Create New Form</div>
                </section>

                <section v-if="$vuetify.breakpoint.mdAndDown">
                  <v-btn icon>
                    <v-icon>
                      {{
                        showSection.createNewForm
                          ? mdiChevronUp
                          : mdiChevronDown
                      }}
                    </v-icon>
                  </v-btn>
                </section>
              </v-card-title>
              <v-card-text v-show="showSection.createNewForm">
                <SharedSiteTicketFormDefDropdown
                  :mapServices="mapServices"
                  :globalId="gisInfoId"
                  :selectedMapServiceId="selectedMapServiceId"
                  :objectId="featureId"
                  @ticket-edit-form-submitted="onTicketEditFormSubmitted"
                />
              </v-card-text>
            </v-card>

            <v-card class="my-1 flex-grow-0" id="siteContacts">
              <v-card-title
                class="font-weight-regular d-flex justify-space-between"
                v-ripple="$vuetify.breakpoint.mdAndDown"
                :style="{
                  cursor: $vuetify.breakpoint.mdAndDown ? 'pointer' : undefined,
                }"
                @click="onHeadingClick('siteContacts')"
              >
                <section class="d-flex gap">
                  <v-btn
                    fab
                    dark
                    color="#2193F6"
                    x-small
                    :ripple="false"
                    elevation="0"
                    class="disable-pointer"
                  >
                    <v-icon>
                      {{ mdiMapMarkerAccountOutline }}
                    </v-icon>
                  </v-btn>
                  <div>Site Contacts</div>
                </section>

                <section v-if="$vuetify.breakpoint.mdAndDown">
                  <v-btn icon>
                    <v-icon>
                      {{
                        showSection.siteContacts ? mdiChevronUp : mdiChevronDown
                      }}
                    </v-icon>
                  </v-btn>
                </section>
              </v-card-title>
              <v-card-text v-show="showSection.siteContacts">
                <SharedSiteSiteContacts
                  :selectedMapServiceId="selectedMapServiceId"
                  :objectId="featureId"
                />
              </v-card-text>
            </v-card>

            <v-card class="my-1 flex-grow-1" id="siteInformation">
              <v-card-title
                class="font-weight-regular d-flex justify-space-between"
                v-ripple="$vuetify.breakpoint.mdAndDown"
                :style="{
                  cursor: $vuetify.breakpoint.mdAndDown ? 'pointer' : undefined,
                }"
                @click="onHeadingClick('siteInformation')"
              >
                <section class="d-flex gap">
                  <v-btn
                    fab
                    dark
                    color="#2193F6"
                    x-small
                    :ripple="false"
                    elevation="0"
                    class="disable-pointer"
                  >
                    <v-icon>
                      {{ mdiGoogleMaps }}
                    </v-icon>
                  </v-btn>
                  <div>Site Information</div>
                </section>

                <section v-if="$vuetify.breakpoint.mdAndDown">
                  <v-btn icon>
                    <v-icon>
                      {{
                        showSection.siteInformation
                          ? mdiChevronUp
                          : mdiChevronDown
                      }}
                    </v-icon>
                  </v-btn>
                </section>
              </v-card-title>
              <v-card-text v-show="showSection.siteInformation">
                <div class="flex-grow-1 overflow-y-auto">
                  <SharedSiteGisInfo
                    :mapServices="mapServices"
                    :globalId="gisInfoId"
                    :selectedMapServiceId="selectedMapServiceId"
                    :objectId="featureId"
                    :gisInfoAttributes="gisInfoAttributes"
                  />
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            lg="4"
            class="d-flex flex-column"
            :class="{
              'my-0': $vuetify.breakpoint.mdAndDown,
              'py-0': $vuetify.breakpoint.mdAndDown,
            }"
          >
            <v-card class="my-1 flex-grow-1" id="tasks">
              <v-card-title
                class="font-weight-regular d-flex justify-space-between"
                v-ripple="$vuetify.breakpoint.mdAndDown"
                :style="{
                  cursor: $vuetify.breakpoint.mdAndDown ? 'pointer' : undefined,
                }"
                @click="onHeadingClick('tasks')"
              >
                <section class="d-flex gap">
                  <v-btn
                    fab
                    dark
                    color="#2193F6"
                    x-small
                    :ripple="false"
                    elevation="0"
                    class="disable-pointer"
                  >
                    <v-icon>
                      {{ mdiFormatListChecks }}
                    </v-icon>
                  </v-btn>
                  <div>Tasks</div>
                </section>

                <section v-if="$vuetify.breakpoint.mdAndDown">
                  <v-btn icon>
                    <v-icon>
                      {{ showSection.tasks ? mdiChevronUp : mdiChevronDown }}
                    </v-icon>
                  </v-btn>
                </section>
              </v-card-title>
              <v-card-text v-show="showSection.tasks">
                <SharedSiteTasksTab
                  :globalId="gisInfoId"
                  :selectedMapServiceId="selectedMapServiceId"
                  :objectId="featureId"
                  :currentUser="currentUser"
                />
              </v-card-text>
            </v-card>
          </v-col>

          <v-col
            cols="12"
            lg="4"
            class="d-flex flex-column"
            :class="{
              'my-0': $vuetify.breakpoint.mdAndDown,
              'py-0': $vuetify.breakpoint.mdAndDown,
            }"
          >
            <v-card class="my-1" id="log">
              <v-card-title
                class="font-weight-regular d-flex justify-space-between"
                v-ripple="$vuetify.breakpoint.mdAndDown"
                :style="{
                  cursor: $vuetify.breakpoint.mdAndDown ? 'pointer' : undefined,
                }"
                @click="onHeadingClick('log')"
              >
                <section class="d-flex gap">
                  <v-btn
                    fab
                    dark
                    color="#2193F6"
                    x-small
                    :ripple="false"
                    elevation="0"
                    class="disable-pointer"
                  >
                    <v-icon>
                      {{ mdiViewList }}
                    </v-icon>
                  </v-btn>
                  <div>Log</div>
                </section>

                <section v-if="$vuetify.breakpoint.mdAndDown">
                  <v-btn icon>
                    <v-icon>
                      {{ showSection.log ? mdiChevronUp : mdiChevronDown }}
                    </v-icon>
                  </v-btn>
                </section>
              </v-card-title>
              <v-card-text v-show="showSection.log">
                <SharedSiteLog
                  :mapServices="mapServices"
                  :globalId="gisInfoId"
                  :selectedMapServiceId="selectedMapServiceId"
                  :objectId="featureId"
                  :currentUser="currentUser"
                  @form-result-deleted="onTicketEditFormSubmitted"
                />
              </v-card-text>
            </v-card>

            <v-card class="my-1 flex-grow-1" id="documents">
              <v-card-title
                class="font-weight-regular d-flex justify-space-between"
                v-ripple="$vuetify.breakpoint.mdAndDown"
                :style="{
                  cursor: $vuetify.breakpoint.mdAndDown ? 'pointer' : undefined,
                }"
                @click="onHeadingClick('documents')"
              >
                <section class="d-flex gap">
                  <v-btn
                    fab
                    dark
                    color="#2193F6"
                    x-small
                    :ripple="false"
                    elevation="0"
                    class="disable-pointer"
                  >
                    <v-icon>
                      {{ mdiFile }}
                    </v-icon>
                  </v-btn>
                  <div>Documents</div>
                </section>

                <section v-if="$vuetify.breakpoint.mdAndDown">
                  <v-btn icon>
                    <v-icon>
                      {{
                        showSection.documents ? mdiChevronUp : mdiChevronDown
                      }}
                    </v-icon>
                  </v-btn>
                </section>
              </v-card-title>
              <v-card-text v-show="showSection.documents">
                <SharedSiteDocsTab
                  :globalId="gisInfoId"
                  :selectedMapServiceId="selectedMapServiceId"
                  :objectId="featureId"
                  :currentUser="currentUser"
                  :currentUserOrg="currentUserOrg"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <div v-else>
          <v-card class="d-flex flex-column">
            <v-card-text class="flex-grow-1 d-flex justify-center align-center">
              <v-progress-circular indeterminate></v-progress-circular>
            </v-card-text>
          </v-card>
        </div>

        <v-snackbar v-model="showFormSubmittedSnackbar">
          <v-icon dark>
            {{ mdiInformation }}
          </v-icon>
          Success! The form was submitted.

          <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="viewForm"> View Form </v-btn>
          </template>
        </v-snackbar>

        <v-dialog
          v-model="showViewFormEditFormDialog"
          max-width="600px"
          persistent
          :fullscreen="$vuetify.breakpoint.xsOnly"
        >
          <v-card>
            <SharedSiteDynamicForm
              :formDefinition="formResultBeingViewed.selectedFormResult"
              :existingFormResultIdMap="
                formResultBeingViewed.existingFormResultIdMap
              "
              :selectedPdfFileUrl="formResultBeingViewed.selectedPdfFileUrl"
              :canEdit="false"
              alreadySubmittedFinalOnline
              :globalId="formResultBeingViewed.globalId"
              :objectId="formResultBeingViewed.objectId"
              :selectedMapServiceId="formResultBeingViewed.selectedMapServiceId"
              @ticket-edit-form-close-button-click="
                showViewFormEditFormDialog = false;
                resetFormResultBeingViewed();
              "
              @ticket-edit-form-close="
                showViewFormEditFormDialog = false;
                resetFormResultBeingViewed();
              "
              @ticket-edit-form-submitted="
                showViewFormEditFormDialog = false;
                resetFormResultBeingViewed();
              "
              v-if="showViewFormEditFormDialog"
            />
          </v-card>
        </v-dialog>

        <SharedSiteActionItemTabActionItemDialog
          v-if="showActionItemTabActionItemDialog"
          :action="getActionItemLabel(selectedOpenActionItem)"
          :showActionItemDialog="showActionItemTabActionItemDialog"
          :selectedActionItem="selectedOpenActionItem"
          :selectedActionItemId="selectedOpenActionItem.action_item_id"
          :hideResponseSection="false"
          @action-item-dialog-close="showActionItemTabActionItemDialog = false"
          @action-item-saved="showActionItemTabActionItemDialog = false"
          @action-item-response-saved="
            showActionItemTabActionItemDialog = false
          "
          @reject-action-item="showActionItemTabActionItemDialog = false"
          @accept-action-item="showActionItemTabActionItemDialog = false"
        />
      </SharedSiteLayout>
    </v-main>
  </v-app>
</template>

<script>
import SharedSiteTicketFormDefDropdown from "@/components/shared-site/SharedSiteTicketFormDefDropdown.vue";
import SharedSiteDocsTab from "@/components/shared-site/SharedSiteDocsTab.vue";
import SharedSiteTasksTab from "@/components/shared-site/SharedSiteTasksTab.vue";
import SharedSiteLog from "@/components/shared-site/SharedSiteLog.vue";
import SharedSiteGisInfo from "@/components/shared-site/SharedSiteGisInfo";
import SharedSiteSiteContacts from "@/components/shared-site/SharedSiteSiteContacts";
import SharedSiteActionItemTabActionItemDialog from "@/components/shared-site/shared-site-ticket-form-def-drop-down/shared-site-ticket-edit-form/shared-site-dynamic-form/shared-site-action-item/SharedSiteActionItemTabActionItemDialog";
import {
  mdiGoogleMaps,
  mdiCheckboxMarkedOutline,
  mdiFile,
  mdiFormatListChecks,
  mdiMenu,
  mdiFileDocumentPlusOutline,
  mdiArrowCollapse,
  mdiMapMarkerAccountOutline,
  mdiViewList,
  mdiChevronDown,
  mdiChevronUp,
  mdiInformation,
} from "@mdi/js";
import { mapMutations, mapGetters } from "vuex";
import SharedSiteTopBar from "@/components/shared-site/SharedSiteTopBar";
import { axiosWithJwtAuth, axiosWithNoAuth } from "@/plugins/axios";
import SharedSiteLayout from "@/components/app/SharedSiteLayout.vue";
import sharedSiteUsetifulMixin from "@/mixins/sharedSiteUsetifulMixin";
import SharedSiteDynamicForm from "@/components/shared-site/shared-site-ticket-form-def-drop-down/shared-site-ticket-edit-form/SharedSiteDynamicForm";
import jwt_decode from "jwt-decode";
import actionItemMixin from "@/mixins/actionItemMixin";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "SharedSite",
  components: {
    SharedSiteTicketFormDefDropdown,
    SharedSiteDocsTab,
    SharedSiteTasksTab,
    SharedSiteLog,
    SharedSiteGisInfo,
    SharedSiteTopBar,
    SharedSiteLayout,
    SharedSiteSiteContacts,
    SharedSiteDynamicForm,
    SharedSiteActionItemTabActionItemDialog,
  },
  mixins: [sharedSiteUsetifulMixin, actionItemMixin],
  computed: {
    ...mapGetters([
      "isMenuOpen",
      "sharedSiteTokenLoaded",
      "formSubmitted",
      "sharedSite",
    ]),
    gisInfoAttributes() {
      if (this.notUtiliSyncLayer) {
        if (!this.attributes || !Array.isArray(this.fields)) {
          return {};
        }

        const entries = this.fields.map(({ name: key }) => {
          const value = this.attributes[key];

          return [this.findAliasByName(this.fields, key), value];
        });
        return Object.fromEntries(entries);
      } else {
        const entries = this.gisDataFieldsAndValues.map((fv) => {
          const { name, value } = fv;
          return [name, value];
        });
        return Object.fromEntries(entries);
      }
    },
    notUtiliSyncLayer() {
      return this.selectedMapService.service_type !== "U";
    },
    title() {
      return this.selectedMapService.service_name;
    },
    subtitle() {
      if (this.notUtiliSyncLayer) {
        return this.attributes[this.selectedMapService.ref_field];
      }
      const { ref_field: refField } = this.selectedMapService;
      return (
        this.gisDataFieldsAndValues.find((f) => f.name === refField)?.value ??
        "[Not Provided]"
      );
    },
  },
  data() {
    return {
      mdiArrowCollapse,
      mdiGoogleMaps,
      mdiCheckboxMarkedOutline,
      mdiFile,
      mdiFormatListChecks,
      mdiMenu,
      mdiFileDocumentPlusOutline,
      mdiMapMarkerAccountOutline,
      mdiViewList,
      mdiChevronDown,
      mdiChevronUp,
      mdiInformation,
      gisInfoId: undefined,
      featureId: undefined,
      selectedMapServiceId: undefined,
      attributes: {},
      fields: [],
      selectedMapService: {},
      gisDataFieldsAndValues: [],
      loaded: false,
      gisDataValues: [],
      showSiteContactAccessDialog: false,
      showSection: {
        createNewForm: true,
        siteContacts: false,
        siteInformation: false,
        tasks: false,
        log: false,
        documents: false,
      },
      showFormSubmittedSnackbar: false,
      showViewFormEditFormDialog: false,
      formResultBeingViewed: {
        selectedFormResult: {},
        existingFormResultIdMap: {},
        selectedPdfFileUrl: "",
        globalId: undefined,
        objectId: undefined,
        selectedMapServiceId: undefined,
      },
      mapServices: [],
      currentUser: {},
      currentUserOrg: {},
      selectedOpenActionItem: {},
      showActionItemTabActionItemDialog: false,
    };
  },
  methods: {
    ...mapMutations([
      "setMenuState",
      "setFormSubmitted",
      "setSharedSiteTokenLoaded",
      "setSharedSite",
    ]),
    async openActionItemDialogOnPageLoad() {
      const { actionItemId, siteId } = this.$route.query;
      if (!actionItemId) {
        return;
      }

      const {
        data: { results },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/action_item/${actionItemId}/${siteId}`
      );
      if (!results) {
        return;
      }
      this.selectedOpenActionItem = results;
      this.showActionItemTabActionItemDialog = true;
    },
    resetFormResultBeingViewed() {
      this.formResultBeingViewed = {
        selectedFormResult: {},
        existingFormResultIdMap: {},
        selectedPdfFileUrl: "",
        globalId: undefined,
        objectId: undefined,
        selectedMapServiceId: undefined,
      };
    },
    async viewForm() {
      const { formResultId } = this.formSubmitted;
      const { data: formResult } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/form_results/${formResultId}/${this.$route.query.siteId}`
      );
      const { feature_id: objectId, map_service_id: mapServiceId } = formResult;
      const { gisDataPointId } = formResult.feature_attributes;
      this.formResultBeingViewed = {
        selectedFormResult: formResult,
        existingFormResultIdMap: {
          formResultId,
        },
        selectedPdfFileUrl: formResult.pdfFileUrl,
        globalId: gisDataPointId,
        objectId,
        selectedMapServiceId: mapServiceId,
      };
      this.showViewFormEditFormDialog = true;
      this.setFormSubmitted({
        isFormSubmitted: false,
        formResultId: undefined,
      });
    },
    onResize() {
      const [elHtml] = document.getElementsByTagName("html");
      if (this.$vuetify.breakpoint.xlOnly) {
        elHtml.style.overflowY = "hidden";
        document.body.style.overflowY = "hidden";
      } else {
        elHtml.style.overflowY = "auto";
        document.body.style.overflowY = "auto";
      }
    },
    onHeadingClick(sectionName) {
      if (this.$vuetify.breakpoint.mdAndDown) {
        this.$set(
          this.showSection,
          sectionName,
          !this.showSection[sectionName]
        );
      }
    },
    async onTicketEditFormSubmitted() {
      this.loaded = false;
      const {
        data: { results: site },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/shared_sites/${this.$route.query.siteId}`
      );
      this.setSharedSite(site);
    },
    findAliasByName(fields, name) {
      const field = fields.find((f) => f.name === name);
      return field?.alias || name;
    },
    async getMapServices() {
      const {
        data: { results: mapServices },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/map_services/${this.$route.query.siteId}`
      );
      this.mapServices = mapServices;
    },
    async getSharedSite() {
      if (!this.sharedSiteTokenLoaded || !this.$route.query.siteId) {
        this.publicSite = {};
        return;
      }
      if (this.sharedSite?.site_id !== this.$route.query.siteId) {
        const {
          data: { results: site },
        } = await axiosWithJwtAuth.get(
          `${APIURL}/shared_site/shared_sites/${this.$route.query.siteId}`
        );
        this.setSharedSite(site);
      }
    },
    async getUserData() {
      if (!this.sharedSiteTokenLoaded) {
        return;
      }
      const token = localStorage.getItem("shared-site-token");
      const decoded = jwt_decode(token);
      const { email_address: emailAddress } = decoded;
      if (!emailAddress) {
        return;
      }

      if (this.$route.query.siteId) {
        const {
          data: { results },
        } = await axiosWithJwtAuth.get(
          `${APIURL}/shared_site/users/one/${this.$route.query.siteId}`
        );
        this.currentUser = results;

        const {
          data: { results: currentUserOrg },
        } = await axiosWithJwtAuth.get(
          `${APIURL}/shared_site/user_group/${this.$route.query.siteId}`
        );
        this.currentUserOrg = currentUserOrg;
      } else {
        this.currentUser = {
          email: emailAddress,
        };
      }
    },
  },
  async beforeMount() {
    if (!this.$route.query.siteId) {
      await this.$router.push({
        name: "Sites",
      });
      return;
    }
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
    this.getMapServices();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    const [elHtml] = document.getElementsByTagName("html");
    elHtml.style.overflowY = "auto";
  },
  watch: {
    sharedSiteTokenLoaded: {
      immediate: true,
      async handler(val) {
        if (val) {
          await this.getUserData();
          await this.getSharedSite();
        }
      },
    },
    "$route.query.siteId": {
      immediate: true,
      handler(val) {
        if (val) {
          this.getSharedSite();
          localStorage.setItem("shared-site-token", val);
          this.setSharedSiteTokenLoaded(true);
        }
      },
    },
    sharedSite: {
      deep: true,
      immediate: true,
      async handler(val) {
        if (!val) {
          return;
        }
        const {
          map_service_id: mapServiceId,
          feature_id: objectId,
          global_id: globalId,
        } = val;
        this.selectedMapServiceId = mapServiceId;
        const { selectedMapServiceId } = this;
        if (!mapServiceId || !this.$route.query.siteId) {
          return;
        }

        const {
          data: { results },
        } = await axiosWithJwtAuth.get(
          `${APIURL}/shared_site/map_services/${mapServiceId}/${this.$route.query.siteId}`
        );
        this.selectedMapService = results;
        if (this.selectedMapService.service_type === "F") {
          this.featureId = +objectId;
          if (navigator.onLine) {
            const mapServiceUrl = results.service_url;
            let queryResult = {};
            try {
              const { data } = await axiosWithNoAuth.get(
                `${mapServiceUrl}/query`,
                {
                  params: {
                    objectids: objectId,
                    outFields: "*",
                    f: "json",
                    token: localStorage.getItem("esri_token"),
                  },
                }
              );
              queryResult = data;
              const { features, fields } = queryResult;
              const [{ attributes }] = features ?? [{}];
              this.attributes = attributes ?? {};
              this.fields = fields;
              this.gisInfoId = globalId;
              this.loaded = true;
            } catch (error) {
              console.log(error);
            }
          }
        } else {
          this.featureId = +objectId;
          const {
            data: { results: allGisDataFields },
          } = await axiosWithJwtAuth.get(
            `${APIURL}/shared_site/gis_data_fields_by_user_group/${this.$route.query.siteId}`
          );

          const {
            data: { results: gisDataValues },
          } = await axiosWithJwtAuth.get(
            `${APIURL}/shared_site/gis_data_values/all/${this.$route.query.siteId}`
          );

          const gisDataValueIds = gisDataValues.map((g) => g.gis_data_field_id);

          const gisDataFields = allGisDataFields.filter((g) => {
            return (
              g.map_service_id === mapServiceId &&
              !gisDataValueIds.includes(objectId)
            );
          });

          this.gisDataFieldsAndValues = gisDataFields?.map((gdf) => {
            const { name } = gdf;
            const value = gisDataValues?.find((gdv) => {
              return (
                gdv?.gis_data_field_id === gdf?.gis_data_field_id &&
                gdv?.feature_id === objectId
              );
            })?.value;

            return {
              name,
              value,
            };
          });

          const {
            data: { results: gisDataPoint },
          } = await axiosWithJwtAuth.get(
            `${APIURL}/shared_site/gis_data_point`,
            {
              params: {
                map_service_id: selectedMapServiceId,
                feature_id: objectId,
              },
            }
          );
          this.gisInfoId = gisDataPoint?.gis_data_point_id;
          this.loaded = true;
        }
      },
    },
    formSubmitted: {
      deep: true,
      handler({ isFormSubmitted }) {
        if (isFormSubmitted) {
          this.showFormSubmittedSnackbar = true;
        }
      },
    },
    "$vuetify.breakpoint": {
      immediate: true,
      deep: true,
      handler(val) {
        if (val.mdAndDown) {
          this.showSection = {
            createNewForm: true,
            siteContacts: false,
            siteInformation: false,
            tasks: false,
            log: false,
            documents: false,
          };
        } else {
          this.showSection = {
            createNewForm: true,
            siteContacts: true,
            siteInformation: true,
            tasks: true,
            log: true,
            documents: true,
          };
        }
      },
    },
    "$route.query.actionItemId": {
      immediate: true,
      async handler(val) {
        if (!val) {
          return;
        }
        await this.openActionItemDialogOnPageLoad();
      },
    },
  },
};
</script>

<style scoped>
.gap {
  gap: 10px;
}

.disable-pointer {
  pointer-events: none;
}

.column {
  height: 50vh;
}
</style>
