var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showSharedSiteSelectDocDialog,
        "max-width": "400px",
        persistent: ""
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-toolbar",
            {
              staticClass: "elevation-0",
              attrs: { dark: "", color: "#3F51B5" }
            },
            [
              _c("v-toolbar-title", [_vm._v("Select Doc")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("select-doc-dialog-close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "py-3" },
            [
              _c("p", { staticClass: "caption py-0 my-0" }, [_vm._v("Folder")]),
              _c("DocsBreadcrumb", {
                staticClass: "py-2",
                attrs: { folderPath: _vm.currentFolder },
                on: { "navigate-folder": _vm.onNavigateToFolder }
              }),
              _c("v-divider", { staticClass: "my-2" }),
              _vm.foldersAtLevel.length > 0 || _vm.filteredFiles.length > 0
                ? _c(
                    "div",
                    [
                      _vm._l(_vm.foldersAtLevel, function(f) {
                        return _c(
                          "div",
                          {
                            key: f.folder,
                            staticClass:
                              "d-flex gap align-center py-1 cursor-pointer",
                            on: {
                              click: function($event) {
                                return _vm.onFolderClick(f.folder)
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              [
                                _c("v-icon", { attrs: { color: "#3F51B5" } }, [
                                  _vm._v(" " + _vm._s(_vm.mdiFolder) + " ")
                                ])
                              ],
                              1
                            ),
                            _c("div", { style: { color: "#00A9F4" } }, [
                              _vm._v(
                                " " +
                                  _vm._s(f.folder.split("/").slice(-1)[0]) +
                                  " "
                              )
                            ])
                          ]
                        )
                      }),
                      _vm._l(_vm.filteredFiles, function(f) {
                        return _c(
                          "div",
                          {
                            key: f.file_id,
                            staticClass:
                              "d-flex gap align-center py-1 cursor-pointer",
                            on: {
                              click: function($event) {
                                return _vm.onFileClick(f)
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              [
                                _c("v-icon", { attrs: { color: "#4F4F4F" } }, [
                                  _vm._v(" " + _vm._s(_vm.mdiFile) + " ")
                                ])
                              ],
                              1
                            ),
                            _c("div", { style: { color: "#00A9F4" } }, [
                              _vm._v(" " + _vm._s(f.name) + " ")
                            ])
                          ]
                        )
                      })
                    ],
                    2
                  )
                : _c("div", [_vm._v("No images in this folder")]),
              _vm.showSelectPdfPageDialog
                ? _c("SelectPdfPageDialog", {
                    attrs: {
                      showSelectPdfPageDialog: _vm.showSelectPdfPageDialog,
                      selectedFile: _vm.selectedFile,
                      maxWidthHeight: +_vm.maxWidthHeight,
                      formResultId: _vm.formResultId
                    },
                    on: {
                      "select-pdf-page-dialog-close": function($event) {
                        _vm.showSelectPdfPageDialog = false
                      },
                      "confirm-add-page": _vm.onConfirmAddPage
                    }
                  })
                : _vm._e(),
              _vm.showAddPageToFormDialog
                ? _c("AddPageToFormDialog", {
                    attrs: {
                      showAddPageToFormDialog: _vm.showAddPageToFormDialog,
                      selectedPage: _vm.selectedPage,
                      maxWidthHeight: +_vm.maxWidthHeight,
                      formResultId: _vm.formResultId
                    },
                    on: {
                      "add-page-to-form-dialog-close": function($event) {
                        _vm.showAddPageToFormDialog = false
                      },
                      "confirm-add-page": _vm.onConfirmAddPage
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }