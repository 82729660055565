import { axiosWithJwtAuth, axiosWithNoAuth } from "@/plugins/axios";
import {
  getGisRelatedTableFieldsToUpdate,
  getUpdateGisMainTable,
} from "@/mixins/esriTablesUtilibots";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  data() {
    return {
      isUpdatingFeatureService: false,
      isAddingToRelatedTables: false,
    };
  },
  methods: {
    async updateFeatureService() {
      const { data: computedFormDefinition } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/form_results/${this.formResultId}/${this.$route.query.siteId}`
      );
      const {
        data: { results: mapService },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/map_services/${computedFormDefinition?.map_service_id}/${this.$route.query.siteId}`
      );
      if (mapService?.token_type === "AGOL") {
        return;
      }

      const { formResultId } = this;
      const { feature_attributes: featureAttributes } = computedFormDefinition;
      const mapServiceUrl = mapService.service_url;
      if (!mapService?.service_url) {
        return;
      }
      this.isUpdatingFeatureService = true;
      const { data } = await axiosWithNoAuth.get(`${mapServiceUrl}/query`, {
        params: {
          objectids: featureAttributes?.OBJECTID,
          outFields: "*",
          f: "json",
          token: localStorage.getItem("esri_token"),
        },
      });
      const [feature] = data.features;
      const {
        updatedFeature,
        formResultTaskMessageErrors,
        formResultTaskMessage,
      } =
        getUpdateGisMainTable(
          computedFormDefinition,
          this.formResultId,
          feature
        ) ?? {};
      const { attributes } = updatedFeature;
      const formData = new FormData();
      formData.append("f", "pjson");
      formData.append("token", localStorage.getItem("esri_token"));
      formData.append(
        "updates",
        JSON.stringify({
          attributes,
        })
      );
      const {
        data: { results },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/form_result_tasks/${formResultId}`
      );
      const esriMainTableTask = results?.find(
        (r) => r.type_name === "update_esri_main_tables"
      );
      const hasUpdatedEsriMainTableTask = Boolean(esriMainTableTask);
      let messageId = esriMainTableTask?.message_id;

      try {
        const { data } = await axiosWithNoAuth.post(
          `${mapServiceUrl}/applyEdits`,
          formData
        );

        if (!hasUpdatedEsriMainTableTask) {
          await axiosWithJwtAuth.post(
            `${APIURL}/shared_site/form_result_tasks`,
            {
              form_result_id: formResultId,
              type_name: "update_esri_main_tables",
            }
          );
          const {
            data: { results },
          } = await axiosWithJwtAuth.get(
            `${APIURL}/shared_site/form_result_tasks/${formResultId}`
          );
          const esriMainTableTask = results?.find(
            (r) => r.type_name === "update_esri_main_tables"
          );
          messageId = esriMainTableTask?.message_id;
        }

        let message = "";
        if (formResultTaskMessageErrors.length >= 1) {
          message = `${formResultTaskMessageErrors.join(
            " - "
          )} ${formResultTaskMessage.join(" - ")}`;
        } else {
          message = formResultTaskMessage.join(" - ");
        }

        const [updateResult] = data?.updateResults ?? [];
        if (updateResult?.success) {
          await axiosWithJwtAuth.put(
            `${APIURL}/shared_site/form_result_tasks/update`,
            {
              message_id: messageId,
              status_name:
                formResultTaskMessageErrors.length >= 1
                  ? "completed_error"
                  : "completed_success",
              message,
            }
          );
        } else {
          await axiosWithJwtAuth.put(
            `${APIURL}/shared_site/form_result_tasks/update`,
            {
              message_id: messageId,
              status_name: "dead_letter_queue",
              message: updateResult?.error?.description,
            }
          );
        }
      } catch (error) {
        if (!hasUpdatedEsriMainTableTask) {
          await axiosWithJwtAuth.post(
            `${APIURL}/shared_site/form_result_tasks`,
            {
              form_result_id: messageId,
              type_name: "update_esri_main_tables",
            }
          );
        }
        await axiosWithJwtAuth.put(
          `${APIURL}/shared_site/form_result_tasks/update`,
          {
            message_id: messageId,
            status_name: "dead_letter_queue",
            message: error?.response?.data?.error,
          }
        );
      } finally {
        this.isUpdatingFeatureService = false;
      }
    },
    async addToRelatedTableFromFormResult() {
      const { data: computedFormDefinition } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/form_results/${this.formResultId}/${this.$route.query.siteId}`
      );

      const {
        data: { results: mapService },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/map_services/${computedFormDefinition?.map_service_id}/${this.$route.query.siteId}`
      );
      if (!mapService?.service_url) {
        return;
      }
      this.isAddingToRelatedTables = true;

      if (
        !(
          computedFormDefinition?.form?.relatedTable?.relationshipId &&
          Array.isArray(computedFormDefinition?.form?.relatedTable?.fields) &&
          computedFormDefinition?.form?.relatedTable?.fields?.length > 0 &&
          mapService?.service_url
            ?.toLowerCase()
            ?.includes("FeatureServer".toLowerCase())
        )
      ) {
        return;
      }
      const entries = getGisRelatedTableFieldsToUpdate(computedFormDefinition);
      const attributes = Object.fromEntries(entries);
      const formData = new FormData();
      formData.append("f", "json");
      formData.append("token", localStorage.getItem("esri_token"));
      formData.append(
        "adds",
        JSON.stringify([
          {
            attributes,
          },
        ])
      );
      const { relationshipId } =
        computedFormDefinition?.form?.relatedTable ?? {};
      const mapServiceUrl = mapService.service_url;
      const relatedTableMapServiceUrl = mapServiceUrl.replace(
        /featureServer\/\d{1,}/i,
        `FeatureServer/${relationshipId}`
      );
      const { formResultId } = this;
      const {
        data: { results },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/form_result_tasks/${formResultId}`
      );
      const relatedTablesTask = results?.find(
        (r) => r.type_name === "update_related_tables"
      );
      let messageId = relatedTablesTask?.message_id;
      const hasUpdatedRelatedTablesTask = Boolean(relatedTablesTask);
      if (!hasUpdatedRelatedTablesTask) {
        await axiosWithJwtAuth.post(`${APIURL}/shared_site/form_result_tasks`, {
          form_result_id: formResultId,
          type_name: "update_related_tables",
        });
        const {
          data: { results },
        } = await axiosWithJwtAuth.get(
          `${APIURL}/shared_site/form_result_tasks/${formResultId}`
        );
        const relatedTablesTask = results?.find(
          (r) => r.type_name === "update_related_tables"
        );
        messageId = relatedTablesTask.message_id;
      }

      try {
        const { data } = await axiosWithNoAuth.post(
          `${relatedTableMapServiceUrl}/applyEdits`,
          formData
        );

        const [addResult] = data?.addResults ?? [];
        if (addResult?.success) {
          await axiosWithJwtAuth.put(
            `${APIURL}/shared_site/form_result_tasks/update`,
            {
              message_id: messageId,
              status_name: "completed_success",
              message: `Add record: ${JSON.stringify(attributes)}`,
            }
          );
        } else {
          await axiosWithJwtAuth.put(
            `${APIURL}/shared_site/form_result_tasks/update`,
            {
              message_id: messageId,
              status_name: "dead_letter_queue",
              message: addResult?.error?.description,
            }
          );
        }
      } catch (error) {
        await axiosWithJwtAuth.put(
          `${APIURL}/shared_site/form_result_tasks/update`,
          {
            message_id: messageId,
            status_name: "dead_letter_queue",
            message: `Failed to add record: ${JSON.stringify(attributes)}`,
          }
        );
      } finally {
        this.isAddingToRelatedTables = false;
      }
    },
  },
};
