<template>
  <div :class="{ background: inRightPane }">
    <div
      v-if="loadingGisInfo"
      class="d-flex justify-center align-center"
      style="overflow: hidden"
      :class="{ background: inRightPane }"
    >
      <v-progress-circular indeterminate></v-progress-circular>
    </div>
    <section
      v-else
      :style="{
        height: $vuetify.breakpoint.lgAndUp ? `${siteInfoHeight}px` : undefined,
      }"
      class="overflow-y-auto"
    >
      <v-expansion-panels
        flat
        multiple
        v-model="openPanels"
        class="d-flex justify-center align-center"
      >
        <v-expansion-panel value="0" :class="{ background: inRightPane }">
          <v-expansion-panel-header
            class="text-uppercase nav-border-top px-5"
            :class="{ background: inRightPane }"
          >
            UtiliSync Fields
          </v-expansion-panel-header>
          <v-expansion-panel-content
            class="py-0"
            :class="{ background: inRightPane }"
          >
            <section
              class="px-5 ma-0"
              :class="{ background: inRightPane }"
              v-if="utiliSyncFields.length > 0"
            >
              <div
                v-for="r of utiliSyncFields"
                :key="r.gis_data_value_id"
                class="py-1 ma-0"
              >
                <div :class="{ background: inRightPane }">
                  <div
                    class="
                      caption
                      d-flex
                      align-center
                      justify-space-between
                      gap
                    "
                  >
                    <section class="d-flex align-center">
                      <div>
                        {{ r.name }}
                      </div>
                    </section>

                    <v-switch
                      v-if="
                        editingUtiliSyncField[r.name] &&
                        getUtiliSyncFieldType(r) === 'string' &&
                        !isUtiliSyncFieldRefField(r)
                      "
                      v-model="richTextUtiliSyncField[r.name]"
                      label="Rich Text"
                    ></v-switch>
                  </div>
                  <div
                    v-if="!isRichTextField(r.value)"
                    style="word-break: break-word"
                  >
                    {{ r.value }}
                  </div>
                  <div
                    v-else
                    style="word-break: break-word"
                    v-html="r.value"
                  ></div>
                </div>
              </div>
            </section>
            <section
              class="px-5 py-3"
              v-else
              :class="{ background: inRightPane }"
            >
              <div class="caption">No UtiliSync fields configured</div>
            </section>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel
          value="1"
          :class="{ background: inRightPane }"
          v-if="notUtiliSyncLayer"
        >
          <v-expansion-panel-header
            class="text-uppercase nav-border-top my-0 py-0 px-5"
            :class="{ background: inRightPane }"
          >
            GIS Fields
          </v-expansion-panel-header>
          <v-expansion-panel-content
            v-if="showGisFields"
            class="my-0 py-0"
            :class="{ background: inRightPane }"
          >
            <section
              class="px-5 ma-0"
              v-if="Object.keys(gisInfoAttributes).length > 0"
            >
              <div
                class="py-1 ma-0"
                v-for="(value, key) in gisInfoAttributes"
                :key="key"
              >
                <div class="caption d-flex align-center justify-space-between">
                  <div>
                    {{ key }}
                  </div>

                  <v-switch
                    v-if="
                      editingGisField[key] &&
                      getEsriFieldType(key) === 'esriFieldTypeString' &&
                      !Array.isArray(getCodeValues(key)) &&
                      !isEsriFieldRefField(key)
                    "
                    v-model="richTextField[key]"
                    label="Rich Text"
                  ></v-switch>
                </div>

                <div v-if="!richTextField[key]" style="word-break: break-word">
                  <div
                    v-if="
                      getEsriFieldType(key) === 'esriFieldTypeDate' &&
                      value !== null
                    "
                  >
                    {{ new Date(value) | formatDate }}
                  </div>
                  <div v-else>
                    {{ value }}
                  </div>
                </div>

                <div v-else v-html="value" style="word-break: break-word"></div>
              </div>
            </section>
            <section class="px-5 py-3 ma-0" v-else>
              <div class="caption">No GIS fields configured</div>
            </section>
          </v-expansion-panel-content>
          <v-expansion-panel-content
            v-else
            :style="{ 'background-color': '#fafafa' }"
          >
            <v-list class="pa-0 ma-0">
              <v-list-item class="px-5 ma-0">
                <v-list-item-content class="caption">
                  GIS Fields Not Available
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </section>
  </div>
</template>

<script>
import { axiosWithJwtAuth } from "@/plugins/axios";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "GisInfo",
  props: {
    selectedMapServiceId: String,
    objectId: Number,
    globalId: String,
    gisInfoAttributes: {
      type: Object,
      default() {
        return {};
      },
    },
    inRightPane: Boolean,
    featureItemFields: Array,
    mapServices: Array,
  },
  data() {
    return {
      openPanels: [0, 1],
      loadingGisInfo: false,
      gisDataFields: [],
      gisDataValues: [],
      editingUtiliSyncField: {},
      editingGisField: {},
      isEditing: false,
      richTextField: {},
      richTextUtiliSyncField: {},
      gisDataFieldOptions: [],
      selectedMapService: {},
      siteInfoHeight: window.innerHeight * 0.85 - 400,
    };
  },
  computed: {
    isViewOnlyUser() {
      try {
        const auth = JSON.parse(localStorage.getItem("auth"));
        return auth?.is_view_only_user;
      } catch (error) {
        return true;
      }
    },
    notUtiliSyncLayer() {
      return this.selectedMapService?.service_type !== "U";
    },
    showGisFields() {
      const selectedMapService = this.mapServices.find?.(
        (m) => m.map_service_id === this.selectedMapServiceId
      );
      return (
        (Boolean(localStorage.getItem("esri_token")) &&
          selectedMapService?.token_type === "AGOL") ||
        selectedMapService?.token_type === "NONE"
      );
    },
    utiliSyncFields() {
      if (!Array.isArray(this.gisDataFields)) {
        return [];
      }

      return this.gisDataFields?.map((gdf) => {
        const { name, gis_data_field_id: gisDataFieldId } = gdf;
        const gisDataValue = this.gisDataValues?.find((gdv) => {
          return (
            gdv?.gis_data_field_id === gdf?.gis_data_field_id &&
            +gdv?.feature_id === +this.objectId
          );
        });
        const value = gisDataValue?.value;
        const gisDataValueId = gisDataValue?.gis_data_value_id;
        const gisDataFieldOptions = this.gisDataFieldOptions.filter(
          (g) => g.gis_data_field_id === gisDataFieldId
        );
        return {
          gisDataFieldId,
          gisDataValueId,
          name,
          value,
          gisDataFieldOptions,
        };
      });
    },
  },
  methods: {
    onResize() {
      this.siteInfoHeight = window.innerHeight * 0.85 - 450;
    },
    isEsriFieldRefField(esriFieldName) {
      return (
        this.selectedMapService?.ref_field
          ?.toLowerCase()
          ?.replace(/_ /g, "") ===
        esriFieldName?.toLowerCase()?.replace(/[_ ]/g, "")
      );
    },
    getCodeValues(gisInfoAttributeKey) {
      return this.featureItemFields?.find((f) => f.name === gisInfoAttributeKey)
        ?.domain?.codedValues;
    },
    isRichTextField(inputValue) {
      return Boolean(inputValue?.match?.(/<[^>]*>/g));
    },
    getEsriFieldType(esriFieldKey) {
      const field = this.featureItemFields?.find(
        (f) => f.name === esriFieldKey || f.alias === esriFieldKey
      );
      return field?.type;
    },
    async saveEsriField(key, value) {
      const { objectId } = this;
      const fieldName = this.featureItemFields.find(
        (f) => f.name === key || f.alias === key
      )?.name;
      const formData = new FormData();
      formData.append("f", "json");
      formData.append("token", localStorage.getItem("esri_token"));
      formData.append(
        "updates",
        JSON.stringify([
          {
            attributes: {
              OBJECTID: objectId,
              [fieldName]: value,
            },
          },
        ])
      );
      const selectedMapService = this.mapServices.find?.(
        (m) => m.map_service_id === this.selectedMapServiceId
      );
      const mapServiceUrl = selectedMapService?.service_url;
      await axiosWithJwtAuth.post(`${mapServiceUrl}/applyEdits`, formData);
      this.$emit("esri-field-saved", this.selectedMapServiceId);
    },
    async saveUtiliSyncField(gisDataValueId, key, value) {
      const { objectId } = this;
      const {
        data: { results: gisDataValues },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/gis_data_values/all/${this.$route.query.siteId}`
      );

      const gisDataValue = gisDataValues.find((g) => {
        return (
          g.gis_data_value_id === gisDataValueId && g.feature_id === objectId
        );
      });

      await axiosWithJwtAuth.put(`${APIURL}/shared_site/gis_data_values`, {
        gis_data_value_id: gisDataValue?.gis_data_value_id,
        value,
      });
      this.$emit("utilisync-field-saved", this.selectedMapServiceId);
    },
    getUtiliSyncFieldType(utiliSyncField) {
      const field = this.gisDataFields.find(
        (g) => g.gis_data_field_id === utiliSyncField.gisDataFieldId
      );
      return field?.type;
    },
    isUtiliSyncFieldsEditable(field) {
      if (this.isEditing || this.isViewOnlyUser) {
        return false;
      }
      return !["objectid", "globalid"].includes(field?.name?.toLowerCase());
    },
    isGisFieldsEditable(key) {
      if (this.isEditing || this.isViewOnlyUser) {
        return false;
      }
      return !["objectid", "globalid"].includes(key?.toLowerCase());
    },
    async getUtiliSyncFieldsAndValues() {
      this.loadingGisInfo = true;
      const { selectedMapServiceId, objectId } = this;
      if (!selectedMapServiceId || !objectId) {
        this.loadingGisInfo = false;
        return;
      }

      const {
        data: { results },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/gis_data_values/all/${this.$route.query.siteId}`
      );
      this.gisDataValues = results;
      const gisDataValueIds = this.gisDataValues.map(
        (g) => g.gis_data_field_id
      );

      const {
        data: { results: allGisDataFields },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/gis_data_fields_by_user_group/${this.$route.query.siteId}`
      );

      this.gisDataFields = allGisDataFields.filter((g) => {
        return (
          g.map_service_id === selectedMapServiceId &&
          !gisDataValueIds.includes(objectId)
        );
      });
      this.loadingGisInfo = false;
    },
    async getMapServices() {
      if (!this.selectedMapServiceId || !this.$route.query.siteId) {
        return;
      }
      const {
        data: { results },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/map_services/${this.selectedMapServiceId}/${this.$route.query.siteId}`
      );
      this.selectedMapService = results;
    },
    async getGisDataFieldOptions() {
      const {
        data: { results },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/gis_data_field_options/${this.$route.query.siteId}`
      );
      this.gisDataFieldOptions = results;
    },
  },
  beforeMount() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
    this.getUtiliSyncFieldsAndValues();
    this.getMapServices();
    this.getGisDataFieldOptions();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  watch: {
    globalId() {
      this.getUtiliSyncFieldsAndValues();
    },
    featureId() {
      this.getUtiliSyncFieldsAndValues();
    },
    selectedMapServiceId() {
      this.getUtiliSyncFieldsAndValues();
    },
    gisInfoAttributes: {
      deep: true,
      immediate: true,
      handler(val) {
        for (const [key, value] of Object.entries(val)) {
          this.$set(this.richTextField, key, this.isRichTextField(value));
        }
      },
    },
    utiliSyncFields: {
      deep: true,
      immediate: true,
      handler(val) {
        for (const { name, value } of val) {
          this.$set(
            this.richTextUtiliSyncField,
            name,
            this.isRichTextField(value)
          );
        }
      },
    },
  },
};
</script>

<style scoped>
.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.gap {
  gap: 5px;
}

.ql-editor {
  margin: 0px;
  padding: 0px;
}
</style>
