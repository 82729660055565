var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-card-actions", { staticClass: "py-3" }, [
    _c(
      "div",
      {
        staticClass: "d-flex align-center justify-space-between",
        staticStyle: { width: "100%" }
      },
      [
        _c("div", { staticClass: "caption" }, [
          !_vm.isOnline
            ? _c(
                "div",
                { staticClass: "d-flex align-center" },
                [
                  _c(
                    "v-icon",
                    { staticClass: "mr-1", attrs: { color: "gray" } },
                    [_vm._v(" " + _vm._s(_vm.mdiCheckUnderlineCircle) + " ")]
                  ),
                  _c(
                    "div",
                    [
                      _c("span", { staticClass: "py-0 my-0 pr-1" }, [
                        _vm._v("Offline")
                      ]),
                      _vm._v("| "),
                      _vm.isUpdatingFeatureService
                        ? _c("span", [_vm._v(" Updating Feature Service... ")])
                        : _vm.isAddingToRelatedTables
                        ? _c("span", [_vm._v(" Adding to Related Table... ")])
                        : _vm.isUpdatingDataAfterSubmit
                        ? _c("span", [_vm._v(" Updating app data... ")])
                        : _c("LastSaved", {
                            attrs: {
                              lastSavedTime: _vm.lastSavedTime,
                              alreadySubmittedFinalOnline:
                                _vm.alreadySubmittedFinalOnline,
                              isSaving: _vm.isSaving,
                              submittingFinalForm: _vm.submittingFinalForm
                            }
                          })
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "d-flex align-center" },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "mr-1",
                      attrs: { color: "orange darken-3" }
                    },
                    [_vm._v(" " + _vm._s(_vm.mdiLightningBoltCircle) + " ")]
                  ),
                  _c(
                    "div",
                    [
                      _c("span", { staticClass: "py-0 my-0 pr-1" }, [
                        _vm._v("Online")
                      ]),
                      _vm._v("| "),
                      _vm.isUpdatingFeatureService
                        ? _c("span", [_vm._v(" Updating Feature Service... ")])
                        : _vm.isAddingToRelatedTables
                        ? _c("span", [_vm._v(" Adding to Related Table... ")])
                        : _vm.isUpdatingDataAfterSubmit
                        ? _c("span", [_vm._v(" Updating App Data... ")])
                        : _c("LastSaved", {
                            attrs: {
                              lastSavedTime: _vm.lastSavedTime,
                              alreadySubmittedFinalOnline:
                                _vm.alreadySubmittedFinalOnline,
                              isSaving: _vm.isSaving,
                              submittingFinalForm: _vm.submittingFinalForm
                            }
                          })
                    ],
                    1
                  )
                ],
                1
              )
        ]),
        _c(
          "div",
          { staticClass: "d-flex align-center px-1" },
          [
            _c(
              "div",
              { staticClass: "d-flex" },
              [
                _vm.invalidFields && _vm.invalidFields.length > 0
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "px-0 mx-0",
                        attrs: { text: "", "x-small": "" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("invalid-fields-info-dialog-show")
                          }
                        }
                      },
                      [
                        _c("v-icon", { attrs: { color: "orange darken-3" } }, [
                          _vm._v(" " + _vm._s(_vm.mdiAlertCircle) + " ")
                        ])
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _c(
              "v-menu",
              {
                attrs: { "offset-y": "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                staticClass: "px-0 mx-0",
                                attrs: {
                                  text: "",
                                  "x-small": "",
                                  disabled: !_vm.canEdit
                                }
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _c("v-icon", { attrs: { color: "#3F51B5" } }, [
                              _vm._v(_vm._s(_vm.mdiDotsVertical))
                            ])
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              },
              [
                _c(
                  "v-list",
                  [
                    !_vm.alreadySubmittedFinalOnline
                      ? _c(
                          "v-list-item",
                          {
                            staticClass: "px-4 mx-0",
                            attrs: { id: "save-button" },
                            on: {
                              click: function($event) {
                                _vm.$emit("auto-save")
                                _vm.$emit("refresh-log")
                              }
                            }
                          },
                          [
                            _c("v-icon", { staticClass: "mr-2" }, [
                              _vm._v(" " + _vm._s(_vm.mdiFile) + " ")
                            ]),
                            _vm._v(" Save Draft ")
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "v-list-item",
                      {
                        staticClass: "px-4 mx-0",
                        on: {
                          click: function($event) {
                            return _vm.$emit("form-info-dialog-show")
                          }
                        }
                      },
                      [
                        _c("v-icon", { staticClass: "mr-2" }, [
                          _vm._v(" " + _vm._s(_vm.mdiInformation) + " ")
                        ]),
                        _vm._v(" Form Submission ID ")
                      ],
                      1
                    ),
                    _c(
                      "v-list-item",
                      {
                        staticClass: "px-4 mx-0",
                        attrs: { id: "delete-button" },
                        on: {
                          click: function($event) {
                            return _vm.$emit("delete-form-result")
                          }
                        }
                      },
                      [
                        _c("v-icon", { staticClass: "mr-2" }, [
                          _vm._v(" " + _vm._s(_vm.mdiDelete) + " ")
                        ]),
                        _vm._v(" Delete Form ")
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-btn",
              {
                staticClass: "white--text",
                attrs: {
                  elevation: "2",
                  type: "submit",
                  disabled:
                    !_vm.canEdit || _vm.submittingFinalForm || _vm.isSaving,
                  color: "#3F51B5",
                  id: "submitFormBtn"
                }
              },
              [_vm._v(" Submit ")]
            )
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }