var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticStyle: { "background-color": "#fafafa" } },
    [
      _c(
        "v-main",
        { staticClass: "pa-0 ma-0", attrs: { fluid: "", tag: "section" } },
        [
          _c(
            "SharedSiteLayout",
            { class: { "overflow-hidden": _vm.$vuetify.breakpoint.lgAndUp } },
            [
              _c("SharedSiteTopBar", {
                attrs: {
                  sharedSite: _vm.sharedSite,
                  selectedMapService: _vm.selectedMapService,
                  gisDataFieldsAndValues: _vm.gisDataFieldsAndValues,
                  objectId: _vm.featureId
                }
              }),
              _vm.loaded
                ? _c(
                    "v-row",
                    {
                      staticClass: "px-3 mt-16",
                      class: {
                        "my-0": _vm.$vuetify.breakpoint.mdAndDown,
                        "py-0": _vm.$vuetify.breakpoint.mdAndDown
                      }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex flex-column",
                          class: {
                            "my-0": _vm.$vuetify.breakpoint.mdAndDown,
                            "py-0": _vm.$vuetify.breakpoint.mdAndDown
                          },
                          attrs: { cols: "12", lg: "4" }
                        },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "my-1 flex-grow-0",
                              attrs: { id: "createNewForm" }
                            },
                            [
                              _c(
                                "v-card-title",
                                {
                                  directives: [
                                    {
                                      name: "ripple",
                                      rawName: "v-ripple",
                                      value: _vm.$vuetify.breakpoint.mdAndDown,
                                      expression:
                                        "$vuetify.breakpoint.mdAndDown"
                                    }
                                  ],
                                  staticClass:
                                    "font-weight-regular d-flex justify-space-between",
                                  style: {
                                    cursor: _vm.$vuetify.breakpoint.mdAndDown
                                      ? "pointer"
                                      : undefined
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onHeadingClick("createNewForm")
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "section",
                                    { staticClass: "d-flex gap" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "disable-pointer",
                                          attrs: {
                                            fab: "",
                                            dark: "",
                                            color: "#2193F6",
                                            "x-small": "",
                                            ripple: false,
                                            elevation: "0"
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.mdiFileDocumentPlusOutline
                                                ) +
                                                " "
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("div", [_vm._v("Create New Form")])
                                    ],
                                    1
                                  ),
                                  _vm.$vuetify.breakpoint.mdAndDown
                                    ? _c(
                                        "section",
                                        [
                                          _c(
                                            "v-btn",
                                            { attrs: { icon: "" } },
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.showSection
                                                        .createNewForm
                                                        ? _vm.mdiChevronUp
                                                        : _vm.mdiChevronDown
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _c(
                                "v-card-text",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.showSection.createNewForm,
                                      expression: "showSection.createNewForm"
                                    }
                                  ]
                                },
                                [
                                  _c("SharedSiteTicketFormDefDropdown", {
                                    attrs: {
                                      mapServices: _vm.mapServices,
                                      globalId: _vm.gisInfoId,
                                      selectedMapServiceId:
                                        _vm.selectedMapServiceId,
                                      objectId: _vm.featureId
                                    },
                                    on: {
                                      "ticket-edit-form-submitted":
                                        _vm.onTicketEditFormSubmitted
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-card",
                            {
                              staticClass: "my-1 flex-grow-0",
                              attrs: { id: "siteContacts" }
                            },
                            [
                              _c(
                                "v-card-title",
                                {
                                  directives: [
                                    {
                                      name: "ripple",
                                      rawName: "v-ripple",
                                      value: _vm.$vuetify.breakpoint.mdAndDown,
                                      expression:
                                        "$vuetify.breakpoint.mdAndDown"
                                    }
                                  ],
                                  staticClass:
                                    "font-weight-regular d-flex justify-space-between",
                                  style: {
                                    cursor: _vm.$vuetify.breakpoint.mdAndDown
                                      ? "pointer"
                                      : undefined
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onHeadingClick("siteContacts")
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "section",
                                    { staticClass: "d-flex gap" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "disable-pointer",
                                          attrs: {
                                            fab: "",
                                            dark: "",
                                            color: "#2193F6",
                                            "x-small": "",
                                            ripple: false,
                                            elevation: "0"
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.mdiMapMarkerAccountOutline
                                                ) +
                                                " "
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("div", [_vm._v("Site Contacts")])
                                    ],
                                    1
                                  ),
                                  _vm.$vuetify.breakpoint.mdAndDown
                                    ? _c(
                                        "section",
                                        [
                                          _c(
                                            "v-btn",
                                            { attrs: { icon: "" } },
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.showSection
                                                        .siteContacts
                                                        ? _vm.mdiChevronUp
                                                        : _vm.mdiChevronDown
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _c(
                                "v-card-text",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.showSection.siteContacts,
                                      expression: "showSection.siteContacts"
                                    }
                                  ]
                                },
                                [
                                  _c("SharedSiteSiteContacts", {
                                    attrs: {
                                      selectedMapServiceId:
                                        _vm.selectedMapServiceId,
                                      objectId: _vm.featureId
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-card",
                            {
                              staticClass: "my-1 flex-grow-1",
                              attrs: { id: "siteInformation" }
                            },
                            [
                              _c(
                                "v-card-title",
                                {
                                  directives: [
                                    {
                                      name: "ripple",
                                      rawName: "v-ripple",
                                      value: _vm.$vuetify.breakpoint.mdAndDown,
                                      expression:
                                        "$vuetify.breakpoint.mdAndDown"
                                    }
                                  ],
                                  staticClass:
                                    "font-weight-regular d-flex justify-space-between",
                                  style: {
                                    cursor: _vm.$vuetify.breakpoint.mdAndDown
                                      ? "pointer"
                                      : undefined
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onHeadingClick(
                                        "siteInformation"
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "section",
                                    { staticClass: "d-flex gap" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "disable-pointer",
                                          attrs: {
                                            fab: "",
                                            dark: "",
                                            color: "#2193F6",
                                            "x-small": "",
                                            ripple: false,
                                            elevation: "0"
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.mdiGoogleMaps) +
                                                " "
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("div", [_vm._v("Site Information")])
                                    ],
                                    1
                                  ),
                                  _vm.$vuetify.breakpoint.mdAndDown
                                    ? _c(
                                        "section",
                                        [
                                          _c(
                                            "v-btn",
                                            { attrs: { icon: "" } },
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.showSection
                                                        .siteInformation
                                                        ? _vm.mdiChevronUp
                                                        : _vm.mdiChevronDown
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _c(
                                "v-card-text",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.showSection.siteInformation,
                                      expression: "showSection.siteInformation"
                                    }
                                  ]
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex-grow-1 overflow-y-auto"
                                    },
                                    [
                                      _c("SharedSiteGisInfo", {
                                        attrs: {
                                          mapServices: _vm.mapServices,
                                          globalId: _vm.gisInfoId,
                                          selectedMapServiceId:
                                            _vm.selectedMapServiceId,
                                          objectId: _vm.featureId,
                                          gisInfoAttributes:
                                            _vm.gisInfoAttributes
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex flex-column",
                          class: {
                            "my-0": _vm.$vuetify.breakpoint.mdAndDown,
                            "py-0": _vm.$vuetify.breakpoint.mdAndDown
                          },
                          attrs: { cols: "12", lg: "4" }
                        },
                        [
                          _c(
                            "v-card",
                            {
                              staticClass: "my-1 flex-grow-1",
                              attrs: { id: "tasks" }
                            },
                            [
                              _c(
                                "v-card-title",
                                {
                                  directives: [
                                    {
                                      name: "ripple",
                                      rawName: "v-ripple",
                                      value: _vm.$vuetify.breakpoint.mdAndDown,
                                      expression:
                                        "$vuetify.breakpoint.mdAndDown"
                                    }
                                  ],
                                  staticClass:
                                    "font-weight-regular d-flex justify-space-between",
                                  style: {
                                    cursor: _vm.$vuetify.breakpoint.mdAndDown
                                      ? "pointer"
                                      : undefined
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onHeadingClick("tasks")
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "section",
                                    { staticClass: "d-flex gap" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "disable-pointer",
                                          attrs: {
                                            fab: "",
                                            dark: "",
                                            color: "#2193F6",
                                            "x-small": "",
                                            ripple: false,
                                            elevation: "0"
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.mdiFormatListChecks
                                                ) +
                                                " "
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("div", [_vm._v("Tasks")])
                                    ],
                                    1
                                  ),
                                  _vm.$vuetify.breakpoint.mdAndDown
                                    ? _c(
                                        "section",
                                        [
                                          _c(
                                            "v-btn",
                                            { attrs: { icon: "" } },
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.showSection.tasks
                                                        ? _vm.mdiChevronUp
                                                        : _vm.mdiChevronDown
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _c(
                                "v-card-text",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.showSection.tasks,
                                      expression: "showSection.tasks"
                                    }
                                  ]
                                },
                                [
                                  _c("SharedSiteTasksTab", {
                                    attrs: {
                                      globalId: _vm.gisInfoId,
                                      selectedMapServiceId:
                                        _vm.selectedMapServiceId,
                                      objectId: _vm.featureId,
                                      currentUser: _vm.currentUser
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "d-flex flex-column",
                          class: {
                            "my-0": _vm.$vuetify.breakpoint.mdAndDown,
                            "py-0": _vm.$vuetify.breakpoint.mdAndDown
                          },
                          attrs: { cols: "12", lg: "4" }
                        },
                        [
                          _c(
                            "v-card",
                            { staticClass: "my-1", attrs: { id: "log" } },
                            [
                              _c(
                                "v-card-title",
                                {
                                  directives: [
                                    {
                                      name: "ripple",
                                      rawName: "v-ripple",
                                      value: _vm.$vuetify.breakpoint.mdAndDown,
                                      expression:
                                        "$vuetify.breakpoint.mdAndDown"
                                    }
                                  ],
                                  staticClass:
                                    "font-weight-regular d-flex justify-space-between",
                                  style: {
                                    cursor: _vm.$vuetify.breakpoint.mdAndDown
                                      ? "pointer"
                                      : undefined
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onHeadingClick("log")
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "section",
                                    { staticClass: "d-flex gap" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "disable-pointer",
                                          attrs: {
                                            fab: "",
                                            dark: "",
                                            color: "#2193F6",
                                            "x-small": "",
                                            ripple: false,
                                            elevation: "0"
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.mdiViewList) +
                                                " "
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("div", [_vm._v("Log")])
                                    ],
                                    1
                                  ),
                                  _vm.$vuetify.breakpoint.mdAndDown
                                    ? _c(
                                        "section",
                                        [
                                          _c(
                                            "v-btn",
                                            { attrs: { icon: "" } },
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.showSection.log
                                                        ? _vm.mdiChevronUp
                                                        : _vm.mdiChevronDown
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _c(
                                "v-card-text",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.showSection.log,
                                      expression: "showSection.log"
                                    }
                                  ]
                                },
                                [
                                  _c("SharedSiteLog", {
                                    attrs: {
                                      mapServices: _vm.mapServices,
                                      globalId: _vm.gisInfoId,
                                      selectedMapServiceId:
                                        _vm.selectedMapServiceId,
                                      objectId: _vm.featureId,
                                      currentUser: _vm.currentUser
                                    },
                                    on: {
                                      "form-result-deleted":
                                        _vm.onTicketEditFormSubmitted
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-card",
                            {
                              staticClass: "my-1 flex-grow-1",
                              attrs: { id: "documents" }
                            },
                            [
                              _c(
                                "v-card-title",
                                {
                                  directives: [
                                    {
                                      name: "ripple",
                                      rawName: "v-ripple",
                                      value: _vm.$vuetify.breakpoint.mdAndDown,
                                      expression:
                                        "$vuetify.breakpoint.mdAndDown"
                                    }
                                  ],
                                  staticClass:
                                    "font-weight-regular d-flex justify-space-between",
                                  style: {
                                    cursor: _vm.$vuetify.breakpoint.mdAndDown
                                      ? "pointer"
                                      : undefined
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.onHeadingClick("documents")
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "section",
                                    { staticClass: "d-flex gap" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "disable-pointer",
                                          attrs: {
                                            fab: "",
                                            dark: "",
                                            color: "#2193F6",
                                            "x-small": "",
                                            ripple: false,
                                            elevation: "0"
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v(
                                              " " + _vm._s(_vm.mdiFile) + " "
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _c("div", [_vm._v("Documents")])
                                    ],
                                    1
                                  ),
                                  _vm.$vuetify.breakpoint.mdAndDown
                                    ? _c(
                                        "section",
                                        [
                                          _c(
                                            "v-btn",
                                            { attrs: { icon: "" } },
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.showSection.documents
                                                        ? _vm.mdiChevronUp
                                                        : _vm.mdiChevronDown
                                                    ) +
                                                    " "
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _c(
                                "v-card-text",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.showSection.documents,
                                      expression: "showSection.documents"
                                    }
                                  ]
                                },
                                [
                                  _c("SharedSiteDocsTab", {
                                    attrs: {
                                      globalId: _vm.gisInfoId,
                                      selectedMapServiceId:
                                        _vm.selectedMapServiceId,
                                      objectId: _vm.featureId,
                                      currentUser: _vm.currentUser,
                                      currentUserOrg: _vm.currentUserOrg
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c(
                        "v-card",
                        { staticClass: "d-flex flex-column" },
                        [
                          _c(
                            "v-card-text",
                            {
                              staticClass:
                                "flex-grow-1 d-flex justify-center align-center"
                            },
                            [
                              _c("v-progress-circular", {
                                attrs: { indeterminate: "" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
              _c(
                "v-snackbar",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "action",
                      fn: function(ref) {
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._b(
                              {
                                attrs: { text: "" },
                                on: { click: _vm.viewForm }
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            [_vm._v(" View Form ")]
                          )
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.showFormSubmittedSnackbar,
                    callback: function($$v) {
                      _vm.showFormSubmittedSnackbar = $$v
                    },
                    expression: "showFormSubmittedSnackbar"
                  }
                },
                [
                  _c("v-icon", { attrs: { dark: "" } }, [
                    _vm._v(" " + _vm._s(_vm.mdiInformation) + " ")
                  ]),
                  _vm._v(" Success! The form was submitted. ")
                ],
                1
              ),
              _c(
                "v-dialog",
                {
                  attrs: {
                    "max-width": "600px",
                    persistent: "",
                    fullscreen: _vm.$vuetify.breakpoint.xsOnly
                  },
                  model: {
                    value: _vm.showViewFormEditFormDialog,
                    callback: function($$v) {
                      _vm.showViewFormEditFormDialog = $$v
                    },
                    expression: "showViewFormEditFormDialog"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _vm.showViewFormEditFormDialog
                        ? _c("SharedSiteDynamicForm", {
                            attrs: {
                              formDefinition:
                                _vm.formResultBeingViewed.selectedFormResult,
                              existingFormResultIdMap:
                                _vm.formResultBeingViewed
                                  .existingFormResultIdMap,
                              selectedPdfFileUrl:
                                _vm.formResultBeingViewed.selectedPdfFileUrl,
                              canEdit: false,
                              alreadySubmittedFinalOnline: "",
                              globalId: _vm.formResultBeingViewed.globalId,
                              objectId: _vm.formResultBeingViewed.objectId,
                              selectedMapServiceId:
                                _vm.formResultBeingViewed.selectedMapServiceId
                            },
                            on: {
                              "ticket-edit-form-close-button-click": function(
                                $event
                              ) {
                                _vm.showViewFormEditFormDialog = false
                                _vm.resetFormResultBeingViewed()
                              },
                              "ticket-edit-form-close": function($event) {
                                _vm.showViewFormEditFormDialog = false
                                _vm.resetFormResultBeingViewed()
                              },
                              "ticket-edit-form-submitted": function($event) {
                                _vm.showViewFormEditFormDialog = false
                                _vm.resetFormResultBeingViewed()
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.showActionItemTabActionItemDialog
                ? _c("SharedSiteActionItemTabActionItemDialog", {
                    attrs: {
                      action: _vm.getActionItemLabel(
                        _vm.selectedOpenActionItem
                      ),
                      showActionItemDialog:
                        _vm.showActionItemTabActionItemDialog,
                      selectedActionItem: _vm.selectedOpenActionItem,
                      selectedActionItemId:
                        _vm.selectedOpenActionItem.action_item_id,
                      hideResponseSection: false
                    },
                    on: {
                      "action-item-dialog-close": function($event) {
                        _vm.showActionItemTabActionItemDialog = false
                      },
                      "action-item-saved": function($event) {
                        _vm.showActionItemTabActionItemDialog = false
                      },
                      "action-item-response-saved": function($event) {
                        _vm.showActionItemTabActionItemDialog = false
                      },
                      "reject-action-item": function($event) {
                        _vm.showActionItemTabActionItemDialog = false
                      },
                      "accept-action-item": function($event) {
                        _vm.showActionItemTabActionItemDialog = false
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }