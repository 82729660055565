<template>
  <v-dialog
    :value="showSharedSiteManageSymbolsDialog"
    :retain-focus="false"
    persistent
    class="rounded-0"
    width="400px"
  >
    <v-card class="rounded-0">
      <v-toolbar dark width="100%" color="#3F51B5" ref="toolbar" height="56px">
        <div class="mr-3">
          <div>Manage Symbols</div>
        </div>

        <v-spacer />

        <v-btn icon @click="$emit('close-button-click')">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar>

      <div class="d-flex justify-end gap">
        <v-btn
          text
          color="#3F51B5"
          @click="showSharedSiteAddSymbolDialog = true"
        >
          <v-icon>
            {{ mdiPlus }}
          </v-icon>
          Add Symbol
        </v-btn>
      </div>

      <section class="px-4">
        <v-chip-group>
          <v-chip
            @click="
              symbolInactiveStatus = !symbolInactiveStatus;
              $emit('get-custom-symbols', symbolInactiveStatus);
            "
          >
            {{ symbolInactiveStatus ? "Inactive" : "Active" }}
            <v-icon class="ml-1 cursor-pointer" :size="18">
              {{ mdiSyncCircle }}
            </v-icon>
          </v-chip>
        </v-chip-group>
      </section>

      <section class="px-4">
        <div class="d-flex align-center gap">
          <form class="flex-grow-1">
            <validation-provider v-slot="{ errors, valid }" name="Symbol">
              <v-text-field
                v-model="symbolKeyword"
                :error-messages="errors"
                :success="valid"
                label="Symbol"
                color="#3F51B5"
              >
                <template #append>
                  <v-icon> {{ mdiMagnify }}</v-icon>
                </template>
              </v-text-field>
            </validation-provider>
          </form>
        </div>

        <table class="flex-grow-1 px-0 mx-0 py-3" style="width: 100%">
          <tbody>
            <tr
              v-for="s of filteredCustomSymbols"
              :key="s.markup_symbol_id"
              class="cursor-pointer"
              @click="
                selectedCustomSymbol = s;
                displayCustomSymbolToolbar = true;
                showSymbolPanel = false;
              "
            >
              <td class="d-flex align-center py-2 gap">
                <SymbolIconDisplay :symbol="s" />
                {{ s.label }}
              </td>
              <td>
                <div class="d-flex justify-end align-center py-2">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="pa-0 d-flex"
                        text
                        v-bind="attrs"
                        v-on="on"
                        x-small
                        icon
                      >
                        <v-btn icon>
                          <v-icon>{{ mdiDotsVertical }}</v-icon>
                        </v-btn>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item>
                        <v-list-item-title
                          class="d-flex align-center cursor-pointer gap"
                          @click="
                            showSharedSiteEditSymbolDialog = true;
                            selectedSymbol = s;
                          "
                        >
                          <v-icon> {{ mdiPencil }} </v-icon>
                          Edit Symbol
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </section>

      <SharedSiteAddSymbolDialog
        v-if="showSharedSiteAddSymbolDialog"
        :showSharedSiteAddSymbolDialog="showSharedSiteAddSymbolDialog"
        @add-symbol-dialog-close="showSharedSiteAddSymbolDialog = false"
        @custom-symbol-added="
          showSharedSiteAddSymbolDialog = false;
          $emit('custom-symbol-added');
        "
      />

      <SharedSiteEditSymbolDialog
        v-if="showSharedSiteEditSymbolDialog"
        :showSharedSiteEditSymbolDialog="showSharedSiteEditSymbolDialog"
        :selectedSymbol="selectedSymbol"
        @edit-symbol-dialog-close="showSharedSiteEditSymbolDialog = false"
        @custom-symbol-edited="
          showSharedSiteEditSymbolDialog = false;
          $emit('custom-symbol-edited');
        "
      />
    </v-card>
  </v-dialog>
</template>

<script>
import SymbolIconDisplay from "@/components/mapView/docs-tab/markup-image-dialog/markup-items-toolbar/SymbolIconDisplay";
import SharedSiteAddSymbolDialog from "@/components/shared-site/shared-site-ticket-form-def-drop-down/shared-site-ticket-edit-form/shared-site-dynamic-form/shared-site-photo-input/shared-site-markup-image-dialog/SharedSiteAddSymbolDialog";
import SharedSiteEditSymbolDialog from "@/components/shared-site/shared-site-ticket-form-def-drop-down/shared-site-ticket-edit-form/shared-site-dynamic-form/shared-site-photo-input/shared-site-markup-image-dialog/SharedSiteEditSymbolDialog";
import { cloneDeep } from "lodash";
import {
  mdiDotsVertical,
  mdiPencil,
  mdiClose,
  mdiSyncCircle,
  mdiMagnify,
  mdiPlus,
} from "@mdi/js";

export default {
  name: "SharedSiteManageSymbolsDialog",
  props: {
    showSharedSiteManageSymbolsDialog: Boolean,
    customSymbols: Array,
  },
  components: {
    SymbolIconDisplay,
    SharedSiteAddSymbolDialog,
    SharedSiteEditSymbolDialog,
  },
  data() {
    return {
      mdiDotsVertical,
      mdiPencil,
      mdiClose,
      mdiSyncCircle,
      mdiMagnify,
      symbolKeyword: "",
      symbolInactiveStatus: false,
      showSharedSiteEditSymbolDialog: false,
      selectedSymbol: {},
      showSharedSiteAddSymbolDialog: false,
      mdiPlus,
    };
  },
  computed: {
    filteredCustomSymbols() {
      if (this.symbolKeyword) {
        return cloneDeep(this.customSymbols).filter((c) =>
          c.label
            .trim()
            .toLowerCase()
            .includes(this.symbolKeyword.trim().toLowerCase())
        );
      }
      return cloneDeep(this.customSymbols);
    },
  },
  async beforeMount() {
    if (this.customSymbols[0]) {
      this.selectedCustomSymbol = this.customSymbols[0];
    }
  },
};
</script>
