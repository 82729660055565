import { render, staticRenderFns } from "./SharedSiteUnsavedMarkupDialog.vue?vue&type=template&id=5248e5f5&"
import script from "./SharedSiteUnsavedMarkupDialog.vue?vue&type=script&lang=js&"
export * from "./SharedSiteUnsavedMarkupDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VDialog})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5248e5f5')) {
      api.createRecord('5248e5f5', component.options)
    } else {
      api.reload('5248e5f5', component.options)
    }
    module.hot.accept("./SharedSiteUnsavedMarkupDialog.vue?vue&type=template&id=5248e5f5&", function () {
      api.rerender('5248e5f5', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/shared-site/shared-site-ticket-form-def-drop-down/shared-site-ticket-edit-form/shared-site-dynamic-form/shared-site-photo-input/shared-site-markup-image-dialog/SharedSiteUnsavedMarkupDialog.vue"
export default component.exports