<template>
  <v-dialog :value="showActionItemNotAcceptedDialog" max-width="500px">
    <v-card>
      <v-toolbar dark class="elevation-0" width="100%" color="#3F51B5">
        <div>Action Item Not Accepted</div>
        <v-spacer />
        <v-btn icon @click="$emit('action-item-dialog-close')">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <form @submit.prevent="rejectActionItem">
          <validation-observer ref="rejectActionItemForm">
            <v-row>
              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Reason Action Item was Not Accepted"
                  rules="required"
                >
                  <v-text-field
                    v-model="rejectionText"
                    :error-messages="errors"
                    :success="valid"
                    color="#3F51B5"
                    label="Reason Action Item was Not Accepted"
                  />
                </validation-provider>
              </v-col>

              <v-col cols="12" class="d-flex justify-end p-3">
                <v-btn color="#3F51B5" dark type="submit">
                  Reject and Notify Assignee
                </v-btn>
              </v-col>
            </v-row>
          </validation-observer>
        </form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose } from "@mdi/js";
import { axiosWithRegularAuth } from "@/plugins/axios";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "ActionItemNotAcceptedDialog",
  props: {
    showActionItemNotAcceptedDialog: Boolean,
    selectedActionItem: Object,
    selectedActionItemId: String,
  },
  data() {
    return {
      mdiClose,
      rejectionText: "",
    };
  },
  methods: {
    async rejectActionItem() {
      const success = await this.$refs.rejectActionItemForm.validate();
      if (!success) {
        return;
      }
      const { rejectionText } = this;
      const payload = {
        rejection_text: rejectionText,
      };
      await axiosWithRegularAuth.put(
        `${APIURL}/shared_site/action_item/${this.selectedActionItemId}/${this.$route.query.siteId}`,
        payload
      );
      this.$emit("reject-action-item");
    },
  },
};
</script>
