var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showSharedSiteAddImageDialog,
        "max-width": "500px",
        persistent: ""
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "d-flex flex-column", attrs: { "max-height": "80vh" } },
        [
          _c(
            "v-toolbar",
            {
              staticClass: "elevation-0 flex-grow-0",
              attrs: { dark: "", color: "#3F51B5" }
            },
            [
              _c("v-toolbar-title", [_vm._v("Add Image")]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("add-photo-dialog-close")
                    }
                  }
                },
                [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "py-3 flex-grow-1 overflow-y-auto" },
            [
              _c("div", [_vm._v("Add image by selecting a source")]),
              _c("input", {
                ref: "cameraInput",
                staticStyle: { visibility: "hidden" },
                attrs: {
                  type: "file",
                  accept: "image/*;capture=camera",
                  multiple: _vm.allowMultiple
                },
                on: { change: _vm.onFileInputChange }
              }),
              _c(
                "div",
                { staticClass: "d-flex justify-center my-3" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "#3F51B5" },
                      on: {
                        click: function($event) {
                          return _vm.$refs.cameraInput.click()
                        }
                      }
                    },
                    [_vm._v(" Camera ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "#3F51B5" },
                      on: {
                        click: function($event) {
                          _vm.showSharedSiteSelectDocDialog = true
                        }
                      }
                    },
                    [_vm._v(" Docs ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "#3F51B5" },
                      on: { click: _vm.onMapClick }
                    },
                    [_vm._v(" Map ")]
                  )
                ],
                1
              ),
              _c("file-pond", {
                ref: "files",
                staticClass: "mb-6",
                attrs: {
                  name: "files",
                  "label-idle":
                    "Drag & Drop or <span class='filepond--label-action'> Browse </span>",
                  "allow-multiple": _vm.allowMultiple,
                  "accepted-file-types": "image/jpeg, image/png",
                  files: _vm.uploadFiles,
                  allowFileSizeValidation: "",
                  maxFileSize: "2MB",
                  disabled: _vm.readOnly
                },
                on: { addfile: _vm.onChange }
              }),
              _vm._l(_vm.base64Images, function(image, index) {
                return _c(
                  "v-card",
                  {
                    key: image.id,
                    staticClass: "d-flex flex-column justify-center my-2"
                  },
                  [
                    _c("v-card-text", { staticClass: "py-0 my-0" }, [
                      _c("div", { staticClass: "d-flex justify-center mt-4" }, [
                        _c("img", {
                          staticClass: "cursor-pointer",
                          staticStyle: {
                            "max-height": "340px",
                            "max-width": "100%"
                          },
                          attrs: { src: image.dataUrl },
                          on: {
                            click: function($event) {
                              return _vm.markUpImage(image)
                            }
                          }
                        })
                      ]),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-space-between align-center"
                        },
                        [
                          _c("v-text-field", {
                            key: image.id,
                            attrs: {
                              label: "Description",
                              color: "utilisync",
                              name: image.id
                            },
                            on: {
                              input: function($event) {
                                return _vm.$emit("input", _vm.base64Images)
                              }
                            },
                            model: {
                              value: image.description,
                              callback: function($$v) {
                                _vm.$set(image, "description", $$v)
                              },
                              expression: "image.description"
                            }
                          }),
                          _vm.showSharedSiteMarkupImageDialog[image.id]
                            ? _c("SharedSiteMarkupImageDialog", {
                                attrs: {
                                  showSharedSiteMarkupImageDialog:
                                    _vm.showSharedSiteMarkupImageDialog[
                                      image.id
                                    ],
                                  selectedFile: _vm.selectedFile
                                },
                                on: {
                                  "markup-image-dialog-close": function(
                                    $event
                                  ) {
                                    _vm.showSharedSiteMarkupImageDialog = false
                                  },
                                  "file-markup-preview-saved":
                                    _vm.onFileMarkupPreviewSaved
                                }
                              })
                            : _vm._e(),
                          _c(
                            "v-menu",
                            {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              { attrs: { icon: "" } },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c("v-icon", [
                                              _vm._v(
                                                _vm._s(_vm.mdiDotsVertical)
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _c(
                                "v-list",
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.markUpImage(image)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-title",
                                        [
                                          _c("v-icon", [
                                            _vm._v(_vm._s(_vm.mdiPencil))
                                          ]),
                                          _vm._v(" Markup ")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                          return _vm.removePhoto(
                                            index,
                                            image.id
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-title",
                                        [
                                          _c("v-icon", [
                                            _vm._v(_vm._s(_vm.mdiDelete))
                                          ]),
                                          _vm._v(" Delete ")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
              }),
              _vm.showSharedSiteSelectDocDialog
                ? _c("SharedSiteSelectDocDialog", {
                    attrs: {
                      showSharedSiteSelectDocDialog:
                        _vm.showSharedSiteSelectDocDialog,
                      formDefinition: _vm.formDefinition,
                      maxWidthHeight: +_vm.maxWidthHeight,
                      formResultId: _vm.formResultId,
                      globalId: _vm.globalId,
                      objectId: _vm.objectId
                    },
                    on: {
                      "select-doc-dialog-close": function($event) {
                        _vm.showSharedSiteSelectDocDialog = false
                      },
                      "image-file-selected": _vm.onImageFileSelected
                    }
                  })
                : _vm._e(),
              _vm.showSharedSiteSelectMapScreenshotDialog
                ? _c("SharedSiteSelectMapScreenshotDialog", {
                    attrs: {
                      showSharedSiteSelectMapScreenshotDialog:
                        _vm.showSharedSiteSelectMapScreenshotDialog
                    },
                    on: {
                      "select-map-screenshot-dialog-close": function($event) {
                        _vm.showSharedSiteSelectMapScreenshotDialog = false
                      },
                      "screenshot-taken": _vm.saveScreenshot
                    }
                  })
                : _vm._e(),
              _vm.showOfflineDialog
                ? _c("OfflineDialog", {
                    attrs: { showOfflineDialog: _vm.showOfflineDialog },
                    on: {
                      "offline-dialog-close": function($event) {
                        _vm.showOfflineDialog = false
                      }
                    }
                  })
                : _vm._e()
            ],
            2
          ),
          _c(
            "v-card-actions",
            { staticClass: "d-flex justify-end px-5 py-3" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "font-weight-regular",
                  attrs: { color: "#3F51B5", dark: "" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("add-photo-dialog-close")
                    }
                  }
                },
                [_vm._v(" Close ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }