<template>
  <v-card-actions class="px-0 py-2 ma-0 d-flex justify-end">
    <v-btn text color="#3F51B5" v-if="canEdit" @click="$emit('edit-form')">
      <v-icon class="mr-1 mt-n1">
        {{ mdiPencil }}
      </v-icon>
      <span v-if="$vuetify.breakpoint.smAndUp">Edit</span>
    </v-btn>
    <v-btn
      text
      color="#3F51B5"
      v-if="selectedPdfFileUrl"
      @click="showPdfDialog = true"
    >
      <v-icon class="mr-1 mt-n1">
        {{ mdiFileDocument }}
      </v-icon>
      <span v-if="$vuetify.breakpoint.smAndUp">View PDF</span>
    </v-btn>
    <v-btn text color="#3F51B5" @click="showUtilibotDialog = true">
      <img
        src="@/assets/UtiliBotIconPrimaryBlue.svg"
        height="24"
        width="24"
        class="mr-2"
      />

      <span v-if="$vuetify.breakpoint.smAndUp">Utilibot Summary</span>
    </v-btn>

    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn text color="#3F51B5" dark v-bind="attrs" v-on="on">
          <v-icon>
            {{ mdiDotsVertical }}
          </v-icon>
        </v-btn>
      </template>
      <v-list class="px-0 mx-0">
        <v-list-item class="px-4 mx-0" @click="showFormIdDialog = true">
          <v-icon class="mr-2">
            {{ mdiInformation }}
          </v-icon>
          Form Submission ID
        </v-list-item>
        <v-list-item
          class="px-4 mx-0"
          @click="showDeleteFormDialog = true"
          v-if="currentUser.user_id === formResult.user_id"
        >
          <v-icon class="mr-2">
            {{ mdiDelete }}
          </v-icon>
          Delete Form
        </v-list-item>
      </v-list>
    </v-menu>

    <DeleteFormDialog
      :showDeleteFormDialog="showDeleteFormDialog"
      @cancel-delete-form="showDeleteFormDialog = false"
      @delete-form="deleteForm"
    />

    <FormIdDialog
      :showFormIdDialog="showFormIdDialog"
      :formResultId="formResultId"
    />

    <SharedSiteUtilibotDialog
      v-if="showUtilibotDialog"
      :showUtilibotDialog="showUtilibotDialog"
      :formResultId="formResultId"
      @utilibot-dialog-close="showUtilibotDialog = false"
    />

    <PdfViewerDialog
      v-if="showPdfDialog"
      :showPdfDialog="showPdfDialog"
      :selectedPdfFileUrl="selectedPdfFileUrl"
      @pdf-viewer-dialog-close="showPdfDialog = false"
    />
  </v-card-actions>
</template>

<script>
import {
  mdiDotsVertical,
  mdiPencil,
  mdiFileDocument,
  mdiInformation,
  mdiDelete,
} from "@mdi/js";
import DeleteFormDialog from "@/components/tickets/ticket-edit-form/dynamic-form/dynamic-form-edit-actions/DeleteFormDialog";
import FormIdDialog from "@/components/tickets/ticket-edit-form/dynamic-form/dynamic-form-edit-actions/FormIdDialog";
import SharedSiteUtilibotDialog from "@/components/shared-site/shared-site-ticket-form-def-drop-down/shared-site-ticket-edit-form/shared-site-dynamic-form/SharedSiteUtilibotDialog";
import PdfViewerDialog from "@/components/tickets/ticket-log/PdfViewerDialog";
import { axiosWithJwtAuth } from "@/plugins/axios";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "SharedSiteDynamicFormEditActions",
  data() {
    return {
      mdiDotsVertical,
      mdiPencil,
      mdiFileDocument,
      mdiInformation,
      mdiDelete,
      showDeleteFormDialog: false,
      showFormIdDialog: false,
      showUtilibotDialog: false,
      showPdfDialog: false,
      formResult: {},
      currentUser: {},
    };
  },
  components: {
    DeleteFormDialog,
    FormIdDialog,
    SharedSiteUtilibotDialog,
    PdfViewerDialog,
  },
  props: {
    canEdit: Boolean,
    formResultId: String,
    selectedPdfFileUrl: String,
  },
  methods: {
    async deleteForm() {
      await axiosWithJwtAuth.delete(
        `${APIURL}/shared_site/form_results/${this.formResultId}`,
        {
          data: {
            site_id: this.$route.query.siteId,
          },
        }
      );
      this.$emit("form-result-deleted");
    },
    async downloadPdf() {
      const { pdf_file_url: pdfFileUrl } = this.formResult;
      if (!pdfFileUrl) {
        return;
      }
      const link = document.createElement("a");
      document.body.appendChild(link);
      link.href = pdfFileUrl;
      link.click();
      link.remove();
    },
    async getFormResult() {
      const {
        data: { results },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/form_results/${this.formResultId}/summary`
      );
      this.formResult = results;
    },
    async getUserData() {
      if (this.$route.query.siteId) {
        const {
          data: { results },
        } = await axiosWithJwtAuth.get(
          `${APIURL}/shared_site/users/one/${this.$route.query.siteId}`
        );
        this.currentUser = results;
      }
    },
  },
  beforeMount() {
    this.getUserData();
    this.getFormResult();
  },
};
</script>

<style scoped>
.icon {
  width: 20px;
  height: 20px;
}
</style>
