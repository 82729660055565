var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "validation-observer",
    { ref: "form" },
    [
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.submit.apply(null, arguments)
            }
          }
        },
        [
          _vm.computedFormDefinition.form
            ? _c(
                "v-toolbar",
                {
                  ref: "toolbar",
                  staticClass: "elevation-0",
                  attrs: {
                    dark: "",
                    color: "#3F51B5",
                    width: "100%",
                    id: "top-bar"
                  }
                },
                [
                  _c("v-toolbar-title", [
                    _vm._v(" " + _vm._s(_vm.formDescription.title) + " ")
                  ]),
                  _c("v-toolbar-title", { staticClass: "caption" }, [
                    _vm._v(" " + _vm._s(_vm.formDescription.description) + " ")
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: { click: _vm.onCloseButtonclick }
                    },
                    [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-card-text",
            {
              staticClass: "pa-0 mb-0",
              style: {
                height: _vm.$vuetify.breakpoint.xsOnly
                  ? _vm.contentHeight + "px"
                  : "65vh"
              },
              attrs: { id: "dynamic-form-card-text" }
            },
            [
              _vm.computedFormDefinition.form
                ? _c(
                    "v-expansion-panels",
                    {
                      staticClass: "px-2 py-0 my-0",
                      attrs: { accordion: "" },
                      model: {
                        value: _vm.openSections,
                        callback: function($$v) {
                          _vm.openSections = $$v
                        },
                        expression: "openSections"
                      }
                    },
                    _vm._l(_vm.computedFormDefinition.form.sections, function(
                      section
                    ) {
                      return _c(
                        "v-expansion-panel",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isSectionVisible(section),
                              expression: "isSectionVisible(section)"
                            }
                          ],
                          key: section.id
                        },
                        [
                          _c(
                            "v-expansion-panel-header",
                            { staticClass: "py-0 my-0 d-flex align-center" },
                            [_c("b", [_vm._v(_vm._s(section.name))])]
                          ),
                          _c(
                            "v-expansion-panel-content",
                            { attrs: { eager: "" } },
                            [
                              _vm.showForm
                                ? _c(
                                    "v-row",
                                    _vm._l(section.items, function(item) {
                                      return _c(
                                        "v-col",
                                        {
                                          key: item.id + "-" + item.number,
                                          style: {
                                            display: _vm.isVisible(item)
                                              ? "block"
                                              : "none"
                                          },
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _vm.isQuestion(item)
                                            ? [
                                                _vm.isVisible(item)
                                                  ? _c(
                                                      "validation-provider",
                                                      [
                                                        [
                                                          "TEXT",
                                                          "EMAIL",
                                                          "NUMBER"
                                                        ].includes(
                                                          item.question.type
                                                        )
                                                          ? _c("TextInput", {
                                                              attrs: {
                                                                item: item,
                                                                formDefinition:
                                                                  _vm.computedFormDefinition,
                                                                canEdit:
                                                                  _vm.canEdit,
                                                                isEditingFinalForm:
                                                                  _vm.isEditingFinalForm,
                                                                alreadySubmittedFinalOnline:
                                                                  _vm.alreadySubmittedFinalOnline,
                                                                color: "#3F51B5"
                                                              },
                                                              on: {
                                                                input:
                                                                  _vm.onInput,
                                                                validated:
                                                                  _vm.onFieldValidated
                                                              },
                                                              model: {
                                                                value:
                                                                  item.value,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "value",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "item.value"
                                                              }
                                                            })
                                                          : _vm._e(),
                                                        ["DATE"].includes(
                                                          item.question.type
                                                        )
                                                          ? _c("DateInput", {
                                                              attrs: {
                                                                item: item,
                                                                formDefinition:
                                                                  _vm.computedFormDefinition,
                                                                canEdit:
                                                                  _vm.canEdit,
                                                                isEditingFinalForm:
                                                                  _vm.isEditingFinalForm,
                                                                alreadySubmittedFinalOnline:
                                                                  _vm.alreadySubmittedFinalOnline
                                                              },
                                                              on: {
                                                                input:
                                                                  _vm.onInput,
                                                                validated:
                                                                  _vm.onFieldValidated
                                                              },
                                                              model: {
                                                                value:
                                                                  item.value,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "value",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "item.value"
                                                              }
                                                            })
                                                          : _vm._e(),
                                                        ["TIME"].includes(
                                                          item.question.type
                                                        )
                                                          ? _c("TimeInput", {
                                                              attrs: {
                                                                item: item,
                                                                formDefinition:
                                                                  _vm.computedFormDefinition,
                                                                canEdit:
                                                                  _vm.canEdit,
                                                                isEditingFinalForm:
                                                                  _vm.isEditingFinalForm,
                                                                alreadySubmittedFinalOnline:
                                                                  _vm.alreadySubmittedFinalOnline
                                                              },
                                                              on: {
                                                                input:
                                                                  _vm.onInput,
                                                                validated:
                                                                  _vm.onFieldValidated
                                                              },
                                                              model: {
                                                                value:
                                                                  item.value,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "value",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "item.value"
                                                              }
                                                            })
                                                          : _vm._e(),
                                                        [
                                                          "SINGLE_SELECT"
                                                        ].includes(
                                                          item.question.type
                                                        )
                                                          ? _c(
                                                              "SingleSelectInput",
                                                              {
                                                                attrs: {
                                                                  item: item,
                                                                  formDefinition:
                                                                    _vm.computedFormDefinition,
                                                                  canEdit:
                                                                    _vm.canEdit,
                                                                  isEditingFinalForm:
                                                                    _vm.isEditingFinalForm,
                                                                  alreadySubmittedFinalOnline:
                                                                    _vm.alreadySubmittedFinalOnline
                                                                },
                                                                on: {
                                                                  input:
                                                                    _vm.onInput,
                                                                  validated:
                                                                    _vm.onFieldValidated
                                                                },
                                                                model: {
                                                                  value:
                                                                    item.value,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "value",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "item.value"
                                                                }
                                                              }
                                                            )
                                                          : _vm._e(),
                                                        [
                                                          "MULTI_SELECT"
                                                        ].includes(
                                                          item.question.type
                                                        )
                                                          ? _c(
                                                              "MultiSelectInput",
                                                              {
                                                                attrs: {
                                                                  item: item,
                                                                  formDefinition:
                                                                    _vm.computedFormDefinition,
                                                                  canEdit:
                                                                    _vm.canEdit,
                                                                  isEditingFinalForm:
                                                                    _vm.isEditingFinalForm,
                                                                  alreadySubmittedFinalOnline:
                                                                    _vm.alreadySubmittedFinalOnline
                                                                },
                                                                on: {
                                                                  input:
                                                                    _vm.onInput,
                                                                  validated:
                                                                    _vm.onFieldValidated
                                                                },
                                                                model: {
                                                                  value:
                                                                    item.value,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "value",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "item.value"
                                                                }
                                                              }
                                                            )
                                                          : _vm._e(),
                                                        ["SIGNATURE"].includes(
                                                          item.question.type
                                                        )
                                                          ? _c("SignaturePad", {
                                                              attrs: {
                                                                item: item,
                                                                formDefinition:
                                                                  _vm.computedFormDefinition,
                                                                canEdit:
                                                                  _vm.canEdit,
                                                                isEditingFinalForm:
                                                                  _vm.isEditingFinalForm,
                                                                alreadySubmittedFinalOnline:
                                                                  _vm.alreadySubmittedFinalOnline
                                                              },
                                                              on: {
                                                                input:
                                                                  _vm.onInput,
                                                                validated:
                                                                  _vm.onFieldValidated
                                                              },
                                                              model: {
                                                                value:
                                                                  item.value,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "value",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "item.value"
                                                              }
                                                            })
                                                          : _vm._e(),
                                                        [
                                                          "CALCULATION"
                                                        ].includes(
                                                          item.question.type
                                                        )
                                                          ? _c(
                                                              "CalculationInput",
                                                              {
                                                                attrs: {
                                                                  formDefinition:
                                                                    _vm.computedFormDefinition,
                                                                  item: item
                                                                },
                                                                model: {
                                                                  value:
                                                                    item.value,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "value",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "item.value"
                                                                }
                                                              }
                                                            )
                                                          : _vm._e(),
                                                        ["FILE"].includes(
                                                          item.question.type
                                                        ) && _vm.formResultId
                                                          ? _c(
                                                              "div",
                                                              [
                                                                item.question
                                                                  .isImage
                                                                  ? _c(
                                                                      "SharedSitePhotoInput",
                                                                      {
                                                                        ref:
                                                                          "photo-input-" +
                                                                          item.id,
                                                                        refInFor: true,
                                                                        attrs: {
                                                                          label:
                                                                            item.number +
                                                                            " " +
                                                                            item
                                                                              .question
                                                                              .label,
                                                                          id:
                                                                            item.id,
                                                                          allowMultiple:
                                                                            item.allowMultiple,
                                                                          showDescription:
                                                                            item
                                                                              .question
                                                                              .showDescription,
                                                                          formResultId:
                                                                            _vm.formResultId,
                                                                          maxWidthHeight: +item
                                                                            .question
                                                                            .maxWidthHeight,
                                                                          formDefinition:
                                                                            _vm.computedFormDefinition,
                                                                          item: item,
                                                                          canEdit:
                                                                            _vm.canEdit,
                                                                          isEditingFinalForm:
                                                                            _vm.isEditingFinalForm,
                                                                          alreadySubmittedFinalOnline:
                                                                            _vm.alreadySubmittedFinalOnline,
                                                                          selectedGisInfo:
                                                                            _vm.selectedGisInfo,
                                                                          selectedMapServiceId:
                                                                            _vm.selectedMapServiceId,
                                                                          globalId:
                                                                            _vm.globalId,
                                                                          objectId:
                                                                            _vm.objectId
                                                                        },
                                                                        on: {
                                                                          "start-photo-upload": function(
                                                                            $event
                                                                          ) {
                                                                            _vm.photoUploading = true
                                                                          },
                                                                          "end-photo-upload": function(
                                                                            $event
                                                                          ) {
                                                                            _vm.photoUploading = false
                                                                            _vm.autoSave()
                                                                          },
                                                                          validated:
                                                                            _vm.onFieldValidated
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            item.value,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              item,
                                                                              "value",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "item.value"
                                                                        }
                                                                      }
                                                                    )
                                                                  : _c(
                                                                      "SharedSiteFileInput",
                                                                      {
                                                                        attrs: {
                                                                          formResultId:
                                                                            _vm.formResultId,
                                                                          id:
                                                                            item.id,
                                                                          label:
                                                                            item.number +
                                                                            " " +
                                                                            item
                                                                              .question
                                                                              .label,
                                                                          allowMultiple:
                                                                            item.allowMultiple,
                                                                          formDefinition:
                                                                            _vm.computedFormDefinition,
                                                                          item: item,
                                                                          canEdit:
                                                                            _vm.canEdit,
                                                                          isEditingFinalForm:
                                                                            _vm.isEditingFinalForm,
                                                                          alreadySubmittedFinalOnline:
                                                                            _vm.alreadySubmittedFinalOnline
                                                                        },
                                                                        on: {
                                                                          "end-file-upload": function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.autoSave()
                                                                          },
                                                                          validated:
                                                                            _vm.onFieldValidated
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            item.value,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              item,
                                                                              "value",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "item.value"
                                                                        }
                                                                      }
                                                                    )
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                        ["GROUP"].includes(
                                                          item.question.type
                                                        )
                                                          ? _c(
                                                              "SharedSiteRepeatingGroup",
                                                              {
                                                                attrs: {
                                                                  item: item,
                                                                  formDefinition:
                                                                    _vm.computedFormDefinition,
                                                                  canEdit:
                                                                    _vm.canEdit,
                                                                  isEditingFinalForm:
                                                                    _vm.isEditingFinalForm,
                                                                  alreadySubmittedFinalOnline:
                                                                    _vm.alreadySubmittedFinalOnline,
                                                                  formResultId:
                                                                    _vm.formResultId,
                                                                  selectedGisInfo:
                                                                    _vm.selectedGisInfo,
                                                                  selectedMapServiceId:
                                                                    _vm.selectedMapServiceId,
                                                                  globalId:
                                                                    _vm.globalId,
                                                                  objectId:
                                                                    _vm.objectId
                                                                },
                                                                model: {
                                                                  value:
                                                                    item.value,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "value",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "item.value"
                                                                }
                                                              }
                                                            )
                                                          : _vm._e(),
                                                        [
                                                          "ACTION_ITEM"
                                                        ].includes(
                                                          item.question.type
                                                        )
                                                          ? _c(
                                                              "SharedSiteActionItem",
                                                              {
                                                                attrs: {
                                                                  item: item,
                                                                  formDefinition:
                                                                    _vm.computedFormDefinition,
                                                                  canEdit:
                                                                    _vm.canEdit,
                                                                  isEditingFinalForm:
                                                                    _vm.isEditingFinalForm,
                                                                  alreadySubmittedFinalOnline:
                                                                    _vm.alreadySubmittedFinalOnline,
                                                                  formResultId:
                                                                    _vm.formResultId,
                                                                  selectedGisInfo:
                                                                    _vm.selectedGisInfo,
                                                                  selectedMapServiceId:
                                                                    _vm.selectedMapServiceId,
                                                                  objectId:
                                                                    _vm.objectId
                                                                },
                                                                model: {
                                                                  value:
                                                                    item.value,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "value",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "item.value"
                                                                }
                                                              }
                                                            )
                                                          : _vm._e()
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ]
                                            : [
                                                _vm.isVisible(item)
                                                  ? _c("InformationItem", {
                                                      attrs: { item: item }
                                                    })
                                                  : _vm._e()
                                              ]
                                        ],
                                        2
                                      )
                                    }),
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _vm._e(),
              _c("v-divider")
            ],
            1
          ),
          [
            _vm.alreadySubmittedFinalOnline && !_vm.isEditingFinalForm
              ? _c("SharedSiteDynamicFormEditActions", {
                  ref: "cardAction",
                  attrs: {
                    canEdit: _vm.canEdit,
                    formResultId: _vm.formResultId,
                    selectedPdfFileUrl: _vm.selectedPdfFileUrl
                  },
                  on: {
                    "edit-form": function($event) {
                      _vm.isEditingFinalForm = true
                    },
                    "form-result-deleted": function($event) {
                      return _vm.$emit("form-result-deleted")
                    }
                  }
                })
              : _c("SharedSiteDynamicFormNormalActions", {
                  ref: "cardAction",
                  attrs: {
                    lastSavedTime: _vm.lastSavedTimes[_vm.formResultId],
                    isSaving: _vm.isSaving,
                    isUpdatingFeatureService: _vm.isUpdatingFeatureService,
                    isAddingToRelatedTables: _vm.isAddingToRelatedTables,
                    isUpdatingDataAfterSubmit: _vm.isUpdatingDataAfterSubmit,
                    canEdit: _vm.canEdit,
                    isOnline: _vm.isOnline,
                    invalidFields: _vm.invalidFields,
                    alreadySubmittedFinalOnline:
                      _vm.alreadySubmittedFinalOnline,
                    submittingFinalForm: _vm.submittingFinalForm,
                    id: "bottom-bar"
                  },
                  on: {
                    "delete-form-result": function($event) {
                      _vm.showDeleteFormDialog = true
                    },
                    "auto-save": _vm.autoSave,
                    "form-info-dialog-show": function($event) {
                      _vm.showFormInfoDialog = true
                    },
                    "invalid-fields-info-dialog-show": function($event) {
                      _vm.showInvalidFieldsDialog = true
                    },
                    "refresh-log": function($event) {
                      return _vm.$emit("refresh-log")
                    }
                  }
                })
          ]
        ],
        2
      ),
      _c("AlreadyClearedDialog", {
        attrs: {
          showAlreadyClearedDialog: _vm.showAlreadyClearedDialog,
          ticketNumbersAlreadySubmitted: _vm.ticketNumbersAlreadySubmitted,
          formResultIdsAlreadySubmitted: _vm.formResultIdsAlreadySubmitted
        },
        on: {
          "delete-forms": function($event) {
            _vm.showAlreadyClearedDialog = false
          },
          "keep-forms": function($event) {
            _vm.showAlreadyClearedDialog = false
          }
        }
      }),
      _c("FormInfoDialog", {
        attrs: {
          formResultId: _vm.formResultId,
          showFormInfoDialog: _vm.showFormInfoDialog
        },
        on: {
          "form-info-dialog-close": function($event) {
            _vm.showFormInfoDialog = false
          }
        }
      }),
      _c("InvalidFieldsDialog", {
        attrs: {
          showInvalidFieldsDialog: _vm.showInvalidFieldsDialog,
          invalidFieldSections: _vm.invalidFieldSections
        },
        on: {
          "invalid-fields-dialog-close": function($event) {
            _vm.showInvalidFieldsDialog = false
          }
        }
      }),
      _vm.showErrorConnectingToGisFeatureDialog
        ? _c("ErrorConnectingToGisFeatureDialog", {
            attrs: {
              showErrorConnectingToGisFeatureDialog:
                _vm.showErrorConnectingToGisFeatureDialog,
              gisInfoQueryResult: _vm.gisInfoQueryResult,
              selectedMapService: _vm.selectedMapService
            },
            on: {
              "error-connecting-to-gis-feature-dialog-close": function($event) {
                _vm.showErrorConnectingToGisFeatureDialog = false
                _vm.deleteFormResult()
              },
              "create-form-result": function($event) {
                _vm.showErrorConnectingToGisFeatureDialog = false
              }
            }
          })
        : _vm._e(),
      _c("DeleteFormDialog", {
        attrs: { showDeleteFormDialog: _vm.showDeleteFormDialog },
        on: {
          "cancel-delete-form": function($event) {
            _vm.showDeleteFormDialog = false
          },
          "delete-form": _vm.deleteFormResult
        }
      }),
      _c("FormOfflineDialog", {
        attrs: { showFormOfflineDialog: _vm.showFormOfflineDialog },
        on: {
          cancel: function($event) {
            _vm.showFormOfflineDialog = false
            _vm.onCloseButtonclick()
          }
        }
      }),
      _c("FeatureServiceUnreachableDialog", {
        attrs: {
          selectedMapServiceId: _vm.selectedMapServiceId,
          showFeatureServiceUnreachableDialog:
            _vm.showFeatureServiceUnreachableDialog,
          fullRequestUrl: _vm.fullRequestUrl
        },
        on: {
          cancel: function($event) {
            _vm.showFeatureServiceUnreachableDialog = false
            _vm.onCloseButtonclick()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }