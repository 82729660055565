<template>
  <validation-observer ref="form">
    <form @submit.prevent="submit">
      <v-toolbar
        dark
        color="#3F51B5"
        class="elevation-0"
        v-if="computedFormDefinition.form"
        width="100%"
        id="top-bar"
        ref="toolbar"
      >
        <v-toolbar-title>
          {{ formDescription.title }}
        </v-toolbar-title>
        <v-toolbar-title class="caption">
          {{ formDescription.description }}
        </v-toolbar-title>

        <v-spacer />
        <v-btn icon @click="onCloseButtonclick">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text
        class="pa-0 mb-0"
        id="dynamic-form-card-text"
        :style="{
          height: $vuetify.breakpoint.xsOnly ? `${contentHeight}px` : '65vh',
        }"
      >
        <v-expansion-panels
          class="px-2 py-0 my-0"
          v-model="openSections"
          accordion
          v-if="computedFormDefinition.form"
        >
          <v-expansion-panel
            v-for="section in computedFormDefinition.form.sections"
            :key="section.id"
            v-show="isSectionVisible(section)"
          >
            <v-expansion-panel-header class="py-0 my-0 d-flex align-center">
              <b>{{ section.name }}</b>
            </v-expansion-panel-header>
            <v-expansion-panel-content eager>
              <v-row v-if="showForm">
                <v-col
                  cols="12"
                  v-for="item in section.items"
                  :key="`${item.id}-${item.number}`"
                  :style="{ display: isVisible(item) ? 'block' : 'none' }"
                >
                  <template v-if="isQuestion(item)">
                    <validation-provider v-if="isVisible(item)">
                      <TextInput
                        v-if="
                          ['TEXT', 'EMAIL', 'NUMBER'].includes(
                            item.question.type
                          )
                        "
                        v-model="item.value"
                        :item="item"
                        :formDefinition="computedFormDefinition"
                        :canEdit="canEdit"
                        :isEditingFinalForm="isEditingFinalForm"
                        :alreadySubmittedFinalOnline="
                          alreadySubmittedFinalOnline
                        "
                        color="#3F51B5"
                        @input="onInput"
                        @validated="onFieldValidated"
                      />

                      <DateInput
                        v-if="['DATE'].includes(item.question.type)"
                        v-model="item.value"
                        :item="item"
                        @input="onInput"
                        @validated="onFieldValidated"
                        :formDefinition="computedFormDefinition"
                        :canEdit="canEdit"
                        :isEditingFinalForm="isEditingFinalForm"
                        :alreadySubmittedFinalOnline="
                          alreadySubmittedFinalOnline
                        "
                      />

                      <TimeInput
                        v-if="['TIME'].includes(item.question.type)"
                        v-model="item.value"
                        :item="item"
                        @input="onInput"
                        @validated="onFieldValidated"
                        :formDefinition="computedFormDefinition"
                        :canEdit="canEdit"
                        :isEditingFinalForm="isEditingFinalForm"
                        :alreadySubmittedFinalOnline="
                          alreadySubmittedFinalOnline
                        "
                      />

                      <SingleSelectInput
                        v-if="['SINGLE_SELECT'].includes(item.question.type)"
                        :item="item"
                        v-model="item.value"
                        :formDefinition="computedFormDefinition"
                        :canEdit="canEdit"
                        :isEditingFinalForm="isEditingFinalForm"
                        :alreadySubmittedFinalOnline="
                          alreadySubmittedFinalOnline
                        "
                        @input="onInput"
                        @validated="onFieldValidated"
                      />

                      <MultiSelectInput
                        v-if="['MULTI_SELECT'].includes(item.question.type)"
                        :item="item"
                        v-model="item.value"
                        :formDefinition="computedFormDefinition"
                        :canEdit="canEdit"
                        :isEditingFinalForm="isEditingFinalForm"
                        :alreadySubmittedFinalOnline="
                          alreadySubmittedFinalOnline
                        "
                        @input="onInput"
                        @validated="onFieldValidated"
                      />

                      <SignaturePad
                        v-if="['SIGNATURE'].includes(item.question.type)"
                        v-model="item.value"
                        :item="item"
                        :formDefinition="computedFormDefinition"
                        :canEdit="canEdit"
                        :isEditingFinalForm="isEditingFinalForm"
                        :alreadySubmittedFinalOnline="
                          alreadySubmittedFinalOnline
                        "
                        @input="onInput"
                        @validated="onFieldValidated"
                      />

                      <CalculationInput
                        v-if="['CALCULATION'].includes(item.question.type)"
                        :formDefinition="computedFormDefinition"
                        :item="item"
                        v-model="item.value"
                      />

                      <div
                        v-if="
                          ['FILE'].includes(item.question.type) && formResultId
                        "
                      >
                        <SharedSitePhotoInput
                          v-if="item.question.isImage"
                          :label="`${item.number} ${item.question.label}`"
                          :id="item.id"
                          :allowMultiple="item.allowMultiple"
                          :showDescription="item.question.showDescription"
                          v-model="item.value"
                          :formResultId="formResultId"
                          :maxWidthHeight="+item.question.maxWidthHeight"
                          :formDefinition="computedFormDefinition"
                          :item="item"
                          :canEdit="canEdit"
                          :isEditingFinalForm="isEditingFinalForm"
                          :alreadySubmittedFinalOnline="
                            alreadySubmittedFinalOnline
                          "
                          :selectedGisInfo="selectedGisInfo"
                          :selectedMapServiceId="selectedMapServiceId"
                          :globalId="globalId"
                          :objectId="objectId"
                          @start-photo-upload="photoUploading = true"
                          @end-photo-upload="
                            photoUploading = false;
                            autoSave();
                          "
                          @validated="onFieldValidated"
                          :ref="`photo-input-${item.id}`"
                        />

                        <SharedSiteFileInput
                          v-else
                          v-model="item.value"
                          :formResultId="formResultId"
                          :id="item.id"
                          :label="`${item.number} ${item.question.label}`"
                          :allowMultiple="item.allowMultiple"
                          :formDefinition="computedFormDefinition"
                          :item="item"
                          :canEdit="canEdit"
                          :isEditingFinalForm="isEditingFinalForm"
                          :alreadySubmittedFinalOnline="
                            alreadySubmittedFinalOnline
                          "
                          @end-file-upload="autoSave()"
                          @validated="onFieldValidated"
                        />
                      </div>

                      <SharedSiteRepeatingGroup
                        v-if="['GROUP'].includes(item.question.type)"
                        :item="item"
                        :formDefinition="computedFormDefinition"
                        :canEdit="canEdit"
                        :isEditingFinalForm="isEditingFinalForm"
                        :alreadySubmittedFinalOnline="
                          alreadySubmittedFinalOnline
                        "
                        :formResultId="formResultId"
                        :selectedGisInfo="selectedGisInfo"
                        :selectedMapServiceId="selectedMapServiceId"
                        :globalId="globalId"
                        :objectId="objectId"
                        v-model="item.value"
                      />

                      <SharedSiteActionItem
                        v-if="['ACTION_ITEM'].includes(item.question.type)"
                        :item="item"
                        :formDefinition="computedFormDefinition"
                        :canEdit="canEdit"
                        :isEditingFinalForm="isEditingFinalForm"
                        :alreadySubmittedFinalOnline="
                          alreadySubmittedFinalOnline
                        "
                        :formResultId="formResultId"
                        :selectedGisInfo="selectedGisInfo"
                        :selectedMapServiceId="selectedMapServiceId"
                        :objectId="objectId"
                        v-model="item.value"
                      />
                    </validation-provider>
                  </template>
                  <template v-else>
                    <InformationItem :item="item" v-if="isVisible(item)" />
                  </template>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-divider></v-divider>
      </v-card-text>
      <template>
        <SharedSiteDynamicFormEditActions
          ref="cardAction"
          v-if="alreadySubmittedFinalOnline && !isEditingFinalForm"
          :canEdit="canEdit"
          :formResultId="formResultId"
          :selectedPdfFileUrl="selectedPdfFileUrl"
          @edit-form="isEditingFinalForm = true"
          @form-result-deleted="$emit('form-result-deleted')"
        />
        <SharedSiteDynamicFormNormalActions
          ref="cardAction"
          v-else
          :lastSavedTime="lastSavedTimes[formResultId]"
          :isSaving="isSaving"
          :isUpdatingFeatureService="isUpdatingFeatureService"
          :isAddingToRelatedTables="isAddingToRelatedTables"
          :isUpdatingDataAfterSubmit="isUpdatingDataAfterSubmit"
          @delete-form-result="showDeleteFormDialog = true"
          @auto-save="autoSave"
          @form-info-dialog-show="showFormInfoDialog = true"
          @invalid-fields-info-dialog-show="showInvalidFieldsDialog = true"
          @refresh-log="$emit('refresh-log')"
          :canEdit="canEdit"
          :isOnline="isOnline"
          :invalidFields="invalidFields"
          :alreadySubmittedFinalOnline="alreadySubmittedFinalOnline"
          :submittingFinalForm="submittingFinalForm"
          id="bottom-bar"
        />
      </template>
    </form>

    <AlreadyClearedDialog
      :showAlreadyClearedDialog="showAlreadyClearedDialog"
      :ticketNumbersAlreadySubmitted="ticketNumbersAlreadySubmitted"
      :formResultIdsAlreadySubmitted="formResultIdsAlreadySubmitted"
      @delete-forms="showAlreadyClearedDialog = false"
      @keep-forms="showAlreadyClearedDialog = false"
    />

    <FormInfoDialog
      :formResultId="formResultId"
      :showFormInfoDialog="showFormInfoDialog"
      @form-info-dialog-close="showFormInfoDialog = false"
    />

    <InvalidFieldsDialog
      :showInvalidFieldsDialog="showInvalidFieldsDialog"
      :invalidFieldSections="invalidFieldSections"
      @invalid-fields-dialog-close="showInvalidFieldsDialog = false"
    />

    <ErrorConnectingToGisFeatureDialog
      v-if="showErrorConnectingToGisFeatureDialog"
      :showErrorConnectingToGisFeatureDialog="
        showErrorConnectingToGisFeatureDialog
      "
      :gisInfoQueryResult="gisInfoQueryResult"
      :selectedMapService="selectedMapService"
      @error-connecting-to-gis-feature-dialog-close="
        showErrorConnectingToGisFeatureDialog = false;
        deleteFormResult();
      "
      @create-form-result="showErrorConnectingToGisFeatureDialog = false"
    />

    <DeleteFormDialog
      :showDeleteFormDialog="showDeleteFormDialog"
      @cancel-delete-form="showDeleteFormDialog = false"
      @delete-form="deleteFormResult"
    />

    <FormOfflineDialog
      :showFormOfflineDialog="showFormOfflineDialog"
      @cancel="
        showFormOfflineDialog = false;
        onCloseButtonclick();
      "
    />

    <FeatureServiceUnreachableDialog
      :selectedMapServiceId="selectedMapServiceId"
      :showFeatureServiceUnreachableDialog="showFeatureServiceUnreachableDialog"
      :fullRequestUrl="fullRequestUrl"
      @cancel="
        showFeatureServiceUnreachableDialog = false;
        onCloseButtonclick();
      "
    />
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import SignaturePad from "@/components/tickets/shared/SignaturePad";
import TextInput from "@/components/tickets/shared/TextInput";
import SharedSitePhotoInput from "@/components/shared-site/shared-site-ticket-form-def-drop-down/shared-site-ticket-edit-form/shared-site-dynamic-form/SharedSitePhotoInput";
import SharedSiteFileInput from "@/components/shared-site/shared-site-ticket-form-def-drop-down/shared-site-ticket-edit-form/shared-site-dynamic-form/SharedSiteFileInput";
import SingleSelectInput from "@/components/tickets/shared/SingleSelectInput";
import MultiSelectInput from "@/components/tickets/shared/MultiSelectInput";
import CalculationInput from "@/components/tickets/shared/CalculationInput";
import DateInput from "@/components/tickets/shared/DateInput";
import TimeInput from "@/components/tickets/shared/TimeInput";
import SharedSiteRepeatingGroup from "@/components/shared-site/shared-site-ticket-form-def-drop-down/shared-site-ticket-edit-form/shared-site-dynamic-form/SharedSiteRepeatingGroup";
import SharedSiteActionItem from "@/components/shared-site/shared-site-ticket-form-def-drop-down/shared-site-ticket-edit-form/shared-site-dynamic-form/SharedSiteActionItem";
import SharedSiteDynamicFormEditActions from "@/components/shared-site/shared-site-ticket-form-def-drop-down/shared-site-ticket-edit-form/shared-site-dynamic-form/SharedSiteDynamicFormEditActions";
import SharedSiteDynamicFormNormalActions from "@/components/shared-site/shared-site-ticket-form-def-drop-down/shared-site-ticket-edit-form/shared-site-dynamic-form//SharedSiteDynamicFormNormalActions";
import ErrorConnectingToGisFeatureDialog from "@/components/tickets/ticket-edit-form/dynamic-form/ErrorConnectingToGisFeatureDialog";
import InformationItem from "@/components/tickets/shared/InformationItem";
import FormInfoDialog from "@/components/tickets/shared/FormInfoDialog";
import InvalidFieldsDialog from "@/components/tickets/shared/InvalidFieldsDialog";
import { axiosWithJwtAuth, axiosWithNoAuth } from "@/plugins/axios";
import { cloneDeep, throttle } from "lodash";
import {
  mdiDotsVertical,
  mdiAlertCircle,
  mdiInformation,
  mdiLightningBoltCircle,
  mdiClose,
} from "@mdi/js";
import { mapMutations } from "vuex";
import dependantValueMixin from "@/mixins/dependantValueMixin";
import AlreadyClearedDialog from "@/components/shared/AlreadyClearedDialog";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import DEFAULT_VALUES_TYPES from "@/constants/defaultValueTypes";
import contentHeightMixin from "@/mixins/contentHeightMixin";
import {
  checkItemVisibilityWithItem,
  checkItemVisibilityWithInfoItem,
} from "@/mixins/checkValues";
import {
  getOrgValue,
  getUserValue,
  getGisValue,
  getGisDataValue,
  getCurrentValue,
  getSharedSiteUrlValue,
} from "@/mixins/getDefaultValues";
import sharedSiteEsriTablesUtilibotsMixin from "@/mixins/sharedSiteEsriTablesUtilibotsMixin";
import DeleteFormDialog from "@/components/tickets/ticket-edit-form/dynamic-form/dynamic-form-edit-actions/DeleteFormDialog";
import FormOfflineDialog from "@/components/tickets/ticket-edit-form/dynamic-form/FormOfflineDialog";
import FeatureServiceUnreachableDialog from "@/components/tickets/ticket-edit-form/dynamic-form/FeatureServiceUnreachableDialog";

const APIURL = process.env.VUE_APP_API_URL;
const APPLY_IF = {
  ANY: "ANY",
  ALL: "ALL",
};

const CONDITION_CHOICES = {
  ALWAYS: "ALWAYS",
  CONDITIONAL: "CONDITIONAL",
};

const FIELD_MATCH_CHOICE_VALUES = {
  EQUAL: "EQUAL",
  GREATER_THAN: "GREATER_THAN",
  GREATER_THAN_OR_EQUAL: "GREATER_THAN_OR_EQUAL",
  LESS_THAN: "LESS_THAN",
  LESS_THAN_OR_EQUAL: "LESS_THAN_OR_EQUAL",
  NOT_EQUAL: "NOT_EQUAL",
};

const checkApplyGisValue = ({ condition, value, field, featureAttributes }) => {
  if (condition === FIELD_MATCH_CHOICE_VALUES.EQUAL) {
    return getGisValue(field, featureAttributes) === value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.GREATER_THAN) {
    return +getGisValue(field, featureAttributes) > +value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.GREATER_THAN_OR_EQUAL) {
    return +getGisValue(field, featureAttributes) >= +value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.LESS_THAN) {
    return +getGisValue(field, featureAttributes) < +value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.LESS_THAN_OR_EQUAL) {
    return +getGisValue(field, featureAttributes) <= +value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.NOT_EQUAL) {
    return getGisValue(field, featureAttributes) !== value;
  }
};

const checkApplyGisDataValue = ({ condition, value, field, gisDataValues }) => {
  if (condition === FIELD_MATCH_CHOICE_VALUES.EQUAL) {
    return getGisDataValue(field, gisDataValues) === value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.GREATER_THAN) {
    return +getGisDataValue(field, gisDataValues) > +value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.GREATER_THAN_OR_EQUAL) {
    return +getGisDataValue(field, gisDataValues) >= +value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.LESS_THAN) {
    return +getGisDataValue(field, gisDataValues) < +value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.LESS_THAN_OR_EQUAL) {
    return +getGisDataValue(field, gisDataValues) <= +value;
  } else if (condition === FIELD_MATCH_CHOICE_VALUES.NOT_EQUAL) {
    return getGisDataValue(field, gisDataValues) !== value;
  }
};

const checkApplyValue = ({
  type,
  field,
  condition,
  value,
  featureAttributes,
  gisDataValues,
}) => {
  if (type === DEFAULT_VALUES_TYPES.GIS) {
    return checkApplyGisValue({ condition, value, field, featureAttributes });
  } else if (type === DEFAULT_VALUES_TYPES.GIS_DATA) {
    return checkApplyGisDataValue({
      condition,
      value,
      field,
      gisDataValues,
    });
  }
};

const getShouldApplyDefaultValue = ({
  item,
  featureAttributes,
  gisDataValues,
}) => {
  if (
    !item?.question?.default?.applyDefault ||
    !item?.question?.default?.applyDefaultConditions ||
    item?.question?.default?.applyDefault === CONDITION_CHOICES.ALWAYS
  ) {
    return true;
  }
  const { applyIf, conditions } =
    item?.question?.default?.applyDefaultConditions ?? {};
  if (applyIf === APPLY_IF.ANY) {
    return conditions.some((c) => {
      const { type, field, condition, value } = c;
      const args = {
        type,
        field,
        condition,
        value,
        featureAttributes,
        gisDataValues,
      };
      return checkApplyValue(args);
    });
  } else if (applyIf === APPLY_IF.ALL) {
    return conditions.every((c) => {
      const { type, field, condition, value } = c;
      const args = {
        type,
        field,
        condition,
        value,
        featureAttributes,
        gisDataValues,
      };
      return checkApplyValue(args);
    });
  }
};

const checkCreateValue = ({
  rowItem,
  userDataValues,
  featureAttributes,
  gisDataValues,
}) => {
  if (
    !rowItem?.question?.create ||
    rowItem?.question?.create?.condition === CONDITION_CHOICES.ALWAYS
  ) {
    return true;
  }

  const { dependentFieldId, dependent, value, condition } =
    rowItem?.question?.create ?? {};
  if (condition === FIELD_MATCH_CHOICE_VALUES.EQUAL) {
    if (dependent === DEFAULT_VALUES_TYPES.USER) {
      return (
        value === getUserValue(dependentFieldId, userDataValues) ||
        +value === +getUserValue(dependentFieldId, userDataValues)
      );
    } else if (dependent === DEFAULT_VALUES_TYPES.GIS) {
      return (
        value === getGisValue(dependentFieldId, featureAttributes) ||
        +value === +getGisValue(dependentFieldId, featureAttributes)
      );
    } else if (dependent === DEFAULT_VALUES_TYPES.GIS_DATA) {
      return (
        value === getGisDataValue(dependentFieldId, gisDataValues) ||
        +value === +getGisDataValue(dependentFieldId, gisDataValues)
      );
    }
  }
};

const checkCreateInfoValue = ({
  rowItem,
  userDataValues,
  featureAttributes,
  gisDataValues,
}) => {
  if (
    !rowItem?.information?.create ||
    rowItem?.information?.create?.condition === CONDITION_CHOICES.ALWAYS
  ) {
    return true;
  }

  const { dependentFieldId, dependent, value, condition } =
    rowItem?.information?.create ?? {};
  if (condition === FIELD_MATCH_CHOICE_VALUES.EQUAL) {
    if (dependent === DEFAULT_VALUES_TYPES.USER) {
      return (
        value === getUserValue(dependentFieldId, userDataValues) ||
        +value === +getUserValue(dependentFieldId, userDataValues)
      );
    } else if (dependent === DEFAULT_VALUES_TYPES.GIS) {
      return (
        value === getGisValue(dependentFieldId, featureAttributes) ||
        +value === +getGisValue(dependentFieldId, featureAttributes)
      );
    } else if (dependent === DEFAULT_VALUES_TYPES.GIS_DATA) {
      return (
        value === getGisDataValue(dependentFieldId, gisDataValues) ||
        +value === +getGisDataValue(dependentFieldId, gisDataValues)
      );
    }
  }
};

export default {
  name: "SharedSiteDynamicForm",
  components: {
    ValidationProvider,
    ValidationObserver,
    SignaturePad,
    TextInput,
    SharedSitePhotoInput,
    SharedSiteFileInput,
    SingleSelectInput,
    MultiSelectInput,
    CalculationInput,
    DateInput,
    TimeInput,
    SharedSiteRepeatingGroup,
    SharedSiteActionItem,
    AlreadyClearedDialog,
    SharedSiteDynamicFormEditActions,
    SharedSiteDynamicFormNormalActions,
    InformationItem,
    FormInfoDialog,
    InvalidFieldsDialog,
    ErrorConnectingToGisFeatureDialog,
    DeleteFormDialog,
    FormOfflineDialog,
    FeatureServiceUnreachableDialog,
  },
  mixins: [
    dependantValueMixin,
    contentHeightMixin,
    sharedSiteEsriTablesUtilibotsMixin,
  ],
  props: {
    formDefinition: {
      type: Object,
      default() {
        return {
          form: {
            formDescription: { title: "" },
            sections: [{ items: [] }],
          },
        };
      },
    },
    selectedGisInfo: Object,
    existingFormResultIdMap: Object,
    canEdit: {
      type: Boolean,
      default: true,
    },
    alreadySubmittedFinalOnline: {
      type: Boolean,
      default: false,
    },
    submittedFinalTicketOffline: Boolean,
    selectedMapServiceId: String,
    objectId: Number,
    globalId: String,
    taskId: String,
    selectedPdfFileUrl: String,
  },
  data() {
    return {
      photoUploading: false,
      isOnline: navigator.onLine,
      lastSavedTimes: {},
      mdiDotsVertical,
      mdiAlertCircle,
      mdiInformation,
      mdiLightningBoltCircle,
      mdiClose,
      isSaving: false,
      openSections: [],
      computedFormDefinition: {},
      formResultIdMap: undefined,
      showAlreadyClearedDialog: false,
      ticketNumbersAlreadySubmitted: [],
      formResultIdsAlreadySubmitted: [],
      ticketNumbersAlreadySubmittedSubmitFinal: [],
      formResultIdsAlreadySubmittedSubmitFinal: [],
      savedComputedFormDefinition: JSON.stringify({}),
      showFormInfoDialog: false,
      showInvalidFieldsDialog: false,
      fieldValidationResult: {},
      isEditingFinalForm: false,
      gisInfoQueryResult: undefined,
      showErrorConnectingToGisFeatureDialog: false,
      selectedMapService: {},
      showForm: false,
      prepopulatedValues: false,
      errorConnectingToGisFeatureDialogNeverAppeared: false,
      hasUnsavedChanges: false,
      gisDataValues: [],
      submittingFinalForm: false,
      isUpdatingFeatureService: false,
      isAddingToRelatedTables: false,
      isUpdatingDataAfterSubmit: false,
      showDeleteFormDialog: false,
      featureAttributes: {},
      showFormOfflineDialog: false,
      showFeatureServiceUnreachableDialog: false,
      fullRequestUrl: "",
    };
  },
  computed: {
    formDescription() {
      return this.computedFormDefinition?.form?.formDescription ?? {};
    },
    formResultId() {
      return this.formResultIdMap?.formResultId;
    },
    invalidFields() {
      return this.invalidFieldSections?.map((s) => s.items).flat();
    },
    invalidFieldSections() {
      const invalidFieldIds = Object.entries(this.fieldValidationResult)
        .filter(([, val]) => !val.valid)
        .map(([key]) => +key);
      const invalidSections = cloneDeep(this.formDefinition?.form?.sections);
      if (Array.isArray(invalidSections)) {
        for (const section of invalidSections) {
          section.items = section.items.filter((i) =>
            invalidFieldIds.includes(+i.id)
          );
        }
      }
      const invalidSectionsWithoutEmpty = invalidSections?.filter(
        ({ items }) => items.length > 0
      );
      return invalidSectionsWithoutEmpty ?? [];
    },
  },
  methods: {
    isSectionVisible(section) {
      if (
        !section.visible ||
        !section.visible?.condition ||
        section.visible?.condition === "ALWAYS"
      ) {
        return true;
      }
      if (section.visible.condition === "NEVER") {
        return false;
      }
      const { sections } = { ...this.computedFormDefinition.form };
      const flattenedItems = sections.map(({ items }) => items).flat();
      const dependantItem = flattenedItems.find(
        (item) => item.id === section.visible.dependantId
      );
      const dependantItemValue = dependantItem?.value;
      if (Array.isArray(dependantItemValue)) {
        if (dependantItemValue.length > 1) {
          return false;
        } else {
          const [dependantValue] = dependantItemValue;
          return this.checkSectionDependantValue(
            dependantValue,
            section,
            "visible"
          );
        }
      } else {
        return this.checkSectionDependantValue(
          dependantItemValue,
          section,
          "visible"
        );
      }
    },
    async onCloseButtonclick() {
      await this.$nextTick();
      this.$emit("ticket-edit-form-close-button-click", this.hasUnsavedChanges);
    },
    onFieldValidated(result) {
      const { id, ...restResult } = result;
      this.$set(this.fieldValidationResult, id, restResult);
    },
    async onInput() {
      await this.$nextTick();
      this.hasUnsavedChanges = true;
      this.$emit("input", this.computedFormDefinition);
    },
    async deleteFormResult() {
      this.showDeleteFormDialog = false;
      if (!this.formResultId) {
        this.$emit("ticket-edit-form-close");
        return;
      }
      if (navigator.onLine) {
        await axiosWithJwtAuth.delete(
          `${APIURL}/shared_site/form_results/${this.formResultId}`,
          {
            data: {
              site_id: this.$route.query.siteId,
            },
          }
        );
      }
      const lastSavedTimes = { ...this.lastSavedTimes };
      delete lastSavedTimes[this.formResultId];
      localStorage.setItem("last-saved-times", JSON.stringify(lastSavedTimes));
      this.$emit("form-result-deleted");
    },
    isRequired(item) {
      const { sections } = { ...this.computedFormDefinition.form };
      for (const section of sections) {
        for (const dependantItem of section.items) {
          if (+dependantItem.id === +item.question.required.dependantId) {
            const dependantItemValue = dependantItem.value;
            if (Array.isArray(dependantItemValue)) {
              if (dependantItemValue.length > 1) {
                return false;
              } else {
                const [dependantValue] = dependantItemValue;
                return this.checkDependantValue(dependantValue);
              }
            } else {
              return this.checkDependantValue(dependantItemValue);
            }
          }
        }
      }
      return item.question.required.condition === "ALWAYS";
    },
    isVisible(item) {
      if (item.question) {
        return this.checkVisibility(item, "question");
      } else {
        return this.checkVisibility(item, "information");
      }
    },
    checkVisibility(item, type = "question") {
      if (
        item[type].visible?.condition === "ALWAYS" ||
        item[type].visible?.applyVisible === "ALWAYS"
      ) {
        return true;
      }

      if (
        item[type].visible?.condition === "NEVER" ||
        item[type].visible?.applyVisible === "NEVER"
      ) {
        return false;
      }

      if (item?.[type]?.visible?.condition) {
        const { sections } = { ...this.computedFormDefinition.form };
        const items = sections.map((s) => s.items).flat();
        const dependantItem = items.find((it) => {
          return +it.id === +item[type].visible.dependantId;
        });
        const dependantItemValue = dependantItem?.value;

        if (Array.isArray(dependantItemValue)) {
          if (dependantItemValue.length > 1) {
            return false;
          } else {
            const [dependantValue] = dependantItemValue;
            return this.checkDependantValueWithItem(
              item,
              dependantValue,
              type,
              "visible"
            );
          }
        } else {
          return this.checkDependantValueWithItem(
            item,
            dependantItemValue,
            type,
            "visible"
          );
        }
      } else {
        const { featureAttributes, gisDataValues } = this;
        const { sections } = { ...this.computedFormDefinition.form };
        const dependantItems = sections.map((s) => s.items).flat();
        if (type === "question") {
          return checkItemVisibilityWithItem({
            item,
            featureAttributes,
            dependantItems,
            gisDataValues,
          });
        } else {
          return checkItemVisibilityWithInfoItem({
            item,
            featureAttributes,
            dependantItems,
            gisDataValues,
          });
        }
      }
    },
    isQuestion(item) {
      return Object.prototype.hasOwnProperty.call(item, "question");
    },
    async submit() {
      const success = await this.$refs.form.validate();
      if (!success) {
        this.showInvalidFieldsDialog = true;
        return;
      }
      try {
        this.submittingFinalForm = true;
        if (navigator.onLine) {
          this.isSaving = true;
          await this.save("SUBMITTED_FINAL");
          this.isSaving = false;
          await this.updateFeatureService();
          await this.addToRelatedTableFromFormResult();
          this.isUpdatingDataAfterSubmit = true;
          this.isUpdatingDataAfterSubmit = false;
          this.submittingFinalForm = false;
        }
        this.$emit("ticket-edit-form-submitted");
      } catch (error) {
        if (Array.isArray(error?.response?.data?.error)) {
          const [errorMsg] = error?.response?.data?.error;
          this.$emit("ticket-edit-form-error", errorMsg);
        } else {
          if (error?.response?.data?.error) {
            this.$emit("ticket-edit-form-error", error?.response?.data?.error);
          }
        }
      } finally {
        this.isSaving = false;
        this.submittingFinalForm = false;
        const { formResultId } = this;
        this.setFormSubmitted({ isFormSubmitted: true, formResultId });
      }
    },
    async prepareRepeatingGroupPayload(repeatingGroupValues) {
      const valuesArr = cloneDeep(repeatingGroupValues);
      if (!Array.isArray(valuesArr)) {
        return [];
      }
      for (const [arrIndex, values] of valuesArr?.entries()) {
        for (const [index, item] of values?.entries()) {
          if (
            typeof item.question === "object" &&
            item.question !== null &&
            ["FILE"].includes(item.question.type)
          ) {
            valuesArr[arrIndex][index] = await this.addFiles(item);
          }
        }
      }
      return valuesArr;
    },
    async createPayload(computedFormDefinition, status) {
      for (const section of computedFormDefinition?.form?.sections ?? []) {
        for (const [index, item] of section.items.entries()) {
          if (typeof item.question !== "object" || item.question === null) {
            continue;
          }

          if (["FILE"].includes(item.question.type)) {
            section.items[index] = await this.addFiles(item);
          }

          if (["GROUP"].includes(item.question.type)) {
            section.items[index].value =
              await this.prepareRepeatingGroupPayload(item.value);
          }

          if (["ACTION_ITEM"].includes(item.question.type)) {
            if (!section.items[index].value?.newActionItems) {
              section.items[index].value = {
                ...section.items[index].value,
                newActionItems: [],
              };
            }
          }

          if (
            ["TEXT", "EMAIL", "NUMBER"].includes(item.question.type) &&
            item.allowMultiple
          ) {
            if (Array.isArray(item.value)) {
              const { id: parentId } = item;
              const [firstVal, ...restVals] = item.value;
              section.items[index] = {
                ...section.items[index],
                value: firstVal,
                children: restVals.map((r, i) => {
                  return {
                    ...section.items[index],
                    value: r,
                    id: `${parentId}.${i + 1}`,
                  };
                }),
              };
            }
          }
        }
      }

      const { featureAttributes } = this;
      const { form_definition_id: formDefinitionId, form } =
        computedFormDefinition;
      const sections = cloneDeep(form.sections);
      const {
        form: formDefinition,
        html_merge_definition_version: htmlMergeDefinitionVersion,
        html_merge_definition: htmlMergeDefinition,
      } = this.formDefinition;

      const payload = {
        form_definition_id: formDefinitionId,
        feature_attributes: featureAttributes,
        feature_id: this.objectId,
        task_id: this.taskId,
        form_values: { sections },
        status,
        form_definition: formDefinition,
        html_merge_definition_version: htmlMergeDefinitionVersion,
        html_merge_definition: htmlMergeDefinition,
      };
      return payload;
    },
    async save(status) {
      if (!this.canEdit) {
        return;
      }

      try {
        this.lastSavedTimes =
          JSON.parse(localStorage.getItem("last-saved-times")) || {};
      } catch (error) {
        this.lastSavedTimes = {};
      }
      this.isSaving = true;
      const { computedFormDefinition } = this;

      if (!computedFormDefinition.form) {
        return;
      }
      const { globalId } = this;
      if (!this.formResultIdMap) {
        if (this.existingFormResultIdMap) {
          this.formResultIdMap = { ...this.existingFormResultIdMap };
        } else {
          this.formResultIdMap = {
            globalId,
            formResultId: uuidv4(),
          };
        }
      }

      const payload = await this.createPayload(
        cloneDeep(computedFormDefinition),
        status
      );
      await axiosWithJwtAuth.put(
        `${APIURL}/shared_site/form_result_values/${this.formResultId}/${this.$route.query.siteId}`,
        payload
      );
      const lastSavedTimes = {
        ...this.lastSavedTimes,
        [this.formResultId]: new Date(),
      };
      localStorage.setItem("last-saved-times", JSON.stringify(lastSavedTimes));

      try {
        this.lastSavedTimes =
          JSON.parse(localStorage.getItem("last-saved-times")) || {};
      } catch (error) {
        this.lastSavedTimes = {};
      }
      this.isSaving = false;
      this.hasUnsavedChanges = false;
    },
    autoSave: throttle(async function () {
      if (!this.canEdit) {
        return;
      }
      if (navigator.onLine) {
        this.save("IN_PROCESS");
      }
    }, 5000),
    async addFiles(item) {
      if (
        !item.value ||
        (typeof item.value === "object" &&
          item.value !== null &&
          !Array.isArray(item.value) &&
          Array.isArray(item.children))
      ) {
        return { ...item };
      }

      const { id: parentId } = item;
      const filePromises = item.value.map((f) => {
        const {
          file_size_kb: fileSizeKb,
          id,
          name,
          url,
          dataUrl,
          fileName,
          fileType,
          description,
        } = f;
        return {
          file_size_kb: fileSizeKb,
          id,
          name,
          url,
          dataUrl,
          fileName,
          fileType,
          description,
        };
      });

      const files = await Promise.all(filePromises);
      const mappedFiles = files.map(({ id, url, description }) => ({
        id,
        url,
        description,
      }));
      const [firstFile, ...restFiles] = mappedFiles;
      return {
        ...item,
        value: firstFile,
        children: restFiles.map((r, i) => {
          const { children, ...restItem } = item;
          return {
            ...restItem,
            value: r,
            id: `${parentId}.${i + 1}`,
          };
        }),
      };
    },
    transformRepeatingGroup(repeatingGroup) {
      if (!Array.isArray(repeatingGroup?.value)) {
        return [];
      }
      const repeatingGroupValuesCopy = cloneDeep(repeatingGroup.value);
      for (const valueArr of repeatingGroupValuesCopy) {
        for (const item of valueArr) {
          if (item?.question?.type === "FILE") {
            if (
              typeof item.value === "object" &&
              item.value !== null &&
              !Array.isArray(item.value)
            ) {
              const { value, children = [] } = item;
              item.value = [value, ...children.map((c) => c.value)];
            }
          }
        }
      }
      return repeatingGroupValuesCopy;
    },
    async transformFormDefinition(formDefinition) {
      const computedFormDefinition = cloneDeep(formDefinition);
      for (const section of computedFormDefinition?.form?.sections ?? []) {
        for (const item of section.items) {
          if (this.isQuestion(item)) {
            if (item?.question?.type === "FILE") {
              if (
                typeof item.value === "object" &&
                item.value !== null &&
                !Array.isArray(item.value)
              ) {
                const { value, children = [] } = item;
                item.value = [value, ...children.map((c) => c.value)];
              }
            } else if (item?.question?.type === "GROUP") {
              item.value = this.transformRepeatingGroup(item);
            } else if (item?.question?.type === "DATE") {
              if (
                typeof item?.value !== "undefined" &&
                moment(item?.value).isValid()
              ) {
                const date = moment(item?.value);
                item.value = date.format("YYYY-MM-DD");
              }
            } else if (
              ["TEXT", "EMAIL", "NUMBER"].includes(item?.question?.type) &&
              item.allowMultiple
            ) {
              const { value, children } = item;
              if (Array.isArray(children)) {
                item.value = [value, ...children.map((c) => c.value)];
              }
            }
          }
        }
      }
      this.computedFormDefinition = computedFormDefinition;
    },
    async getGisDataFieldsAndValues() {
      const { selectedMapServiceId, objectId } = this;
      const {
        data: { results: gisDataValues },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/gis_data_values/all/${this.$route.query.siteId}`
      );

      const gisDataValueIds = gisDataValues.map((g) => g.gis_data_field_id);

      const {
        data: { results: allGisDataFields },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/gis_data_fields_by_user_group/${this.$route.query.siteId}`
      );

      const gisDataFields = await allGisDataFields.filter((g) => {
        return (
          g.map_service_id === selectedMapServiceId &&
          !gisDataValueIds.includes(objectId)
        );
      });

      return gisDataFields?.map((gdf) => {
        const { gis_data_field_id: gisDataFieldId } = gdf;
        const gisDataValue = gisDataValues?.find((gdv) => {
          return (
            gdv?.gis_data_field_id === gdf?.gis_data_field_id &&
            +gdv?.feature_id === +objectId
          );
        });
        const value = gisDataValue?.value;
        const gisDataValueId = gisDataValue?.gis_data_value_id;
        return {
          feature_id: objectId,
          gis_data_field_id: gisDataFieldId,
          gis_data_value_id: gisDataValueId,
          value,
        };
      });
    },
    async prePopulateValues(formDefinition) {
      try {
        if (!this.alreadySubmittedFinalOnline) {
          if (this.isOnline) {
            await this.save("IN_PROCESS");
          }
        }
        const computedFormDefinition = cloneDeep(formDefinition);
        const { globalId } = this;

        if (this.isOnline) {
          const { formResultId } = this.formResultIdMap ?? {};
          if (!formResultId) {
            this.prepopulatedValues = true;
            return;
          }
        } else {
          if (!this.formResultIdMap) {
            if (this.existingFormResultIdMap) {
              this.formResultIdMap = { ...this.existingFormResultIdMap };
            } else {
              this.formResultIdMap = {
                globalId,
                formResultId: uuidv4(),
              };
            }
          }
        }
        const { formResultId } = this.formResultIdMap;
        const gisDataValues = await this.getGisDataFieldsAndValues();
        this.gisDataValues = gisDataValues;
        const { featureAttributes } = this;

        for (const section of computedFormDefinition.form.sections) {
          for (const item of section.items) {
            if (this.isQuestion(item)) {
              const { type, value } = item?.question?.default ?? {};
              if (item.question.type !== "GROUP") {
                if (!item.value) {
                  if (
                    !getShouldApplyDefaultValue({
                      item,
                      featureAttributes,
                      gisDataValues,
                    })
                  ) {
                    continue;
                  }

                  if (type === DEFAULT_VALUES_TYPES.ORGANIZATION) {
                    item.value = getOrgValue();
                  } else if (type === DEFAULT_VALUES_TYPES.CUSTOM) {
                    if (Array.isArray(value)) {
                      const allStrings = value.every(
                        (v) => typeof v === "string"
                      );
                      if (allStrings) {
                        item.value = value.map((v) => {
                          return {
                            key: v,
                            val: v,
                          };
                        });
                      } else {
                        item.value = value;
                      }
                    } else {
                      item.value = value;
                    }
                  } else if (type === DEFAULT_VALUES_TYPES.GIS) {
                    item.value = getGisValue(value, featureAttributes);
                  } else if (type === DEFAULT_VALUES_TYPES.GIS_DATA) {
                    item.value = getGisDataValue(value, gisDataValues);
                  } else if (type === DEFAULT_VALUES_TYPES.CURRENT) {
                    item.value = getCurrentValue(item, value);
                  } else if (type === DEFAULT_VALUES_TYPES.URL) {
                    item.value = await getSharedSiteUrlValue(
                      value,
                      formResultId,
                      item
                    );
                  }
                }
              } else {
                if (
                  !getShouldApplyDefaultValue({
                    item,
                    featureAttributes,
                    gisDataValues,
                  })
                ) {
                  continue;
                }

                if (!Array.isArray(item.value) || item.value.length === 0) {
                  const { groupedItems, default: defaultSetting } =
                    item.question;
                  const { type, value } = defaultSetting;
                  if (type === DEFAULT_VALUES_TYPES.CUSTOM) {
                    item.value = cloneDeep(value).filter((r) => {
                      const [rowItem] = r;
                      if (rowItem?.question) {
                        return checkCreateValue({
                          rowItem,
                          userDataValues: [],
                          featureAttributes,
                          gisDataValues,
                        });
                      } else if (rowItem?.information) {
                        return checkCreateInfoValue({
                          rowItem,
                          userDataValues: [],
                          featureAttributes,
                          gisDataValues,
                        });
                      }
                    });
                  } else {
                    try {
                      item.value = JSON.parse(
                        getGisDataValue(value, gisDataValues)
                      );
                    } catch (error) {
                      console.log(error);
                      continue;
                    }

                    if (!Array.isArray(item.value)) {
                      continue;
                    }

                    for (const row of item.value) {
                      for (const rowItem of row) {
                        const groupedItem = groupedItems.find(
                          (gIt) => gIt.id === rowItem.id
                        );
                        const defaultSettings =
                          groupedItem?.question?.default ?? {};
                        if (
                          Object.keys(defaultSettings).includes(
                            "applyDefaultValue"
                          ) &&
                          !defaultSettings.applyDefaultValue
                        ) {
                          rowItem.value = undefined;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        for (const section of computedFormDefinition?.form?.sections ?? []) {
          for (const item of section.items) {
            if (this.isQuestion(item)) {
              if (item?.question?.type === "FILE") {
                if (
                  typeof item.value === "object" &&
                  item.value !== null &&
                  !Array.isArray(item.value)
                ) {
                  const { value, children = [] } = item;
                  item.value = [value, ...children.map((c) => c.value)];
                }
              } else if (item?.question?.type === "GROUP") {
                item.value = this.transformRepeatingGroup(item);
              } else if (item?.question?.type === "DATE") {
                if (
                  typeof item?.value !== "undefined" &&
                  moment(item?.value).isValid()
                ) {
                  const date = moment(item?.value);
                  item.value = date.format("YYYY-MM-DD");
                }
              }
            }
          }
        }
        this.computedFormDefinition = { ...computedFormDefinition };
      } catch (error) {
        console.log(error);
      } finally {
        this.prepopulatedValues = true;
      }
    },
    async getFeature() {
      const { selectedMapServiceId, objectId } = this;

      const {
        data: { results: selectedMapService },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/map_services/${selectedMapServiceId}/${this.$route.query.siteId}`
      );
      this.selectedMapService = selectedMapService;
      if (selectedMapService?.service_type === "F") {
        if (navigator.onLine) {
          let config = {};
          try {
            let params;
            if (selectedMapService?.token_type === "NONE") {
              params = {
                objectids: objectId,
                outFields: "*",
                f: "json",
              };
            } else {
              params = {
                objectids: objectId,
                outFields: "*",
                f: "json",
                token: localStorage.getItem("esri_token"),
              };
            }
            const { data: queryResult, config: requestConfig } =
              await axiosWithNoAuth.get(
                `${selectedMapService.service_url}/query`,
                {
                  params,
                }
              );
            this.gisInfoQueryResult = queryResult;
            config = requestConfig;
            const { gisInfoQueryResult } = this;
            const [feature] = gisInfoQueryResult?.features ?? [];
            this.featureAttributes = {
              ...feature?.attributes,
            };
          } catch (error) {
            this.showFeatureServiceUnreachableDialog = true;
          } finally {
            const { params, url } = config;
            const searchParams = new URLSearchParams(params);
            const fullRequestUrl = new URL(url);
            for (const [key, val] of searchParams) {
              fullRequestUrl.searchParams.append(key, val);
            }

            this.fullRequestUrl = fullRequestUrl.toString();
          }
        }
      } else if (selectedMapService?.service_type === "U") {
        const { objectId, selectedMapServiceId } = this;

        const {
          data: { results: gisDataPoint },
        } = await axiosWithJwtAuth.get(`${APIURL}/shared_site/gis_data_point`, {
          params: {
            map_service_id: selectedMapServiceId,
            feature_id: objectId,
          },
        });
        this.featureAttributes = {
          OBJECTID: objectId,
          gisDataPointId: gisDataPoint?.gis_data_point_id,
        };
      }
    },
    ...mapMutations(["setNumUnsubmittedTickets", "setFormSubmitted"]),
  },
  async mounted() {
    try {
      this.lastSavedTimes =
        JSON.parse(localStorage.getItem("last-saved-times")) || {};
    } catch (error) {
      this.lastSavedTimes = {};
    }
    this.showForm = false;
    if (this.isOnline) {
      await this.getFeature();
    } else {
      const { selectedMapServiceId } = this;
      const {
        data: { results: selectedMapService },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/map_services/${selectedMapServiceId}/${this.$route.query.siteId}`
      );
      if (selectedMapService?.service_type !== "U") {
        this.showFormOfflineDialog = true;
        return;
      }
    }
    await this.$nextTick();
    await this.transformFormDefinition(this.formDefinition);
    await this.prePopulateValues(this.computedFormDefinition);
    this.showForm = true;
    this.openSections = [
      ...(this.computedFormDefinition?.form?.sections?.map((_, i) => i) ?? []),
    ];
    await this.$nextTick();
    this.showForm = false;
    this.openSections = [
      ...(this.computedFormDefinition?.form?.sections
        ?.filter((s) => s.isOpenByDefault)
        ?.map((_, i) => i) ?? []),
    ];
    await this.$nextTick();
    this.showForm = true;
    this.savedComputedFormDefinition = JSON.stringify(
      this.computedFormDefinition
    );
  },
  watch: {
    existingFormResultIdMap: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.prepopulatedValues = true;
          this.formResultIdMap = val;
        }
      },
    },
  },
};
</script>

<style scoped>
.v-expansion-panel::before {
  box-shadow: none;
}

.v-expansion-panel--active:not(:first-child)::after {
  opacity: 1;
}
</style>

<style>
#dynamic-form-card-text {
  height: 60vh;
  overflow-y: auto;
}

#top-bar {
  position: sticky;
  top: 0;
  z-index: 999;
}

#bottom-bar {
  position: sticky;
  top: 0;
  z-index: 999;
}
</style>
