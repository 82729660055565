import { render, staticRenderFns } from "./SharedSiteEditTextForm.vue?vue&type=template&id=fd2b70d8&"
import script from "./SharedSiteEditTextForm.vue?vue&type=script&lang=js&"
export * from "./SharedSiteEditTextForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCol,VRow,VTextarea})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fd2b70d8')) {
      api.createRecord('fd2b70d8', component.options)
    } else {
      api.reload('fd2b70d8', component.options)
    }
    module.hot.accept("./SharedSiteEditTextForm.vue?vue&type=template&id=fd2b70d8&", function () {
      api.rerender('fd2b70d8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/shared-site/shared-site-ticket-form-def-drop-down/shared-site-ticket-edit-form/shared-site-dynamic-form/shared-site-photo-input/shared-site-markup-image-dialog/SharedSiteEditTextForm.vue"
export default component.exports