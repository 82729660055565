<template>
  <v-dialog :value="showSharedSiteAddImageDialog" max-width="500px" persistent>
    <v-card class="d-flex flex-column" max-height="80vh">
      <v-toolbar dark color="#3F51B5" class="elevation-0 flex-grow-0">
        <v-toolbar-title>Add Image</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('add-photo-dialog-close')">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="py-3 flex-grow-1 overflow-y-auto">
        <div>Add image by selecting a source</div>

        <input
          type="file"
          accept="image/*;capture=camera"
          style="visibility: hidden"
          ref="cameraInput"
          @change="onFileInputChange"
          :multiple="allowMultiple"
        />

        <div class="d-flex justify-center my-3">
          <v-btn text color="#3F51B5" @click="$refs.cameraInput.click()">
            Camera
          </v-btn>
          <v-btn
            text
            color="#3F51B5"
            @click="showSharedSiteSelectDocDialog = true"
          >
            Docs
          </v-btn>
          <v-btn text color="#3F51B5" @click="onMapClick"> Map </v-btn>
        </div>

        <file-pond
          name="files"
          ref="files"
          label-idle="Drag & Drop or <span class='filepond--label-action'> Browse </span>"
          :allow-multiple="allowMultiple"
          accepted-file-types="image/jpeg, image/png"
          :files="uploadFiles"
          @addfile="onChange"
          allowFileSizeValidation
          maxFileSize="2MB"
          :disabled="readOnly"
          class="mb-6"
        />

        <v-card
          v-for="(image, index) of base64Images"
          :key="image.id"
          class="d-flex flex-column justify-center my-2"
        >
          <v-card-text class="py-0 my-0">
            <div class="d-flex justify-center mt-4">
              <img
                :src="image.dataUrl"
                @click="markUpImage(image)"
                class="cursor-pointer"
                style="max-height: 340px; max-width: 100%"
              />
            </div>

            <div class="d-flex justify-space-between align-center">
              <v-text-field
                :key="image.id"
                label="Description"
                color="utilisync"
                :name="image.id"
                v-model="image.description"
                @input="$emit('input', base64Images)"
              >
              </v-text-field>

              <SharedSiteMarkupImageDialog
                v-if="showSharedSiteMarkupImageDialog[image.id]"
                @markup-image-dialog-close="
                  showSharedSiteMarkupImageDialog = false
                "
                @file-markup-preview-saved="onFileMarkupPreviewSaved"
                :showSharedSiteMarkupImageDialog="
                  showSharedSiteMarkupImageDialog[image.id]
                "
                :selectedFile="selectedFile"
              />

              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>{{ mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click.stop="markUpImage(image)">
                    <v-list-item-title>
                      <v-icon>{{ mdiPencil }}</v-icon>
                      Markup
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click.stop="removePhoto(index, image.id)">
                    <v-list-item-title>
                      <v-icon>{{ mdiDelete }}</v-icon>
                      Delete
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-card-text>
        </v-card>

        <SharedSiteSelectDocDialog
          v-if="showSharedSiteSelectDocDialog"
          :showSharedSiteSelectDocDialog="showSharedSiteSelectDocDialog"
          :formDefinition="formDefinition"
          :maxWidthHeight="+maxWidthHeight"
          :formResultId="formResultId"
          :globalId="globalId"
          :objectId="objectId"
          @select-doc-dialog-close="showSharedSiteSelectDocDialog = false"
          @image-file-selected="onImageFileSelected"
        />

        <SharedSiteSelectMapScreenshotDialog
          v-if="showSharedSiteSelectMapScreenshotDialog"
          :showSharedSiteSelectMapScreenshotDialog="
            showSharedSiteSelectMapScreenshotDialog
          "
          @select-map-screenshot-dialog-close="
            showSharedSiteSelectMapScreenshotDialog = false
          "
          @screenshot-taken="saveScreenshot"
        />

        <OfflineDialog
          v-if="showOfflineDialog"
          :showOfflineDialog="showOfflineDialog"
          @offline-dialog-close="showOfflineDialog = false"
        />
      </v-card-text>

      <v-card-actions class="d-flex justify-end px-5 py-3">
        <v-btn
          color="#3F51B5"
          dark
          class="font-weight-regular"
          @click="$emit('add-photo-dialog-close')"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import vueFilePond, { setOptions } from "vue-filepond";
import "filepond/dist/filepond.min.css";
import { mdiDotsVertical, mdiDelete, mdiPencil, mdiClose } from "@mdi/js";
import SharedSiteSelectMapScreenshotDialog from "@/components/shared-site/shared-site-ticket-form-def-drop-down/shared-site-ticket-edit-form/shared-site-dynamic-form/shared-site-photo-input/SharedSiteSelectMapScreenshotDialog";
import { axiosWithJwtAuth } from "@/plugins/axios";
import SharedSiteMarkupImageDialog from "@/components/shared-site/shared-site-ticket-form-def-drop-down/shared-site-ticket-edit-form/shared-site-dynamic-form/shared-site-photo-input/SharedSiteMarkupImageDialog";
import OfflineDialog from "@/components/tickets/shared/photo-input/OfflineDialog";
import SharedSiteSelectDocDialog from "@/components/shared-site/shared-site-ticket-form-def-drop-down/shared-site-ticket-edit-form/shared-site-dynamic-form/shared-site-photo-input/SharedSiteSelectDocDialog";
import { v4 as uuidv4 } from "uuid";
import imageCompression from "browser-image-compression";

const Compress = require("compress.js");
const compress = new Compress();
const FilePond = vueFilePond();
const APIURL = process.env.VUE_APP_API_URL;

setOptions({
  imageResizeMode: "contain",
  allowImageTransform: true,
});

export default {
  name: "SharedSiteAddImageDialog",
  props: {
    showSharedSiteAddImageDialog: Boolean,
    maxWidthHeight: Number,
    readOnly: Boolean,
    allowMultiple: Boolean,
    value: Array,
    formResultId: String,
    formDefinition: Object,
    selectedMapServiceId: String,
    item: Object,
    objectId: Number,
    globalId: String,
  },
  components: {
    FilePond,
    SharedSiteSelectMapScreenshotDialog,
    SharedSiteMarkupImageDialog,
    OfflineDialog,
    SharedSiteSelectDocDialog,
  },
  data() {
    return {
      mdiClose,
      uploadFiles: undefined,
      base64Images: [],
      showPhotoEditor: {},
      resizedImageWidth: 0,
      resizedImageHeight: 0,
      mdiDotsVertical,
      mdiDelete,
      mdiPencil,
      selectedFile: {},
      showSharedSiteSelectMapScreenshotDialog: false,
      showSharedSiteMarkupImageDialog: {},
      showOfflineDialog: true,
      showSharedSiteSelectDocDialog: false,
    };
  },
  methods: {
    async onFileMarkupPreviewSaved(newFileResult) {
      const { file_id: fileId, url } = newFileResult;
      const index = this.base64Images.findIndex((im) => im.id === fileId);
      this.$set(this.base64Images, index, {
        ...this.base64Images[index],
        url,
        dataUrl: url,
      });
      this.$emit("file-markup-preview-saved", newFileResult);
    },
    async markUpImage(image) {
      if (!navigator.onLine) {
        this.showOfflineDialog = true;
        return;
      }
      const { id, fileName, description } = image;
      const {
        data: { results },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/files/${id}/metadata`
      );
      this.selectedFile = {
        file_id: id,
        s3_file_path_original_image: results?.s3_file_path_original_image,
        name: fileName ?? description,
      };
      this.showSharedSiteMarkupImageDialog = {
        ...this.showSharedSiteMarkupImageDialog,
        [id]: true,
      };
    },
    onMapClick() {
      this.showSharedSiteSelectMapScreenshotDialog = true;
    },
    onImageFileSelected(imageFile) {
      this.base64Images = [...this.base64Images, imageFile];
      this.$emit("input", this.base64Images);
      this.showSharedSiteSelectDocDialog = false;
    },
    async createPayload(result, fileName, fileType) {
      const [{ data, prefix }] = result;
      const dataUrl = `${prefix}${data}`;
      const res = await fetch(dataUrl);
      const buf = await res.arrayBuffer();
      const resizedImageFile = new File([buf], fileName, {
        type: fileType,
      });
      const formData = new FormData();
      formData.append("file", resizedImageFile);
      formData.append("file_name", fileName);
      formData.append("is_image", true);
      formData.append("site_id", this.$route.query.siteId);
      return { formData, dataUrl };
    },
    async saveScreenshot({ dataUrl: imgDataUrl }) {
      try {
        const imgRes = await fetch(imgDataUrl);
        const blob = await imgRes.blob();
        const file = new File([blob], "screenshot.png", {
          type: "image/png",
        });
        const { name: fileName, type: fileType } = file;
        const result = await compress.compress([file], {
          maxWidth: this.maxWidthHeight,
          maxHeight: this.maxWidthHeight,
          resize: true,
        });
        const { formData, dataUrl } = await this.createPayload(
          result,
          fileName,
          fileType
        );
        if (this.formResultId) {
          const {
            data: { file },
          } = await axiosWithJwtAuth.post(
            `${APIURL}/shared_site/form_results/${this.formResultId}/files`,
            formData
          );
          const screenshotImg = {
            dataUrl,
            description: "",
            fileName,
            fileType,
            ...file,
            uploaded: true,
            isImage: true,
          };
          this.base64Images = [...this.base64Images, screenshotImg];
          this.$emit("input", this.base64Images);
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.showSharedSiteSelectMapScreenshotDialog = false;
      }
    },
    async onChange() {
      const files = this.$refs.files.getFiles();
      const base64ImagePromises = files.map(async (f) => {
        try {
          const { name: fileName, type: fileType } = f.file;
          const options = {
            exifOrientation: await imageCompression.getExifOrientation(f.file),
          };
          const compressedFile = await imageCompression(f.file, options);
          const result = await compress.compress([compressedFile], {
            maxWidth: this.maxWidthHeight,
            maxHeight: this.maxWidthHeight,
            resize: true,
          });
          const { formData, dataUrl } = await this.createPayload(
            result,
            fileName,
            fileType
          );
          if (this.formResultId) {
            const {
              data: { file },
            } = await axiosWithJwtAuth.post(
              `${APIURL}/shared_site/form_results/${this.formResultId}/files`,
              formData
            );
            return {
              dataUrl,
              description: "",
              fileName,
              fileType,
              ...file,
              uploaded: true,
              isImage: true,
            };
          } else {
            return {
              dataUrl,
              description: "",
              fileName,
              fileType,
              id: uuidv4(),
              name: fileName,
              url: dataUrl,
              uploaded: false,
              isImage: true,
            };
          }
        } catch (error) {
          console.log(error);
          return undefined;
        }
      });
      if (files.length > 0) {
        for (const f of files) {
          this.$refs.files.removeFile(f);
        }
        this.$emit("start-photo-upload");
        const base64Images = await Promise.all(base64ImagePromises);
        this.$emit("end-photo-upload");
        this.base64Images = [
          ...this.base64Images,
          ...(base64Images?.filter((b) => Boolean(b)) ?? []),
        ];
        this.$emit("input", this.base64Images);
      }
    },
    async removePhoto(index, imageId) {
      this.base64Images.splice(index, 1);
      if (navigator.onLine) {
        await axiosWithJwtAuth.delete(
          `${APIURL}/shared_site/files/${imageId}/${this.$route.query.siteId}`
        );
      }
      this.$emit("image-deleted", imageId);
    },
    async onFileInputChange(e) {
      for (const file of e.target.files) {
        this.selectedFile = file;
        try {
          const { name: fileName, type: fileType } = file;
          const result = await compress.compress([file], {
            maxWidth: this.maxWidthHeight,
            maxHeight: this.maxWidthHeight,
            resize: true,
          });
          const [{ data, prefix, endWidthInPx, endHeightInPx }] = result;
          const dataUrl = `${prefix}${data}`;
          this.resizedImageWidth = endWidthInPx;
          this.resizedImageHeight = endHeightInPx;
          const res = await fetch(dataUrl);
          const buf = await res.arrayBuffer();
          const resizedImageFile = new File([buf], fileName, {
            type: fileType,
          });
          const formData = new FormData();
          formData.append("file", resizedImageFile);
          formData.append("file_name", fileName);
          formData.append("is_image", true);
          formData.append("site_id", this.$route.query.siteId);
          if (this.formResultId) {
            if (navigator.onLine) {
              const {
                data: { file },
              } = await axiosWithJwtAuth.post(
                `${APIURL}/shared_site/form_results/${this.formResultId}/files`,
                formData
              );
              const imageFile = {
                dataUrl,
                description: "",
                fileName,
                fileType,
                ...file,
                uploaded: true,
                isImage: true,
              };
              this.base64Images = [...this.base64Images, imageFile];
              this.$emit("input", this.base64Images);
            }
          } else {
            const imageFile = {
              dataUrl,
              description: "",
              fileName,
              fileType,
              id: uuidv4(),
              name: fileName,
              url: dataUrl,
              uploaded: false,
              isImage: true,
            };
            this.base64Images = [...this.base64Images, imageFile];
            this.$emit("input", this.base64Images);
          }
        } catch (error) {
          console.error(error);
          continue;
        }
      }
    },
  },
};
</script>
