<template>
  <v-dialog :value="showSharedSiteAddFilesDialog" width="600px">
    <v-card>
      <v-toolbar dark class="elevation-0" width="100%" color="#3F51B5">
        <div>Add Files</div>
        <v-spacer />
        <v-btn icon @click="$emit('add-files-dialog-close')">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="pt-5">
        <p class="caption">Folder to Save Files To</p>

        <SharedSiteFolderPathDisplay
          @select-folder="selectedFolder = $event"
          :selectedMapServiceId="selectedMapServiceId"
          :globalId="globalId"
          :objectId="objectId"
          :selectedFolder="selectedFolder"
          class="py-2"
        />

        <p class="caption">Photos</p>

        <file-pond
          name="photos"
          ref="photos"
          label-idle="Tap or drop photos here..."
          allow-multiple
          :files="uploadFiles"
          @addfile="onPhotoChange"
          allowFileSizeValidation
          maxFileSize="2MB"
          accepted-file-types="image/jpeg, image/png"
        />

        <p class="caption">Files</p>

        <file-pond
          name="files"
          ref="files"
          label-idle="Tap or drop files here..."
          allow-multiple
          :files="uploadFiles"
          @addfile="onFileChange"
          allowFileSizeValidation
          maxFileSize="2MB"
        />

        <FileDescriptionDialog
          v-if="showFileDescriptionDialog"
          :showFileDescriptionDialog="showFileDescriptionDialog"
          :uploadedFiles="uploadedFiles"
          @file-description-dialog-close="showFileDescriptionDialog = false"
          @description-saved="
            showFileDescriptionDialog = false;
            fileDescriptions = $event;
            uploadFile();
          "
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
import { mdiClose, mdiHome } from "@mdi/js";
import SharedSiteFolderPathDisplay from "@/components/shared-site/shared-site-docs-tab/shared-site-add-files-dialog/SharedSiteFolderPathDisplay.vue";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FileDescriptionDialog from "@/components/mapView/docs-tab/add-files-dialog/FileDescriptionDialog.vue";
import { axiosWithJwtAuth } from "@/plugins/axios";

const APIURL = process.env.VUE_APP_API_URL;
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize
);

export default {
  name: "SharedSiteAddFilesDialog",
  props: {
    showSharedSiteAddFilesDialog: Boolean,
    selectedMapServiceId: String,
    objectId: Number,
    globalId: String,
    currentFolder: String,
  },
  components: {
    FilePond,
    SharedSiteFolderPathDisplay,
    FileDescriptionDialog,
  },
  beforeMount() {
    if (this.currentFolder) {
      this.selectedFolder = this.currentFolder;
    }
  },
  watch: {
    currentFolder(val) {
      if (val) {
        this.selectedFolder = val;
      }
    },
  },
  data() {
    return {
      mdiClose,
      mdiHome,
      selectedFolder: "",
      uploadFiles: undefined,
      showFileDescriptionDialog: false,
      uploadedFiles: [],
      fileDescriptions: [],
      isImage: false,
    };
  },
  methods: {
    async onPhotoChange() {
      const photos = this.$refs.photos.getFiles();
      this.showFileDescriptionDialog = true;
      this.uploadedFiles = [...photos];
      this.isImage = true;
    },
    async onFileChange() {
      const files = this.$refs.files.getFiles();
      this.showFileDescriptionDialog = true;
      this.uploadedFiles = [...files];
      this.isImage = false;
    },
    async uploadFile() {
      const {
        isImage,
        selectedFolder,
        selectedMapServiceId,
        globalId,
        fileDescriptions,
      } = this;
      await Promise.all(
        this.uploadedFiles.map((f) => {
          const formData = new FormData();
          const desc = fileDescriptions?.find(
            (d) => d.fileName === f.file.name
          )?.description;
          formData.append("file", f.file);
          formData.append("file_name", f.file.name);
          formData.append("folder", selectedFolder ?? "");
          formData.append("is_image", isImage);
          formData.append("comment", desc);
          formData.append("site_id", this.$route.query.siteId);
          return axiosWithJwtAuth.post(
            `${APIURL}/shared_site/map_services/${selectedMapServiceId}/features/${globalId}/files`,
            formData
          );
        })
      );
      this.$emit("add-files-dialog-uploaded");
    },
  },
};
</script>
