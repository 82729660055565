<template>
  <v-dialog :value="showActionItemDialog" max-width="500px">
    <v-card style="background-color: #fafafa">
      <v-toolbar dark class="elevation-0" width="100%" color="#3F51B5">
        <div>{{ getAction() }} Action Item</div>
        <v-spacer />
        <v-btn icon @click="$emit('action-item-dialog-close')">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text
        style="background-color: #fafafa; height: 60vh"
        class="overflow-y-auto"
      >
        <div
          class="d-flex justify-space-between align-center pt-3"
          style="width: 100%"
        >
          <div class="caption d-flex align-center gap my-0">
            Action Item
            <v-icon
              small
              class="cursor-pointer"
              color="#3F51B5"
              @click="showEditItemDialog = true"
              v-if="canEdit"
            >
              {{ mdiPencil }}
            </v-icon>
          </div>
          <div class="caption" v-if="actionItem.last_updated">
            Last Updated: {{ actionItem.last_updated | formatFullDate }}
          </div>
        </div>
        <v-card id="actionItemInfo">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <div>
                  <p class="caption my-0 py-0">Status</p>
                  <div class="body-1 black--text font-weight-normal">
                    {{ actionItem.status | actionItemStatus }}
                  </div>
                </div>
              </v-col>

              <v-col cols="12">
                <p class="caption my-0 py-0">Created</p>
                <div class="body-1 black--text font-weight-normal">
                  {{ actionItem.created_on | date }}
                </div>
              </v-col>

              <v-col cols="12" class="my-0 py-0">
                <p class="caption my-0 py-0">Due By</p>
                <div class="body-1 black--text font-weight-normal">
                  {{ actionItem.due_by | date }}
                </div>
              </v-col>

              <v-col cols="12" class="my-0 py-3">
                <p class="caption my-0 py-0">Assigned To</p>
                <div class="body-1 black--text font-weight-normal">
                  {{ actionItem.assignee_email || "Unassigned" }}
                </div>
              </v-col>

              <v-col cols="12" class="my-0 py-0">
                <p class="caption my-0 py-0">Description</p>
                <div class="body-1 black--text font-weight-normal">
                  {{ actionItem.action_item_description }}
                </div>
              </v-col>

              <v-col cols="12" class="my-0 pb-3 px-3">
                <p class="caption">Photos</p>
                <div
                  class="d-flex gap flex-wrap"
                  v-if="assignerPhotos.length > 0"
                >
                  <img
                    :class="{
                      'cursor-pointer':
                        actionItemDetails.status !==
                        ACTION_ITEM_STATUSES.READY_FOR_REVIEW,
                    }"
                    :style="{
                      'pointer-events':
                        actionItemDetails.status ===
                        ACTION_ITEM_STATUSES.READY_FOR_REVIEW
                          ? 'none'
                          : undefined,
                    }"
                    class="image"
                    @click="onClick(p.file_id)"
                    v-for="p of assignerPhotos"
                    :key="p.file_id"
                    :src="p.s3_file_path"
                  />
                </div>
                <div
                  class="d-flex justify-center body-1 font-weight-normal"
                  v-else
                >
                  No Photos Uploaded
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <p
          class="caption d-flex gap my-0 py-0 pt-3"
          v-if="selectedActionItem.status === ACTION_ITEM_STATUSES.REJECTED"
        >
          Reason Action Item Was Not Accepted
        </p>

        <v-alert
          v-if="selectedActionItem.status === ACTION_ITEM_STATUSES.REJECTED"
          color="#D10000"
          dark
        >
          {{ actionItem.rejection_text }}
        </v-alert>

        <template v-if="!hideResponseSection">
          <p class="caption d-flex gap my-0 py-0 pt-3">Response</p>

          <v-card
            id="actionItemResponse"
            v-if="
              selectedActionItem.status === ACTION_ITEM_STATUSES.OPEN ||
              selectedActionItem.status === ACTION_ITEM_STATUSES.REJECTED
            "
          >
            <v-card-text>
              <form>
                <validation-observer ref="responseForm">
                  <v-row>
                    <v-col cols="12">
                      <validation-provider
                        v-slot="{ errors, valid }"
                        name="Response"
                      >
                        <v-text-field
                          v-model="response.response"
                          :error-messages="errors"
                          :success="valid"
                          color="#3F51B5"
                          label="Response"
                          ref="responseField"
                        />
                      </validation-provider>
                    </v-col>

                    <v-col cols="12">
                      <p class="caption">Photos</p>
                      <file-pond
                        name="files"
                        ref="files"
                        label-idle="Tap or drop photos here..."
                        allow-multiple
                        accepted-file-types="image/jpeg, image/png"
                        :files="uploadFiles"
                        allowFileSizeValidation
                        maxFileSize="2MB"
                        @addfile="onAssigneePhotoInputChange"
                      />

                      <div v-if="assigneePhotos.length > 0">
                        <v-card
                          v-for="(p, index) of assigneePhotos"
                          :key="p.file_id"
                        >
                          <v-card-text
                            class="justify-space-between align-center d-flex"
                          >
                            <img
                              class="cursor-pointer image"
                              @click="markup(p.file_id)"
                              :src="p.s3_file_path"
                            />

                            <v-menu>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                  <v-icon>{{ mdiDotsVertical }}</v-icon>
                                </v-btn>
                              </template>

                              <v-list>
                                <v-list-item @click.stop="markup(p.file_id)">
                                  <v-list-item-title>
                                    <v-icon>{{ mdiPencil }}</v-icon>
                                    Markup
                                  </v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                  @click.stop="removePhoto(index, p.file_id)"
                                >
                                  <v-list-item-title>
                                    <v-icon>{{ mdiDelete }}</v-icon>
                                    Delete
                                  </v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-card-text>
                        </v-card>
                      </div>
                    </v-col>
                  </v-row>
                </validation-observer>
              </form>
            </v-card-text>
          </v-card>

          <v-card v-else>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <p class="caption py-0 my-0">Responded</p>
                  <div class="body-1 black--text font-weight-normal">
                    {{ actionItemDetails.last_response | formatDateTime }}
                  </div>
                </v-col>

                <v-col cols="12">
                  <p class="caption py-0 my-0">Response From</p>
                  <div class="body-1 black--text font-weight-normal">
                    {{ actionItemDetails.last_response_user_name }},
                    {{ actionItemDetails.last_response_user_group_name }}
                  </div>
                </v-col>

                <v-col cols="12">
                  <p class="caption my-0 py-0">Response</p>
                  <div class="body-1 black--text font-weight-normal">
                    {{ actionItemDetails.response }}
                  </div>
                </v-col>

                <v-col cols="12">
                  <p class="caption my-0 py-0">Photos</p>
                  <div v-if="assigneePhotos.length > 0">
                    <section
                      v-if="
                        actionItemDetails.status ===
                        ACTION_ITEM_STATUSES.READY_FOR_REVIEW
                      "
                      class="d-flex flex-wrap gap"
                    >
                      <img
                        v-for="p of assigneePhotos"
                        :key="p.file_id"
                        class="image"
                        :src="p.s3_file_path"
                      />
                    </section>
                    <v-card
                      v-for="(p, index) of assigneePhotos"
                      :key="p.file_id"
                    >
                      <v-card-text
                        class="justify-space-between align-center d-flex"
                      >
                        <img
                          class="cursor-pointer image"
                          @click="markup(p.file_id)"
                          :src="p.s3_file_path"
                        />

                        <v-menu>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon>{{ mdiDotsVertical }}</v-icon>
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item @click.stop="markup(p.file_id)">
                              <v-list-item-title>
                                <v-icon>{{ mdiPencil }}</v-icon>
                                Markup
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                              @click.stop="removePhoto(index, p.file_id)"
                            >
                              <v-list-item-title>
                                <v-icon>{{ mdiDelete }}</v-icon>
                                Delete
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-card-text>
                    </v-card>
                  </div>

                  <div
                    class="d-flex justify-center body-1 font-weight-normal"
                    v-else
                  >
                    No Photos Uploaded
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>

        <SharedSiteEditActionItemDialog
          v-if="showEditItemDialog"
          :showEditItemDialog="showEditItemDialog"
          :selectedActionItem="selectedActionItem"
          @edit-action-item-dialog-close="showEditItemDialog = false"
          @action-item-saved="
            $emit('action-item-saved');
            showEditItemDialog = false;
          "
        />

        <SharedSiteActionItemNotAcceptedDialog
          :showActionItemNotAcceptedDialog="showActionItemNotAcceptedDialog"
          :selectedActionItem="selectedActionItem"
          :selectedActionItemId="selectedActionItemId"
          @reject-action-item="
            showActionItemNotAcceptedDialog = false;
            $emit('reject-action-item');
          "
        />

        <SharedSiteMarkupImageDialog
          v-if="showSharedSiteMarkupImageDialog[selectedFile.file_id]"
          @markup-image-dialog-close="
            showSharedSiteMarkupImageDialog = {};
            onFileMarkupPreviewSaved();
          "
          :showSharedSiteMarkupImageDialog="
            showSharedSiteMarkupImageDialog[selectedFile.file_id]
          "
          :selectedFile="selectedFile"
        />

        <SharedSiteMarkupViewerDialog
          v-if="showSharedSiteMarkupViewerDialog[selectedFile.file_id]"
          @markup-viewer-dialog-close="showSharedSiteMarkupViewerDialog = {}"
          :showMarkupViewerDialog="
            showSharedSiteMarkupViewerDialog[selectedFile.file_id]
          "
          :selectedFile="selectedFile"
        />
      </v-card-text>

      <v-card-actions
        class="d-flex justify-end p-3"
        v-if="
          selectedActionItem.status === ACTION_ITEM_STATUSES.OPEN ||
          selectedActionItem.status === ACTION_ITEM_STATUSES.REJECTED
        "
      >
        <v-btn
          id="submitResponse"
          :color="Boolean(response.response) ? '#00A9F4' : undefined"
          :dark="Boolean(response.response)"
          type="submit"
          @click="submitResponse()"
          :disabled="!response.response"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mdiClose,
  mdiCalendar,
  mdiPencil,
  mdiDotsVertical,
  mdiDelete,
} from "@mdi/js";
import ACTION_ITEM_STATUSES from "@/constants/actionItemStatuses";
import moment from "moment";
import { axiosWithJwtAuth } from "@/plugins/axios";
import SharedSiteEditActionItemDialog from "@/components/shared-site/shared-site-ticket-form-def-drop-down/shared-site-ticket-edit-form/shared-site-dynamic-form/shared-site-action-item/SharedSiteEditActionItemDialog";
import SharedSiteActionItemNotAcceptedDialog from "@/components/shared-site/shared-site-ticket-form-def-drop-down/shared-site-ticket-edit-form/shared-site-dynamic-form/shared-site-action-item/SharedSiteActionItemNotAcceptedDialog";
import vueFilePond, { setOptions } from "vue-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import SharedSiteMarkupImageDialog from "@/components/shared-site/shared-site-ticket-form-def-drop-down/shared-site-ticket-edit-form/shared-site-dynamic-form/shared-site-photo-input/SharedSiteMarkupImageDialog";
import SharedSiteMarkupViewerDialog from "@/components/shared-site/shared-site-ticket-form-def-drop-down/shared-site-ticket-edit-form/shared-site-dynamic-form/shared-site-photo-input/SharedSiteMarkupViewerDialog";
import actionItemMixin from "@/mixins/actionItemMixin";

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize
);

setOptions({
  imageResizeMode: "contain",
  allowImageTransform: true,
});

const APIURL = process.env.VUE_APP_API_URL;

const STATUS_CHOICES = [
  { name: "Open", value: ACTION_ITEM_STATUSES.OPEN },
  { name: "Ready for Review", value: ACTION_ITEM_STATUSES.READY_FOR_REVIEW },
  { name: "Rejected", value: ACTION_ITEM_STATUSES.REJECTED },
  { name: "Canceled", value: ACTION_ITEM_STATUSES.CANCELED },
  { name: "Closed", value: ACTION_ITEM_STATUSES.CLOSED },
];

export default {
  name: "SharedSiteActionItemTabActionItemDialog",
  mixins: [actionItemMixin],
  data() {
    return {
      uploadFiles: undefined,
      mdiClose,
      mdiCalendar,
      mdiPencil,
      mdiDotsVertical,
      mdiDelete,
      ACTION_ITEM_STATUSES,
      STATUS_CHOICES,
      actionItem: {},
      assignerPhotos: [],
      assigneePhotos: [],
      editing: false,
      showEditItemDialog: false,
      assignerUserGroupId: undefined,
      actionItemDetails: {},
      response: {},
      showActionItemNotAcceptedDialog: false,
      users: [],
      currentUser: {},
      showSharedSiteMarkupImageDialog: {},
      showSharedSiteMarkupViewerDialog: {},
      selectedFile: {},
      submittingFiles: false,
    };
  },
  components: {
    SharedSiteEditActionItemDialog,
    SharedSiteActionItemNotAcceptedDialog,
    FilePond,
    SharedSiteMarkupImageDialog,
    SharedSiteMarkupViewerDialog,
  },
  props: {
    showActionItemDialog: Boolean,
    selectedActionItem: Object,
    hideResponseSection: {
      type: Boolean,
      default: false,
    },
    action: String,
    selectedActionItemId: String,
  },
  computed: {
    canEdit() {
      if (
        this.selectedActionItem?.status === ACTION_ITEM_STATUSES.CLOSED ||
        this.selectedActionItem?.status === ACTION_ITEM_STATUSES.CANCELED
      ) {
        return false;
      }

      const {
        user_id: userId,
        user_group_id: userGroupId,
        is_gis_admin: isGisAdmin,
        is_sys_admin: isSysAdmin,
      } = this.currentUser;
      if (isGisAdmin || isSysAdmin) {
        const { assignerUserId, assigneeUserId } = this.selectedActionItem;
        const assignerOrAssigneeUserGroupIds = this.users
          .filter((u) => {
            return [assignerUserId, assigneeUserId].includes(u?.user_id);
          })
          .map((u) => u.user_group_id);
        return assignerOrAssigneeUserGroupIds.includes(userGroupId);
      }
      return this.selectedActionItem?.assigner_user_id === userId;
    },
  },
  methods: {
    async removePhoto(index, imageId) {
      this.assigneePhotos.splice(index, 1);
      if (navigator.onLine) {
        await axiosWithJwtAuth.delete(
          `${APIURL}/shared_site/files/${imageId}/${this.$route.query.siteId}`
        );
      }
    },
    async onAssigneePhotoInputChange() {
      if (this.submittingFiles) {
        return;
      }
      this.submittingFiles = true;
      await this.submitFiles();
      const files = this.$refs.files.getFiles();
      for (const f of files) {
        this.$refs.files.removeFile(f);
      }
      await this.getActionItemDetails();
      this.submittingFiles = false;
    },
    async onClick(fileId) {
      const {
        data: { results },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/files/${fileId}/metadata`
      );
      this.selectedFile = {
        file_id: fileId,
        s3_file_path_original_image: results?.s3_file_path_original_image,
      };

      const { user_id: userId } = this.currentUser;
      console.log(results.created_by, userId);
      if (results.created_by === userId) {
        this.showSharedSiteMarkupImageDialog = {
          ...this.showSharedSiteMarkupImageDialog,
          [fileId]: true,
        };
      } else {
        this.showSharedSiteMarkupViewerDialog = {
          ...this.showSharedSiteMarkupViewerDialog,
          [fileId]: true,
        };
      }
    },
    async markup(fileId) {
      const {
        data: { results },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/files/${fileId}/metadata`
      );
      this.selectedFile = {
        file_id: fileId,
        s3_file_path_original_image: results?.s3_file_path_original_image,
      };
      this.showSharedSiteMarkupImageDialog = {
        ...this.showSharedSiteMarkupImageDialog,
        [fileId]: true,
      };
    },
    onFileMarkupPreviewSaved() {
      this.getActionItemDetails();
    },
    async getUserData() {
      if (this.$route.query.siteId) {
        const {
          data: { results },
        } = await axiosWithJwtAuth.get(
          `${APIURL}/shared_site/users/one/${this.$route.query.siteId}`
        );
        this.currentUser = results;
      }
    },
    getAction() {
      if (this.action) {
        return this.action;
      }
      return this.canEdit ? "Edit" : "View";
    },
    async getUsers() {
      const {
        data: { results },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/users/${this.$route.query.siteId}`
      );
      this.users = results;
    },
    async acceptAndClose() {
      const payload = {
        accept_and_close: true,
      };
      await axiosWithJwtAuth.put(
        `${APIURL}/shared_site/action_item/${this.selectedActionItemId}/${this.$route.query.siteId}`,
        payload
      );
      this.$emit("accept-action-item");
    },
    async submitResponse() {
      await this.submitResponseText();
      await this.submitFiles();
      this.$emit("action-item-response-saved");
    },
    async submitResponseText() {
      const success = await this.$refs.responseForm.validate();
      if (!success) {
        return;
      }
      const { response } = this.response;
      const payload = {
        response,
      };
      await axiosWithJwtAuth.put(
        `${APIURL}/shared_site/action_item/${this.selectedActionItemId}/${this.$route.query.siteId}`,
        payload
      );
    },
    async submitFiles() {
      const files = this.$refs.files.getFiles();
      const fileUploadPromises = files.map(async (file) => {
        if (!file?.file) {
          return;
        }
        const formData = new FormData();
        formData.append("file", file?.file);
        await axiosWithJwtAuth.post(
          `${APIURL}/shared_site/action_item/${
            this.selectedActionItemId
          }/is_assigner_file/${false}/files/${this.$route.query.siteId}`,
          formData
        );
      });
      await Promise.all(fileUploadPromises);
    },
    loadActionItem(selectedActionItem) {
      if (!selectedActionItem) {
        return;
      }
      this.actionItem = {
        ...selectedActionItem,
        due_by: moment(selectedActionItem.due_by).utc().format("YYYY-MM-DD"),
      };
    },
    async getActionItemDetails() {
      if (!this.selectedActionItemId) {
        return;
      }
      const {
        data: { results },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/action_item/${this.selectedActionItemId}/${this.$route.query.siteId}`
      );
      const { assigner_photos: aPhotos, assignee_photos: aePhotos } = results;
      const assignerPhotoIds = aPhotos.map((p) => p.file_id);
      const assigneePhotoIds = aePhotos.map((p) => p.file_id);
      const assignerPhotos = await Promise.all(
        assignerPhotoIds.map(async (fileId) => {
          const {
            data: { results },
          } = await axiosWithJwtAuth.get(
            `${APIURL}/shared_site/files/${fileId}/metadata`
          );
          return results;
        })
      );

      const assigneePhotos = await Promise.all(
        assigneePhotoIds.map(async (fileId) => {
          const {
            data: { results },
          } = await axiosWithJwtAuth.get(
            `${APIURL}/shared_site/files/${fileId}/metadata`
          );
          return results;
        })
      );
      this.assignerPhotos = assignerPhotos;
      this.assigneePhotos = assigneePhotos;
      this.actionItemDetails = {
        ...results,
      };
    },
  },
  async beforeMount() {
    await this.loadActionItem(this.selectedActionItem);
    await this.getActionItemDetails();
    await this.getUsers();
    await this.getUserData();
  },
  async mounted() {
    setTimeout(() => {
      this.$refs.responseField?.$refs?.input?.focus();
    }, 0);
  },
  watch: {
    selectedActionItem: {
      deep: true,
      async handler(val) {
        await this.loadActionItem(val);
        await this.getActionItemDetails();
      },
    },
  },
};
</script>

<style scoped>
.half {
  width: 50%;
}

.image {
  max-width: 128px;
}
</style>
