<template>
  <v-dialog :value="showUtilibotDialog" max-width="600px" persistent>
    <v-toolbar color="#3F51B5" dark>
      <v-toolbar-title style="width: 100%">
        <div class="d-flex justify-space-between">
          <div class="mt-2">Form Utilibots</div>
          <div>
            <v-btn icon @click="$emit('utilibot-dialog-close')">
              <v-icon>
                {{ mdiClose }}
              </v-icon>
            </v-btn>
          </div>
        </div>
      </v-toolbar-title>
    </v-toolbar>
    <v-card color="#fafafa">
      <v-card-text class="pa-5">
        <div v-if="formResultTasks.length === 0">
          No UtilIBots configured for this form.
        </div>
        <v-expansion-panels
          v-else
          multiple
          accordion
          v-model="openPanels"
          class="elevation-0 ma-0 pa-0"
        >
          <v-expansion-panel v-for="t of formResultTasks" :key="t.id">
            <v-expansion-panel-header>
              <div class="d-flex justify-space-between">
                <div class="d-flex align-center">
                  <b>{{ t.type_display_name }}</b>
                </div>

                <div class="d-flex align-center">
                  <v-btn
                    v-if="t.status_name !== 'completed_success'"
                    text
                    color="red"
                    @click.stop="reprocessFailedMessages(t.form_result_task_id)"
                  >
                    Retry
                  </v-btn>
                  <v-icon
                    v-if="processing[t.form_result_task_id]"
                    color="#00A3FF"
                    class="mr-4"
                  >
                    {{ mdiAutorenew }}
                  </v-icon>
                  <template v-else>
                    <img
                      v-if="iconUrlMap[t.status_name]"
                      :src="require(`@/assets/${iconUrlMap[t.status_name]}`)"
                      style="width: 20px; height: 20px"
                      class="mr-4"
                    />
                  </template>
                </div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{ t.status_message || "No additional information available" }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <FormIdDialog
          :showFormIdDialog="showFormIdDialog"
          :formResultId="formResultId"
          @form-id-dialog-close="showFormIdDialog = false"
        />
      </v-card-text>
      <v-card-actions class="d-flex justify-end mr-1">
        <v-btn icon @click="showFormIdDialog = true">
          <v-icon color="#3F51B5">
            {{ mdiInformation }}
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose, mdiInformation, mdiAutorenew } from "@mdi/js";
import FormIdDialog from "@/components/tickets/ticket-edit-form/dynamic-form/dynamic-form-edit-actions/FormIdDialog";
import { axiosWithJwtAuth } from "@/plugins/axios";
import sleep from "@/mixins/sleep";
import sharedSiteEsriTablesUtilibotsMixin from "@/mixins/sharedSiteEsriTablesUtilibotsMixin";

const APIURL = process.env.VUE_APP_API_URL;
const iconUrlMap = {
  completed_success: "check_green.svg",
  dead_letter_queue: "failed_red.svg",
  completed_with_errors: "completed_with_errors_yellow.svg",
  completed_error: "completed_with_errors_yellow.svg",
  error: "failed_red.svg",
};

export default {
  name: "SharedSiteUtilibotDialog",
  props: {
    showUtilibotDialog: Boolean,
    formResultId: String,
  },
  components: {
    FormIdDialog,
  },
  data() {
    return {
      mdiClose,
      formResultTasks: {},
      openPanels: [],
      iconUrlMap,
      showFormIdDialog: false,
      mdiInformation,
      mdiAutorenew,
      processing: {},
    };
  },
  mixins: [sharedSiteEsriTablesUtilibotsMixin],
  methods: {
    async reprocessFailedMessages(formResultTaskId) {
      const formResultTask = this.formResultTasks.find(
        (ft) => ft.form_result_task_id === formResultTaskId
      );
      if (formResultTask?.type_name === "update_esri_main_tables") {
        await this.updateFeatureService();
      } else if (formResultTask?.type_name === "update_related_tables") {
        await this.addToRelatedTableFromFormResult();
      } else {
        await axiosWithJwtAuth.get(
          `${APIURL}/shared_site/reprocess_failed_messages/${formResultTaskId}`
        );
        this.$set(this.processing, formResultTaskId, true);
        await sleep(2000);
        this.$set(this.processing, formResultTaskId, false);
      }
      await this.getFormResultTasks();
    },
    async getFormResultTasks() {
      if (!this.formResultId) {
        return;
      }
      const {
        data: { results },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/form_result_tasks/${this.formResultId}/${this.$route.query.siteId}`
      );
      this.formResultTasks = results;
    },
  },
  beforeMount() {
    this.getFormResultTasks();
  },
  watch: {
    formResultId: {
      immediate: true,
      handler() {
        this.getFormResultTasks();
      },
    },
  },
};
</script>
