var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.formSelected
    ? _c("SharedSiteDynamicForm", {
        attrs: {
          formDefinition: _vm.formDefinition,
          selectedMapServiceId: _vm.selectedMapServiceId,
          globalId: _vm.globalId,
          objectId: _vm.objectId,
          taskId: _vm.taskId,
          canEdit: ""
        },
        on: {
          "ticket-edit-form-close-button-click": function($event) {
            return _vm.$emit("ticket-edit-form-close-button-click", $event)
          },
          "ticket-edit-form-close": function($event) {
            return _vm.$emit("ticket-edit-form-close")
          },
          "ticket-edit-form-submitted": function($event) {
            return _vm.$emit("ticket-edit-form-submitted")
          },
          "ticket-edit-form-error": function($event) {
            return _vm.$emit("ticket-edit-form-error", $event)
          },
          "form-result-deleted": function($event) {
            return _vm.$emit("form-result-deleted")
          },
          input: function($event) {
            _vm.formResult = $event
          }
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }