var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.showEditItemDialog,
        "max-width": "500px",
        persistent: ""
      }
    },
    [
      _c(
        "form",
        {
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.saveActionItem.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "validation-observer",
            { ref: "actionItemForm" },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    {
                      staticClass: "elevation-0",
                      attrs: { dark: "", width: "100%", color: "#3F51B5" }
                    },
                    [
                      _c("div", [_vm._v("Edit Action Item")]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("edit-action-item-dialog-close")
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-text",
                    {
                      staticClass: "overflow-y-auto",
                      staticStyle: {
                        "background-color": "#fafafa",
                        "max-height": "60vh"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-center justify-space-between pb-3"
                        },
                        [
                          _c("div", { staticClass: "caption" }, [
                            _vm._v("Action Item")
                          ]),
                          _vm.actionItem.status !==
                          _vm.ACTION_ITEM_STATUSES.CANCELED
                            ? _c(
                                "v-menu",
                                {
                                  attrs: { "offset-y": "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    staticClass: "px-0 mx-0",
                                                    attrs: { icon: "" }
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v(
                                                    _vm._s(_vm.mdiDotsVertical)
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    2473825798
                                  )
                                },
                                [
                                  _c(
                                    "v-list",
                                    { staticClass: "px-0 mx-0" },
                                    [
                                      _c(
                                        "v-list-item",
                                        { staticClass: "px-0 mx-0" },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: { text: "" },
                                              on: {
                                                click: function($event) {
                                                  _vm.showConfirmActionItemCancelDialog = true
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-weight-regular"
                                                },
                                                [_vm._v(" Cancel Action Item ")]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("ConfirmActionItemCancelDialog", {
                        attrs: {
                          showConfirmActionItemCancelDialog:
                            _vm.showConfirmActionItemCancelDialog
                        },
                        on: {
                          "cancel-confirm-cancel": function($event) {
                            _vm.showConfirmActionItemCancelDialog = false
                          },
                          "confirm-cancel": function($event) {
                            _vm.actionItem.status = "canceled"
                            _vm.showConfirmActionItemCancelDialog = false
                            _vm.saveActionItem()
                          }
                        }
                      }),
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-col", { attrs: { cols: "12" } }, [
                                    _c("div", { staticClass: "half" }, [
                                      _c(
                                        "p",
                                        { staticClass: "caption py-0 my-0" },
                                        [_vm._v("Status")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "body-1 black--text font-weight-normal"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("actionItemStatus")(
                                                  _vm.actionItem.status
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ])
                                  ]),
                                  _c("v-col", { attrs: { cols: "12" } }, [
                                    _c("div", { staticClass: "half" }, [
                                      _c(
                                        "p",
                                        { staticClass: "caption my-0 py-0" },
                                        [_vm._v("Created")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "body-1 black--text font-weight-normal"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("formatDateTime")(
                                                  _vm.actionItem.created_on
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ])
                                  ]),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "my-0 py-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "half" },
                                        [
                                          _c("validation-provider", {
                                            attrs: { name: "Due By" },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function(ref) {
                                                  var errors = ref.errors
                                                  var valid = ref.valid
                                                  return [
                                                    _c(
                                                      "v-menu",
                                                      {
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "activator",
                                                              fn: function(
                                                                ref
                                                              ) {
                                                                var on = ref.on
                                                                return [
                                                                  _c(
                                                                    "v-text-field",
                                                                    _vm._g(
                                                                      {
                                                                        attrs: {
                                                                          "error-messages": errors,
                                                                          success: valid,
                                                                          color:
                                                                            "#3F51B5",
                                                                          label:
                                                                            "Due Date"
                                                                        },
                                                                        scopedSlots: _vm._u(
                                                                          [
                                                                            {
                                                                              key:
                                                                                "append",
                                                                              fn: function() {
                                                                                return [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    [
                                                                                      _vm._v(
                                                                                        " " +
                                                                                          _vm._s(
                                                                                            _vm.mdiCalendar
                                                                                          ) +
                                                                                          " "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ]
                                                                              },
                                                                              proxy: true
                                                                            }
                                                                          ],
                                                                          null,
                                                                          true
                                                                        ),
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .actionItem
                                                                              .due_by,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.actionItem,
                                                                              "due_by",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "actionItem.due_by"
                                                                        }
                                                                      },
                                                                      on
                                                                    )
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          true
                                                        )
                                                      },
                                                      [
                                                        _c("v-date-picker", {
                                                          model: {
                                                            value:
                                                              _vm.actionItem
                                                                .due_by,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.actionItem,
                                                                "due_by",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "actionItem.due_by"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "my-0 py-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "Assigned To",
                                          rules: "email"
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              var valid = ref.valid
                                              return [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    "error-messages": errors,
                                                    success: valid,
                                                    color: "#3F51B5",
                                                    label: "Assigned To"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.actionItem
                                                        .assignee_email,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.actionItem,
                                                        "assignee_email",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "actionItem.assignee_email"
                                                  }
                                                })
                                              ]
                                            }
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "my-0 py-0",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: { name: "Description" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              var valid = ref.valid
                                              return [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    "error-messages": errors,
                                                    success: valid,
                                                    color: "#3F51B5",
                                                    label: "Description"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.actionItem
                                                        .action_item_description,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.actionItem,
                                                        "action_item_description",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "actionItem.action_item_description"
                                                  }
                                                })
                                              ]
                                            }
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "my-0 pb-3 px-3",
                                      attrs: { cols: "12" }
                                    },
                                    [
                                      _c("p", { staticClass: "caption" }, [
                                        _vm._v("Photos")
                                      ]),
                                      _vm.assignerPhotos.length > 0
                                        ? _c(
                                            "div",
                                            { staticClass: "d-flex gap" },
                                            _vm._l(_vm.assignerPhotos, function(
                                              p
                                            ) {
                                              return _c("img", {
                                                key: p,
                                                attrs: {
                                                  src:
                                                    "data:image/jpeg+png+svg+xml;base64," +
                                                    p
                                                }
                                              })
                                            }),
                                            0
                                          )
                                        : _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex justify-center body-1 font-weight-normal"
                                            },
                                            [_vm._v(" No Photos Uploaded ")]
                                          )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    { staticClass: "d-flex justify-end p-3 gap" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "#3F51B5",
                            dark: !_vm.saving,
                            type: "submit",
                            disabled: _vm.saving
                          }
                        },
                        [_vm._v(" Save ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }