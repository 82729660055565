<template>
  <v-dialog :value="showEditActionItemDialog" max-width="600px">
    <v-card>
      <v-toolbar dark color="#3F51B5">
        <v-toolbar-title>Action Item</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeForm">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text
        class="pt-3"
        id="dynamic-form-card-text"
        :style="{
          height: $vuetify.breakpoint.xsOnly ? `${contentHeight}px` : '65vh',
        }"
      >
        <validation-observer ref="actionItemForm">
          <v-row>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors, valid }"
                name="Description"
                rules="required"
              >
                <v-text-field
                  label="Description *"
                  hide-details="auto"
                  :error-messages="errors"
                  :success="valid"
                  color="#3F51B5"
                  name="value"
                  v-model="actionItem.action_item_description"
                  :disabled="readOnly"
                  :hint="item.question.descriptionHint"
                >
                </v-text-field>
              </validation-provider>
            </v-col>

            <v-col
              cols="12"
              class="d-flex gap justify-space-between"
              v-if="selectedMapService.site_enabled"
            >
              <div class="half">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Assign To"
                  rules="required"
                >
                  <v-select
                    v-model="assignTo"
                    :items="ASSIGN_TO_CHOICES"
                    hide-details="auto"
                    name="assignTo"
                    item-value="value"
                    item-text="label"
                    :error-messages="errors"
                    :success="valid"
                    label="Assign To *"
                    :disabled="readOnly"
                  />
                </validation-provider>
              </div>
            </v-col>

            <v-col
              cols="12"
              class="d-flex gap justify-space-between"
              v-if="assignTo === ASSIGN_TO.SITE_CONTACT"
            >
              <div class="half">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Site Contact"
                  rules="required"
                >
                  <v-combobox
                    v-model="sharedSiteContactUser"
                    :items="filteredSharedSiteContacts"
                    hide-details="auto"
                    name="siteContact"
                    item-value="user_id"
                    :error-messages="errors"
                    :success="valid"
                    label="Site Contact *"
                    :disabled="readOnly"
                    :search-input.sync="searchContact"
                    no-filter
                  >
                    <template #item="{ item }">
                      <section>
                        <div>{{ item.f_name }} {{ item.l_name }}</div>
                        <div class="caption">{{ item.email }}</div>
                      </section>
                    </template>

                    <template #selection="{ item }">
                      <section>
                        <div>{{ item.f_name }} {{ item.l_name }}</div>
                        <div class="caption">{{ item.email }}</div>
                      </section>
                    </template>
                  </v-combobox>
                </validation-provider>
              </div>
            </v-col>
            <v-col
              cols="12"
              class="d-flex gap justify-space-between"
              v-else-if="assignTo === ASSIGN_TO.ORG_USER"
            >
              <div class="half">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="Assigned To"
                  rules="required"
                >
                  <v-select
                    v-model="assigneeUserId"
                    :items="userChoices"
                    hide-details="auto"
                    name="assignedTo"
                    item-value="value"
                    item-text="name"
                    :error-messages="errors"
                    :success="valid"
                    label="Assigned To *"
                    :disabled="readOnly"
                  />
                </validation-provider>
              </div>
            </v-col>

            <v-col cols="12">
              <div class="half">
                <validation-provider
                  slim
                  v-slot="{ errors, valid }"
                  name="Due By"
                  rules="required"
                >
                  <v-menu>
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-on="on"
                        label="Due By *"
                        color="#3F51B5"
                        v-model="actionItem.due_by"
                        :error-messages="errors"
                        :success="valid"
                        :disabled="readOnly"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="actionItem.due_by"></v-date-picker>
                  </v-menu>
                </validation-provider>
              </div>
            </v-col>

            <v-col cols="12">
              <p class="caption">Photos</p>
              <file-pond
                name="files"
                ref="files"
                label-idle="Tap or drop photos here..."
                allow-multiple
                accepted-file-types="image/jpeg, image/png"
                :files="uploadFiles"
                @addfile="onChange"
                allowFileSizeValidation
                maxFileSize="2MB"
                :disabled="readOnly"
              />
              <v-card
                v-for="(image, index) of this.actionItem.assigner_photos"
                :key="image.id"
              >
                <v-card-text>
                  <v-list-item>
                    <v-list-item-content>
                      <v-card class="d-flex flex-row justify-start elevation-0">
                        <v-card
                          class="
                            elevation-0
                            d-flex
                            flex-column
                            justify-center
                            mr-2
                          "
                        >
                          <img :src="image.url" class="thumbnail mr-2" />
                        </v-card>
                        <v-card class="elevation-0" width="100%">
                          <v-text-field
                            :key="image.id"
                            label="Description"
                            color="#3F51B5"
                            :name="image.id"
                            v-model="image.description"
                          >
                          </v-text-field>
                        </v-card>
                      </v-card>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-list-item-action-text>
                        <v-menu>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon>{{ mdiDotsVertical }}</v-icon>
                            </v-btn>
                          </template>

                          <v-list>
                            <v-list-item @click="removePhoto(index, image.id)">
                              <v-list-item-title>
                                <v-icon>{{ mdiDelete }}</v-icon>
                                Delete
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-action-text>
                    </v-list-item-action>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </validation-observer>
      </v-card-text>

      <v-card-actions class="d-flex justify-end px-5">
        <v-btn
          color="#3F51B5"
          text
          @click="$emit('delete-action-item', selectedActionItem)"
        >
          Delete
        </v-btn>
        <v-btn color="#3F51B5" dark @click="save">Save Action Item</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { cloneDeep } from "lodash";
import vueFilePond, { setOptions } from "vue-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import { mdiDelete, mdiDotsVertical } from "@mdi/js";
import "filepond/dist/filepond.min.css";
import { axiosWithJwtAuth } from "@/plugins/axios";
import {
  ASSIGN_TO,
  ASSIGN_TO_CHOICES,
  MANUALLY_ADD_EMAIL,
} from "@/constants/actionItemChoices";
import contentHeightMixin from "@/mixins/contentHeightMixin";

const APIURL = process.env.VUE_APP_API_URL;

const Compress = require("compress.js");
const compress = new Compress();
const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize
);

setOptions({
  imageResizeMode: "contain",
  allowImageTransform: true,
});

export default {
  name: "EditActionItemDialog",
  props: {
    showEditActionItemDialog: Boolean,
    item: Object,
    readOnly: Boolean,
    selectedActionItem: Object,
    userGroup: Array,
    formResultId: String,
    formDefinition: Object,
    selectedMapServiceId: String,
    gisInfoObjectId: [String, Number],
  },
  mixins: [contentHeightMixin],
  data() {
    return {
      actionItem: {
        action_item_description: "",
        assignee_user_id: "",
        due_by: "",
        assigner_photos: [],
      },
      uploadFiles: undefined,
      mdiDelete,
      mdiDotsVertical,
      assigneeUserId: undefined,
      manuallyEnteredAssigneeEmail: "",
      users: [],
      ASSIGN_TO_CHOICES,
      ASSIGN_TO,
      MANUALLY_ADD_EMAIL,
      assignTo: ASSIGN_TO.SITE_CONTACT,
      selectedMapService: {},
      sharedSiteContacts: [],
      sharedSiteContactUser: "",
      searchContact: undefined,
    };
  },
  watch: {
    gisInfoObjectId: {
      immediate: true,
      handler() {
        this.getData();
      },
    },
    selectedMapServiceId: {
      immediate: true,
      handler() {
        this.getData();
      },
    },
  },
  beforeMount() {
    this.getData();
  },

  components: {
    FilePond,
  },
  computed: {
    filteredSharedSiteContacts() {
      const { searchContact } = this;
      if (!searchContact) {
        return this.sharedSiteContacts;
      }
      return this.sharedSiteContacts.filter((c) => {
        return (
          c.email?.toLowerCase()?.includes(searchContact?.toLowerCase()) ||
          c.f_name?.toLowerCase()?.includes(searchContact?.toLowerCase()) ||
          c.l_name?.toLowerCase()?.includes(searchContact?.toLowerCase())
        );
      });
    },
    assigneeEmailFieldChoices() {
      return (
        this.formDefinition?.form?.sections
          ?.filter((s) => {
            return s.items.some((it) =>
              this.item.question?.populateEmailWithIds?.includes(it.id)
            );
          })
          ?.map((s) => {
            return [
              {
                header: s.name,
              },
              ...(s.items
                ?.filter(
                  (it) =>
                    this.item.question?.populateEmailWithIds?.includes(it.id) &&
                    it.value
                )
                ?.map((it) => {
                  const {
                    id,
                    number,
                    question: { label },
                    value,
                  } = it;

                  let emailChoices = [];
                  if (Array.isArray(value)) {
                    emailChoices = value.map((v, i) => {
                      return {
                        name: v,
                        value: ["item", id, i].join("-"),
                      };
                    });
                  } else {
                    emailChoices = [
                      {
                        name: value,
                        value: ["item", id, 0].join("-"),
                      },
                    ];
                  }

                  return [
                    {
                      header: `${number} ${label}`,
                    },
                    ...emailChoices.filter((c) => c.name),
                  ];
                })
                ?.flat() ?? []),
            ];
          })
          .flat() ?? []
      );
    },
    userChoices() {
      if (!Array.isArray(this.users)) {
        return [];
      }
      return [
        { header: "User Group" },
        ...this.users.map((u) => {
          const { user_id: value, f_name: fName, l_name: lName } = u;
          return {
            name: `${fName} ${lName}`,
            value,
          };
        }),
        { divider: true },
        ...this.assigneeEmailFieldChoices,
        { divider: true },
        { name: "Manually Add Email", value: "manually-add-email" },
      ];
    },
  },
  methods: {
    async getData() {
      await this.getSiteContacts();
      await this.getMapService();
      await this.getOrgUsers();
      this.actionItem = cloneDeep(this.selectedActionItem);
      const { assignee_user_id: assigneeUserId } = this.actionItem;

      const orgUser = this.users.find((u) => u.user_id === assigneeUserId);
      const sharedSiteContact = this.sharedSiteContacts.find(
        (c) => c.user_id === assigneeUserId
      );
      const assigneeIsOrgUser = Boolean(orgUser);
      const assigneeIsSiteContact = Boolean(sharedSiteContact);

      if (assigneeIsOrgUser) {
        this.assignTo = ASSIGN_TO.ORG_USER;
        this.assigneeUserId = assigneeUserId;
      } else if (assigneeIsSiteContact) {
        this.assignTo = ASSIGN_TO.SITE_CONTACT;
        this.sharedSiteContactUser = sharedSiteContact;
      }
    },
    async getOrgUsers() {
      const {
        data: { results: allUsers },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/users/${this.$route.query.siteId}`
      );
      const siteContactUserIds = this.sharedSiteContacts.map((u) => u.user_id);
      this.users = allUsers.filter(
        (u) => !siteContactUserIds.includes(u.user_id)
      );
    },
    async getMapService() {
      if (!this.selectedMapServiceId || !this.$route.query.siteId) {
        return;
      }
      const {
        data: { results: selectedMapService },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/map_services/${this.selectedMapServiceId}/${this.$route.query.siteId}`
      );
      this.selectedMapService = selectedMapService;
    },
    async getSiteContacts() {
      const { selectedMapServiceId, gisInfoObjectId } = this;
      if (!selectedMapServiceId || !gisInfoObjectId) {
        return;
      }
      const {
        data: { results },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/shared_site_contacts/${this.$route.query.siteId}`,
        {
          params: {
            map_service_id: selectedMapServiceId,
            feature_id: gisInfoObjectId,
          },
        }
      );
      this.sharedSiteContacts = results;
    },
    closeForm() {
      this.$emit("close-form");
    },
    async getAssignableUsers() {
      const {
        data: { results },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/users/${this.$route.query.siteId}`
      );
      this.users = results;
    },
    async save() {
      const success = await this.$refs.actionItemForm.validate();
      if (!success) {
        return;
      }
      if (this.assignTo === ASSIGN_TO.SITE_CONTACT) {
        const sharedSiteUser = this.sharedSiteContacts.find(
          (u) => u.user_id === this.sharedSiteContactUser.user_id
        );
        const {
          email,
          user_id: userId,
          f_name: fName,
          l_name: lName,
          company,
        } = sharedSiteUser;

        this.actionItem = {
          ...this.actionItem,
          assignee_user_id: userId,
          email_from_from: null,
          assignee_email: email,
          assigned_to_user_name: `${fName} ${lName}`,
          assigned_to_user_group_name: company,
        };
      } else {
        const user = this.users.find((u) => u.user_id === this.assigneeUserId);
        if (user) {
          const { user_id: userId, f_name: fName, l_name: lName } = user;
          const {
            data: { results: organization },
          } = await axiosWithJwtAuth.get(
            `${APIURL}/shared_site/user_group/${this.$route.query.siteId}`
          );
          this.actionItem = {
            ...this.actionItem,
            assignee_user_id: userId,
            email_from_from: null,
            assignee_email: null,
            assigned_to_user_name: `${fName} ${lName}`,
            assigned_to_user_group_name: organization.name,
          };
        }
      }
      const actionItem = cloneDeep(this.actionItem);
      this.$emit("form-saved", actionItem);
    },
    async onChange() {
      const files = this.$refs.files.getFiles();
      const base64ImagePromises = files.map(async (f) => {
        try {
          const { name: fileName, type: fileType } = f.file;
          const result = await compress.compress([f.file], {
            maxWidth: this.maxWidthHeight,
            maxHeight: this.maxWidthHeight,
            resize: true,
          });
          const [{ data, prefix, endWidthInPx, endHeightInPx }] = result;
          const dataUrl = `${prefix}${data}`;
          this.resizedImageWidth = endWidthInPx;
          this.resizedImageHeight = endHeightInPx;
          const res = await fetch(dataUrl);
          const buf = await res.arrayBuffer();
          const resizedImageFile = new File([buf], fileName, {
            type: fileType,
          });
          const formData = new FormData();
          formData.append("file", resizedImageFile);
          formData.append("file_name", fileName);
          formData.append("is_image", true);
          formData.append("site_id", this.$route.query.siteId);
          if (this.formResultId) {
            const {
              data: { file },
            } = await axiosWithJwtAuth.post(
              `${APIURL}/shared_site/form_results/${this.formResultId}/files`,
              formData
            );
            return {
              dataUrl,
              description: fileName,
              fileName,
              fileType,
              uploaded: true,
              isImage: true,
              ...file,
            };
          }
        } catch (error) {
          return undefined;
        }
      });

      if (files.length > 0) {
        for (const f of files) {
          this.$refs.files.removeFile(f);
        }

        const base64Images = await Promise.all(base64ImagePromises);
        this.actionItem.assigner_photos = [
          ...this.actionItem.assigner_photos,
          ...base64Images.map((im) => {
            const { id, description, url, thumbnail } = im;
            return { id, description, url, thumbnail };
          }),
        ];
      }
    },
    async removePhoto(index, imageId) {
      this.actionItem.assigner_photos.splice(index, 1);
      await axiosWithJwtAuth.delete(
        `${APIURL}/shared_site/files/${imageId}/${this.$route.query.siteId}`
      );
    },
  },
};
</script>

<style scoped>
.thumbnail {
  width: 70px;
}

.half {
  width: 50%;
}
</style>
