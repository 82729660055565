<template>
  <v-dialog :value="showUrlEditorDialog" max-width="300px">
    <v-card>
      <validation-observer
        slim
        ref="urlEditorForm"
        v-slot="{ valid: isFormValid }"
      >
        <form @submit.prevent="save">
          <v-card-text class="pa-3">
            <validation-provider
              :rules="{ required: true }"
              v-slot="{ errors, valid }"
              name="URL"
            >
              <v-text-field
                v-model="url"
                label="URL"
                hide-details
                :error-messages="errors"
                :success="valid"
              >
              </v-text-field>
            </validation-provider>
          </v-card-text>

          <v-card-actions class="d-flex justify-end pa-3">
            <v-btn type="submit" :disabled="!isFormValid">Save</v-btn>
          </v-card-actions>
        </form>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SharedSiteUrlEditorDialog",
  props: {
    showUrlEditorDialog: Boolean,
    value: String,
  },
  data() {
    return {
      url: "",
    };
  },
  beforeMount() {
    this.url = this.value;
  },
  methods: {
    async save() {
      const success = await this.$refs.urlEditorForm.validate();
      console.log(success);
      if (!success) {
        return;
      }
      this.$emit("input", this.url);
    },
  },
};
</script>
