var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { width: "100%", elevation: "0" } },
    [
      _c(
        "v-card-text",
        { attrs: { width: "526px" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "d-flex align-center justify-space-between",
                  attrs: { cols: "12" }
                },
                [
                  _c("div", [
                    _vm._v(" " + _vm._s(_vm.item.question.label) + " ")
                  ]),
                  _c(
                    "div",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            text: "",
                            color: "#3F51B5",
                            disabled: _vm.isDisabled
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              _vm.showRepeatingGroupDialog = true
                            }
                          }
                        },
                        [_vm._v(" + Add ")]
                      )
                    ],
                    1
                  )
                ]
              ),
              _c(
                "v-col",
                { staticClass: "pa-0 ma-0", attrs: { cols: "12" } },
                [
                  _c(
                    "v-card",
                    { staticClass: "px-0 mx-0" },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "px-0 mx-0" },
                        [
                          _c(
                            "v-responsive",
                            {
                              staticStyle: { "overflow-x": "auto" },
                              attrs: { width: "100%", "max-width": "100%" }
                            },
                            [
                              _c("v-simple-table", [
                                _c("thead", [
                                  _c(
                                    "tr",
                                    [
                                      _vm._l(_vm.tableHeaders, function(h) {
                                        return _c(
                                          "td",
                                          { key: h.id },
                                          [
                                            h.information
                                              ? [
                                                  _vm.isHtml(
                                                    h.information.label
                                                  )
                                                    ? _c("div", {
                                                        staticClass: "raw-html",
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            h.information.label
                                                          )
                                                        }
                                                      })
                                                    : _c("b", [
                                                        _vm._v(
                                                          _vm._s(
                                                            h.information.label
                                                          )
                                                        )
                                                      ])
                                                ]
                                              : h.label
                                              ? [
                                                  _vm.isHtml(h.label)
                                                    ? _c("div", {
                                                        staticClass: "raw-html",
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            h.label
                                                          )
                                                        }
                                                      })
                                                    : _c("b", [
                                                        _vm._v(_vm._s(h.label))
                                                      ])
                                                ]
                                              : _vm._e()
                                          ],
                                          2
                                        )
                                      }),
                                      _c("td")
                                    ],
                                    2
                                  )
                                ]),
                                _c(
                                  "tbody",
                                  _vm._l(_vm.tableValues, function(v, index) {
                                    return _c(
                                      "tr",
                                      {
                                        key: index,
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            _vm.selectedValueIndex = index
                                            _vm.showEditRepeatingGroupDialog = true
                                            _vm.selectedValueItems =
                                              _vm.groupValues[index]
                                          }
                                        }
                                      },
                                      [
                                        _vm._l(_vm.tableHeaders, function(h) {
                                          return _c("td", { key: h.id }, [
                                            h.type === "TEXT" && h.isLong
                                              ? _c("div", {
                                                  staticClass: "raw-html",
                                                  domProps: {
                                                    innerHTML: _vm._s(v[h.id])
                                                  }
                                                })
                                              : _c("div", [
                                                  _vm._v(
                                                    " " + _vm._s(v[h.id]) + " "
                                                  )
                                                ])
                                          ])
                                        }),
                                        _c("td", [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex justify-end align-center"
                                            },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: { icon: "" },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      _vm.selectedValueIndex = index
                                                      _vm.showEditRepeatingGroupDialog = true
                                                      _vm.selectedValueItems =
                                                        _vm.groupValues[index]
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(_vm.mdiPencil) +
                                                        " "
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ])
                                      ],
                                      2
                                    )
                                  }),
                                  0
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.showRepeatingGroupDialog
            ? _c("SharedSiteRepeatingGroupDialog", {
                attrs: {
                  showRepeatingGroupDialog: _vm.showRepeatingGroupDialog,
                  item: _vm.item,
                  readOnly: _vm.isDisabled,
                  formResultId: _vm.formResultId,
                  selectedMapServiceId: _vm.selectedMapServiceId,
                  globalId: _vm.globalId,
                  objectId: _vm.objectId,
                  formDefinition: _vm.formDefinition,
                  canEdit: _vm.canEdit,
                  isEditingFinalForm: _vm.isEditingFinalForm,
                  alreadySubmittedFinalOnline: _vm.alreadySubmittedFinalOnline,
                  selectedGisInfo: _vm.selectedGisInfo
                },
                on: {
                  "close-form": function($event) {
                    _vm.showRepeatingGroupDialog = false
                  },
                  "form-saved": _vm.onFormSaved
                }
              })
            : _vm._e(),
          _vm.showEditRepeatingGroupDialog
            ? _c("SharedSiteEditRepeatingGroupDialog", {
                attrs: {
                  showEditRepeatingGroupDialog:
                    _vm.showEditRepeatingGroupDialog,
                  item: _vm.item,
                  selectedValueItems: _vm.selectedValueItems,
                  selectedValueIndex: _vm.selectedValueIndex,
                  readOnly: _vm.isDisabled,
                  formResultId: _vm.formResultId,
                  formDefinition: _vm.formDefinition,
                  canEdit: _vm.canEdit,
                  isEditingFinalForm: _vm.isEditingFinalForm,
                  alreadySubmittedFinalOnline: _vm.alreadySubmittedFinalOnline,
                  selectedGisInfo: _vm.selectedGisInfo,
                  globalId: _vm.globalId,
                  objectId: _vm.objectId
                },
                on: {
                  "close-form": function($event) {
                    _vm.showEditRepeatingGroupDialog = false
                  },
                  "form-saved": _vm.onEditFormSaved,
                  "delete-item": _vm.deleteItem
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }