var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex justify-space-between align-center" },
        [
          _c("div", { staticClass: "caption" }, [_vm._v(_vm._s(_vm.label))]),
          _c(
            "v-btn",
            {
              attrs: { text: "", color: "#3F51B5", disabled: _vm.isDisabled },
              on: {
                click: function($event) {
                  _vm.showSharedSiteAddImageDialog = true
                }
              }
            },
            [
              _c("v-icon", { attrs: { small: "" } }, [
                _vm._v(" " + _vm._s(_vm.mdiPlus) + " ")
              ]),
              _vm._v(" Add ")
            ],
            1
          )
        ],
        1
      ),
      _c("v-simple-table", [
        _c("thead", [
          _c("tr", [
            _c("th", [_vm._v("Image")]),
            _c("th", [_vm._v("Description")]),
            _c("th")
          ])
        ]),
        _vm.base64Images.length > 0
          ? _c(
              "tbody",
              _vm._l(_vm.base64Images, function(image, index) {
                return _c("tr", { key: image.id }, [
                  _c("td", [
                    _c("img", {
                      staticClass: "thumbnail",
                      attrs: { src: _vm.getPreviewUrl(image) },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.onClick(image)
                        }
                      }
                    })
                  ]),
                  _c(
                    "td",
                    [
                      _c("v-text-field", {
                        attrs: { label: "Description", color: "#3F51B5" },
                        on: {
                          change: function($event) {
                            return _vm.$emit("input", _vm.base64Images)
                          }
                        },
                        model: {
                          value: image.description,
                          callback: function($$v) {
                            _vm.$set(image, "description", $$v)
                          },
                          expression: "image.description"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "td",
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-end align-center" },
                        [
                          _c(
                            "v-menu",
                            {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function(ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              { attrs: { icon: "" } },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _c("v-icon", [
                                              _vm._v(
                                                _vm._s(_vm.mdiDotsVertical)
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _c(
                                "v-list",
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.onClick(image)
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-title",
                                        [
                                          _c("v-icon", [
                                            _vm._v(_vm._s(_vm.mdiFileEdit))
                                          ]),
                                          _vm._v(" Markup ")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.removePhoto(
                                            index,
                                            image.id
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c(
                                        "v-list-item-title",
                                        [
                                          _c("v-icon", [
                                            _vm._v(_vm._s(_vm.mdiDelete))
                                          ]),
                                          _vm._v(" Delete ")
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.showSharedSiteMarkupImageDialog[image.id]
                        ? _c("SharedSiteMarkupImageDialog", {
                            attrs: {
                              showSharedSiteMarkupImageDialog:
                                _vm.showSharedSiteMarkupImageDialog[image.id],
                              selectedFile: _vm.selectedFile
                            },
                            on: {
                              "markup-image-dialog-close": function($event) {
                                _vm.showSharedSiteMarkupImageDialog = false
                                _vm.onFileMarkupPreviewSaved()
                              },
                              "file-markup-preview-saved":
                                _vm.onFileMarkupPreviewSaved
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ])
              }),
              0
            )
          : _c("tbody", [
              _c("tr", [
                _c("td", { attrs: { colspan: "3" } }, [
                  _c("div", { staticClass: "d-flex justify-center" }, [
                    _vm._v("No Images")
                  ])
                ])
              ])
            ])
      ]),
      _vm.showSharedSiteAddImageDialog
        ? _c("SharedSiteAddImageDialog", {
            attrs: {
              showSharedSiteAddImageDialog: _vm.showSharedSiteAddImageDialog,
              maxWidthHeight: +_vm.maxWidthHeight,
              readOnly: _vm.isDisabled,
              allowMultiple: _vm.allowMultiple,
              formResultId: _vm.formResultId,
              formDefinition: _vm.formDefinition,
              selectedGisInfo: _vm.selectedGisInfo,
              selectedMapServiceId: _vm.selectedMapServiceId,
              item: _vm.item,
              globalId: _vm.globalId,
              objectId: _vm.objectId
            },
            on: {
              "add-photo-dialog-close": function($event) {
                _vm.showSharedSiteAddImageDialog = false
              },
              input: _vm.onSharedSiteAddImageDialogInput,
              "image-deleted": _vm.onImageDeleted,
              "file-markup-preview-saved": _vm.onFileMarkupPreviewSaved
            }
          })
        : _vm._e(),
      _vm.showSharedSiteOfflineDialog
        ? _c("SharedSiteOfflineDialog", {
            attrs: {
              showSharedSiteOfflineDialog: _vm.showSharedSiteOfflineDialog
            },
            on: {
              "offline-dialog-close": function($event) {
                _vm.showSharedSiteOfflineDialog = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }