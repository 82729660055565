var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          staticClass: "rounded-0",
          attrs: {
            value: _vm.showSharedSiteMarkupImageDialog,
            fullscreen: "",
            "retain-focus": false,
            persistent: "",
            "no-click-animation": ""
          }
        },
        [
          _c(
            "v-card",
            {
              ref: "card",
              staticClass: "d-flex rounded-0",
              staticStyle: { overflow: "hidden" },
              attrs: { height: "calc(100vh - (100vh - 100%) - 56px)" }
            },
            [
              _c("v-slide-x-reverse-transition", [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showPdfPagesPanel,
                        expression: "showPdfPagesPanel"
                      }
                    ],
                    ref: "pdfList",
                    style: { width: "200px" }
                  },
                  [
                    _c("SharedSiteMarkupPdfList", {
                      attrs: { selectedFile: _vm.selectedFile },
                      on: {
                        "markup-pdf-list-close": function($event) {
                          _vm.showPdfPagesPanel = false
                        },
                        "page-selected": function($event) {
                          _vm.selectedPdfPage = $event
                          _vm.loadPdfPageOrMarkup()
                        }
                      }
                    })
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "flex-grow-1 d-flex flex-column" }, [
                _c(
                  "div",
                  { staticClass: "flex-grow-1 stage" },
                  [
                    _vm.isCropping
                      ? _c("SharedSiteCropToolbar", {
                          ref: "SharedSiteCropToolbar",
                          on: {
                            "close-button-click": function($event) {
                              _vm.isCropping = false
                              _vm.$set(_vm.configKonva, "draggable", true)
                              _vm.clearCropRect()
                            },
                            reset: _vm.resetCrop
                          }
                        })
                      : _c("SharedSiteMarkupToolbar", {
                          ref: "SharedSiteMarkupToolbar",
                          attrs: {
                            selectedFile: _vm.selectedFile,
                            selectedPdfPage: _vm.selectedPdfPage,
                            hasSharedSiteLegend: _vm.hasSharedSiteLegend,
                            fileMarkupRevisions: _vm.fileMarkupRevisions,
                            pdfPageFileMarkupCounts:
                              _vm.pdfPageFileMarkupCounts,
                            imageFileMarkupCount: _vm.imageFileMarkupCount,
                            disableFitToScreen:
                              _vm.rotateAngleDegrees % 360 !== 0
                          },
                          on: {
                            "revision-selected": _vm.onRevisionSelected,
                            "show-pdf-pages-panel": function($event) {
                              _vm.showPdfPagesPanel = !_vm.showPdfPagesPanel
                            },
                            rotate: function($event) {
                              _vm.rotate($event)
                              _vm.updateRotation($event)
                            },
                            "fit-to-screen": _vm.fitToScreen,
                            crop: _vm.crop,
                            download: _vm.download,
                            undo: _vm.undo,
                            redo: _vm.redo,
                            "add-SharedSiteLegend": function($event) {
                              return _vm.showToolbar(
                                _vm.ITEM_TYPES.SharedSiteLegend
                              )
                            },
                            "dialog-close-button-click":
                              _vm.onDialogCloseButtonClick,
                            "show-more": function($event) {
                              return _vm.getFileMarkupRevisions(
                                ++_vm.markupRevisionPage
                              )
                            },
                            "revision-deleted": function($event) {
                              return _vm.onRevisionDeleted()
                            }
                          }
                        }),
                    _c("SharedSiteMarkupItemsToolbar", {
                      ref: "markupItemToolbar",
                      attrs: {
                        itemType: _vm.itemType,
                        displayCustomSymbolToolbar:
                          _vm.displayCustomSymbolToolbar,
                        customSymbols: _vm.customSymbols,
                        selectedItem: _vm.selectedItem
                      },
                      on: {
                        "show-toolbar": _vm.showToolbar,
                        "hide-toolbar": _vm.hideToolbar,
                        undo: _vm.undo,
                        redo: _vm.redo,
                        "selected-custom-symbol": _vm.onSelectCustomSymbol,
                        "custom-symbol-added": function($event) {
                          return _vm.getCustomSymbols()
                        },
                        "custom-symbol-edited": function($event) {
                          return _vm.getCustomSymbols()
                        },
                        "get-custom-symbols": function($event) {
                          _vm.symbolInactiveStatus = $event
                          _vm.getCustomSymbols()
                        }
                      }
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "d-flex pa-0 ma-0",
                        staticStyle: { overflow: "hidden" }
                      },
                      [
                        _c("SharedSiteUnsavedMarkupDialog", {
                          attrs: {
                            showSharedSiteUnsavedMarkupDialog:
                              _vm.showSharedSiteUnsavedMarkupDialog
                          },
                          on: {
                            "cancel-markup-image-dialog-close": function(
                              $event
                            ) {
                              _vm.showSharedSiteUnsavedMarkupDialog = false
                            },
                            "confirm-markup-image-dialog-close": function(
                              $event
                            ) {
                              _vm.$emit("markup-image-dialog-close")
                              _vm.undoItems = {}
                              _vm.redoItems = {}
                            }
                          }
                        }),
                        _c("SharedSiteLegend", {
                          ref: "SharedSiteLegend",
                          attrs: { addedCustomSymbols: _vm.addedCustomSymbols }
                        }),
                        _c(
                          "div",
                          { staticClass: "flex-grow-1 stage" },
                          [
                            _c(
                              "v-stage",
                              {
                                ref: "stage",
                                staticClass:
                                  "d-flex justify-center align-center",
                                attrs: { config: _vm.configKonva },
                                on: {
                                  mousedown: _vm.handleStageMouseDown,
                                  touchstart: _vm.handleStageMouseDown,
                                  click: _vm.addItem,
                                  tap: _vm.addItem,
                                  dblclick: _vm.handleStageDoubleClick,
                                  dbltap: _vm.handleStageDoubleClick
                                }
                              },
                              [
                                _c(
                                  "v-layer",
                                  {
                                    ref: "layer",
                                    attrs: { config: _vm.layerConfig }
                                  },
                                  [
                                    _vm._l(_vm.items, function(item, index) {
                                      return [
                                        item.type === _vm.ITEM_TYPES.IMAGE ||
                                        item.type ===
                                          _vm.ITEM_TYPES.SharedSiteLegend
                                          ? _c("v-image", {
                                              key: item.id,
                                              ref: "items",
                                              refInFor: true,
                                              attrs: { config: item.config },
                                              on: {
                                                dragend: function($event) {
                                                  return _vm.onDragEnd(
                                                    index,
                                                    $event
                                                  )
                                                }
                                              }
                                            })
                                          : item.type === _vm.ITEM_TYPES.TEXT
                                          ? _c("v-text", {
                                              key: item.id,
                                              ref: "items",
                                              refInFor: true,
                                              attrs: { config: item.config },
                                              on: {
                                                dragend: function($event) {
                                                  return _vm.onDragEnd(
                                                    index,
                                                    $event
                                                  )
                                                }
                                              }
                                            })
                                          : item.type === _vm.ITEM_TYPES.LINE
                                          ? _c("v-line", {
                                              key: item.id,
                                              ref: "items",
                                              refInFor: true,
                                              attrs: { config: item.config },
                                              on: {
                                                dragmove: function($event) {
                                                  return _vm.onLineDrag(index)
                                                },
                                                dragend: function($event) {
                                                  return _vm.onLineDrag(index)
                                                }
                                              }
                                            })
                                          : item.type ===
                                              _vm.ITEM_TYPES.SHAPE &&
                                            item.shape ===
                                              _vm.SHAPE_TYPES.POLYGON
                                          ? _c("v-line", {
                                              key: item.id,
                                              ref: "items",
                                              refInFor: true,
                                              attrs: { config: item.config },
                                              on: {
                                                dragmove: function($event) {
                                                  return _vm.onPolygonDrag(
                                                    index
                                                  )
                                                },
                                                dragend: function($event) {
                                                  return _vm.onPolygonDrag(
                                                    index
                                                  )
                                                }
                                              }
                                            })
                                          : item.type === _vm.ITEM_TYPES.SKETCH
                                          ? _c("v-line", {
                                              key: item.id,
                                              ref: "items",
                                              refInFor: true,
                                              attrs: { config: item.config },
                                              on: {
                                                dragend: function($event) {
                                                  return _vm.onDragEnd(
                                                    index,
                                                    $event
                                                  )
                                                }
                                              }
                                            })
                                          : item.type ===
                                              _vm.ITEM_TYPES.SHAPE &&
                                            item.shape ===
                                              _vm.SHAPE_TYPES.RECTANGLE
                                          ? _c("v-rect", {
                                              key: item.id,
                                              ref: "items",
                                              refInFor: true,
                                              attrs: { config: item.config },
                                              on: {
                                                dragend: function($event) {
                                                  return _vm.onDragEnd(
                                                    index,
                                                    $event
                                                  )
                                                },
                                                transformend: function($event) {
                                                  return _vm.onTransformEnd(
                                                    index
                                                  )
                                                }
                                              }
                                            })
                                          : item.type ===
                                              _vm.ITEM_TYPES.SHAPE &&
                                            item.shape ===
                                              _vm.SHAPE_TYPES.ELLIPSE
                                          ? _c("v-ellipse", {
                                              key: item.id,
                                              ref: "items",
                                              refInFor: true,
                                              attrs: { config: item.config },
                                              on: {
                                                dragend: function($event) {
                                                  return _vm.onDragEnd(
                                                    index,
                                                    $event
                                                  )
                                                },
                                                transformend: function($event) {
                                                  return _vm.onTransformEnd(
                                                    index
                                                  )
                                                }
                                              }
                                            })
                                          : item.type ===
                                              _vm.ITEM_TYPES.SHAPE &&
                                            item.shape ===
                                              _vm.SHAPE_TYPES.MAP_SYMBOL
                                          ? _c("v-image", {
                                              key: item.id,
                                              ref: "items",
                                              refInFor: true,
                                              attrs: { config: item.config },
                                              on: {
                                                dragend: function($event) {
                                                  return _vm.onDragEnd(
                                                    index,
                                                    $event
                                                  )
                                                }
                                              }
                                            })
                                          : item.type === _vm.ITEM_TYPES.POINT
                                          ? _c("v-circle", {
                                              key: item.id,
                                              ref: "items",
                                              refInFor: true,
                                              attrs: { config: item.config },
                                              on: {
                                                dragend: function($event) {
                                                  return _vm.onDragEnd(
                                                    index,
                                                    $event
                                                  )
                                                },
                                                transformend: function($event) {
                                                  return _vm.onTransformEnd(
                                                    index
                                                  )
                                                }
                                              }
                                            })
                                          : item.type === _vm.ITEM_TYPES.ARROW
                                          ? _c("v-arrow", {
                                              key: item.id,
                                              ref: "items",
                                              refInFor: true,
                                              attrs: { config: item.config },
                                              on: {
                                                dragmove: function($event) {
                                                  return _vm.onArrowDrag(index)
                                                },
                                                dragend: function($event) {
                                                  return _vm.onArrowDrag(index)
                                                }
                                              }
                                            })
                                          : _vm._e()
                                      ]
                                    }),
                                    _c("v-transformer", { ref: "transformer" })
                                  ],
                                  2
                                ),
                                _c(
                                  "v-layer",
                                  { ref: "cropperLayer" },
                                  [
                                    _vm.isCropping
                                      ? _c("v-transformer", {
                                          ref: "cropperRectTransformer",
                                          on: {
                                            transformend:
                                              _vm.onCropperRectDragTransform,
                                            transformstart:
                                              _vm.onCropperRectDragTransform,
                                            transform:
                                              _vm.onCropperRectDragTransform,
                                            dragend:
                                              _vm.onCropperRectDragTransform,
                                            dragstart:
                                              _vm.onCropperRectDragTransform,
                                            drag: _vm.onCropperRectDragTransform
                                          }
                                        })
                                      : _vm._e(),
                                    _vm.isCropping
                                      ? _c("v-rect", {
                                          ref: "cropperRect",
                                          attrs: { config: _vm.cropperConfig }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _vm.isCropping
                              ? _c(
                                  "v-btn",
                                  {
                                    staticClass: "bottom-right-button",
                                    attrs: { color: "#3F51B5", dark: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.confirmCrop()
                                      }
                                    }
                                  },
                                  [_vm._v(" Crop ")]
                                )
                              : _c(
                                  "div",
                                  { staticClass: "bottom-right-button" },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          dark: "",
                                          tile: "",
                                          color: "utilisync",
                                          disabled:
                                            !_vm.isSaveAndClose &&
                                            !_vm.changesMade
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.isSaveAndClose
                                              ? _vm.saveAndClose()
                                              : _vm.saveFileMarkup()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.isSaveAndClose
                                                ? "Save and Close"
                                                : "Save Draft"
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "v-menu",
                                      {
                                        attrs: { "offset-y": "" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "activator",
                                            fn: function(ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          tile: "",
                                                          dark: "",
                                                          width: "30px",
                                                          "min-width": "30px",
                                                          color: "utilisync"
                                                        }
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          color: "white"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.mdiChevronDown
                                                            ) +
                                                            " "
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ]
                                            }
                                          }
                                        ])
                                      },
                                      [
                                        _c(
                                          "v-list",
                                          { attrs: { dense: "" } },
                                          [
                                            _c(
                                              "v-list-item",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    _vm.isSaveAndClose = false
                                                  }
                                                }
                                              },
                                              [_vm._v(" Save Draft ")]
                                            ),
                                            _c(
                                              "v-list-item",
                                              {
                                                on: {
                                                  click: function($event) {
                                                    _vm.isSaveAndClose = true
                                                  }
                                                }
                                              },
                                              [_vm._v(" Save and Close ")]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                            _vm.displayToolbar
                              ? _c("SharedSiteBottomToolbar", {
                                  staticClass: "bottom-bar",
                                  attrs: {
                                    itemType: _vm.itemType,
                                    drawingLine: _vm.drawingLine,
                                    isPaintPolygon: _vm.isPaintPolygon,
                                    selectedFile: _vm.selectedFile
                                  },
                                  on: {
                                    "options-changed": function($event) {
                                      _vm.itemOptions = $event
                                    },
                                    "toolbar-close": function($event) {
                                      _vm.displayToolbar = false
                                      _vm.itemType = ""
                                    },
                                    "finish-line": _vm.finishLine,
                                    "finish-polygon": _vm.finishPolygon
                                  }
                                })
                              : _vm._e(),
                            _vm.displayEditItemToolbar
                              ? _c("SharedSiteEditItemBottomToolbar", {
                                  staticClass: "bottom-bar",
                                  attrs: { selectedItem: _vm.selectedItem },
                                  on: {
                                    "options-changed":
                                      _vm.onSelectedItemOptionsChanged,
                                    "toolbar-close": function($event) {
                                      _vm.displayEditItemToolbar = false
                                      _vm.clearSelection()
                                    },
                                    "graphic-details-changed":
                                      _vm.onSelectedItemDetailsChanged,
                                    "delete-item": _vm.deleteItem
                                  }
                                })
                              : _vm._e(),
                            _vm.displayCustomSymbolToolbar
                              ? _c("SharedSiteCustomSymbolBottomBar", {
                                  staticClass: "bottom-bar",
                                  attrs: {
                                    selectedCustomSymbol:
                                      _vm.selectedCustomSymbol
                                  },
                                  on: {
                                    "toolbar-close": function($event) {
                                      _vm.displayCustomSymbolToolbar = false
                                      _vm.selectedCustomSymbol = undefined
                                    }
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-navigation-drawer",
                      {
                        staticClass: "elevation-1",
                        staticStyle: { "z-index": "100" },
                        attrs: {
                          app: "",
                          right: "",
                          permanent: _vm.showGraphicDetailsPanel,
                          "hide-overlay": "",
                          width: "300px",
                          stateless: ""
                        },
                        model: {
                          value: _vm.showGraphicDetailsPanel,
                          callback: function($$v) {
                            _vm.showGraphicDetailsPanel = $$v
                          },
                          expression: "showGraphicDetailsPanel"
                        }
                      },
                      [
                        _c(
                          "v-toolbar",
                          {
                            staticClass: "elevation-0",
                            attrs: { dark: "", width: "100%", color: "#3F51B5" }
                          },
                          [
                            _c("div", [_vm._v("Details")]),
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: { icon: "" },
                                on: {
                                  click: function($event) {
                                    _vm.showGraphicDetailsPanel = false
                                  }
                                }
                              },
                              [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                              1
                            )
                          ],
                          1
                        ),
                        _c("SharedSiteGraphicDetailsForm", {
                          attrs: { selectedItem: _vm.selectedItem },
                          on: {
                            "graphic-details-changed":
                              _vm.onGraphicDetailsChanged
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-navigation-drawer",
                      {
                        staticClass: "elevation-1",
                        staticStyle: { "z-index": "100" },
                        attrs: {
                          app: "",
                          right: "",
                          permanent: _vm.editingText,
                          "hide-overlay": "",
                          width: "300px",
                          stateless: ""
                        },
                        model: {
                          value: _vm.editingText,
                          callback: function($$v) {
                            _vm.editingText = $$v
                          },
                          expression: "editingText"
                        }
                      },
                      [
                        _c(
                          "v-toolbar",
                          {
                            staticClass: "elevation-0",
                            attrs: { dark: "", width: "100%", color: "#3F51B5" }
                          },
                          [
                            _c("div", [_vm._v("Edit Text")]),
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                attrs: { icon: "" },
                                on: {
                                  click: function($event) {
                                    _vm.editingText = false
                                  }
                                }
                              },
                              [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                              1
                            )
                          ],
                          1
                        ),
                        _c("SharedSiteEditTextForm", {
                          attrs: { selectedItem: _vm.selectedItem },
                          on: { "text-changed": _vm.onTextChanged }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", value: _vm.savingAndClosing, fullscreen: "" }
        },
        [
          _c(
            "v-card",
            {
              staticClass: "d-flex justify-center align-center",
              staticStyle: { "background-color": "black" }
            },
            [
              _c(
                "v-card-text",
                { staticClass: "d-flex justify-center align-center" },
                [
                  _c("v-progress-circular", {
                    attrs: { color: "white", indeterminate: "" }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }