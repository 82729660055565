<template>
  <div>
    <template v-if="isOnline">
      <div v-if="loadingDocs" class="d-flex justify-center align-center">
        <v-progress-circular indeterminate></v-progress-circular>
      </div>
      <template v-else>
        <SharedSiteAddFilesDialog
          v-if="showSharedSiteAddFilesDialog"
          :currentFolder="currentFolder"
          :showSharedSiteAddFilesDialog="showSharedSiteAddFilesDialog"
          :selectedMapServiceId="selectedMapServiceId"
          :globalId="globalId"
          :objectId="objectId"
          @add-files-dialog-close="showSharedSiteAddFilesDialog = false"
          @add-files-dialog-uploaded="onFilesUploaded"
        />

        <FilesFilterDialog
          :showFilesFilterDialog="showFilesFilterDialog"
          :savedFilterChoices="filterChoices"
          @files-filter-dialog-close="showFilesFilterDialog = false"
          @update-filter="onUpdateFilter"
        />

        <section class="d-flex align-center">
          <v-btn text color="#3F51B5" @click="showFilesFilterDialog = true">
            <v-icon> {{ mdiFilter }}</v-icon>
            Filter
          </v-btn>

          <div class="d-flex flex-wrap gap">
            <v-chip
              v-for="f of filterChoices"
              :key="f.selectedField"
              close
              @click:close="removeFilter(f.selectedField)"
            >
              {{ f.selectedField | filesFieldMap }}
              <MatchIcon :value="f.selectedMatchChoice" class="mx-2" />
              {{ f.selectedMatchValue }}
            </v-chip>
          </div>
        </section>

        <DocsBreadcrumb
          :folderPath="currentFolder"
          @navigate-folder="onNavigateToFolder"
          class="py-2 px-4"
        />

        <v-divider class="my-2"></v-divider>

        <div class="d-flex justify-end px-5">
          <v-btn
            text
            color="#3F51B5"
            class="px-0 mx-0"
            @click="onAddFilesButtonClick()"
          >
            + Add Files
          </v-btn>

          <v-menu offset-y class="px-0 mx-0">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>{{ mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list class="py-0 my-0" width="170px">
              <v-list-item class="pa-3 mx-0 d-block" id="menu">
                <div class="caption bold">Download</div>

                <ul>
                  <li
                    class="py-2"
                    @mouseenter="highlightedChoice = DOWNLOAD_CHOICES.ALL_FILES"
                    @mouseleave="highlightedChoice = undefined"
                    :style="{
                      ...getLinkColorStyle(DOWNLOAD_CHOICES.ALL_FILES),
                      ...getLinkBackgroundColorStyle(
                        DOWNLOAD_CHOICES.ALL_FILES
                      ),
                    }"
                    @click="downloadFiles(DOWNLOAD_CHOICES.ALL_FILES)"
                  >
                    All Files and Folders
                  </li>
                </ul>

                <div class="caption bold">Sort By</div>

                <ul>
                  <li
                    class="py-2"
                    @mouseenter="
                      highlightedChoice = ORDER_BY_CHOICES.NEWEST_FIRST
                    "
                    @mouseleave="highlightedChoice = undefined"
                    @click="orderFiles(ORDER_BY_CHOICES.NEWEST_FIRST)"
                    :style="{
                      ...getLinkColorStyle(ORDER_BY_CHOICES.NEWEST_FIRST),
                      ...getLinkBackgroundColorStyle(
                        ORDER_BY_CHOICES.NEWEST_FIRST
                      ),
                    }"
                  >
                    Newest First
                  </li>
                  <li
                    class="py-2"
                    @mouseenter="
                      highlightedChoice = ORDER_BY_CHOICES.OLDEST_FIRST
                    "
                    @mouseleave="highlightedChoice = undefined"
                    @click="orderFiles(ORDER_BY_CHOICES.OLDEST_FIRST)"
                    :style="{
                      ...getLinkColorStyle(ORDER_BY_CHOICES.OLDEST_FIRST),
                      ...getLinkBackgroundColorStyle(
                        ORDER_BY_CHOICES.OLDEST_FIRST
                      ),
                    }"
                  >
                    Oldest First
                  </li>
                  <li
                    class="py-2"
                    @mouseenter="highlightedChoice = ORDER_BY_CHOICES.NAME_ASC"
                    @mouseleave="highlightedChoice = undefined"
                    @click="orderFiles(ORDER_BY_CHOICES.NAME_ASC)"
                    :style="{
                      ...getLinkColorStyle(ORDER_BY_CHOICES.NAME_ASC),
                      ...getLinkBackgroundColorStyle(ORDER_BY_CHOICES.NAME_ASC),
                    }"
                  >
                    Name A - Z
                  </li>
                  <li
                    class="py-2"
                    @mouseenter="highlightedChoice = ORDER_BY_CHOICES.NAME_DESC"
                    @mouseleave="highlightedChoice = undefined"
                    @click="orderFiles(ORDER_BY_CHOICES.NAME_DESC)"
                    :style="{
                      ...getLinkColorStyle(ORDER_BY_CHOICES.NAME_DESC),
                      ...getLinkBackgroundColorStyle(
                        ORDER_BY_CHOICES.NAME_DESC
                      ),
                    }"
                  >
                    Name Z - A
                  </li>
                  <li
                    class="py-2"
                    @mouseenter="
                      highlightedChoice = ORDER_BY_CHOICES.SMALLEST_FIRST
                    "
                    @mouseleave="highlightedChoice = undefined"
                    @click="orderFiles(ORDER_BY_CHOICES.SMALLEST_FIRST)"
                    :style="{
                      ...getLinkColorStyle(ORDER_BY_CHOICES.SMALLEST_FIRST),
                      ...getLinkBackgroundColorStyle(
                        ORDER_BY_CHOICES.SMALLEST_FIRST
                      ),
                    }"
                  >
                    Smallest First
                  </li>
                  <li
                    class="py-2"
                    @mouseenter="
                      highlightedChoice = ORDER_BY_CHOICES.LARGEST_FIRST
                    "
                    @mouseleave="highlightedChoice = undefined"
                    @click="orderFiles(ORDER_BY_CHOICES.LARGEST_FIRST)"
                    :style="{
                      ...getLinkColorStyle(ORDER_BY_CHOICES.LARGEST_FIRST),
                      ...getLinkBackgroundColorStyle(
                        ORDER_BY_CHOICES.LARGEST_FIRST
                      ),
                    }"
                  >
                    Largest First
                  </li>
                </ul>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <template v-if="foldersAtLevel.length > 0 || files.length > 0">
          <section
            class="overflow-y-auto"
            :style="{
              height: $vuetify.breakpoint.lgAndUp
                ? `${docsTabHeight}px`
                : 'auto',
            }"
          >
            <div
              class="px-4 d-flex gap align-center py-1 cursor-pointer"
              v-for="f of foldersAtLevel"
              :key="f.folder"
              @click="onFolderClick(f.folder)"
              @mouseenter="highlightedFileName = f.folder"
              @mouseleave="highlightedFileName = undefined"
              :style="getFileBackgroundColorStyle(f.folder)"
            >
              <div>
                <v-icon color="#3F51B5">
                  {{ mdiFolder }}
                </v-icon>
              </div>
              <span
                class="d-inline-block text-truncate"
                style="max-width: 100%"
                :style="{ color: '#00A9F4' }"
              >
                {{ f.folder.split("/").slice(-1)[0] }}
              </span>
            </div>

            <div
              class="px-4 d-flex gap align-center py-1 cursor-pointer"
              v-for="f of files"
              :key="f.file_id"
              @click="onViewButtonClick(f)"
              @mouseenter="highlightedFileName = f.file_id"
              @mouseleave="highlightedFileName = undefined"
              :style="getFileBackgroundColorStyle(f.file_id)"
            >
              <div style="width: 80%">
                <div class="d-flex align-center text-truncate">
                  <img
                    :src="resizedImages[f.file_id] || f.s3_file_path"
                    v-if="
                      f.s3_file_path.endsWith('png') ||
                      f.s3_file_path.endsWith('jpg') ||
                      f.s3_file_path.endsWith('jpeg')
                    "
                    style="width: 20px; margin-right: 16px"
                  />
                  <v-icon class="mr-3" v-else>{{ mdiFile }} </v-icon>
                  <section class="text-truncate">
                    <div
                      class="text-truncate py-0 my-0"
                      style="max-width: 100%"
                    >
                      {{ f.name }}
                    </div>
                    <div class="caption py-0 my-0">
                      {{ f.created_on | formatDate }} -
                      {{ (f.file_size_kb / 1000).toFixed(2) }}M
                    </div>
                  </section>
                </div>
              </div>
              <div>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>{{ mdiDotsVertical }}</v-icon>
                    </v-btn>
                  </template>

                  <v-list class="py-0 my-0">
                    <v-list-item
                      class="px-3 mx-0 d-flex align-center"
                      @click="onViewButtonClick(f)"
                      v-if="
                        f.s3_file_path.endsWith('png') ||
                        f.s3_file_path.endsWith('jpg') ||
                        f.s3_file_path.endsWith('jpeg') ||
                        f.s3_file_path.endsWith('pdf')
                      "
                    >
                      <v-icon class="mr-2">
                        {{ mdiFile }}
                      </v-icon>
                      <span> View </span>
                    </v-list-item>
                    <v-list-item
                      class="px-3 mx-0 d-flex align-center"
                      @click="onMarkupClick(f)"
                      v-if="
                        f.s3_file_path.endsWith('png') ||
                        f.s3_file_path.endsWith('jpg') ||
                        f.s3_file_path.endsWith('jpeg') ||
                        f.s3_file_path.endsWith('pdf')
                      "
                    >
                      <v-icon class="mr-2">
                        {{ mdiFileEdit }}
                      </v-icon>
                      <span> Markup </span>
                    </v-list-item>
                    <v-list-item
                      v-if="getCanDelete(f)"
                      class="px-3 mx-0"
                      @click="
                        idOfFileToDelete = f.file_id;
                        showConfirmFileDeleteDialog = true;
                      "
                    >
                      <v-icon class="mr-2">
                        {{ mdiDelete }}
                      </v-icon>
                      <span> Delete </span>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
          </section>

          <SharedSiteMarkupImageDialog
            v-if="showSharedSiteMarkupImageDialog"
            @markup-image-dialog-close="
              showSharedSiteMarkupImageDialog = false;
              reloadFilesAndFolders();
            "
            :showSharedSiteMarkupImageDialog="showSharedSiteMarkupImageDialog"
            :selectedFile="selectedFile"
          />

          <SharedSitePdfViewerDialog
            v-if="showSharedSitePdfViewerDialog"
            @view-pdf-dialog-close="showSharedSitePdfViewerDialog = false"
            :showSharedSitePdfViewerDialog="showSharedSitePdfViewerDialog"
            :selectedFile="selectedFile"
          />

          <ImageViewerDialog
            v-if="showImageViewerDialog"
            @view-image-dialog-close="showImageViewerDialog = false"
            :showImageViewerDialog="showImageViewerDialog"
            :selectedFile="selectedFile"
          />

          <SharedSiteMarkupViewerDialog
            v-if="showSharedSiteMarkupViewerDialog"
            @markup-viewer-dialog-close="
              showSharedSiteMarkupViewerDialog = false;
              reloadFilesAndFolders();
            "
            :showSharedSiteMarkupViewerDialog="showSharedSiteMarkupViewerDialog"
            :selectedFile="selectedFile"
          />

          <SharedSiteConvertMarkupDialog
            v-if="showSharedSiteConvertMarkupDialog"
            :showSharedSiteConvertMarkupDialog="
              showSharedSiteConvertMarkupDialog
            "
            :selectedFile="selectedFile"
            @cancel-convert-markup-dialog-close="
              showSharedSiteConvertMarkupDialog = false
            "
          />

          <ConvertingMarkupDialog
            v-if="showConvertingMarkupDialog"
            :showConvertingMarkupDialog="showConvertingMarkupDialog"
            @cancel-converting-markup-dialog-close="
              showConvertingMarkupDialog = false
            "
          />

          <ConfirmFileDeleteDialog
            :showConfirmFileDeleteDialog="showConfirmFileDeleteDialog"
            @cancel="showConfirmFileDeleteDialog = false"
            @delete="deleteFile(idOfFileToDelete)"
          />
        </template>
        <div v-else class="d-flex justify-center align-center">
          No Documents Added
        </div>
      </template>
    </template>
    <div v-else class="text-center pa-5">
      <p class="text-center">
        <v-icon>
          {{ mdiWifiOff }}
        </v-icon>
        Offline
      </p>
      <p class="text-center">
        The device must be connected to the internet internet to show Docs.
      </p>
    </div>

    <SharedSiteNotLicensedDialog
      :showSharedSiteNotLicensedDialog="showSharedSiteNotLicensedDialog"
      :currentUserOrg="currentUserOrg"
      @close="showSharedSiteNotLicensedDialog = false"
    />
  </div>
</template>

<script>
import { axiosWithJwtAuth } from "@/plugins/axios";
import {
  mdiFolder,
  mdiFile,
  mdiDotsVertical,
  mdiFileEdit,
  mdiDelete,
  mdiWifiOff,
  mdiFilter,
} from "@mdi/js";
import DocsBreadcrumb from "@/components/mapView/docs-tab/DocsBreadcrumb.vue";
import SharedSitePdfViewerDialog from "@/components/shared-site/shared-site-docs-tab/SharedSitePdfViewerDialog";
import ImageViewerDialog from "@/components/mapView/docs-tab/ImageViewerDialog";
import SharedSiteAddFilesDialog from "@/components/shared-site/shared-site-docs-tab/SharedSiteAddFilesDialog";
import SharedSiteMarkupImageDialog from "@/components/shared-site/shared-site-ticket-form-def-drop-down/shared-site-ticket-edit-form/shared-site-dynamic-form/shared-site-photo-input/SharedSiteMarkupImageDialog";
import SharedSiteMarkupViewerDialog from "@/components/shared-site/shared-site-ticket-form-def-drop-down/shared-site-ticket-edit-form/shared-site-dynamic-form/shared-site-photo-input/SharedSiteMarkupViewerDialog";
import SharedSiteConvertMarkupDialog from "@/components/shared-site/shared-site-docs-tab/SharedSiteConvertMarkupDialog";
import ConvertingMarkupDialog from "@/components/mapView/docs-tab/ConvertingMarkupDialog";
import ConfirmFileDeleteDialog from "@/components/mapView/docs-tab/ConfirmFileDeleteDialog";
import { cloneDeep } from "lodash";
import networkStatusMixin from "@/mixins/networkStatusMixin";
import FilesFilterDialog from "@/components/mapView/docs-tab/FilesFilterDialog";
import MatchIcon from "@/components/list/gis-data-point-filter-dialog/MatchIcon";
import SharedSiteNotLicensedDialog from "@/components/shared-site/shared/SharedSiteNotLicensedDialog";

const APIURL = process.env.VUE_APP_API_URL;
const ORDER_BY_CHOICES = {
  NEWEST_FIRST: "newest-first",
  OLDEST_FIRST: "oldest-first",
  NAME_ASC: "name-asc",
  NAME_DESC: "name-desc",
  SMALLEST_FIRST: "smallest-first",
  LARGEST_FIRST: "largest-first",
};
const DOWNLOAD_CHOICES = {
  ALL_FILES: "all-files",
  FILTERED_FILES: "filtered-files",
};

export default {
  name: "SharedSiteDocsTab",
  props: {
    selectedMapServiceId: String,
    objectId: Number,
    globalId: String,
    currentUser: {},
    currentUserOrg: {},
  },
  mixins: [networkStatusMixin],
  components: {
    DocsBreadcrumb,
    SharedSitePdfViewerDialog,
    SharedSiteAddFilesDialog,
    SharedSiteMarkupImageDialog,
    ImageViewerDialog,
    SharedSiteMarkupViewerDialog,
    SharedSiteConvertMarkupDialog,
    ConvertingMarkupDialog,
    FilesFilterDialog,
    MatchIcon,
    ConfirmFileDeleteDialog,
    SharedSiteNotLicensedDialog,
  },
  computed: {
    allFolders() {
      const folders = this.folders
        ?.map((f) => {
          const splitPath = f.folder.split("/");
          const subfolders = Array(splitPath.length)
            .fill()
            .map((_, i) => splitPath.slice(0, i + 1).join("/"));
          return [...subfolders];
        })
        .flat()
        .filter((f) => Boolean(f) && !f.includes("undefined"));
      const foldersWithoutDups = [...new Set(folders)];
      return foldersWithoutDups.map((folderName) => {
        const folder = this.folders?.find((fs) =>
          fs.folder.includes(folderName)
        );
        return {
          folder: folderName,
          cover_image_url: folder?.cover_image_url,
        };
      });
    },
    foldersAtLevel() {
      return this.allFolders?.filter(
        (f) => f?.folder?.split("/")?.length === this.level
      );
    },
  },
  data() {
    return {
      folders: [],
      files: [],
      openFolders: [],
      currentFolder: undefined,
      mdiFolder,
      mdiFile,
      mdiDotsVertical,
      mdiFileEdit,
      mdiDelete,
      mdiWifiOff,
      mdiFilter,
      level: 1,
      showMarkupPdfDialog: false,
      showSharedSitePdfViewerDialog: false,
      showSharedSiteAddFilesDialog: false,
      showSharedSiteMarkupImageDialog: false,
      showImageViewerDialog: false,
      showSharedSiteMarkupViewerDialog: false,
      showSharedSiteConvertMarkupDialog: false,
      showConvertingMarkupDialog: false,
      selectedFile: {},
      loadingDocs: false,
      showFilesFilterDialog: false,
      filterChoices: [],
      orderBy: ORDER_BY_CHOICES.NEWEST_FIRST,
      ORDER_BY_CHOICES,
      DOWNLOAD_CHOICES,
      resizedImages: {},
      highlightedChoice: undefined,
      highlightedFileName: undefined,
      downloadFileTimer: undefined,
      showConfirmFileDeleteDialog: false,
      idOfFileToDelete: undefined,
      showSharedSiteNotLicensedDialog: false,
      docsTabHeight: window.innerHeight * 0.8 - 250,
    };
  },
  async beforeMount() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
    this.loadingDocs = true;
    await this.getFolders();
    await this.getFiles();
    await this.resizeImages();
    this.loadingDocs = false;
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    clearInterval(this.downloadFileTimer);
  },
  methods: {
    onResize() {
      this.docsTabHeight = window.innerHeight * 0.8 - 450;
    },
    onAddFilesButtonClick() {
      if (!this.currentUserOrg?.paying_for_linked_sites) {
        this.showSharedSiteNotLicensedDialog = true;
      } else {
        this.showSharedSiteAddFilesDialog = true;
      }
    },
    async reloadFilesAndFolders() {
      await this.getFolders();
      await this.getFiles();
      await this.resizeImages();
    },
    async removeFilter(selectedField) {
      const index = this.filterChoices.find(
        (f) => f.selectedField === selectedField
      );
      this.filterChoices.splice(index, 1);
      await this.getFolders();
      await this.getFiles();
      await this.resizeImages();
    },
    async resizeImages() {
      const resizeFilePromises = this.files
        .filter((f) => {
          return (
            f?.name?.endsWith("jpg") ||
            f?.name?.endsWith("jpeg") ||
            f?.name?.endsWith("png")
          );
        })
        .map(async (f) => {
          const { data } = await axiosWithJwtAuth.get(
            `${APIURL}/shared_site/files/${f.file_id}/resize`
          );
          return data;
        });
      const resizedFiles = await Promise.all(resizeFilePromises);
      const entries = this.files.map((f) => {
        const index = this.files.find((f) => f.file_id === f.file_id);
        return [f.file_id, resizedFiles[index]];
      });
      this.resizedImages = Object.fromEntries(entries);
    },
    getFileBackgroundColorStyle(fileOrFolderId) {
      return {
        backgroundColor:
          this.highlightedFileName === fileOrFolderId ? "#fafafa" : undefined,
      };
    },
    getLinkColorStyle(orderByChoice) {
      return {
        color: this.orderBy === orderByChoice ? "rgb(33, 150, 243)" : undefined,
      };
    },
    getLinkBackgroundColorStyle(orderByChoice) {
      return {
        backgroundColor:
          this.highlightedChoice === orderByChoice ? "#fafafa" : undefined,
      };
    },
    async orderFiles(orderBy) {
      this.orderBy = orderBy;
      await this.getFiles();
      this.resizeImages();
    },
    async onUpdateFilter(filterChoices) {
      this.filterChoices = filterChoices;
      this.showFilesFilterDialog = false;
      await this.getFiles();
      this.resizeImages();
    },
    async hasMarkup(selectedFile) {
      if (
        selectedFile.s3_file_path.endsWith("png") ||
        selectedFile.s3_file_path.endsWith("jpg") ||
        selectedFile.s3_file_path.endsWith("jpeg")
      ) {
        const {
          data: { results },
        } = await axiosWithJwtAuth.get(
          `${APIURL}/shared_site/image_file_markup_counts/${selectedFile.file_id}`
        );
        return results?.count > 0;
      } else if (selectedFile.s3_file_path.endsWith("pdf")) {
        const {
          data: { results },
        } = await axiosWithJwtAuth.get(
          `${APIURL}/shared_site/pdf_file_markup_counts/${selectedFile.file_id}`
        );
        return results.some((r) => r.count > 0);
      }
    },
    async onViewButtonClick(selectedFile) {
      if (
        selectedFile.s3_file_path.endsWith("png") ||
        selectedFile.s3_file_path.endsWith("jpg") ||
        selectedFile.s3_file_path.endsWith("jpeg")
      ) {
        if (await this.hasMarkup(selectedFile)) {
          this.showSharedSiteMarkupViewerDialog = true;
        } else {
          this.showImageViewerDialog = true;
        }
      } else {
        if (await this.hasMarkup(selectedFile)) {
          this.showSharedSiteMarkupViewerDialog = true;
        } else {
          this.showSharedSitePdfViewerDialog = true;
        }
      }
      this.selectedFile = selectedFile;
    },
    async onMarkupClick(selectedFile) {
      await this.getFiles();
      this.resizeImages();
      await this.$nextTick();
      this.selectedFile = cloneDeep(
        this.files.find((f) => f.file_id === selectedFile.file_id)
      );
      this.showConvertingMarkupDialog = false;
      this.showSharedSiteMarkupImageDialog = false;
      this.showSharedSiteConvertMarkupDialog = false;
      if (this.selectedFile.s3_file_path.endsWith("pdf")) {
        if (this.selectedFile.create_image_from_pdf_status === "processing") {
          this.showConvertingMarkupDialog = true;
        } else if (
          this.selectedFile.create_image_from_pdf_status === "completed_success"
        ) {
          this.showSharedSiteMarkupImageDialog = true;
        } else {
          this.showSharedSiteConvertMarkupDialog = true;
        }
      } else {
        this.showSharedSiteMarkupImageDialog = true;
      }
    },
    async onFilesUploaded() {
      this.showSharedSiteAddFilesDialog = false;
      await this.getFolders();
      await this.getFiles();
      this.resizeImages();
    },
    async deleteFile(fileId) {
      this.showConfirmFileDeleteDialog = false;
      await axiosWithJwtAuth.delete(
        `${APIURL}/shared_site/files/${fileId}/${this.$route.query.siteId}`
      );
      await this.getFolders();
      await this.getFiles();
      this.resizeImages();
      this.idOfFileToDelete = undefined;
    },
    async onNavigateToFolder(index) {
      if (typeof index === "undefined") {
        this.currentFolder = undefined;
        this.level = 1;
        await this.getFiles();
        this.resizeImages();
        return;
      }
      this.currentFolder = this.currentFolder
        ?.split("/")
        ?.slice(0, index + 1)
        ?.join("/");
      this.level = index + 2;
      await this.getFiles();
    },
    async onFolderClick(folderName) {
      this.level++;
      this.currentFolder = folderName;
      await this.getFiles();
      this.resizeImages();
    },
    async getFolders() {
      const { objectId, globalId } = this;
      if (!this.selectedMapServiceId || !objectId || !globalId) {
        return;
      }
      const {
        data: { results },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/map_services/${this.selectedMapServiceId}/features/${globalId}/folders/${this.$route.query.siteId}`,
        {
          params: {
            object_id: objectId,
          },
        }
      );
      this.folders = results;
    },
    async getFiles() {
      const { currentFolder, objectId, globalId, filterChoices, orderBy } =
        this;
      if (!this.selectedMapServiceId || !objectId || !globalId) {
        return;
      }
      const [{ selectedMatchChoice, selectedMatchValue } = {}] = filterChoices;
      const {
        data: { results },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/map_services/${this.selectedMapServiceId}/features/${globalId}/files/${this.$route.query.siteId}`,
        {
          params: {
            folder: currentFolder,
            object_id: objectId,
            created_on: selectedMatchValue,
            created_on_match_choice: selectedMatchChoice,
            order_by: orderBy,
          },
        }
      );
      this.files = results.filter((r) => !r.created_from_pdf_file_id);
    },
    async downloadFiles(downloadChoice) {
      const { currentFolder, objectId, globalId, filterChoices, orderBy } =
        this;
      const [{ selectedMatchChoice, selectedMatchValue } = {}] = filterChoices;
      clearInterval(this.downloadFileTimer);
      let params = {};
      if (downloadChoice === DOWNLOAD_CHOICES.ALL_FILES) {
        params = {
          folder: currentFolder,
          object_id: objectId,
        };
      } else {
        params = {
          folder: currentFolder,
          object_id: objectId,
          created_on: selectedMatchValue,
          created_on_match_choice: selectedMatchChoice,
          order_by: orderBy,
        };
      }

      if (!this.selectedMapServiceId || !objectId) {
        return;
      }
      const {
        data: {
          results: { file_id: fileId },
        },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/map_services/${this.selectedMapServiceId}/features/${globalId}/files/download/${this.$route.query.siteId}`,
        {
          params,
        }
      );
      this.downloadFileTimer = setInterval(async () => {
        try {
          const { data } = await axiosWithJwtAuth.get(
            `${APIURL}/shared_site/files/big_job/${fileId}`,
            {
              responseType: "blob",
            }
          );
          const dataURL = window.URL.createObjectURL(data);
          const link = document.createElement("a");
          link.download = `files`;
          link.href = dataURL;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          link.remove();
          clearInterval(this.downloadFileTimer);
          this.downloadFileTimer = undefined;
        } catch (error) {
          console.log(error);
        }
      }, 5000);
    },
    getCanDelete(file) {
      return file.created_by === this.currentUser.user_id;
    },
  },
  watch: {
    async globalId() {
      await this.getFolders();
      await this.getFiles();
      this.resizeImages();
    },
    async objectId() {
      await this.getFolders();
      await this.getFiles();
      this.resizeImages();
    },
    async selectedMapServiceId() {
      await this.getFolders();
      await this.getFiles();
      this.resizeImages();
    },
    async loadingDocs(val) {
      if (!val) {
        await this.$nextTick();
        this.docsTabHeight = window.innerHeight * 0.7 - 750;
      }
    },
  },
};
</script>

<style scoped>
#menu >>> ul {
  list-style-type: none;
  padding-left: 5px;
  cursor: pointer;
}

.bold {
  font-weight: 600;
}
</style>
