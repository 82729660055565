var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isOnline
        ? [
            _vm.loadingDocs
              ? _c(
                  "div",
                  { staticClass: "d-flex justify-center align-center" },
                  [_c("v-progress-circular", { attrs: { indeterminate: "" } })],
                  1
                )
              : [
                  _vm.showSharedSiteAddFilesDialog
                    ? _c("SharedSiteAddFilesDialog", {
                        attrs: {
                          currentFolder: _vm.currentFolder,
                          showSharedSiteAddFilesDialog:
                            _vm.showSharedSiteAddFilesDialog,
                          selectedMapServiceId: _vm.selectedMapServiceId,
                          globalId: _vm.globalId,
                          objectId: _vm.objectId
                        },
                        on: {
                          "add-files-dialog-close": function($event) {
                            _vm.showSharedSiteAddFilesDialog = false
                          },
                          "add-files-dialog-uploaded": _vm.onFilesUploaded
                        }
                      })
                    : _vm._e(),
                  _c("FilesFilterDialog", {
                    attrs: {
                      showFilesFilterDialog: _vm.showFilesFilterDialog,
                      savedFilterChoices: _vm.filterChoices
                    },
                    on: {
                      "files-filter-dialog-close": function($event) {
                        _vm.showFilesFilterDialog = false
                      },
                      "update-filter": _vm.onUpdateFilter
                    }
                  }),
                  _c(
                    "section",
                    { staticClass: "d-flex align-center" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", color: "#3F51B5" },
                          on: {
                            click: function($event) {
                              _vm.showFilesFilterDialog = true
                            }
                          }
                        },
                        [
                          _c("v-icon", [_vm._v(" " + _vm._s(_vm.mdiFilter))]),
                          _vm._v(" Filter ")
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex flex-wrap gap" },
                        _vm._l(_vm.filterChoices, function(f) {
                          return _c(
                            "v-chip",
                            {
                              key: f.selectedField,
                              attrs: { close: "" },
                              on: {
                                "click:close": function($event) {
                                  return _vm.removeFilter(f.selectedField)
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("filesFieldMap")(f.selectedField)
                                  ) +
                                  " "
                              ),
                              _c("MatchIcon", {
                                staticClass: "mx-2",
                                attrs: { value: f.selectedMatchChoice }
                              }),
                              _vm._v(" " + _vm._s(f.selectedMatchValue) + " ")
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c("DocsBreadcrumb", {
                    staticClass: "py-2 px-4",
                    attrs: { folderPath: _vm.currentFolder },
                    on: { "navigate-folder": _vm.onNavigateToFolder }
                  }),
                  _c("v-divider", { staticClass: "my-2" }),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-end px-5" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "px-0 mx-0",
                          attrs: { text: "", color: "#3F51B5" },
                          on: {
                            click: function($event) {
                              return _vm.onAddFilesButtonClick()
                            }
                          }
                        },
                        [_vm._v(" + Add Files ")]
                      ),
                      _c(
                        "v-menu",
                        {
                          staticClass: "px-0 mx-0",
                          attrs: { "offset-y": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function(ref) {
                                  var on = ref.on
                                  var attrs = ref.attrs
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { icon: "" } },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("v-icon", [
                                          _vm._v(_vm._s(_vm.mdiDotsVertical))
                                        ])
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1924150619
                          )
                        },
                        [
                          _c(
                            "v-list",
                            {
                              staticClass: "py-0 my-0",
                              attrs: { width: "170px" }
                            },
                            [
                              _c(
                                "v-list-item",
                                {
                                  staticClass: "pa-3 mx-0 d-block",
                                  attrs: { id: "menu" }
                                },
                                [
                                  _c("div", { staticClass: "caption bold" }, [
                                    _vm._v("Download")
                                  ]),
                                  _c("ul", [
                                    _c(
                                      "li",
                                      {
                                        staticClass: "py-2",
                                        style: Object.assign(
                                          {},
                                          _vm.getLinkColorStyle(
                                            _vm.DOWNLOAD_CHOICES.ALL_FILES
                                          ),
                                          _vm.getLinkBackgroundColorStyle(
                                            _vm.DOWNLOAD_CHOICES.ALL_FILES
                                          )
                                        ),
                                        on: {
                                          mouseenter: function($event) {
                                            _vm.highlightedChoice =
                                              _vm.DOWNLOAD_CHOICES.ALL_FILES
                                          },
                                          mouseleave: function($event) {
                                            _vm.highlightedChoice = undefined
                                          },
                                          click: function($event) {
                                            return _vm.downloadFiles(
                                              _vm.DOWNLOAD_CHOICES.ALL_FILES
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" All Files and Folders ")]
                                    )
                                  ]),
                                  _c("div", { staticClass: "caption bold" }, [
                                    _vm._v("Sort By")
                                  ]),
                                  _c("ul", [
                                    _c(
                                      "li",
                                      {
                                        staticClass: "py-2",
                                        style: Object.assign(
                                          {},
                                          _vm.getLinkColorStyle(
                                            _vm.ORDER_BY_CHOICES.NEWEST_FIRST
                                          ),
                                          _vm.getLinkBackgroundColorStyle(
                                            _vm.ORDER_BY_CHOICES.NEWEST_FIRST
                                          )
                                        ),
                                        on: {
                                          mouseenter: function($event) {
                                            _vm.highlightedChoice =
                                              _vm.ORDER_BY_CHOICES.NEWEST_FIRST
                                          },
                                          mouseleave: function($event) {
                                            _vm.highlightedChoice = undefined
                                          },
                                          click: function($event) {
                                            return _vm.orderFiles(
                                              _vm.ORDER_BY_CHOICES.NEWEST_FIRST
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" Newest First ")]
                                    ),
                                    _c(
                                      "li",
                                      {
                                        staticClass: "py-2",
                                        style: Object.assign(
                                          {},
                                          _vm.getLinkColorStyle(
                                            _vm.ORDER_BY_CHOICES.OLDEST_FIRST
                                          ),
                                          _vm.getLinkBackgroundColorStyle(
                                            _vm.ORDER_BY_CHOICES.OLDEST_FIRST
                                          )
                                        ),
                                        on: {
                                          mouseenter: function($event) {
                                            _vm.highlightedChoice =
                                              _vm.ORDER_BY_CHOICES.OLDEST_FIRST
                                          },
                                          mouseleave: function($event) {
                                            _vm.highlightedChoice = undefined
                                          },
                                          click: function($event) {
                                            return _vm.orderFiles(
                                              _vm.ORDER_BY_CHOICES.OLDEST_FIRST
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" Oldest First ")]
                                    ),
                                    _c(
                                      "li",
                                      {
                                        staticClass: "py-2",
                                        style: Object.assign(
                                          {},
                                          _vm.getLinkColorStyle(
                                            _vm.ORDER_BY_CHOICES.NAME_ASC
                                          ),
                                          _vm.getLinkBackgroundColorStyle(
                                            _vm.ORDER_BY_CHOICES.NAME_ASC
                                          )
                                        ),
                                        on: {
                                          mouseenter: function($event) {
                                            _vm.highlightedChoice =
                                              _vm.ORDER_BY_CHOICES.NAME_ASC
                                          },
                                          mouseleave: function($event) {
                                            _vm.highlightedChoice = undefined
                                          },
                                          click: function($event) {
                                            return _vm.orderFiles(
                                              _vm.ORDER_BY_CHOICES.NAME_ASC
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" Name A - Z ")]
                                    ),
                                    _c(
                                      "li",
                                      {
                                        staticClass: "py-2",
                                        style: Object.assign(
                                          {},
                                          _vm.getLinkColorStyle(
                                            _vm.ORDER_BY_CHOICES.NAME_DESC
                                          ),
                                          _vm.getLinkBackgroundColorStyle(
                                            _vm.ORDER_BY_CHOICES.NAME_DESC
                                          )
                                        ),
                                        on: {
                                          mouseenter: function($event) {
                                            _vm.highlightedChoice =
                                              _vm.ORDER_BY_CHOICES.NAME_DESC
                                          },
                                          mouseleave: function($event) {
                                            _vm.highlightedChoice = undefined
                                          },
                                          click: function($event) {
                                            return _vm.orderFiles(
                                              _vm.ORDER_BY_CHOICES.NAME_DESC
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" Name Z - A ")]
                                    ),
                                    _c(
                                      "li",
                                      {
                                        staticClass: "py-2",
                                        style: Object.assign(
                                          {},
                                          _vm.getLinkColorStyle(
                                            _vm.ORDER_BY_CHOICES.SMALLEST_FIRST
                                          ),
                                          _vm.getLinkBackgroundColorStyle(
                                            _vm.ORDER_BY_CHOICES.SMALLEST_FIRST
                                          )
                                        ),
                                        on: {
                                          mouseenter: function($event) {
                                            _vm.highlightedChoice =
                                              _vm.ORDER_BY_CHOICES.SMALLEST_FIRST
                                          },
                                          mouseleave: function($event) {
                                            _vm.highlightedChoice = undefined
                                          },
                                          click: function($event) {
                                            return _vm.orderFiles(
                                              _vm.ORDER_BY_CHOICES
                                                .SMALLEST_FIRST
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" Smallest First ")]
                                    ),
                                    _c(
                                      "li",
                                      {
                                        staticClass: "py-2",
                                        style: Object.assign(
                                          {},
                                          _vm.getLinkColorStyle(
                                            _vm.ORDER_BY_CHOICES.LARGEST_FIRST
                                          ),
                                          _vm.getLinkBackgroundColorStyle(
                                            _vm.ORDER_BY_CHOICES.LARGEST_FIRST
                                          )
                                        ),
                                        on: {
                                          mouseenter: function($event) {
                                            _vm.highlightedChoice =
                                              _vm.ORDER_BY_CHOICES.LARGEST_FIRST
                                          },
                                          mouseleave: function($event) {
                                            _vm.highlightedChoice = undefined
                                          },
                                          click: function($event) {
                                            return _vm.orderFiles(
                                              _vm.ORDER_BY_CHOICES.LARGEST_FIRST
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" Largest First ")]
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.foldersAtLevel.length > 0 || _vm.files.length > 0
                    ? [
                        _c(
                          "section",
                          {
                            staticClass: "overflow-y-auto",
                            style: {
                              height: _vm.$vuetify.breakpoint.lgAndUp
                                ? _vm.docsTabHeight + "px"
                                : "auto"
                            }
                          },
                          [
                            _vm._l(_vm.foldersAtLevel, function(f) {
                              return _c(
                                "div",
                                {
                                  key: f.folder,
                                  staticClass:
                                    "px-4 d-flex gap align-center py-1 cursor-pointer",
                                  style: _vm.getFileBackgroundColorStyle(
                                    f.folder
                                  ),
                                  on: {
                                    click: function($event) {
                                      return _vm.onFolderClick(f.folder)
                                    },
                                    mouseenter: function($event) {
                                      _vm.highlightedFileName = f.folder
                                    },
                                    mouseleave: function($event) {
                                      _vm.highlightedFileName = undefined
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "#3F51B5" } },
                                        [
                                          _vm._v(
                                            " " + _vm._s(_vm.mdiFolder) + " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "d-inline-block text-truncate",
                                      staticStyle: { "max-width": "100%" },
                                      style: { color: "#00A9F4" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            f.folder.split("/").slice(-1)[0]
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              )
                            }),
                            _vm._l(_vm.files, function(f) {
                              return _c(
                                "div",
                                {
                                  key: f.file_id,
                                  staticClass:
                                    "px-4 d-flex gap align-center py-1 cursor-pointer",
                                  style: _vm.getFileBackgroundColorStyle(
                                    f.file_id
                                  ),
                                  on: {
                                    click: function($event) {
                                      return _vm.onViewButtonClick(f)
                                    },
                                    mouseenter: function($event) {
                                      _vm.highlightedFileName = f.file_id
                                    },
                                    mouseleave: function($event) {
                                      _vm.highlightedFileName = undefined
                                    }
                                  }
                                },
                                [
                                  _c("div", { staticStyle: { width: "80%" } }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-center text-truncate"
                                      },
                                      [
                                        f.s3_file_path.endsWith("png") ||
                                        f.s3_file_path.endsWith("jpg") ||
                                        f.s3_file_path.endsWith("jpeg")
                                          ? _c("img", {
                                              staticStyle: {
                                                width: "20px",
                                                "margin-right": "16px"
                                              },
                                              attrs: {
                                                src:
                                                  _vm.resizedImages[
                                                    f.file_id
                                                  ] || f.s3_file_path
                                              }
                                            })
                                          : _c(
                                              "v-icon",
                                              { staticClass: "mr-3" },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.mdiFile) + " "
                                                )
                                              ]
                                            ),
                                        _c(
                                          "section",
                                          { staticClass: "text-truncate" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "text-truncate py-0 my-0",
                                                staticStyle: {
                                                  "max-width": "100%"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(f.name) + " "
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "caption py-0 my-0"
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("formatDate")(
                                                        f.created_on
                                                      )
                                                    ) +
                                                    " - " +
                                                    _vm._s(
                                                      (
                                                        f.file_size_kb / 1000
                                                      ).toFixed(2)
                                                    ) +
                                                    "M "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          attrs: { "offset-y": "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  var attrs = ref.attrs
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: { icon: "" }
                                                          },
                                                          "v-btn",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.mdiDotsVertical
                                                            )
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [
                                          _c(
                                            "v-list",
                                            { staticClass: "py-0 my-0" },
                                            [
                                              f.s3_file_path.endsWith("png") ||
                                              f.s3_file_path.endsWith("jpg") ||
                                              f.s3_file_path.endsWith("jpeg") ||
                                              f.s3_file_path.endsWith("pdf")
                                                ? _c(
                                                    "v-list-item",
                                                    {
                                                      staticClass:
                                                        "px-3 mx-0 d-flex align-center",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.onViewButtonClick(
                                                            f
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.mdiFile
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      ),
                                                      _c("span", [
                                                        _vm._v(" View ")
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              f.s3_file_path.endsWith("png") ||
                                              f.s3_file_path.endsWith("jpg") ||
                                              f.s3_file_path.endsWith("jpeg") ||
                                              f.s3_file_path.endsWith("pdf")
                                                ? _c(
                                                    "v-list-item",
                                                    {
                                                      staticClass:
                                                        "px-3 mx-0 d-flex align-center",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.onMarkupClick(
                                                            f
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.mdiFileEdit
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      ),
                                                      _c("span", [
                                                        _vm._v(" Markup ")
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.getCanDelete(f)
                                                ? _c(
                                                    "v-list-item",
                                                    {
                                                      staticClass: "px-3 mx-0",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.idOfFileToDelete =
                                                            f.file_id
                                                          _vm.showConfirmFileDeleteDialog = true
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        { staticClass: "mr-2" },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.mdiDelete
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      ),
                                                      _c("span", [
                                                        _vm._v(" Delete ")
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            })
                          ],
                          2
                        ),
                        _vm.showSharedSiteMarkupImageDialog
                          ? _c("SharedSiteMarkupImageDialog", {
                              attrs: {
                                showSharedSiteMarkupImageDialog:
                                  _vm.showSharedSiteMarkupImageDialog,
                                selectedFile: _vm.selectedFile
                              },
                              on: {
                                "markup-image-dialog-close": function($event) {
                                  _vm.showSharedSiteMarkupImageDialog = false
                                  _vm.reloadFilesAndFolders()
                                }
                              }
                            })
                          : _vm._e(),
                        _vm.showSharedSitePdfViewerDialog
                          ? _c("SharedSitePdfViewerDialog", {
                              attrs: {
                                showSharedSitePdfViewerDialog:
                                  _vm.showSharedSitePdfViewerDialog,
                                selectedFile: _vm.selectedFile
                              },
                              on: {
                                "view-pdf-dialog-close": function($event) {
                                  _vm.showSharedSitePdfViewerDialog = false
                                }
                              }
                            })
                          : _vm._e(),
                        _vm.showImageViewerDialog
                          ? _c("ImageViewerDialog", {
                              attrs: {
                                showImageViewerDialog:
                                  _vm.showImageViewerDialog,
                                selectedFile: _vm.selectedFile
                              },
                              on: {
                                "view-image-dialog-close": function($event) {
                                  _vm.showImageViewerDialog = false
                                }
                              }
                            })
                          : _vm._e(),
                        _vm.showSharedSiteMarkupViewerDialog
                          ? _c("SharedSiteMarkupViewerDialog", {
                              attrs: {
                                showSharedSiteMarkupViewerDialog:
                                  _vm.showSharedSiteMarkupViewerDialog,
                                selectedFile: _vm.selectedFile
                              },
                              on: {
                                "markup-viewer-dialog-close": function($event) {
                                  _vm.showSharedSiteMarkupViewerDialog = false
                                  _vm.reloadFilesAndFolders()
                                }
                              }
                            })
                          : _vm._e(),
                        _vm.showSharedSiteConvertMarkupDialog
                          ? _c("SharedSiteConvertMarkupDialog", {
                              attrs: {
                                showSharedSiteConvertMarkupDialog:
                                  _vm.showSharedSiteConvertMarkupDialog,
                                selectedFile: _vm.selectedFile
                              },
                              on: {
                                "cancel-convert-markup-dialog-close": function(
                                  $event
                                ) {
                                  _vm.showSharedSiteConvertMarkupDialog = false
                                }
                              }
                            })
                          : _vm._e(),
                        _vm.showConvertingMarkupDialog
                          ? _c("ConvertingMarkupDialog", {
                              attrs: {
                                showConvertingMarkupDialog:
                                  _vm.showConvertingMarkupDialog
                              },
                              on: {
                                "cancel-converting-markup-dialog-close": function(
                                  $event
                                ) {
                                  _vm.showConvertingMarkupDialog = false
                                }
                              }
                            })
                          : _vm._e(),
                        _c("ConfirmFileDeleteDialog", {
                          attrs: {
                            showConfirmFileDeleteDialog:
                              _vm.showConfirmFileDeleteDialog
                          },
                          on: {
                            cancel: function($event) {
                              _vm.showConfirmFileDeleteDialog = false
                            },
                            delete: function($event) {
                              return _vm.deleteFile(_vm.idOfFileToDelete)
                            }
                          }
                        })
                      ]
                    : _c(
                        "div",
                        { staticClass: "d-flex justify-center align-center" },
                        [_vm._v(" No Documents Added ")]
                      )
                ]
          ]
        : _c("div", { staticClass: "text-center pa-5" }, [
            _c(
              "p",
              { staticClass: "text-center" },
              [
                _c("v-icon", [_vm._v(" " + _vm._s(_vm.mdiWifiOff) + " ")]),
                _vm._v(" Offline ")
              ],
              1
            ),
            _c("p", { staticClass: "text-center" }, [
              _vm._v(
                " The device must be connected to the internet internet to show Docs. "
              )
            ])
          ]),
      _c("SharedSiteNotLicensedDialog", {
        attrs: {
          showSharedSiteNotLicensedDialog: _vm.showSharedSiteNotLicensedDialog,
          currentUserOrg: _vm.currentUserOrg
        },
        on: {
          close: function($event) {
            _vm.showSharedSiteNotLicensedDialog = false
          }
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }