<template>
  <div class="py-1" :class="{ background: inRightPane }">
    <section class="px-5 py-3 d-flex justify-space-between align-center">
      <div :class="{ background: inRightPane }">
        <section v-if="mapServicesOfNonLocateRequestChoices.length > 1">
          <v-select
            label="Layer"
            :items="mapServicesOfNonLocateRequestChoices"
            item-value="value"
            item-text="name"
            name="layer"
            v-model="selectedLayer"
            @change="$emit('layer-selected', selectedLayer)"
            style="max-width: 200px; width: 200px"
          />
        </section>

        <section>
          <v-select
            v-model="formDefinitionId"
            :items="formDefinitionsOfSelectedMapServiceChoices"
            label="Report Form"
            hide-details="auto"
            name="formDef"
            item-value="value"
            item-text="name"
            :disabled="!selectedLayer"
            style="max-width: 200px; width: 200px"
          >
            <template #no-data>
              <div class="px-5 py-3">
                No report forms configured for this layer
              </div>
            </template>
          </v-select>
        </section>
      </div>

      <div :class="{ background: inRightPane }" class="pl-4">
        <v-btn
          color="#3F51B5"
          @click="onFabClick"
          :disabled="!formDefinitionId"
          :dark="Boolean(formDefinitionId)"
          fab
          small
        >
          <v-icon>
            {{ mdiNotePlusOutline }}
          </v-icon>
        </v-btn>
      </div>
    </section>

    <v-dialog
      v-model="showEditDialog"
      max-width="600"
      close
      persistent
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <SharedSiteTicketEditForm
          v-if="showEditDialog"
          :selectedGisInfo="selectedGisInfoObj"
          :selectedMapServiceId="selectedMapServiceId"
          :formDefinitionId="formDefinitionId"
          :globalId="globalId"
          :objectId="objectId"
          @ticket-edit-form-close-button-click="
            onTicketEditFormCloseButtonClick
          "
          @ticket-edit-form-close="
            showEditDialog = false;
            $emit('ticket-edit-form-close');
          "
          @ticket-edit-form-submitted="
            showEditDialog = false;
            $emit('ticket-edit-form-submitted');
          "
          @ticket-edit-form-error="
            showErrorDialog = true;
            errMsg = $event;
          "
          @form-result-deleted="showEditDialog = false"
        />
      </v-card>
    </v-dialog>

    <v-dialog v-model="showErrorDialog" max-width="600px" close>
      <v-card>
        <v-card-title> Error </v-card-title>
        <v-card-text>
          {{ errMsg }}
        </v-card-text>
      </v-card>
    </v-dialog>

    <ViewOnlyUserDialog
      :showViewOnlyUserDialog="showViewOnlyUserDialog"
      @view-only-dialog-close="showViewOnlyUserDialog = false"
    />

    <UnsavedChangesDialog
      :showUnsavedChangesWarningDialog="showUnsavedChangesWarningDialog"
      @close-and-lose-changes="onCloseAndLoseChanges"
      @return-to-form="showUnsavedChangesWarningDialog = false"
    />

    <SharedSiteNotLicensedDialog
      :showSharedSiteNotLicensedDialog="showSharedSiteNotLicensedDialog"
      :currentUserOrg="currentUserOrg"
      @close="showSharedSiteNotLicensedDialog = false"
    />

    <SharedSiteChooseTaskDialog
      v-if="showChooseTaskDialog"
      :showChooseTaskDialog="showChooseTaskDialog"
      :selectedMapServiceId="selectedMapServiceId"
      :objectId="objectId"
      :globalId="globalId"
      :formDefinitionId="formDefinitionId"
      @choose-task-dialog-close="showChooseTaskDialog = false"
      @task-selected="onTaskSelected"
      @start-new-form="onStartNewForm"
    />
  </div>
</template>

<script>
import SharedSiteTicketEditForm from "@/components/shared-site/shared-site-ticket-form-def-drop-down/SharedSiteTicketEditForm.vue";
import { mdiNotePlusOutline } from "@mdi/js";
import ViewOnlyUserDialog from "@/components/tickets/ticket-form-def-dropdown/ViewOnlyUserDialog";
import UnsavedChangesDialog from "@/components/tickets/ticket-log/UnsavedChangesDialog";
import { axiosWithJwtAuth } from "@/plugins/axios";
import SharedSiteNotLicensedDialog from "@/components/shared-site/shared/SharedSiteNotLicensedDialog";
import SharedSiteChooseTaskDialog from "@/components/shared-site/shared-site-ticket-form-def-drop-down/SharedSiteChooseTaskDialog";
import { mapMutations } from "vuex";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "SharedSiteTicketFormDefDropdown",
  props: {
    selectedGisInfoObj: Object,
    selectedMapServiceId: String,
    objectId: Number,
    globalId: String,
    inRightPane: Boolean,
    mapServices: Array,
  },
  async beforeMount() {
    this.formDefinitionId = localStorage.getItem("selected-form-definition-id");
    await this.getUserGroup();
  },
  components: {
    SharedSiteTicketEditForm,
    ViewOnlyUserDialog,
    UnsavedChangesDialog,
    SharedSiteNotLicensedDialog,
    SharedSiteChooseTaskDialog,
  },
  computed: {
    selectedGisInfo() {
      return this.selectedGisInfoObj;
    },
    mapServicesOfNonLocateRequestChoices() {
      const { selectedMapServiceId, globalId } = this;
      return [{ value: selectedMapServiceId, name: globalId }];
    },
    formDefinitionsOfSelectedMapServiceChoices() {
      if (!Array.isArray(this.formDefinitionsOfSelectedMapService)) {
        return [];
      }
      return this.formDefinitionsOfSelectedMapService
        .filter((f) => {
          return f.site_contact_enabled;
        })
        .map(({ form_definition_id: formDefinitionId, title }) => {
          return {
            value: formDefinitionId,
            name: title,
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    mapServicesOfNonLocateRequest() {
      return this.mapServices;
    },
  },
  data() {
    return {
      formDefinitionId: "",
      showEditDialog: false,
      formDefinitionsOfSelectedMapService: [],
      selectedLayer: undefined,
      mdiNotePlusOutline,
      showErrorDialog: false,
      errMsg: "",
      showViewOnlyUserDialog: false,
      showUnsavedChangesWarningDialog: false,
      currentUserOrg: {},
      showSharedSiteNotLicensedDialog: false,
      showChooseTaskDialog: false,
    };
  },
  methods: {
    ...mapMutations(["setDynamicFormProps"]),
    async onStartNewForm() {
      this.showChooseTaskDialog = false;
      this.showEditDialog = true;
      localStorage.setItem(
        "selected-form-definition-id",
        this.formDefinitionId
      );
    },
    async onTaskSelected(selectedTask) {
      this.selectedTask = selectedTask;
      const { form_definition_id: formDefinitionId } = selectedTask;
      this.showChooseTaskDialog = false;
      this.showEditDialog = true;
      localStorage.setItem("selected-form-definition-id", formDefinitionId);
    },
    async getUserGroup() {
      if (!this.$route.query.siteId) {
        return;
      }
      const {
        data: { results: currentUserOrg },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/user_group/${this.$route.query.siteId}`
      );
      this.currentUserOrg = currentUserOrg;
    },
    onCloseAndLoseChanges() {
      this.showUnsavedChangesWarningDialog = false;
      this.showEditDialog = false;
    },
    onTicketEditFormCloseButtonClick(hasUnsavedChanges) {
      if (hasUnsavedChanges) {
        this.showUnsavedChangesWarningDialog = true;
      } else {
        this.showEditDialog = false;
      }
    },
    async onFabClick() {
      const {
        data: { results: currentUser },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/users/one/${this.$route.query.siteId}`
      );

      if (currentUser?.is_view_only_user) {
        this.showViewOnlyUserDialog = true;
        return;
      }

      if (!this.currentUserOrg?.paying_for_linked_sites) {
        this.showSharedSiteNotLicensedDialog = true;
        return;
      }

      const { objectId, selectedMapServiceId, formDefinitionId } = this;
      if (!objectId || !selectedMapServiceId || !formDefinitionId) {
        return;
      }
      const {
        data: { results },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/action_item_and_tasks/${this.$route.query.siteId}`,
        {
          params: {
            map_service_id: selectedMapServiceId,
            object_id: objectId,
          },
        }
      );
      this.tasks = results;
      const filteredTasks = this.tasks.filter(
        (t) =>
          t.status.toLowerCase() !== "closed" &&
          t.status.toLowerCase() !== "canceled" &&
          t.status.toLowerCase() !== "cancelled" &&
          t.form_definition_id === formDefinitionId
      );
      if (filteredTasks.length > 0) {
        this.showChooseTaskDialog = true;
      } else {
        this.showEditDialog = true;
      }
      this.$emit("show-edit-dialog");
    },
  },
  watch: {
    selectedLayer: {
      immediate: true,
      async handler(val) {
        if (!val) {
          return;
        }

        const {
          data: { results },
        } = await axiosWithJwtAuth.get(
          `${APIURL}/shared_site/map_services/${val}/${this.$route.query.siteId}`
        );
        if (!Array.isArray(results?.forms)) {
          return;
        }
        this.formDefinitionsOfSelectedMapService = [...results.forms];
      },
    },
    mapServicesOfNonLocateRequestChoices: {
      deep: true,
      immediate: true,
      handler(val) {
        if (Array.isArray(val) && val.length === 1) {
          const [selectedLayer] = val;
          this.selectedLayer = selectedLayer.value;
        }
      },
    },
  },
};
</script>
