<template>
  <div :class="{ background: inRightPane }">
    <div
      v-if="loading"
      class="d-flex justify-center align-center"
      style="overflow: hidden"
      :class="{ background: inRightPane }"
    >
      <v-progress-circular indeterminate></v-progress-circular>
    </div>
    <template v-else>
      <v-list
        class="overflow-y-auto"
        :style="{
          height: $vuetify.breakpoint.lgAndUp ? '15vh' : undefined,
        }"
        v-if="sharedSiteContacts.length > 0"
      >
        <v-list-item
          v-for="c of sharedSiteContacts"
          :key="`${c.site_id}-${c.site_contact_id}`"
        >
          <v-list-item-content>
            <h3>{{ c.f_name }} {{ c.l_name }}</h3>
            <section>{{ c.title }}</section>
            <section>{{ c.company }}</section>
            <section>
              <a :href="`mailto:${c.email}`">{{ c.email }}</a>
            </section>
            <section>
              <a :href="`tel:${c.phone_no}`">{{ c.phone_no }}</a>
            </section>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div v-else class="d-flex justify-center align-center">
        No Site Contacts Available
      </div>
    </template>
  </div>
</template>

<script>
import { axiosWithJwtAuth } from "@/plugins/axios";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "SharedSiteSiteContacts",
  data() {
    return {
      sharedSiteContacts: [],
      loading: true,
      currentUser: {},
    };
  },
  props: {
    selectedMapServiceId: String,
    objectId: Number,
    inRightPane: Boolean,
  },
  methods: {
    async getUserData() {
      if (this.$route.query.siteId) {
        const {
          data: { results },
        } = await axiosWithJwtAuth.get(
          `${APIURL}/shared_site/users/one/${this.$route.query.siteId}`
        );
        this.currentUser = results;
      }
    },
    async getSiteContacts() {
      this.loading = true;
      const { selectedMapServiceId, objectId } = this;
      const {
        data: { results },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/shared_site_contacts/${this.$route.query.siteId}`,
        {
          params: {
            map_service_id: selectedMapServiceId,
            feature_id: objectId,
          },
        }
      );
      this.sharedSiteContacts = results.filter(
        (r) => r.email !== this.currentUser.email
      );
      this.loading = false;
    },
  },
  async beforeMount() {
    await this.getUserData();
    await this.getSiteContacts();
  },
};
</script>
