var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "template-editor" },
    [
      _vm.editor
        ? _c(
            "section",
            {
              staticClass:
                "\n      d-flex\n      align-center\n      flex-wrap\n      border\n      px-2\n      py-1\n      justify-space-between\n    ",
              staticStyle: { "background-color": "#e3e3e3" }
            },
            [
              _c(
                "div",
                { staticClass: "d-flex flex-wrap flex-grow-0" },
                [
                  _c(
                    "v-btn",
                    {
                      class: { "is-active": _vm.editor.isActive("bold") },
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          _vm.editor
                            .chain()
                            .focus()
                            .toggleBold()
                            .run()
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v(_vm._s(_vm.mdiFormatBold))])],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      class: { "is-active": _vm.editor.isActive("italic") },
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          _vm.editor
                            .chain()
                            .focus()
                            .toggleItalic()
                            .run()
                        }
                      }
                    },
                    [_c("v-icon", [_vm._v(_vm._s(_vm.mdiFormatItalic))])],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      class: { "is-active": _vm.editor.isActive("underline") },
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          _vm.editor
                            .chain()
                            .focus()
                            .toggleUnderline()
                            .run()
                        }
                      }
                    },
                    [
                      _c("v-icon", [
                        _vm._v(" " + _vm._s(_vm.mdiFormatUnderline) + " ")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      class: { "is-active": _vm.editor.isActive("bulletList") },
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          _vm.editor
                            .chain()
                            .focus()
                            .toggleBulletList()
                            .run()
                        }
                      }
                    },
                    [
                      _c("v-icon", [
                        _vm._v(" " + _vm._s(_vm.mdiFormatListBulleted) + " ")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      class: {
                        "is-active": _vm.editor.isActive("orderedList")
                      },
                      attrs: { icon: "" },
                      on: {
                        click: function($event) {
                          _vm.editor
                            .chain()
                            .focus()
                            .toggleOrderedList()
                            .run()
                        }
                      }
                    },
                    [
                      _c("v-icon", [
                        _vm._v(" " + _vm._s(_vm.mdiFormatListNumbered) + " ")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "" },
                      on: { click: _vm.onLinkButtonClick }
                    },
                    [
                      _c("v-icon", [
                        _vm._v(" " + _vm._s(_vm.mdiLinkVariant) + " ")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-menu",
                {
                  attrs: { "offset-y": "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function(ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                _vm._b(
                                  { attrs: { dark: "", color: "#286054" } },
                                  "v-btn",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v(_vm._s(_vm.mdiPlus))
                                ]),
                                _vm._v(" Smart Field "),
                                _c("v-icon", [
                                  _vm._v(_vm._s(_vm.mdiChevronDown))
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3684974166
                  )
                },
                [
                  _c(
                    "v-list",
                    { staticClass: "pa-0 ma-0" },
                    _vm._l(_vm.smartFieldChoices, function(field) {
                      return _c(
                        "v-list-item",
                        {
                          key: field.value,
                          staticClass: "py-1 px-4 mx-0 justify-space-between",
                          on: {
                            click: function($event) {
                              return _vm.insertContent(field.value)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(field.label) + " ")]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("editor-content", {
        staticClass: "border",
        attrs: { editor: _vm.editor }
      }),
      Array.isArray(_vm.errors)
        ? _c("p", { staticClass: "caption red--text" }, [
            _vm._v(" " + _vm._s(_vm.errors[0]) + " ")
          ])
        : _vm._e(),
      _vm.showUrlEditorDialog
        ? _c("SharedSiteUrlEditorDialog", {
            attrs: { showUrlEditorDialog: _vm.showUrlEditorDialog },
            on: { input: _vm.setLink },
            model: {
              value: _vm.previousUrl,
              callback: function($$v) {
                _vm.previousUrl = $$v
              },
              expression: "previousUrl"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }