<template>
  <v-card
    flat
    class="rounded-0 pdf-list d-flex flex-column"
    height="100vh"
    width="150px"
  >
    <v-toolbar dark class="d-block rounded-0 flex-grow-0" height="56px">
      <v-toolbar-title class="d-flex flex-grow-1">
        <div class="d-flex justify-end align-center flex-grow-1">
          <v-btn icon @click="$emit('markup-pdf-list-close')">
            <v-icon>{{ mdiClose }}</v-icon>
          </v-btn>
        </div>
      </v-toolbar-title>
    </v-toolbar>

    <v-card-text class="pdf-list flex-grow-1 overflow-y-auto">
      <div class="d-flex align-center justify-center" v-if="isLoading">
        <v-progress-circular indeterminate color="white"> </v-progress-circular>
      </div>
      <div v-else>
        <div class="d-flex justify-center align-center">
          <validation-observer ref="form" slim>
            <form @submit.prevent>
              <validation-provider name="Page" rules="numeric">
                <v-text-field
                  v-model.number="page"
                  style="width: 60px"
                  class="page-input py-0 my-0"
                  @input="onPageChange"
                  dark
                >
                  <template #append-outer>
                    <div style="margin-top: 6px; width: 30px">
                      of {{ pdfPageImages.length }}
                    </div>
                  </template>
                </v-text-field>
              </validation-provider>
            </form>
          </validation-observer>
        </div>

        <div class="d-flex flex-wrap justify-center">
          <img
            :style="{
              outline: index + 1 === page ? '6px solid #8AB4F8' : 'none',
              opacity: index + 1 !== page ? '0.2' : 1,
            }"
            v-for="(p, index) of pdfPageImages"
            :key="p.file_id"
            :src="p.thumbnail"
            style="width: 80px"
            class="my-5 cursor-pointer elevation-5"
            @click="
              $emit('page-selected', p);
              page = index + 1;
            "
          />
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiClose, mdiChevronDown } from "@mdi/js";
import { axiosWithJwtAuth } from "@/plugins/axios";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "SharedSiteMarkupPdfList",
  props: {
    showMarkupPdfDialog: Boolean,
    selectedFile: Object,
  },
  data() {
    return {
      mdiClose,
      mdiChevronDown,
      pdfPageImages: [],
      page: 1,
      isLoading: false,
    };
  },
  watch: {
    selectedFile: {
      deep: true,
      handler() {
        this.getPdfPageImages();
      },
    },
  },
  async beforeMount() {
    await this.getPdfPageImages();
  },
  methods: {
    async onPageChange() {
      const success = await this.$refs.form.validate();
      if (!success) {
        return;
      }

      if (this.pdfPageImages[this.page - 1]) {
        this.$emit("page-selected", this.pdfPageImages[this.page - 1]);
      }
    },
    async getPdfPageImages() {
      if (!this.selectedFile) {
        return;
      }
      this.isLoading = true;
      const { file_id: fileId } = this.selectedFile;
      const {
        data: { results },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/files/${fileId}/images/${this.$route.query.siteId}`
      );
      this.pdfPageImages = await Promise.all(
        results
          .sort((p1, p2) => p1.pdf_page_number - p2.pdf_page_number)
          .map(async (r) => {
            const { data } = await axiosWithJwtAuth.get(
              `${APIURL}/shared_site/files/${r.file_id}/resize`,
              {
                params: {
                  max_size: 128,
                },
              }
            );
            const thumbnail = `data:image/jpeg+png+svg+xml;base64,${data}`;
            return {
              ...r,
              thumbnail,
            };
          })
      );
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.page-input >>> input {
  text-align: right;
  padding-bottom: 1px;
  width: 20px;
}

.pdf-list {
  background-color: #38383d;
}
</style>
