import { render, staticRenderFns } from "./SharedSiteUrlEditorDialog.vue?vue&type=template&id=bdc0dc26&"
import script from "./SharedSiteUrlEditorDialog.vue?vue&type=script&lang=js&"
export * from "./SharedSiteUrlEditorDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VDialog,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bdc0dc26')) {
      api.createRecord('bdc0dc26', component.options)
    } else {
      api.reload('bdc0dc26', component.options)
    }
    module.hot.accept("./SharedSiteUrlEditorDialog.vue?vue&type=template&id=bdc0dc26&", function () {
      api.rerender('bdc0dc26', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/shared-site/shared-site-tasks-tab/shared/shared-site-notification-template-dialog/shared-site-notification-template-body-editor/SharedSiteUrlEditorDialog.vue"
export default component.exports