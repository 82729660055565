var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    { attrs: { value: _vm.showSharedSiteConvertMarkupDialog, width: "500px" } },
    [
      _vm.convertingPdf
        ? _c(
            "v-card",
            [
              _c("v-card-text", { staticClass: "py-4" }, [
                _c("p", { staticClass: "py-2" }, [
                  _vm._v(
                    " The file is being converted now. Close this dialog and try the MARKUP tool again in a few minutes. "
                  )
                ])
              ]),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-end" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "#3F51B5", dark: "" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("cancel-convert-markup-dialog-close")
                        }
                      }
                    },
                    [_vm._v(" Close ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "v-card",
            [
              _c("v-card-text", { staticClass: "py-4" }, [
                _c("p", { staticClass: "py-2" }, [
                  _vm._v(
                    " This PDF has not been converted to a markup format. Would you like to convert it so you can add markup to it? "
                  )
                ])
              ]),
              _c(
                "v-card-actions",
                { staticClass: "d-flex justify-end gap" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "#3F51B5" },
                      on: {
                        click: function($event) {
                          return _vm.$emit("cancel-convert-markup-dialog-close")
                        }
                      }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "#3F51B5", dark: "" },
                      on: { click: _vm.convertPdf }
                    },
                    [_vm._v(" Convert PDF ")]
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }