<template>
  <v-dialog :value="showSharedSiteAddTaskDialog" max-width="500px">
    <v-card>
      <v-toolbar dark class="elevation-0" width="100%" color="#3F51B5">
        <div>Schedule Task</div>
        <v-spacer />
        <v-btn icon @click="$emit('add-task-dialog-close')">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-toolbar>

      <form @submit.prevent="saveTask">
        <validation-observer ref="form">
          <v-card-text
            style="background-color: #fafafa; max-height: 60vh"
            class="overflow-y-auto"
          >
            <p class="caption">Task Info</p>
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <div class="half">
                      <validation-provider
                        v-slot="{ errors, valid }"
                        name="Form To Be Completed"
                        rules="required"
                      >
                        <v-select
                          v-model="task.formDefId"
                          :items="formChoices"
                          hide-details="auto"
                          name="form"
                          item-value="value"
                          item-text="name"
                          :error-messages="errors"
                          :success="valid"
                          label="Form To Be Completed *"
                        />
                      </validation-provider>
                    </div>
                  </v-col>

                  <v-col cols="12">
                    <div class="half">
                      <p class="caption my-0 py-0">Assigned To</p>
                      <p class="my-0 py-0">{{ currentUser.email }}</p>
                    </div>
                  </v-col>

                  <v-col cols="12">
                    <div class="half">
                      <validation-provider
                        v-slot="{ errors, valid }"
                        name="Due Date"
                        rules="required"
                      >
                        <v-menu>
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-on="on"
                              v-model="task.due"
                              :error-messages="errors"
                              :success="valid"
                              color="#3F51B5"
                              label="Due Date *"
                            >
                              <template #append>
                                <v-icon>
                                  {{ mdiCalendar }}
                                </v-icon>
                              </template>
                            </v-text-field>
                          </template>
                          <v-date-picker v-model="task.due"></v-date-picker>
                        </v-menu>
                      </validation-provider>
                    </div>
                  </v-col>

                  <v-col cols="12">
                    <validation-provider
                      v-slot="{ errors, valid }"
                      name="Description"
                    >
                      <v-text-field
                        v-model="task.description"
                        :error-messages="errors"
                        :success="valid"
                        label="Description"
                        color="#3F51B5"
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12">
                    <div class="d-flex align-end">
                      <div class="half">
                        <validation-provider
                          v-slot="{ errors, valid }"
                          name="Notification Template"
                        >
                          <v-select
                            v-model="task.taskNotificationTemplateId"
                            :items="taskNotificationTemplateChoices"
                            hide-details="auto"
                            name="notificationTemplate"
                            item-value="value"
                            item-text="name"
                            :error-messages="errors"
                            :success="valid"
                            label="Notification Template"
                            clearable
                            :disabled="!isOnline"
                          >
                          </v-select>
                        </validation-provider>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <SharedSiteNotificationTemplateDialog
              v-if="showNotificationTemplateDialog"
              :showNotificationTemplateDialog="showNotificationTemplateDialog"
              :assignTo="assignTo"
              @notification-template-dialog-close="
                showNotificationTemplateDialog = false
              "
              @notification-template-saved="
                showNotificationTemplateDialog = false;
                getTaskNotificationTemplates();
              "
            />

            <SharedSiteEditNotificationTemplateDialog
              v-if="showEditNotificationTemplateDialog"
              :selectedNotificationTemplate="selectedNotificationTemplate"
              :assignTo="assignTo"
              :showEditNotificationTemplateDialog="
                showEditNotificationTemplateDialog
              "
              @notification-template-dialog-close="
                showEditNotificationTemplateDialog = false
              "
              @notification-template-saved="
                showEditNotificationTemplateDialog = false;
                getTaskNotificationTemplates();
              "
            />
          </v-card-text>
          <v-card-actions class="d-flex justify-end py-3 px-4">
            <v-btn type="submit" color="#3F51B5" dark>Save Task</v-btn>
          </v-card-actions>
        </validation-observer>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mdiClose,
  mdiCalendar,
  mdiPencil,
  mdiDotsVertical,
  mdiPlus,
  mdiDelete,
} from "@mdi/js";
import { axiosWithJwtAuth } from "@/plugins/axios";
import {
  ASSIGN_TO,
  ASSIGN_TO_CHOICES,
  MANUALLY_ADD_EMAIL,
} from "@/constants/actionItemChoices";
import SharedSiteNotificationTemplateDialog from "@/components/shared-site/shared-site-tasks-tab/shared/SharedSiteNotificationTemplateDialog";
import SharedSiteEditNotificationTemplateDialog from "@/components/shared-site/shared-site-tasks-tab/shared/SharedSiteEditNotificationTemplateDialog";

const APIURL = process.env.VUE_APP_API_URL;

export default {
  name: "SharedSiteAddTaskDialog",
  props: {
    selectedMapServiceId: String,
    objectId: Number,
    globalId: String,
    showSharedSiteAddTaskDialog: Boolean,
    currentUser: Object,
  },
  components: {
    SharedSiteNotificationTemplateDialog,
    SharedSiteEditNotificationTemplateDialog,
  },
  computed: {
    sharedSiteContactsChoices() {
      return this.sharedSiteContacts.map((c) => {
        const { email, f_name: fName, l_name: lName, user_id: value } = c;
        return {
          name: `${fName} ${lName}`,
          email,
          value,
        };
      });
    },
    userChoices() {
      return this.users.map((u) => {
        const { f_name: fName, l_name: lName, user_id: value } = u;
        return {
          name: `${fName} ${lName}`,
          value,
        };
      });
    },
    formChoices() {
      return this.forms.map((f) => {
        const { title, form_definition_id: formDefId } = f;
        return {
          name: title,
          value: formDefId,
        };
      });
    },
    taskNotificationTemplateChoices() {
      return this.taskNotificationTemplates.map((t) => {
        const {
          task_notification_template_id: tId,
          task_notification_template_name: name,
        } = t;
        return {
          name,
          value: tId,
        };
      });
    },
    taskTypeChoices() {
      return this.taskTypes.map((t) => {
        const { name, task_type_id: value } = t;
        return { name, value };
      });
    },
  },
  data() {
    return {
      mdiClose,
      mdiCalendar,
      mdiPencil,
      mdiDotsVertical,
      mdiPlus,
      mdiDelete,
      task: {
        formDefId: "",
        assignedTo: "",
        description: "",
        due: "",
        taskNotificationTemplateId: "",
      },
      users: [],
      forms: [],
      taskNotificationTemplates: [],
      taskTypes: [],
      isOnline: navigator.onLine,
      selectedMapService: {},
      ASSIGN_TO,
      ASSIGN_TO_CHOICES,
      MANUALLY_ADD_EMAIL,
      assignTo: undefined,
      sharedSiteContactEmail: "",
      taskAssignedTo: {},
      showNotificationTemplateDialog: false,
      showEditNotificationTemplateDialog: false,
      selectedNotificationTemplate: {},
    };
  },
  methods: {
    async editSelectedNotificationTemplate() {
      const taskNotificationTemplate = this.taskNotificationTemplates.find(
        (t) =>
          t.task_notification_template_id ===
          this.task.taskNotificationTemplateId
      );
      this.selectedNotificationTemplate = taskNotificationTemplate;
      this.showEditNotificationTemplateDialog = true;
    },
    async deleteSelectedNotificationTemplate() {
      await axiosWithJwtAuth.delete(
        `${APIURL}/shared_site/task_notification_template/${this.task.taskNotificationTemplateId}/${this.$route.query.siteId}`
      );
      await this.getTaskNotificationTemplates();
    },
    async getMapService() {
      if (!this.selectedMapServiceId || !this.$route.query.siteId) {
        return;
      }
      const {
        data: { results: mapService },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/map_services/${this.selectedMapServiceId}/${this.$route.query.siteId}`
      );
      this.selectedMapService = mapService;
    },
    async saveTask() {
      const success = await this.$refs.form.validate();
      if (!success) {
        return;
      }
      const { selectedMapServiceId, globalId, objectId, task } = this;

      const { description, due, taskNotificationTemplateId, formDefId } = task;

      const payload = {
        map_service_id: selectedMapServiceId,
        feature_id: objectId,
        description,
        assigned_to: this.currentUser?.user_id,
        due,
        assignee_closable: true,
        task_notification_template_id: taskNotificationTemplateId
          ? taskNotificationTemplateId
          : undefined,
        task_type_id: this.taskTypeChoices.find(
          (tt) => tt.name === "User Created Task"
        )?.value,
        form_definition_id: formDefId,
        globalId,
        status: "Open",
        site_id: this.$route.query.siteId,
      };
      await axiosWithJwtAuth.post(`${APIURL}/shared_site/tasks`, payload);
      this.$emit("add-task-success");
    },
    async getUsers() {
      const {
        data: { results },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/users/${this.$route.query.siteId}`
      );
      this.users = results;
    },
    async getTaskNotificationTemplates() {
      const {
        data: { results },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/task_notification_templates/${this.$route.query.siteId}`
      );
      this.taskNotificationTemplates = results;
    },
    async getForms() {
      if (!this.selectedMapServiceId || !this.$route.query.siteId) {
        return;
      }
      const {
        data: { results: mapService },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/map_services/${this.selectedMapServiceId}/${this.$route.query.siteId}`
      );
      this.forms = mapService?.forms ?? [];
    },
    async getTaskTypes() {
      const {
        data: { results },
      } = await axiosWithJwtAuth.get(`${APIURL}/shared_site/task_types`);
      this.taskTypes = results;
    },
    onNetworkStatusChange() {
      this.isOnline = navigator.onLine;
    },
  },
  async beforeMount() {
    await this.getUsers();
    await this.getTaskNotificationTemplates();
    await this.getForms();
    await this.getTaskTypes();
    await this.getMapService();
    window.addEventListener("online", this.onNetworkStatusChange);
    window.addEventListener("offline", this.onNetworkStatusChange);
  },
  beforeDestroy() {
    window.removeEventListener("online", this.onNetworkStatusChange);
    window.removeEventListener("offline", this.onNetworkStatusChange);
  },
};
</script>

<style scoped>
.half {
  width: 50%;
}
</style>
