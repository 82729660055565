<template>
  <v-dialog
    v-model="showSharedSiteNotLicensedDialog"
    max-width="600"
    close
    persistent
  >
    <v-card>
      <v-card-text class="py-3">
        <p class="pt-5">
          {{ currentUserOrg.name }} is not licensed to allow site contacts to
          complete this operation. If you would like this enabled, please let
          your contact at {{ currentUserOrg.name }} know of your interest.
        </p>
      </v-card-text>
      <v-card-actions class="pa-5 d-flex justify-end">
        <v-btn text @click="$emit('close')" color="#3F51B5"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SharedSiteNotLicensedDialog",
  props: {
    showSharedSiteNotLicensedDialog: Boolean,
    currentUserOrg: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>
