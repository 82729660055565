<template>
  <v-dialog :value="showEditItemDialog" max-width="500px" persistent>
    <form @submit.prevent="saveActionItem">
      <validation-observer ref="actionItemForm">
        <v-card>
          <v-toolbar dark class="elevation-0" width="100%" color="#3F51B5">
            <div>Edit Action Item</div>
            <v-spacer />
            <v-btn icon @click="$emit('edit-action-item-dialog-close')">
              <v-icon>{{ mdiClose }}</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text
            style="background-color: #fafafa; max-height: 60vh"
            class="overflow-y-auto"
          >
            <div class="d-flex align-center justify-space-between pb-3">
              <div class="caption">Action Item</div>

              <v-menu
                offset-y
                v-if="actionItem.status !== ACTION_ITEM_STATUSES.CANCELED"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" class="px-0 mx-0">
                    <v-icon>{{ mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-list class="px-0 mx-0">
                  <v-list-item class="px-0 mx-0">
                    <v-btn
                      text
                      @click="showConfirmActionItemCancelDialog = true"
                    >
                      <span class="font-weight-regular">
                        Cancel Action Item
                      </span>
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>

            <ConfirmActionItemCancelDialog
              :showConfirmActionItemCancelDialog="
                showConfirmActionItemCancelDialog
              "
              @cancel-confirm-cancel="showConfirmActionItemCancelDialog = false"
              @confirm-cancel="
                actionItem.status = 'canceled';
                showConfirmActionItemCancelDialog = false;
                saveActionItem();
              "
            />

            <v-card>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <div class="half">
                      <p class="caption py-0 my-0">Status</p>
                      <div class="body-1 black--text font-weight-normal">
                        {{ actionItem.status | actionItemStatus }}
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12">
                    <div class="half">
                      <p class="caption my-0 py-0">Created</p>
                      <div class="body-1 black--text font-weight-normal">
                        {{ actionItem.created_on | formatDateTime }}
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" class="my-0 py-0">
                    <div class="half">
                      <validation-provider
                        v-slot="{ errors, valid }"
                        name="Due By"
                      >
                        <v-menu>
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-on="on"
                              v-model="actionItem.due_by"
                              :error-messages="errors"
                              :success="valid"
                              color="#3F51B5"
                              label="Due Date"
                            >
                              <template #append>
                                <v-icon>
                                  {{ mdiCalendar }}
                                </v-icon>
                              </template>
                            </v-text-field>
                          </template>
                          <v-date-picker v-model="actionItem.due_by">
                          </v-date-picker>
                        </v-menu>
                      </validation-provider>
                    </div>
                  </v-col>

                  <v-col cols="12" class="my-0 py-0">
                    <validation-provider
                      v-slot="{ errors, valid }"
                      name="Assigned To"
                      rules="email"
                    >
                      <v-text-field
                        v-model="actionItem.assignee_email"
                        :error-messages="errors"
                        :success="valid"
                        color="#3F51B5"
                        label="Assigned To"
                      />
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" class="my-0 py-0">
                    <validation-provider
                      v-slot="{ errors, valid }"
                      name="Description"
                    >
                      <v-text-field
                        v-model="actionItem.action_item_description"
                        :error-messages="errors"
                        :success="valid"
                        color="#3F51B5"
                        label="Description"
                      />
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" class="my-0 pb-3 px-3">
                    <p class="caption">Photos</p>
                    <div class="d-flex gap" v-if="assignerPhotos.length > 0">
                      <img
                        v-for="p of assignerPhotos"
                        :key="p"
                        :src="`data:image/jpeg+png+svg+xml;base64,${p}`"
                      />
                    </div>
                    <div
                      class="d-flex justify-center body-1 font-weight-normal"
                      v-else
                    >
                      No Photos Uploaded
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-card-text>

          <v-card-actions class="d-flex justify-end p-3 gap">
            <v-btn
              color="#3F51B5"
              :dark="!saving"
              type="submit"
              :disabled="saving"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </validation-observer>
    </form>
  </v-dialog>
</template>

<script>
import { mdiClose, mdiCalendar, mdiPencil, mdiDotsVertical } from "@mdi/js";
import ACTION_ITEM_STATUSES from "@/constants/actionItemStatuses";
import ConfirmActionItemCancelDialog from "@/components/mapView/action-items-tab/action-item-dialog/edit-action-item-dialog/ConfirmActionItemCancelDialog";
import moment from "moment";
import { axiosWithJwtAuth } from "@/plugins/axios";

const APIURL = process.env.VUE_APP_API_URL;

const STATUS_CHOICES = [
  { name: "Canceled", value: ACTION_ITEM_STATUSES.CANCELED },
];

export default {
  name: "EditActionItemDialog",
  data() {
    return {
      mdiClose,
      mdiCalendar,
      mdiPencil,
      mdiDotsVertical,
      ACTION_ITEM_STATUSES,
      STATUS_CHOICES,
      actionItem: {},
      assignerPhotos: [],
      editing: false,
      users: [],
      showConfirmActionItemCancelDialog: false,
      saving: false,
    };
  },
  components: {
    ConfirmActionItemCancelDialog,
  },
  props: {
    showEditItemDialog: Boolean,
    selectedActionItem: Object,
  },
  methods: {
    async getUsers() {
      const {
        data: { results },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/users/${this.$route.query.siteId}`
      );
      this.users = results;
    },
    async saveActionItem() {
      const success = await this.$refs.actionItemForm.validate();
      if (!success) {
        return;
      }
      this.saving = true;
      const {
        status,
        due_by: dueBy,
        assignee_email: assigneeEmail,
        action_item_description: actionItemDescription,
      } = this.actionItem;

      const assigneeUserId = this.users.find(
        (u) => u.email?.trim() === assigneeEmail?.trim()
      )?.user_id;

      const payload = {
        status,
        due_by: moment(dueBy).utc().format("YYYY-MM-DD"),
        assignee_user_id: assigneeUserId,
        assignee_email: assigneeEmail,
        action_item_description: actionItemDescription,
      };
      await axiosWithJwtAuth.put(
        `${APIURL}/shared_site/action_item/details/${this.selectedActionItem.action_item_id}/${this.$route.query.siteId}`,
        payload
      );
      this.$emit("action-item-saved");
      this.saving = false;
    },
    loadActionItem(selectedActionItem) {
      this.actionItem = {
        ...selectedActionItem,
        due_by: moment(selectedActionItem.due_by).utc().format("YYYY-MM-DD"),
      };
    },
    async getActionItemDetails() {
      if (!this.selectedActionItem?.action_item_id) {
        return;
      }
      const {
        data: { results },
      } = await axiosWithJwtAuth.get(
        `${APIURL}/shared_site/action_item/${this.selectedActionItem.action_item_id}/${this.$route.query.siteId}`
      );
      const { assigner_photos: aPhotos } = results;
      const assignerPhotoIds = aPhotos.map((p) => p.file_id);
      const assignerPhotos = await Promise.all(
        assignerPhotoIds.map(async (fId) => {
          const { data } = await axiosWithJwtAuth.get(
            `${APIURL}/shared_site/files/${fId}/resize`,
            {
              params: {
                max_size: 128,
              },
            }
          );
          return data;
        })
      );
      this.assignerPhotos = assignerPhotos;
    },
  },
  async beforeMount() {
    await this.loadActionItem(this.selectedActionItem);
    await this.getActionItemDetails();
    await this.getUsers();
  },
  watch: {
    selectedActionItem: {
      deep: true,
      async handler(val) {
        await this.loadActionItem(val);
        await this.getActionItemDetails();
      },
    },
  },
};
</script>

<style scoped>
.half {
  width: 50%;
}
</style>
