var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("p", { staticClass: "caption" }, [
        _vm._v(_vm._s(_vm.item.question.label))
      ]),
      !_vm.readOnly
        ? _c("file-pond", {
            ref: "files",
            attrs: {
              name: "files",
              "label-idle": "Drop files here...",
              "allow-multiple": _vm.item.allowMultiple,
              files: _vm.uploadFiles,
              allowFileSizeValidation: "",
              maxFileSize: "2MB",
              disabled:
                _vm.readOnly ||
                (!_vm.item.allowMultiple && _vm.files.length === 1)
            },
            on: { addfile: _vm.onChange }
          })
        : _vm._e(),
      _vm._l(_vm.files, function(file, index) {
        return _c(
          "v-card",
          { key: file.id },
          [
            _c(
              "v-card-text",
              [
                _c(
                  "v-list-item",
                  [
                    _c(
                      "v-list-item-content",
                      [
                        _c(
                          "v-card",
                          {
                            staticClass:
                              "d-flex flex-row justify-start elevation-0"
                          },
                          [
                            _c(
                              "v-card",
                              {
                                staticClass:
                                  "elevation-0 d-flex flex-column justify-center mr-2"
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(file.fileName || file.description) +
                                    " "
                                )
                              ]
                            ),
                            _vm.item.question.showDescription
                              ? _c(
                                  "v-card",
                                  {
                                    staticClass: "elevation-0",
                                    attrs: { width: "100%" }
                                  },
                                  [
                                    _c("v-text-field", {
                                      key: file.id,
                                      attrs: {
                                        label: "Description",
                                        color: "#3F51B5",
                                        name: file.id
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-list-item-action",
                      [
                        _c(
                          "v-list-item-action-text",
                          [
                            _c(
                              "v-menu",
                              {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function(ref) {
                                        var on = ref.on
                                        var attrs = ref.attrs
                                        return [
                                          _c(
                                            "v-btn",
                                            _vm._g(
                                              _vm._b(
                                                { attrs: { icon: "" } },
                                                "v-btn",
                                                attrs,
                                                false
                                              ),
                                              on
                                            ),
                                            [
                                              _c("v-icon", [
                                                _vm._v(
                                                  _vm._s(_vm.mdiDotsVertical)
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              },
                              [
                                _c(
                                  "v-list",
                                  [
                                    _c(
                                      "v-list-item",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.removeFile(
                                              index,
                                              file.id
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c(
                                          "v-list-item-title",
                                          [
                                            _c("v-icon", [
                                              _vm._v(_vm._s(_vm.mdiDelete))
                                            ]),
                                            _vm._v(" Delete ")
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }