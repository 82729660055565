var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { "max-width": "600", close: "", persistent: "" },
      model: {
        value: _vm.showSharedSiteNotLicensedDialog,
        callback: function($$v) {
          _vm.showSharedSiteNotLicensedDialog = $$v
        },
        expression: "showSharedSiteNotLicensedDialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-text", { staticClass: "py-3" }, [
            _c("p", { staticClass: "pt-5" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.currentUserOrg.name) +
                  " is not licensed to allow site contacts to complete this operation. If you would like this enabled, please let your contact at " +
                  _vm._s(_vm.currentUserOrg.name) +
                  " know of your interest. "
              )
            ])
          ]),
          _c(
            "v-card-actions",
            { staticClass: "pa-5 d-flex justify-end" },
            [
              _c(
                "v-btn",
                {
                  attrs: { text: "", color: "#3F51B5" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v(" Close ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }