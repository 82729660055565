<template>
  <div class="d-flex justify-space-between flex-grow-1 gap">
    <div class="mt-2 flex-grow-1">
      <validation-provider v-slot="{ errors, valid }" name="Image URL">
        <v-text-field
          v-model="imageOptions.imageUrl"
          :error-messages="errors"
          :success="valid"
          label="Image URL"
          color="#3F51B5"
          @change="onImageUrlChange"
        >
        </v-text-field>
      </validation-provider>
    </div>

    <div class="mt-2">
      <p class="pb-1 px-1 my-0 caption">Symbol Size</p>
      <v-slider
        :max="40"
        :min="0"
        hide-details
        v-model="imageOptions.size"
        @input="$emit('image-options-changed', imageOptions)"
      >
        <template v-slot:append>
          <v-text-field
            v-model="imageOptions.size"
            readonly
            class="number-input my-0 py-0 mt-n2"
            hide-details
            @input="$emit('image-options-changed', imageOptions)"
          />
        </template>
      </v-slider>
    </div>
  </div>
</template>

<script>
import {
  mdiClose,
  mdiChevronDown,
  mdiVectorRectangle,
  mdiVectorPolygon,
  mdiEllipseOutline,
} from "@mdi/js";
import SHAPE_TYPES from "@/constants/shapeTypes";

const colorChoices = [
  { label: "Black", value: "black", fileNameColor: "Black" },
  { label: "Blue", value: "#2196F3", fileNameColor: "Blue" },
  { label: "Green", value: "green", fileNameColor: "Green" },
  { label: "Orange", value: "orange", fileNameColor: "Orange" },
  { label: "Purple", value: "purple", fileNameColor: "Purple" },
  { label: "Red", value: "red", fileNameColor: "Red" },
  { label: "Yellow", value: "yellow", fileNameColor: "Yellow" },
];

const symbols = [
  "CircleLargeB",
  "DiamondLargeB",
  "Pin1LargeB",
  "Pin2LargeB",
  "SquareLargeB",
  "StarLargeB",
];

export default {
  name: "SharedSiteImageForm",
  props: {
    imageOptionsObj: Object,
  },
  data() {
    return {
      mdiClose,
      mdiChevronDown,
      mdiVectorRectangle,
      mdiVectorPolygon,
      mdiEllipseOutline,
      SHAPE_TYPES,
      imageOptions: {
        imageUrl: "",
        size: 30,
      },
      colorChoices,
      symbols,
    };
  },
  beforeMount() {
    this.imageOptions = { ...this.imageOptionsObj };
  },
  methods: {
    onImageUrlChange() {
      const canvas = document.createElement("canvas");
      const img = new Image();
      img.src = this.imageOptions.imageUrl;
      img.crossOrigin = "anonymous";
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        const imageDataURL = canvas.toDataURL("image/png");
        this.imageOptions = {
          ...this.imageOptions,
          imageDataURL,
        };
        this.$emit("image-options-changed", this.imageOptions);
      };
    },
  },
};
</script>
